import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../../components/Page";
import { FormRowContainer } from "../../../../../components/ui/Form/FormRowContainer";
import { LargeCenteredDetailsLayout } from "../../../../../components/ui/Layout/LargeCenteredDetailsLayout";
import { Typography } from "../../../../../components/ui/Typography";
import { useQuery } from "../../../../../hooks/useQuery";
import {
  APPLICATION_ROUTES,
  useOrganizationIdParam,
} from "../../../../../paths";
import { SingleTriggerAccelerationDetails_Query } from "./__generated__/SingleTriggerAccelerationDetails_Query.graphql";

const QUERY = graphql`
  query SingleTriggerAccelerationDetails_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      name
      ...LargeCenteredDetailsLayout_Organization
    }
  }
`;

export default function SingleTriggerAccelerationDetails() {
  const organizationId = useOrganizationIdParam();

  const { query } = useQuery<SingleTriggerAccelerationDetails_Query>(QUERY, {
    organizationId,
  });

  const parentLink = generatePath(
    APPLICATION_ROUTES["organizationEquityConfigureAcceleration"],
    {
      organizationId,
    },
  );

  return (
    <Page
      analyticsCategory="Admin Configure Equity"
      analyticsName="Admin - Equity - Configure - Single trigger acceleration details"
      organizationId={organizationId}
      title={`Admin | ${query.organization.name} organization equity acceleration settings | Single trigger acceleration details`}
    >
      <LargeCenteredDetailsLayout
        organizationFragment={query.organization}
        parents={[
          {
            link: parentLink,
            title: "Acceleration",
          },
        ]}
        subtitle="Here are the terms of the Single trigger acceleration"
        title="Single trigger acceleration"
      >
        <FormRowContainer title="Terms">
          <Typography as="div" variant="Regular/Small">
            In the event that a Change in Control (as defined below) occurs
            during the Optionee&apos;s continuous Service (as defined in the
            Plan), then this Option will become vested as to one hundred percent
            (100%) of the shares subject to this Option then unvested, effective
            immediately prior to the consummation of the Change in Control.
            <br />
            <br />
            “Change in Control” shall have the meaning ascribed thereto in the
            Plan. In case the Plan does not include a definition of Change in
            Control, Change in Control shall have the meaning ascribed to the
            terms “Sale Event”, “Change of Control” or “Corporate Transaction”.
            In the event the Plan does not include a definition of any of the
            above terms, Change in Control means the consummation of (i) the
            dissolution or liquidation of the Company, (ii) the sale of all or
            substantially all of the assets of the Company on a consolidated
            basis to an unrelated person or entity, (iii) a merger,
            reorganization or consolidation pursuant to which the holders of the
            Company&apos;s outstanding voting power immediately prior to such
            transaction do not own a majority of the outstanding voting power of
            the surviving or resulting entity (or its ultimate parent, if
            applicable), (iv) the acquisition of all or a majority of the
            outstanding stock of the Company in a single transaction or a series
            of related transactions by a Person or group of Persons, or (v) any
            other acquisition of the business of the Company, as determined by
            the Board; provided, however, that the Company&apos;s Initial Public
            Offering, any subsequent public offering or another capital raising
            event, or a merger effected solely to change the Company&apos;s
            domicile shall constitute a “Change in Control”.
          </Typography>
        </FormRowContainer>
      </LargeCenteredDetailsLayout>
    </Page>
  );
}
