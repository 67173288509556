/**
 * @generated SignedSource<<dc776e66fa602b5b7ffa1ee484b5be11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UpdateGranteePortalSettingsAttributes = {
  displayFullyDilutedValues: boolean;
  showProjectionScenarios: boolean;
};
export type EmployeePortalTopSection_UpdateGranteePortalSettingsMutation$variables = {
  attributes: UpdateGranteePortalSettingsAttributes;
  organizationId: string;
};
export type EmployeePortalTopSection_UpdateGranteePortalSettingsMutation$data = {
  readonly updateGranteePortalSettings: {
    readonly granteePortalSettings: {
      readonly displayFullyDilutedValues: boolean;
    };
  };
};
export type EmployeePortalTopSection_UpdateGranteePortalSettingsMutation$rawResponse = {
  readonly updateGranteePortalSettings: {
    readonly granteePortalSettings: {
      readonly displayFullyDilutedValues: boolean;
    };
    readonly id: string;
  };
};
export type EmployeePortalTopSection_UpdateGranteePortalSettingsMutation = {
  rawResponse: EmployeePortalTopSection_UpdateGranteePortalSettingsMutation$rawResponse;
  response: EmployeePortalTopSection_UpdateGranteePortalSettingsMutation$data;
  variables: EmployeePortalTopSection_UpdateGranteePortalSettingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "attributes"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "GranteePortalSettings",
  "kind": "LinkedField",
  "name": "granteePortalSettings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayFullyDilutedValues",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmployeePortalTopSection_UpdateGranteePortalSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "updateGranteePortalSettings",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmployeePortalTopSection_UpdateGranteePortalSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "updateGranteePortalSettings",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "99c7ced46eaca65f69e3276db2ee8114",
    "id": null,
    "metadata": {},
    "name": "EmployeePortalTopSection_UpdateGranteePortalSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation EmployeePortalTopSection_UpdateGranteePortalSettingsMutation(\n  $attributes: UpdateGranteePortalSettingsAttributes!\n  $organizationId: OrganizationId!\n) {\n  updateGranteePortalSettings(attributes: $attributes, organizationId: $organizationId) {\n    granteePortalSettings {\n      displayFullyDilutedValues\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb19caf9eb63c5b02afabe95c369de55";

export default node;
