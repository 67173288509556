import classNames from "classnames";
import { AnimatePresence, motion } from "motion/react";
import { ComponentProps } from "react";
import { FormattedNumberParts, useIntl } from "react-intl";
import { usePreviousDistinct } from "react-use";

export function FormattedNumberAnimated({
  className,
  value,
  ...props
}: Omit<ComponentProps<typeof FormattedNumberParts>, "children" | "value"> & {
  className?: string;
  value: number;
}) {
  const intl = useIntl();
  const previousValue = usePreviousDistinct(value);
  const goingUp = previousValue && value > previousValue;
  const formattedValue = intl.formatNumber(value, props);

  return (
    <span className="relative">
      <AnimatePresence>
        <motion.span
          animate={{ opacity: 1, scale: 1, y: 0 }}
          className={classNames("absolute", className)}
          exit={{ opacity: 0, scale: 0.9, y: goingUp ? "50%" : "-50%" }}
          initial={{ opacity: 0, scale: 0.9, y: goingUp ? "-50%" : "50%" }}
          key={`${previousValue}-${value}`}
        >
          {formattedValue}
        </motion.span>
      </AnimatePresence>
      <span className={classNames("invisible", className)}>
        {formattedValue}
      </span>
    </span>
  );
}
