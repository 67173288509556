import { ChevronDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { ReactNode } from "react";
import { useMedia } from "react-use";

import { useBoolean } from "../../hooks/useBoolean";
import { Typography } from "./Typography";

function FAQOrderedList({ children }: React.PropsWithChildren) {
  return <ol className="my-4 ml-4 list-decimal">{children}</ol>;
}

function FAQStrongText({ children }: React.PropsWithChildren) {
  return <Typography variant="Medium/Extra Small">{children}</Typography>;
}

function FAQUnorderedList({ children }: React.PropsWithChildren) {
  return <ul className="my-4 ml-4 list-disc">{children}</ul>;
}

const FAQQuestion_: React.FC<{
  children: ReactNode;
  question: string;
}> = ({ children, question }) => {
  const mediaIsPrint = useMedia("print");
  const { toggle: toggleAccordion, value: accordionIsOpen } = useBoolean(false);
  const accordionIsShown = mediaIsPrint || accordionIsOpen;

  return (
    <div>
      <div>
        <button
          className="flex w-full items-center gap-4 rounded-lg bg-gray-01 p-4 text-left"
          onClick={toggleAccordion}
        >
          <Typography className="flex-grow" variant="Medium/Small">
            {question}
          </Typography>

          <ChevronDownIcon
            className={classNames(
              "h-5 w-5 flex-shrink-0 transition-all print:hidden",
              {
                "rotate-180": accordionIsOpen,
              },
            )}
          />
        </button>

        <div
          className={classNames("py-2 text-black", {
            hidden: !accordionIsShown,
          })}
        >
          <Typography className="space-y-4" variant="Regular/Extra Small">
            {children}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export const FAQQuestion = Object.assign(FAQQuestion_, {
  OrderedList: FAQOrderedList,
  StrongText: FAQStrongText,
  UnorderedList: FAQUnorderedList,
});
