import {
  Calendar2,
  HomeTrendUp,
  ReceiptDiscount,
  RefreshCircle,
  StatusUp,
} from "iconsax-react";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";

import { NonEmptyArray } from "../../helpers/ts-utlity";
import { APPLICATION_ROUTES } from "../../paths";
import { useSections_Grantee$key } from "./__generated__/useSections_Grantee.graphql";

export function generateEmployeePortalSectionAnchorPath({
  anchor,
  granteeId,
}: {
  anchor: SectionAnchor;
  granteeId: string;
}): string {
  return `${generatePath(APPLICATION_ROUTES.employeePortal, {
    granteeId,
  })}#${anchor}`;
}

export const ALL_SECTIONS = {
  exercise: {
    anchor: "exercise",
    icon: <RefreshCircle variant="Bulk" />,
    label: "Exercise",
  },
  exerciseDetails: {
    anchor: "exercise-details",
    label: "Exercise details",
  },
  grantDetails: {
    anchor: "grant-details",
    icon: <StatusUp variant="Bulk" />,
    label: "Grant details",
  },
  ownership: {
    anchor: "ownership",
    icon: <HomeTrendUp variant="Bulk" />,
    label: "Ownership",
  },
  reportingAndGeneralTaxation: {
    anchor: "reporting-and-general-taxation",
    icon: <ReceiptDiscount variant="Bulk" />,
    label: "Reporting & General taxation",
  },
  reportingAndGeneralTaxationDetails: {
    anchor: "reporting-and-general-taxation-details",
    label: "Reporting and general taxation details",
  },
  vestingTimeline: {
    anchor: "vesting-timeline",
    icon: <Calendar2 variant="Bulk" />,
    label: "Vesting timeline",
  },
  whatDoYouHave: {
    anchor: "what-do-you-have",
    label: "What do you have",
  },
} as const;

const GRANTEE_FRAGMENT = graphql`
  fragment useSections_Grantee on Grantee {
    useSectionsNonVirtualCTMSGrants: ctmsGrants(
      grantStatusIn: [Active, Terminated]
      grantType: real
    ) {
      __typename
    }
  }
`;

export interface Section {
  anchor: SectionAnchor;
  icon?: React.ReactElement;
  label: SectionLabel;
}

export type SectionAnchor =
  (typeof ALL_SECTIONS)[keyof typeof ALL_SECTIONS]["anchor"];
export type SectionLabel =
  (typeof ALL_SECTIONS)[keyof typeof ALL_SECTIONS]["label"];

export function scrollToEmployeePortalSection(anchor: SectionAnchor) {
  const element = document.getElementById(anchor);

  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

export function useSections({
  granteeFragment,
}: {
  granteeFragment: useSections_Grantee$key;
}): NonEmptyArray<Section> {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  if (!grantee.useSectionsNonVirtualCTMSGrants.length) {
    return [
      ALL_SECTIONS.ownership,
      ALL_SECTIONS.grantDetails,
      ALL_SECTIONS.vestingTimeline,
      ALL_SECTIONS.reportingAndGeneralTaxation,
    ];
  }

  return [
    ALL_SECTIONS.ownership,
    ALL_SECTIONS.grantDetails,
    ALL_SECTIONS.vestingTimeline,
    ALL_SECTIONS.exercise,
    ALL_SECTIONS.reportingAndGeneralTaxation,
  ];
}
