import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { z } from "zod";

import { Page } from "../../components/Page";
import { Button } from "../../components/ui/Button";
import { FormRow } from "../../components/ui/Form/FormRow";
import { Input } from "../../components/ui/Form/Inputs/Input";
import { InfoCard } from "../../components/ui/InfoCard";
import { LogoWithoutText } from "../../components/ui/Logo/Logo";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import { APPLICATION_ROUTES } from "../../paths";
import { AuthenticationAPIClient } from "../../services/AuthenticationAPIClient";

const formSchema = z.object({
  email: z.string().trim().email(),
});

export type FormInputs = z.infer<typeof formSchema>;

const ForgotPasswordPage_: React.FC = () => {
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  const navigate = useNavigate();

  const [failure, setFailure] = useState<"EMAIL_NOT_FOUND" | null>(null);

  const onSubmit = form.handleSubmit(async (_formData) => {
    const formData = _formData as FormInputs;
    setFailure(null);

    const resetPasswordResult = await AuthenticationAPIClient.resetPassword({
      email: formData.email,
    });

    if (resetPasswordResult.outcome === "FAILURE") {
      setFailure(resetPasswordResult.reason);
    } else {
      void navigate(
        generatePath(APPLICATION_ROUTES.forgotPasswordSuccess, {}),
        {
          replace: true,
        },
      );
    }
  });

  return (
    <AuthLayout.Card
      logo={<LogoWithoutText />}
      subtitle="No worries, we’ll send you reset instructions."
      title="You forgot your password?"
    >
      {failure === "EMAIL_NOT_FOUND" && (
        <InfoCard title="Email does not exist." variant="warning">
          This email does not seem to exist.
        </InfoCard>
      )}
      <form className="space-y-6" onSubmit={onSubmit}>
        <FormRow
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          error={form.formState.errors.email?.message?.toString()}
          id="email"
          label="Email address"
        >
          <Input
            autoComplete="email"
            placeholder="Enter your email"
            type="email"
            {...form.register("email")}
          />
        </FormRow>

        <Button
          className="mt-6"
          fullWidth
          loading={form.formState.isSubmitting}
          type="submit"
        >
          Reset my password
        </Button>
      </form>
    </AuthLayout.Card>
  );
};

const ForgotPasswordPage: React.FC = () => {
  return (
    <Page
      analyticsCategory="Authentication"
      analyticsName="Authentication - Reset password"
      title="Authentication - Reset passord"
    >
      <ForgotPasswordPage_ />
    </Page>
  );
};

export default ForgotPasswordPage;
