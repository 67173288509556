import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useTransition,
} from "react";

interface Controller<Data, Result> {
  open: (props: { data: Data; onClose?: (result: Result) => void }) => void;
  transitionInProgress: boolean;
}

type State<Data, Result> =
  | {
      data: Data;
      onClose?: (result: Result) => void;
      show: true;
    }
  | {
      data?: Data;
      onClose?: (result: Result) => void;
      show: false;
    };

export function makeRemoteController<Data, Result = void>({
  render,
}: {
  render: (props: {
    close: (result: Result) => void;
    reset: () => void;
    state: State<Data, Result>;
  }) => React.ReactNode;
}) {
  const context = createContext<Controller<Data, Result> | null>(null);

  function useController() {
    const contextValue = useContext(context);

    if (!contextValue) {
      throw new Error(
        "useSlideOverController must be used inside a SlideOverControllerProvider",
      );
    }

    return contextValue;
  }

  function Controller({
    render,
  }: {
    render: (props: Controller<Data, Result>) => React.ReactNode;
  }) {
    const controller = useContext(context);

    if (!controller) {
      return (
        <Provider>
          <Controller render={render} />
        </Provider>
      );
    }

    return <>{render(controller)}</>;
  }

  function Provider({
    children,
    onClose,
  }: React.PropsWithChildren<{ onClose?: (result: Result) => void }>) {
    const [state, setState] = useState<State<Data, Result>>({ show: false });
    const [transitionInProgress, startTransition] = useTransition();

    const open = useCallback(
      ({
        data,
        onClose,
      }: {
        data: Data;
        onClose?: (result: Result) => void;
      }) => {
        startTransition(() => {
          setState({
            data,
            onClose,
            show: true,
          });
        });
      },
      [],
    );

    const close = useCallback(
      (result: Result) => {
        setState((previousState) => {
          onClose?.(result);
          previousState.onClose?.(result);

          return {
            ...previousState,
            show: false,
          };
        });
      },
      [onClose],
    );

    const reset = useCallback(() => {
      setState((previousState) => ({
        ...previousState,
        data: undefined,
        show: false,
      }));
    }, []);

    const renderProps = useMemo(
      () => ({
        close,
        reset,
        state,
      }),
      [close, state, reset],
    );

    const contextValue = useMemo(
      () => ({ open, transitionInProgress }),
      [open, transitionInProgress],
    );

    return (
      <context.Provider value={contextValue}>
        {render(renderProps)}
        {children}
      </context.Provider>
    );
  }

  return {
    Controller,
    Provider,
    useController,
  };
}
