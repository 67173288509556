/**
 * @generated SignedSource<<2f0eabe3f8a96a337133e82b634ecdff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AwardSuperType = "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type CTMSGrantVestingStatus = "fully_vested" | "terminated" | "vesting";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalExerciseDetailsSection_Grantee$data = {
  readonly nonVirtualCTMSGrants: ReadonlyArray<{
    readonly cumulativeVested: number;
    readonly earlyExercise: boolean;
    readonly exercisableNonExercisedAmount: number | null;
    readonly exercisableShares: number;
    readonly exercisableUntilDate: string | null;
    readonly exercisePrice: number | null;
    readonly grantDate: string;
    readonly label: string;
    readonly matchingInstrument: {
      readonly awardSuperType: AwardSuperType;
      readonly taxResidenceCountry: {
        readonly emoji: string;
      };
      readonly workRelationship: WorkRelationship;
    } | null;
    readonly postTerminationExercisePeriod: {
      readonly displayName: string;
    } | null;
    readonly quantityExercised: number;
    readonly quantityIssued: number;
    readonly vestingEndDate: string | null;
    readonly vestingStatus: CTMSGrantVestingStatus;
  }>;
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"useOrganizationCTMS_Organization">;
  };
  readonly " $fragmentType": "EmployeePortalExerciseDetailsSection_Grantee";
};
export type EmployeePortalExerciseDetailsSection_Grantee$key = {
  readonly " $data"?: EmployeePortalExerciseDetailsSection_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalExerciseDetailsSection_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalExerciseDetailsSection_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useOrganizationCTMS_Organization"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "nonVirtualCTMSGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "grantStatusIn",
          "value": [
            "Active",
            "Terminated"
          ]
        },
        {
          "kind": "Literal",
          "name": "grantType",
          "value": "real"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "ASC",
            "field": "vestingStartDate"
          }
        }
      ],
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantityIssued",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grantDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vestingStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cumulativeVested",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "exercisePrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "exercisableNonExercisedAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "exercisableShares",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "earlyExercise",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantityExercised",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "exercisableUntilDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vestingEndDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "postTerminationExercisePeriod",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Instrument",
          "kind": "LinkedField",
          "name": "matchingInstrument",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Country",
              "kind": "LinkedField",
              "name": "taxResidenceCountry",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "emoji",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "workRelationship",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "awardSuperType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "ctmsGrants(grantStatusIn:[\"Active\",\"Terminated\"],grantType:\"real\",orderBy:{\"direction\":\"ASC\",\"field\":\"vestingStartDate\"})"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "78f425f1659a0577a0da78c18ad08650";

export default node;
