/**
 * @generated SignedSource<<cb53e9f51f9c5d9ab49b08a62849dedf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type FairMarketValueStatus = "Approved" | "Draft" | "PendingBoardConsent" | "PendingReview" | "Reviewed";
import { FragmentRefs } from "relay-runtime";
export type AdvancedApplicationSidebar_Organization$data = {
  readonly adminInvitationIsAllowed: boolean;
  readonly draftEasopGrants: ReadonlyArray<{
    readonly __typename: "EasopGrant";
  }>;
  readonly fairMarketValueHistory: ReadonlyArray<{
    readonly status: FairMarketValueStatus;
  }>;
  readonly granteeCleanupSuggestionsCount: number;
  readonly id: string;
  readonly implementationCTMSGrantAmendmentRequests: ReadonlyArray<{
    readonly __typename: "CTMSGrantAmendmentRequest";
  }>;
  readonly implementationGranteeTerminationCTMSGrantAmendmentRequests: ReadonlyArray<{
    readonly __typename: "GranteeTerminationCTMSGrantAmendmentRequest";
  }>;
  readonly isConnectedToCarta: boolean;
  readonly isOriginatingFromRemoteEquity: boolean;
  readonly pendingApprovalCTMSGrantAmendmentRequests: ReadonlyArray<{
    readonly __typename: "CTMSGrantAmendmentRequest";
  }>;
  readonly pendingApprovalFairMarketValueBoardConsents: ReadonlyArray<{
    readonly __typename: "FairMarketValueBoardConsent";
  }>;
  readonly pendingApprovalGranteeTerminationCTMSGrantAmendmentRequests: ReadonlyArray<{
    readonly __typename: "GranteeTerminationCTMSGrantAmendmentRequest";
  }>;
  readonly pendingBoardConsentEasopGrants: ReadonlyArray<{
    readonly __typename: "EasopGrant";
  }>;
  readonly pendingCtmsImplementationEasopGrants: ReadonlyArray<{
    readonly __typename: "EasopGrant";
  }>;
  readonly pendingReviewEasopGrants: ReadonlyArray<{
    readonly __typename: "EasopGrant";
  }>;
  readonly planIsFreemium: boolean;
  readonly readyForConsentCTMSGrantAmendmentRequests: ReadonlyArray<{
    readonly __typename: "CTMSGrantAmendmentRequest";
  }>;
  readonly readyForConsentGranteeTerminationCTMSGrantAmendmentRequests: ReadonlyArray<{
    readonly __typename: "GranteeTerminationCTMSGrantAmendmentRequest";
  }>;
  readonly reviewedEasopGrants: ReadonlyArray<{
    readonly __typename: "EasopGrant";
  }>;
  readonly shouldShowExerciseHandler: boolean;
  readonly underReviewCTMSGrantAmendmentRequests: ReadonlyArray<{
    readonly __typename: "CTMSGrantAmendmentRequest";
  }>;
  readonly underReviewGranteeTerminationCTMSGrantAmendmentRequests: ReadonlyArray<{
    readonly __typename: "GranteeTerminationCTMSGrantAmendmentRequest";
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationSideBar_Organization">;
  readonly " $fragmentType": "AdvancedApplicationSidebar_Organization";
};
export type AdvancedApplicationSidebar_Organization$key = {
  readonly " $data"?: AdvancedApplicationSidebar_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdvancedApplicationSidebar_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "statusIn",
    "value": [
      "UNDER_REVIEW"
    ]
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "statusIn",
    "value": [
      "READY_FOR_CONSENT"
    ]
  }
],
v3 = [
  {
    "kind": "Literal",
    "name": "statusIn",
    "value": [
      "PENDING_APPROVAL"
    ]
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "statusIn",
    "value": [
      "IMPLEMENTATION"
    ]
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdvancedApplicationSidebar_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "granteeCleanupSuggestionsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planIsFreemium",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shouldShowExerciseHandler",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminInvitationIsAllowed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValueBoardConsent",
      "kind": "LinkedField",
      "name": "pendingApprovalFairMarketValueBoardConsents",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "fairMarketValueHistory",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToCarta",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOriginatingFromRemoteEquity",
      "storageKey": null
    },
    {
      "alias": "draftEasopGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusIn": [
              "Draft"
            ]
          }
        }
      ],
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "easopGrants(filters:{\"statusIn\":[\"Draft\"]})"
    },
    {
      "alias": "pendingReviewEasopGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusIn": [
              "PendingReview"
            ]
          }
        }
      ],
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "easopGrants(filters:{\"statusIn\":[\"PendingReview\"]})"
    },
    {
      "alias": "reviewedEasopGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusIn": [
              "Reviewed"
            ]
          }
        }
      ],
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "easopGrants(filters:{\"statusIn\":[\"Reviewed\"]})"
    },
    {
      "alias": "pendingBoardConsentEasopGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusIn": [
              "PendingBoardConsent"
            ]
          }
        }
      ],
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "easopGrants(filters:{\"statusIn\":[\"PendingBoardConsent\"]})"
    },
    {
      "alias": "pendingCtmsImplementationEasopGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusIn": [
              "PendingCtmsImplementation"
            ]
          }
        }
      ],
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "easopGrants(filters:{\"statusIn\":[\"PendingCtmsImplementation\"]})"
    },
    {
      "alias": "underReviewGranteeTerminationCTMSGrantAmendmentRequests",
      "args": (v1/*: any*/),
      "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "granteeTerminationCTMSGrantAmendmentRequests",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "granteeTerminationCTMSGrantAmendmentRequests(statusIn:[\"UNDER_REVIEW\"])"
    },
    {
      "alias": "readyForConsentGranteeTerminationCTMSGrantAmendmentRequests",
      "args": (v2/*: any*/),
      "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "granteeTerminationCTMSGrantAmendmentRequests",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "granteeTerminationCTMSGrantAmendmentRequests(statusIn:[\"READY_FOR_CONSENT\"])"
    },
    {
      "alias": "pendingApprovalGranteeTerminationCTMSGrantAmendmentRequests",
      "args": (v3/*: any*/),
      "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "granteeTerminationCTMSGrantAmendmentRequests",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "granteeTerminationCTMSGrantAmendmentRequests(statusIn:[\"PENDING_APPROVAL\"])"
    },
    {
      "alias": "implementationGranteeTerminationCTMSGrantAmendmentRequests",
      "args": (v4/*: any*/),
      "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "granteeTerminationCTMSGrantAmendmentRequests",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "granteeTerminationCTMSGrantAmendmentRequests(statusIn:[\"IMPLEMENTATION\"])"
    },
    {
      "alias": "underReviewCTMSGrantAmendmentRequests",
      "args": (v1/*: any*/),
      "concreteType": "CTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "ctmsGrantAmendmentRequests",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "ctmsGrantAmendmentRequests(statusIn:[\"UNDER_REVIEW\"])"
    },
    {
      "alias": "readyForConsentCTMSGrantAmendmentRequests",
      "args": (v2/*: any*/),
      "concreteType": "CTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "ctmsGrantAmendmentRequests",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "ctmsGrantAmendmentRequests(statusIn:[\"READY_FOR_CONSENT\"])"
    },
    {
      "alias": "pendingApprovalCTMSGrantAmendmentRequests",
      "args": (v3/*: any*/),
      "concreteType": "CTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "ctmsGrantAmendmentRequests",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "ctmsGrantAmendmentRequests(statusIn:[\"PENDING_APPROVAL\"])"
    },
    {
      "alias": "implementationCTMSGrantAmendmentRequests",
      "args": (v4/*: any*/),
      "concreteType": "CTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "ctmsGrantAmendmentRequests",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "ctmsGrantAmendmentRequests(statusIn:[\"IMPLEMENTATION\"])"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplicationSideBar_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "804138afd23c541525c88b8a7323418f";

export default node;
