/**
 * @generated SignedSource<<99f1fd6e596734ca7f87ac689eba8b1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettingsIntegrations_Organization$data = {
  readonly id: string;
  readonly isConnectedToCarta: boolean;
  readonly isConnectedToDeel: boolean;
  readonly isOriginatingFromRemoteEquity: boolean;
  readonly name: string;
  readonly " $fragmentType": "OrganizationSettingsIntegrations_Organization";
};
export type OrganizationSettingsIntegrations_Organization$key = {
  readonly " $data"?: OrganizationSettingsIntegrations_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsIntegrations_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationSettingsIntegrations_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToDeel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToCarta",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOriginatingFromRemoteEquity",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "627f742a6e79dbe985113b5c67bfddc1";

export default node;
