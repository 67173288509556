import classNames from "classnames";
import { debounce, noop } from "lodash";
import { Suspense, useCallback, useMemo, useState, useTransition } from "react";
import { useFragment, usePaginationFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { LoadMoreButton } from "../../../components/ui/LoadMoreButton";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { SearchBar } from "../../../components/ui/SearchBar";
import { SkeletonWrapper } from "../../../components/ui/SkeletonWrapper";
import { Switch } from "../../../components/ui/Switch";
import { Table } from "../../../components/ui/Table";
import { EmployeePortalsSettingsBody_Deferred_Organization_PaginatedGrantees$key } from "./__generated__/EmployeePortalsSettingsBody_Deferred_Organization_PaginatedGrantees.graphql";
import {
  EmployeePortalsSettingsBody_Deferred_Organization_PaginatedGrantees_RefetchQuery,
  EmployeePortalsSettingsBody_Deferred_Organization_PaginatedGrantees_RefetchQuery$variables,
} from "./__generated__/EmployeePortalsSettingsBody_Deferred_Organization_PaginatedGrantees_RefetchQuery.graphql";
import { EmployeePortalsSettingsBody_Employees$key } from "./__generated__/EmployeePortalsSettingsBody_Employees.graphql";
import { EmployeePortalsSettingsBody_Organization$key } from "./__generated__/EmployeePortalsSettingsBody_Organization.graphql";
import { EmployeeTableRow, EmployeeTableRowUI } from "./EmployeeTableRow";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EmployeePortalsSettingsBody_Organization on Organization {
    ...EmployeePortalsSettingsBody_Deferred_Organization_PaginatedGrantees
      @defer
  }
`;

const ORGANIZATION_PAGINATED_GRANTEES_FRAGMENT = graphql`
  fragment EmployeePortalsSettingsBody_Deferred_Organization_PaginatedGrantees on Organization
  @refetchable(
    queryName: "EmployeePortalsSettingsBody_Deferred_Organization_PaginatedGrantees_RefetchQuery"
  )
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 20 }
    employeesHRISProvider: { type: "HRISProvider", defaultValue: null }
    employeesHRISProviderNotIn: { type: "[HRISProvider!]", defaultValue: null }
    employeesSearch: { type: "String", defaultValue: "" }
  ) {
    id
    paginatedGrantees: grantees(
      first: $count
      after: $cursor
      filters: {
        hRISProvider: $employeesHRISProvider
        hRISProviderNotIn: $employeesHRISProviderNotIn
        search: $employeesSearch
        canBeInvitedOrHasEmployeePortalAccess: true
      }
    )
      @connection(
        key: "EmployeePortalsSettingsBody_Deferred_Organization_paginatedGrantees"
      ) {
      edges {
        node {
          ...EmployeePortalsSettingsBody_Employees
        }
      }
    }
  }
`;

const ORIGINATION_SWITCH_OPTIONS = ["ALL", "REMOTE", "OTHERS"] as const;
type OriginationSwitchOption = (typeof ORIGINATION_SWITCH_OPTIONS)[number];
const ORIGINATION_SWITCH_OPTION_LABELS = {
  ALL: "All",
  OTHERS: "Others",
  REMOTE: "Remote employees",
};

export function DeferredEmployeePortalsSettingsBody({
  organizationFragment,
}: {
  organizationFragment: EmployeePortalsSettingsBody_Deferred_Organization_PaginatedGrantees$key;
}) {
  const {
    data: organization,
    hasNext,
    isLoadingNext,
    loadNext,
    refetch: refetchOrganizationFragment,
  } = usePaginationFragment<
    EmployeePortalsSettingsBody_Deferred_Organization_PaginatedGrantees_RefetchQuery,
    EmployeePortalsSettingsBody_Deferred_Organization_PaginatedGrantees$key
  >(ORGANIZATION_PAGINATED_GRANTEES_FRAGMENT, organizationFragment);
  const [originationSwitchOption, setOriginationSwitchOption] =
    useState<OriginationSwitchOption>("ALL");

  const [search, setSearch] = useState("");
  const [transitionInProgress, startTransition] = useTransition();

  const handleFiltersChange = useCallback(
    (
      updatedFilters: Partial<{
        originationSwitchOption: OriginationSwitchOption;
        search: string;
      }>,
    ) => {
      startTransition(() => {
        refetchOrganizationFragment(
          getRefetchVariables({
            originationSwitchOption,
            search,
            ...updatedFilters,
          }),
        );
      });
    },
    [originationSwitchOption, refetchOrganizationFragment, search],
  );

  const handleSwitchChange = useCallback(
    (option: OriginationSwitchOption) => {
      setOriginationSwitchOption(option);
      handleFiltersChange({
        originationSwitchOption: option,
      });
    },
    [handleFiltersChange],
  );

  const debouncedOnSearchChange = useMemo(
    () =>
      debounce((search: string) => {
        handleFiltersChange({
          search,
        });
      }, 300),
    [handleFiltersChange],
  );

  const handleSearchChange = useCallback(
    (search: string) => {
      setSearch(search);
      debouncedOnSearchChange(search);
    },
    [debouncedOnSearchChange],
  );

  const paginatedGrantees = useMemo(() => {
    return organization.paginatedGrantees.edges.map(({ node }) => node);
  }, [organization.paginatedGrantees.edges]);

  const handleLoadNext = useCallback(() => {
    loadNext(20);
  }, [loadNext]);

  return (
    <EmployeePortalsSettingsBodyUI
      employeesTable={
        paginatedGrantees.length > 0 ? (
          <EmployeesTable
            employeesFragment={paginatedGrantees}
            hasNext={hasNext}
            isLoading={transitionInProgress}
            isLoadingNext={isLoadingNext}
            loadNext={handleLoadNext}
            organizationId={organization.id}
          />
        ) : (
          <NoticeMessage size="Small" variant="Info">
            No employees found. Try changing your filters.
          </NoticeMessage>
        )
      }
      filtersAreUpdating={transitionInProgress}
      onSearchChange={handleSearchChange}
      onSwitchChange={handleSwitchChange}
      originationSwitchValue={originationSwitchOption}
      search={search}
    />
  );
}

export function EmployeePortalsSettingsBody({
  organizationFragment,
}: {
  organizationFragment: EmployeePortalsSettingsBody_Organization$key;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Suspense
      fallback={
        <EmployeePortalsSettingsBodyUI
          employeesTable={
            <EmployeesTableUI
              tableRows={
                <>
                  <EmployeeTableRowUI
                    employeeEmail="john.doe@gmail.com"
                    employeeHasPortalAccess={false}
                    employeeHasVisitedTheirPortal={false}
                    employeeJobTitle="Master of the Universe"
                    employeeName="John Doe"
                    employeeTaxResidenceCountryEmoji="🇺🇸"
                    employeeWorkRelationship="DirectEmployee"
                    skeleton
                  />
                  <EmployeeTableRowUI
                    employeeEmail="jean.dupont@gmail.com"
                    employeeHasPortalAccess={true}
                    employeeHasVisitedTheirPortal={true}
                    employeeJobTitle="King of the World"
                    employeeName="Jean Dupont"
                    employeeTaxResidenceCountryEmoji="🇫🇷"
                    employeeWorkRelationship="ContractorManagementCompany"
                    skeleton
                  />
                  <EmployeeTableRowUI
                    employeeEmail="giovanni.giovanni@gmail.com"
                    employeeHasPortalAccess={true}
                    employeeHasVisitedTheirPortal={false}
                    employeeJobTitle="Emperor of the Galaxy"
                    employeeName="Giovanni Giovanni"
                    skeleton
                  />
                </>
              }
            />
          }
          skeleton
        />
      }
    >
      <DeferredEmployeePortalsSettingsBody
        organizationFragment={organization}
      />
    </Suspense>
  );
}

function EmployeePortalsSettingsBodyUI({
  employeesTable,
  filtersAreUpdating,
  onSearchChange = noop,
  onSwitchChange = noop,
  originationSwitchValue = "ALL",
  search,
  skeleton,
}: {
  employeesTable: React.ReactNode;
  filtersAreUpdating?: boolean;
  onSearchChange?: (search: string) => void;
  onSwitchChange?: (option: OriginationSwitchOption) => void;
  originationSwitchValue?: OriginationSwitchOption;
  search?: string;
  skeleton?: boolean;
}) {
  return (
    <div className="space-y-6">
      <div className="space-y-6">
        <div className="border-b-[0.5px] border-grey-300">
          <div className="inline-block border-b-2 border-grey-900 py-2 text-SM/Medium text-grey-700">
            Employees
          </div>
        </div>
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <SkeletonWrapper reveal={!skeleton}>
              <Switch
                getOptionValue={(option) =>
                  ORIGINATION_SWITCH_OPTION_LABELS[option]
                }
                loading={filtersAreUpdating}
                name="origination"
                onChange={onSwitchChange}
                options={ORIGINATION_SWITCH_OPTIONS}
                selectedOption={originationSwitchValue}
              />
            </SkeletonWrapper>
          </div>
          <div>
            <SkeletonWrapper reveal={!skeleton}>
              <SearchBar
                loading={filtersAreUpdating}
                onChange={onSearchChange}
                placeholder="Search employees..."
                value={search}
              />
            </SkeletonWrapper>
          </div>
        </div>
      </div>
      {employeesTable}
    </div>
  );
}

function getOriginationSwitchOptionRefetchVariables(
  originationSwitchOption: OriginationSwitchOption,
): Partial<EmployeePortalsSettingsBody_Deferred_Organization_PaginatedGrantees_RefetchQuery$variables> {
  switch (originationSwitchOption) {
    case "ALL":
      return {};
    case "OTHERS":
      return {
        employeesHRISProvider: null,
        employeesHRISProviderNotIn: ["REMOTE"],
      };
    case "REMOTE":
      return {
        employeesHRISProvider: "REMOTE",
        employeesHRISProviderNotIn: null,
      };
  }
}

function getRefetchVariables({
  originationSwitchOption,
  search,
}: {
  originationSwitchOption: OriginationSwitchOption;
  search: string;
}): Partial<EmployeePortalsSettingsBody_Deferred_Organization_PaginatedGrantees_RefetchQuery$variables> {
  return {
    ...getOriginationSwitchOptionRefetchVariables(originationSwitchOption),
    employeesSearch: search,
  };
}

const EMPLOYEES_FRAGMENT = graphql`
  fragment EmployeePortalsSettingsBody_Employees on Grantee
  @relay(plural: true) {
    id
    ...EmployeeTableRow_Employees
  }
`;

function EmployeesTable({
  employeesFragment,
  hasNext,
  isLoading,
  isLoadingNext,
  loadNext,
  organizationId,
}: {
  employeesFragment: EmployeePortalsSettingsBody_Employees$key;
  hasNext: boolean;
  isLoading?: boolean;
  isLoadingNext: boolean;
  loadNext: () => void;
  organizationId: string;
}) {
  const employees = useFragment(EMPLOYEES_FRAGMENT, employeesFragment);

  return (
    <EmployeesTableUI
      hasNext={hasNext}
      isLoading={isLoading}
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
      tableRows={employees.map((employee) => (
        <EmployeeTableRow
          employeeFragment={employee}
          key={employee.id}
          organizationId={organizationId}
        />
      ))}
    />
  );
}

function EmployeesTableUI({
  hasNext,
  isLoading,
  isLoadingNext,
  loadNext = noop,
  tableRows,
}: {
  hasNext?: boolean;
  isLoading?: boolean;
  isLoadingNext?: boolean;
  loadNext?: () => void;
  tableRows: React.ReactNode;
}) {
  return (
    <>
      <Table.Containerized
        className={classNames("grid-cols-[1fr_1fr_1fr_auto]", {
          "animate-pulse": isLoading,
        })}
        display="grid"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Employee</Table.HeaderCell>
            <Table.HeaderCell>Position</Table.HeaderCell>
            <Table.HeaderCell alignRight>Access</Table.HeaderCell>
            <Table.HeaderCell alignRight>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{tableRows}</Table.Body>
      </Table.Containerized>
      {hasNext && (
        <div className="flex justify-center">
          <LoadMoreButton
            loading={isLoadingNext}
            onLoadMoreRequest={loadNext}
          />
        </div>
      )}
    </>
  );
}
