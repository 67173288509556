import { IconUmbrella } from "@remote-com/norma/icons/IconUmbrella";
import { IconV2OutlineBriefcase } from "@remote-com/norma/icons/IconV2OutlineBriefcase";
import { IconV2OutlineCalculator } from "@remote-com/norma/icons/IconV2OutlineCalculator";
import { IconV2OutlineColumns2 } from "@remote-com/norma/icons/IconV2OutlineColumns2";
import { IconV2OutlineFilesText } from "@remote-com/norma/icons/IconV2OutlineFilesText";
import { IconV2OutlineFolderOpen } from "@remote-com/norma/icons/IconV2OutlineFolderOpen";
import { IconV2OutlineHome } from "@remote-com/norma/icons/IconV2OutlineHome";
import { IconV2OutlinePieChart } from "@remote-com/norma/icons/IconV2OutlinePieChart";
import { IconV2OutlineSlidersVertical } from "@remote-com/norma/icons/IconV2OutlineSlidersVertical";
import { IconV2OutlineUsers } from "@remote-com/norma/icons/IconV2OutlineUsers";
import { useEffect, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath, useMatch } from "react-router-dom";

import { APPLICATION_ROUTES } from "../../paths";
import { ApplicationSideBar } from "../ui/ApplicationSideBar/ApplicationSideBar";
import { PoweredByCarta } from "../ui/PoweredByCarta/PoweredByCarta";
import { BasicApplicationSidebar_Organization$key } from "./__generated__/BasicApplicationSidebar_Organization.graphql";
import { BasicApplicationSidebar_Viewer$key } from "./__generated__/BasicApplicationSidebar_Viewer.graphql";

const VIEWER_FRAGMENT = graphql`
  fragment BasicApplicationSidebar_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...ApplicationSideBar_Viewer
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment BasicApplicationSidebar_Organization on Organization {
    id
    isConnectedToCarta
    ...ApplicationSideBar_Organization
  }
`;

export const BasicApplicationSidebar = ({
  organizationFragment,
  viewerFragment,
}: {
  organizationFragment: BasicApplicationSidebar_Organization$key;
  viewerFragment: BasicApplicationSidebar_Viewer$key;
}) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const isOnSettingsSection = !!useMatch(
    `/organizations/:organizationId/settings/*`,
  );

  const [unfoldedSection, setUnfoldedSection] = useState<"settings" | null>(
    null,
  );

  useEffect(() => {
    if (isOnSettingsSection) {
      setUnfoldedSection("settings");
      return;
    }
    setUnfoldedSection(null);
  }, [isOnSettingsSection]);

  return (
    <ApplicationSideBar
      activeRole="Admin"
      bottomFixedContent={
        organization.isConnectedToCarta && (
          <PoweredByCarta className="m-auto h-full" />
        )
      }
      homePath={generatePath(APPLICATION_ROUTES.organizationHome, {
        organizationId: organization.id,
      })}
      organizationFragment={organization}
      viewerFragment={viewer}
    >
      <ApplicationSideBar.NavLink
        end
        icon={<IconV2OutlineColumns2 />}
        to={generatePath(APPLICATION_ROUTES.organizationHome, {
          organizationId: organization.id,
        })}
      >
        Dashboard
      </ApplicationSideBar.NavLink>

      <ApplicationSideBar.Section title="Equity">
        <ApplicationSideBar.NavLink
          icon={<IconV2OutlineUsers />}
          to={generatePath(APPLICATION_ROUTES.organizationGrantees, {
            organizationId: organization.id,
          })}
        >
          Team overview
        </ApplicationSideBar.NavLink>
        <ApplicationSideBar.NavLink
          icon={<IconV2OutlinePieChart />}
          to={generatePath(APPLICATION_ROUTES.organizationEquityGrants, {
            organizationId: organization.id,
          })}
        >
          Equity grants
        </ApplicationSideBar.NavLink>
        <ApplicationSideBar.NavLink
          icon={<IconUmbrella />}
          to={generatePath(
            APPLICATION_ROUTES.organizationEmployeePortalsSettings,
            {
              organizationId: organization.id,
            },
          )}
        >
          Employee portals
        </ApplicationSideBar.NavLink>
        <ApplicationSideBar.NavLink
          icon={<IconV2OutlineBriefcase />}
          to={generatePath(
            APPLICATION_ROUTES.organizationToolsEquityOffersSettings,
            {
              organizationId: organization.id,
            },
          )}
        >
          Equity offers
        </ApplicationSideBar.NavLink>
        <ApplicationSideBar.NavLink
          icon={<IconV2OutlineSlidersVertical />}
          to={generatePath(APPLICATION_ROUTES.organizationEquityConfigure, {
            organizationId: organization.id,
          })}
        >
          Configure equity
        </ApplicationSideBar.NavLink>
      </ApplicationSideBar.Section>

      <ApplicationSideBar.Section title="Tax handling">
        <ApplicationSideBar.NavLink
          icon={<IconV2OutlineFilesText />}
          to={generatePath(APPLICATION_ROUTES.organizationExercises, {
            organizationId: organization.id,
          })}
        >
          Exercise requests
        </ApplicationSideBar.NavLink>
      </ApplicationSideBar.Section>

      <ApplicationSideBar.Section title="Tools">
        <ApplicationSideBar.NavLink
          icon={<IconV2OutlineCalculator />}
          to={generatePath(
            APPLICATION_ROUTES.organizationSettingsSafeCalculator,
            {
              organizationId: organization.id,
            },
          )}
        >
          SAFE converter
        </ApplicationSideBar.NavLink>
      </ApplicationSideBar.Section>

      <ApplicationSideBar.Section title="Company">
        <ApplicationSideBar.NavLink
          icon={<IconV2OutlineFolderOpen />}
          to={generatePath(APPLICATION_ROUTES.organizationDocuments, {
            organizationId: organization.id,
          })}
        >
          Documents
        </ApplicationSideBar.NavLink>
        {viewer.isAllowedToManageOrganization && (
          <ApplicationSideBar.FoldableItem
            icon={<IconV2OutlineHome />}
            isOpen={unfoldedSection === "settings"}
            label="Company settings"
            onToggle={() => {
              setUnfoldedSection(
                unfoldedSection !== "settings" ? "settings" : null,
              );
            }}
          >
            <ApplicationSideBar.NavLink
              end
              isSubLink
              to={generatePath(APPLICATION_ROUTES.organizationSettings, {
                organizationId: organization.id,
              })}
            >
              Organization
            </ApplicationSideBar.NavLink>
            <ApplicationSideBar.NavLink
              isSubLink
              to={generatePath(APPLICATION_ROUTES.organizationSettingsAdmins, {
                organizationId: organization.id,
              })}
            >
              Admins
            </ApplicationSideBar.NavLink>
            <ApplicationSideBar.NavLink
              isSubLink
              to={generatePath(
                APPLICATION_ROUTES.organizationSettingsEquityViewers,
                {
                  organizationId: organization.id,
                },
              )}
            >
              Equity viewers
            </ApplicationSideBar.NavLink>
            <ApplicationSideBar.NavLink
              isSubLink
              to={generatePath(APPLICATION_ROUTES.organizationSettingsBilling, {
                organizationId: organization.id,
              })}
            >
              Plan & Billing
            </ApplicationSideBar.NavLink>
          </ApplicationSideBar.FoldableItem>
        )}
      </ApplicationSideBar.Section>
    </ApplicationSideBar>
  );
};
