import { createContext, useContext } from "react";

import { SectionLabel } from "./useSections";

export const EmployeePortalContext = createContext<
  EmployeePortalContext | undefined
>(undefined);

export interface EmployeePortalContext {
  containerHeight: number;
  containerScrollY: number;
  setActiveSection: (sectionLabel: SectionLabel) => void;
  setValuationMultiple: (valuationMultiple: number) => void;
  valuationMultiple: number;
}

export function useEmployeePortalContext() {
  const context = useContext(EmployeePortalContext);

  if (!context) {
    throw new Error(
      "useEmployeePortalContext must be used within an EmployeePortalContext.Provider",
    );
  }

  return context;
}
