/**
 * @generated SignedSource<<da7b9d582b91205dc2d6531722d8172d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MatchGranteeView_Organization_RefetchQuery$variables = {
  id: string;
};
export type MatchGranteeView_Organization_RefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"MatchGranteeView_Organization">;
  } | null;
};
export type MatchGranteeView_Organization_RefetchQuery = {
  response: MatchGranteeView_Organization_RefetchQuery$data;
  variables: MatchGranteeView_Organization_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "hRISProvider",
    "value": "REMOTE"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
},
v7 = [
  (v2/*: any*/)
],
v8 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hRISProviderEmployeeId",
    "storageKey": null
  },
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hRISProvider",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "ignored",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Grantee",
    "kind": "LinkedField",
    "name": "grantee",
    "plural": false,
    "selections": (v7/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Grantee",
    "kind": "LinkedField",
    "name": "suggestedGrantee",
    "plural": false,
    "selections": (v7/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MatchGranteeView_Organization_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MatchGranteeView_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MatchGranteeView_Organization_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remoteEquityCTMSSyncCompleted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "HRISProviderEmployee",
                "kind": "LinkedField",
                "name": "unmatchedHRISProviderEmployees",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": "unmatchedHRISProviderEmployees(hRISProvider:\"REMOTE\")"
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "HRISProviderEmployee",
                "kind": "LinkedField",
                "name": "matchedHRISProviderEmployees",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": "matchedHRISProviderEmployees(hRISProvider:\"REMOTE\")"
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "HRISProviderEmployee",
                "kind": "LinkedField",
                "name": "ignoredHRISProviderEmployees",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": "ignoredHRISProviderEmployees(hRISProvider:\"REMOTE\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GranteesConnection",
                "kind": "LinkedField",
                "name": "grantees",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GranteeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Grantee",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v5/*: any*/),
                          (v4/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remoteCompanyId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isConnectedToCarta",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isConnectedToPulley",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isConnectedToAngelList",
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "142c6d7763deed21489901346127fe5f",
    "id": null,
    "metadata": {},
    "name": "MatchGranteeView_Organization_RefetchQuery",
    "operationKind": "query",
    "text": "query MatchGranteeView_Organization_RefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...MatchGranteeView_Organization\n    id\n  }\n}\n\nfragment MatchGranteeTableRow_HRISProviderEmployee on HRISProviderEmployee {\n  hRISProviderEmployeeId\n  name\n  email\n  jobTitle\n  hRISProvider\n  ignored\n  grantee {\n    id\n  }\n  suggestedGrantee {\n    id\n  }\n}\n\nfragment MatchGranteeTableRow_Organization on Organization {\n  id\n  grantees {\n    edges {\n      node {\n        id\n        name\n        email\n        jobTitle\n      }\n    }\n  }\n  ...useTrackRemoteEquityOnboardingFlowEvent_Organization\n}\n\nfragment MatchGranteeTable_HRISProviderEmployee on HRISProviderEmployee {\n  id\n  ...MatchGranteeTableRow_HRISProviderEmployee\n}\n\nfragment MatchGranteeTable_Organization on Organization {\n  id\n  ...MatchGranteeTableRow_Organization\n}\n\nfragment MatchGranteeView_Organization on Organization {\n  remoteEquityCTMSSyncCompleted\n  unmatchedHRISProviderEmployees(hRISProvider: REMOTE) {\n    email\n    name\n    ...MatchGranteeTable_HRISProviderEmployee\n    id\n  }\n  matchedHRISProviderEmployees(hRISProvider: REMOTE) {\n    email\n    name\n    ...MatchGranteeTable_HRISProviderEmployee\n    id\n  }\n  ignoredHRISProviderEmployees(hRISProvider: REMOTE) {\n    email\n    name\n    ...MatchGranteeTable_HRISProviderEmployee\n    id\n  }\n  ...MatchGranteeTable_Organization\n  ...useOrganizationCTMS_Organization\n  ...useTrackRemoteEquityOnboardingFlowEvent_Organization\n  id\n}\n\nfragment useOrganizationCTMS_Organization on Organization {\n  isConnectedToCarta\n  isConnectedToPulley\n  isConnectedToAngelList\n}\n\nfragment useTrackRemoteEquityOnboardingFlowEvent_Organization on Organization {\n  id\n  name\n  remoteCompanyId\n}\n"
  }
};
})();

(node as any).hash = "6bde5ee267a20e82ba1c043e9e608d7b";

export default node;
