import classNames from "classnames";
import { motion } from "motion/react";
import { ForwardedRef, forwardRef } from "react";

const COLORED_BOX_COLORS = {
  blueLight: {
    background: classNames("bg-blueLight-100"),
    text: classNames("text-blueLight-700"),
  },
  green: {
    background: classNames("bg-green-100"),
    text: classNames("text-green-700"),
  },
  purple: {
    background: classNames("bg-purple-100"),
    text: classNames("text-purple-700"),
  },
};

export const ColoredBox = forwardRef(function ColoredBox(
  {
    className,
    color,
    title,
    unit,
    value,
  }: {
    className?: string;
    color: keyof typeof COLORED_BOX_COLORS;
    title: React.ReactNode;
    unit: React.ReactNode;
    value: React.ReactNode;
  },
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <motion.div
      animate={{ opacity: 1, scale: 1 }}
      className={classNames(
        "space-y-2 rounded-remote-lg border-[8px] border-white p-6",
        COLORED_BOX_COLORS[color].background,
        className,
      )}
      exit={{ opacity: 0, scale: 0.9 }}
      initial={{ opacity: 0, scale: 0.9 }}
      layout="preserve-aspect"
      ref={ref}
    >
      <div className="text-SM/SemiBold">{title}</div>
      <div
        className={classNames(
          "space-y-1 font-brand",
          COLORED_BOX_COLORS[color].text,
        )}
      >
        <div className="text-3XL/Medium">{value}</div>
        <div className="text-XL/Medium">{unit}</div>
      </div>
    </motion.div>
  );
});
