import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { BoxedIcon, Button, Pill, Text } from "@remote-com/norma";
import { IconV2DuotoneInfo } from "@remote-com/norma/icons/IconV2DuotoneInfo";
import { IconV2DuotoneListUnordered } from "@remote-com/norma/icons/IconV2DuotoneListUnordered";
import { ArrowDown } from "iconsax-react";
import { forwardRef, PropsWithChildren, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";

import { SectionHero } from "../../../components/SectionHero";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { generateEmployeePortalSectionAnchorPath } from "../useSections";
import { EmployeePortalReportingAndGeneralTaxationSection_Grantee$key } from "./__generated__/EmployeePortalReportingAndGeneralTaxationSection_Grantee.graphql";
import { EmployeePortalReportingAndGeneralTaxationDetailsSection } from "./EmployeePortalReportingAndGeneralTaxationDetailsSection";
import illustration from "./illustration.png";

const GRANTEE_FRAGMENT = graphql`
  fragment EmployeePortalReportingAndGeneralTaxationSection_Grantee on Grantee {
    id
    ...EmployeePortalReportingAndGeneralTaxationDetailsSection_Grantee
  }
`;

export const EmployeePortalReportingAndGeneralTaxationSection = forwardRef<
  HTMLDivElement,
  {
    granteeFragment: EmployeePortalReportingAndGeneralTaxationSection_Grantee$key;
  }
>(function EmployeePortalReportingAndGeneralTaxationSection(
  { granteeFragment },
  ref,
) {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  return (
    <div className="space-y-10" ref={ref}>
      <SectionHero
        aria-labelledby="reporting-and-general-taxation-heading"
        className="bg-background-subtle"
        hideBorderOnMobile
        id="reporting-and-general-taxation"
        role="region"
        withBorder
      >
        <div className="flex items-center gap-10">
          <div className="flex-1 space-y-4">
            <BoxedIcon
              Icon={IconV2DuotoneListUnordered}
              size="lg"
              tone="green"
            />
            <Text
              id="reporting-and-general-taxation-heading"
              variant="3xlMedium"
            >
              Reporting and general taxation
            </Text>
            <Text variant="lgMedium">We made it simple for you</Text>
            <Text className="text-grey-600" variant="sm">
              Reporting to your local tax can be painful. We’re here to help you
              through the process because receiving equity has to be declared
              properly, to the correct authority and at the right moment,
              depending on where you live...
            </Text>
            <Button
              as="a"
              href={generateEmployeePortalSectionAnchorPath({
                anchor: "reporting-and-general-taxation-details",
                granteeId: grantee.id,
              })}
              IconAfter={ArrowDown}
              size="md"
            >
              Learn more
            </Button>
          </div>
          <img className="hidden w-[320px] lg:block" src={illustration} />
        </div>
        <RoundedBox
          background="white"
          className="space-y-6 p-6 @container md:px-14 md:py-10"
          withBorder
        >
          <Disclosure>
            {({ open }) => (
              <>
                <div className="items-center gap-3 space-y-3 @md:flex">
                  <BoxedIcon Icon={IconV2DuotoneInfo} size="sm" tone="cyan" />
                  <Text className="flex-1" variant="lgMedium">
                    What&apos;s in this section of your employee portal and
                    where can you find additional info.
                  </Text>
                  <DisclosureButton>
                    <Button
                      className="!bg-transparent"
                      IconAfter={open ? ChevronUpIcon : ChevronDownIcon}
                      size="sm"
                      tone="primary"
                      variant="outline"
                    >
                      {open ? <>Hide details</> : <>Show details</>}
                    </Button>
                  </DisclosureButton>
                </div>

                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <DisclosurePanel>
                    <div className="rounded-remote-lg bg-background-subtle px-4 py-2 md:px-8 md:py-6">
                      <ValueList>
                        <ValueListItem label="The info you need at each step of the stock options timeline.">
                          <div className="space-y-2">
                            <div>
                              This section explains the general taxation and
                              other legal obligations you&apos;ll likely need to
                              comply with at different times:
                            </div>
                            <div className="grid grid-cols-[minmax(80px,auto)_1fr] gap-2">
                              <div className="col-span-full grid grid-cols-subgrid">
                                <div>
                                  <Pill tone="neutralDark">Grant</Pill>
                                </div>
                                <div>
                                  when you receive your stock options and sign
                                  the legal doc on Carta or Pulley
                                </div>
                              </div>
                              <div className="col-span-full grid grid-cols-subgrid">
                                <div>
                                  <Pill tone="neutralDark">Exercise</Pill>
                                </div>
                                <div>
                                  if and when you decide to exercise your stock
                                  options (usually by paying what is called the
                                  “exercise price” or “strike price”)
                                </div>
                              </div>
                              <div className="col-span-full grid grid-cols-subgrid">
                                <div>
                                  <Pill tone="neutralDark">Sale</Pill>
                                </div>
                                <div>
                                  if and when you have the opportunity to sell
                                  the shares that you&apos;ve acquired by
                                  exercising your stock options
                                </div>
                              </div>
                            </div>
                            <div>
                              This section gives you a snapshot of your legal
                              and tax obligations at each of these moments, as
                              you&apos;ll for sure have to do some stuff and pay
                              taxes at a certain point. It&apos;s particularly
                              useful at the time you wonder whether you should
                              exercise your stock options, as it&apos;s
                              generally then that taxation starts to matter.
                            </div>
                          </div>
                        </ValueListItem>

                        <ValueListItem label="Sharing the info in this section to your personal tax advisor or accountant (if you have one) will help!">
                          This will help them assess your tax liability and give
                          you personal advice based on your own situation. To be
                          complete, we recommend that you also share with them
                          the legal doc (grant notice, award agreement, etc.)
                          that you&apos;ve signed when you were offered the
                          stock options.
                        </ValueListItem>
                        <ValueListItem label="You want an example on the basis of your own equity package?">
                          👉 Have a look at your &ldquo;Taxation - Grantee
                          portal&rdquo;.
                        </ValueListItem>
                        <ValueListItem label="Some terms seem obscure or you need a general recap on how stock options work?">
                          👉 Have a look at the “Overview” section or the FAQ.
                          It’s likely that you’ll find some answers there 🤓.
                        </ValueListItem>
                      </ValueList>
                    </div>
                  </DisclosurePanel>
                </Transition>
              </>
            )}
          </Disclosure>
        </RoundedBox>
      </SectionHero>
      <EmployeePortalReportingAndGeneralTaxationDetailsSection
        granteeFragment={grantee}
      />
    </div>
  );
});

function ValueList({ children }: PropsWithChildren) {
  return <div className="divide-y-[0.5px] divide-grey-300">{children}</div>;
}

function ValueListItem({
  children,
  label,
}: PropsWithChildren<{
  label: ReactNode;
}>) {
  return (
    <div className="flex flex-col gap-x-10 gap-y-2 py-2">
      <Text variant="baseMedium">{label}</Text>
      <Text className="text-grey-600" variant="sm">
        {children}
      </Text>
    </div>
  );
}
