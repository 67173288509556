import {
  EllipsisHorizontalIcon,
  FolderArrowDownIcon,
} from "@heroicons/react/24/outline";
import { Pill } from "@remote-com/norma";
import classNames from "classnames";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { FormattedFMV } from "../../../components/FormattedFMV";
import { ShortDate } from "../../../components/ShortDate";
import { Button } from "../../../components/ui/Button";
import { Checkbox } from "../../../components/ui/Form/Checkbox";
import { LoadingSpinner } from "../../../components/ui/LoadingSpinner";
import { MenuButton } from "../../../components/ui/MenuButton";
import { Table } from "../../../components/ui/Table";
import {
  FairMarketValueApprovalTable_FairMarketValue$data,
  FairMarketValueApprovalTable_FairMarketValue$key,
  FairMarketValueStatus,
} from "./__generated__/FairMarketValueApprovalTable_FairMarketValue.graphql";

const FAIR_MARKET_VALUE_FRAGMENT = graphql`
  fragment FairMarketValueApprovalTable_FairMarketValue on FairMarketValue
  @relay(plural: true) {
    id
    date
    value
    valuationFirm
    status
    valuationReportDocument {
      downloadUrl
    }
  }
`;

const STATUS_TO_LABEL_MAP: Record<FairMarketValueStatus, string> = {
  Approved: "Approved",
  Draft: "Draft",
  PendingBoardConsent: "Pending approval",
  PendingReview: "Under review",
  Reviewed: "Ready for consent",
};

const FairMarketValueStatusCell: React.FC<{
  boardConsentGenerationIsInProgress?: boolean;
  fairMarketValue: FairMarketValueApprovalTable_FairMarketValue$data[number];
}> = ({ boardConsentGenerationIsInProgress, fairMarketValue }) => {
  if (boardConsentGenerationIsInProgress) {
    return (
      <Pill tone="neutralLight">
        <LoadingSpinner className="h-3 w-3 shrink-0" /> Generating board consent
      </Pill>
    );
  } else {
    return (
      <Pill tone="neutralLight">
        {STATUS_TO_LABEL_MAP[fairMarketValue.status]}
      </Pill>
    );
  }
};

export const FairMarketValueApprovalTable: React.FC<
  {
    boardConsentGenerationIsInProgress?: boolean;
    containerized?: boolean;
    fairMarketValuesFragment: FairMarketValueApprovalTable_FairMarketValue$key;
  } & (
    | {
        selectedFairMarketValueId: null | string;
        setSelectedFairMarketValueId: (id: string) => void;
      }
    | {
        selectedFairMarketValueId?: undefined;
        setSelectedFairMarketValueId?: undefined;
      }
  )
> = ({
  boardConsentGenerationIsInProgress,
  containerized,
  fairMarketValuesFragment,
  selectedFairMarketValueId,
  setSelectedFairMarketValueId,
}) => {
  const fairMarketValues = useFragment(
    FAIR_MARKET_VALUE_FRAGMENT,
    fairMarketValuesFragment,
  );

  const Table_ = containerized ? Table.Containerized : Table;

  return (
    <Table_
      className={classNames(
        "min-w-[720px]",
        setSelectedFairMarketValueId
          ? "grid-cols-[auto,1fr,1fr,1fr,1fr,1fr,auto]"
          : "grid-cols-[1fr,1fr,1fr,1fr,1fr,auto]",
      )}
      display="grid"
    >
      <Table.Header>
        <Table.Row className="uppercase">
          {setSelectedFairMarketValueId && <Table.HeaderCell />}
          <Table.HeaderCell>Document</Table.HeaderCell>
          <Table.HeaderCell>Valuation firm</Table.HeaderCell>
          <Table.HeaderCell>Valuation date</Table.HeaderCell>
          <Table.HeaderCell>Fair Market Value</Table.HeaderCell>
          <Table.HeaderCell alignRight>Status</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {fairMarketValues.map((fairMarketValue) => (
          <Table.Row key={fairMarketValue.id}>
            {setSelectedFairMarketValueId && (
              <Table.Cell>
                <Checkbox
                  checked={selectedFairMarketValueId === fairMarketValue.id}
                  onChange={() => {
                    setSelectedFairMarketValueId(fairMarketValue.id);
                  }}
                />
              </Table.Cell>
            )}
            <Table.Cell>
              <Pill tone="neutralLight">409a valuation</Pill>
            </Table.Cell>
            <Table.Cell>{fairMarketValue.valuationFirm}</Table.Cell>
            <Table.Cell>
              <ShortDate value={fairMarketValue.date} />
            </Table.Cell>
            <Table.Cell>
              <FormattedFMV value={fairMarketValue.value} />
            </Table.Cell>
            <Table.Cell className="justify-end">
              <FairMarketValueStatusCell
                boardConsentGenerationIsInProgress={
                  boardConsentGenerationIsInProgress
                }
                fairMarketValue={fairMarketValue}
              />
            </Table.Cell>

            <Table.Cell className="justify-end">
              <MenuButton
                button={
                  <Button
                    leftIcon={<EllipsisHorizontalIcon />}
                    size="extra small"
                    variant="Secondary Full"
                  />
                }
              >
                {fairMarketValue.valuationReportDocument ? (
                  <MenuButton.LinkItem
                    leftIcon={<FolderArrowDownIcon />}
                    target="_blank"
                    to={fairMarketValue.valuationReportDocument.downloadUrl}
                  >
                    Download valuation report
                  </MenuButton.LinkItem>
                ) : (
                  <MenuButton.Item
                    disabled
                    leftIcon={<FolderArrowDownIcon />}
                    onClick={() => null}
                  >
                    Download valuation report
                  </MenuButton.Item>
                )}
              </MenuButton>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table_>
  );
};
