/**
 * @generated SignedSource<<315ae1622154db025ef9f50fb29913e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CTMSGrantVestingStatus = "fully_vested" | "terminated" | "vesting";
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalGrantAndValueDetailsSection_Grantee$data = {
  readonly ctmsGrants: ReadonlyArray<{
    readonly cliffDate: string | null;
    readonly cumulativeVested: number;
    readonly exercisePrice: number | null;
    readonly grantDate: string;
    readonly label: string;
    readonly quantityIssued: number;
    readonly vestingStartDate: string | null;
    readonly vestingStatus: CTMSGrantVestingStatus;
  }>;
  readonly organization: {
    readonly granteePortalSettings: {
      readonly displayFullyDilutedValues: boolean;
    };
    readonly latestPricePerShare: {
      readonly value: number;
    } | null;
  };
  readonly " $fragmentType": "EmployeePortalGrantAndValueDetailsSection_Grantee";
};
export type EmployeePortalGrantAndValueDetailsSection_Grantee$key = {
  readonly " $data"?: EmployeePortalGrantAndValueDetailsSection_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalGrantAndValueDetailsSection_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalGrantAndValueDetailsSection_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PricePerShare",
          "kind": "LinkedField",
          "name": "latestPricePerShare",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteePortalSettings",
          "kind": "LinkedField",
          "name": "granteePortalSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayFullyDilutedValues",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "grantStatusIn",
          "value": [
            "Active",
            "Terminated"
          ]
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "ASC",
            "field": "vestingStartDate"
          }
        }
      ],
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantityIssued",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grantDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vestingStartDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cliffDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vestingStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cumulativeVested",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "exercisePrice",
          "storageKey": null
        }
      ],
      "storageKey": "ctmsGrants(grantStatusIn:[\"Active\",\"Terminated\"],orderBy:{\"direction\":\"ASC\",\"field\":\"vestingStartDate\"})"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "91e9fb3c8279298d0c64ab9c00aa7481";

export default node;
