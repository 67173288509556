import * as configCat from "configcat-js";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

interface FeatureFlag {
  key: string;
  value: boolean;
}

const ConfigCatContext = createContext<null | ReturnType<
  typeof configCat.getClient
>>(null);

export const ConfigCatProvider: React.FC<{
  children: React.ReactNode;
  configCatSDKKey: string;
}> = ({ children, configCatSDKKey }) => {
  const configCatClient = useMemo(
    () =>
      configCat.getClient(configCatSDKKey, configCat.PollingMode.AutoPoll, {
        pollIntervalSeconds: 60,
      }),
    [configCatSDKKey],
  );

  return (
    <ConfigCatContext.Provider value={configCatClient}>
      {children}
    </ConfigCatContext.Provider>
  );
};

const useConfigCatClient = () => {
  const configCatClient = useContext(ConfigCatContext);

  if (!configCatClient) {
    throw new Error("config cat context is not set");
  }

  return configCatClient;
};

const FeatureFlagContext = createContext<FeatureFlag[] | null>(null);

export const FeatureFlagProvider: React.FC<{
  account?: null | { email: string; id: string };
  children: React.ReactNode;
  organizationName?: string;
}> = ({ account, children, organizationName }) => {
  const configCatClient = useConfigCatClient();
  const [state, setState] = useState<
    { featureFlags: FeatureFlag[]; state: "loaded" } | { state: "loading" }
  >({ state: "loading" });

  const reloadConfigCatValues = useCallback(() => {
    configCatClient
      .getAllValuesAsync(
        account
          ? {
              custom: organizationName
                ? { organization: organizationName }
                : {},
              email: account.email,
              identifier: account.id,
            }
          : undefined,
      )
      .then((result) => {
        setState(() => ({
          featureFlags: result.map(({ settingKey, settingValue }) => {
            if (typeof settingValue !== "boolean") {
              throw new Error(
                `feature flags should be boolean values, got "${typeof settingValue}"`,
              );
            }

            return {
              key: settingKey,
              value: settingValue,
            };
          }),
          state: "loaded",
        }));
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [account, configCatClient, organizationName]);

  useEffect(() => {
    reloadConfigCatValues();

    const intervalId = setInterval(reloadConfigCatValues, 1000 * 30);

    return () => {
      clearInterval(intervalId);
    };
  }, [reloadConfigCatValues]);

  if (state.state === "loading") {
    return null;
  }

  return (
    <FeatureFlagContext.Provider value={state.featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const MockFeatureFlagProvider: React.FC<{
  children: React.ReactNode;
  featureFlags?: FeatureFlag[];
}> = ({ children, featureFlags = [] }) => (
  <FeatureFlagContext.Provider value={featureFlags}>
    {children}
  </FeatureFlagContext.Provider>
);

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw new Error("feature flag context is not set");
  }

  return context;
};

const useIsFeatureEnabled = (key: string) => {
  const featureFlags = useFeatureFlags();

  return (
    featureFlags.find((featureFlag) => featureFlag.key === key)?.value ?? false
  );
};

export default useIsFeatureEnabled;
