/**
 * @generated SignedSource<<e3bb419667bd09bafb437aff680cf886>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteePortalSettingsUsageSection_ResendInvitationEmail_Mutation$variables = {
  organizationId: string;
};
export type GranteePortalSettingsUsageSection_ResendInvitationEmail_Mutation$data = {
  readonly resendInvitationEmailToGranteesThatDidNotVisitTheirPortalYet: {
    readonly " $fragmentSpreads": FragmentRefs<"GranteePortalSettingsUsageSection_Organization">;
  };
};
export type GranteePortalSettingsUsageSection_ResendInvitationEmail_Mutation = {
  response: GranteePortalSettingsUsageSection_ResendInvitationEmail_Mutation$data;
  variables: GranteePortalSettingsUsageSection_ResendInvitationEmail_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GranteePortalSettingsUsageSection_ResendInvitationEmail_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "resendInvitationEmailToGranteesThatDidNotVisitTheirPortalYet",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "kind": "FragmentSpread",
            "name": "GranteePortalSettingsUsageSection_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GranteePortalSettingsUsageSection_ResendInvitationEmail_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "resendInvitationEmailToGranteesThatDidNotVisitTheirPortalYet",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Grantee",
            "kind": "LinkedField",
            "name": "granteesThatDidNotVisitTheirPortalYet",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Grantee",
            "kind": "LinkedField",
            "name": "granteesInvitedMoreThanAWeekAgoThatDidNotVisitTheirPortalYet",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GranteesConnection",
            "kind": "LinkedField",
            "name": "grantees",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GranteeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Grantee",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cannotBeInvitedReason",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPortalAccess",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasVisitedTheirPortal",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "workRelationship",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EquityGridLevel",
                        "kind": "LinkedField",
                        "name": "equityGridLevel",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "sortBy",
                            "value": "TaxFavored"
                          },
                          {
                            "kind": "Literal",
                            "name": "sortDirection",
                            "value": "DESC"
                          }
                        ],
                        "concreteType": "Instrument",
                        "kind": "LinkedField",
                        "name": "instruments",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": (v1/*: any*/),
                            "concreteType": "InstrumentValuation",
                            "kind": "LinkedField",
                            "name": "valuation",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "valueInDollars",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "instruments(sortBy:\"TaxFavored\",sortDirection:\"DESC\")"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "defaultVestingSchedule",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastSeenAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstSeenAt",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c1acf901a39a876791ee87209d807ebc",
    "id": null,
    "metadata": {},
    "name": "GranteePortalSettingsUsageSection_ResendInvitationEmail_Mutation",
    "operationKind": "mutation",
    "text": "mutation GranteePortalSettingsUsageSection_ResendInvitationEmail_Mutation(\n  $organizationId: OrganizationId!\n) {\n  resendInvitationEmailToGranteesThatDidNotVisitTheirPortalYet(organizationId: $organizationId) {\n    ...GranteePortalSettingsUsageSection_Organization_4xMPKw\n    id\n  }\n}\n\nfragment GrantFormSlide_DefaultGrantee_4xMPKw on Grantee {\n  id\n  workRelationship\n  equityGridLevel {\n    __typename\n    id\n  }\n  instruments(sortBy: TaxFavored, sortDirection: DESC) {\n    id\n    valuation(organizationId: $organizationId) {\n      valueInDollars\n    }\n  }\n  defaultVestingSchedule {\n    __typename\n    id\n  }\n}\n\nfragment GranteePortalSettingsUsageSection_Organization_4xMPKw on Organization {\n  id\n  granteesThatDidNotVisitTheirPortalYet {\n    __typename\n    id\n  }\n  granteesInvitedMoreThanAWeekAgoThatDidNotVisitTheirPortalYet {\n    __typename\n    id\n  }\n  grantees {\n    edges {\n      node {\n        id\n        cannotBeInvitedReason\n        hasPortalAccess\n        hasVisitedTheirPortal\n        ...NewEquitySelectionModal_DefaultGrantee_4xMPKw\n        ...PreviewGranteesPortalModal_Grantees\n      }\n    }\n  }\n}\n\nfragment NewEquitySelectionModal_DefaultGrantee_4xMPKw on Grantee {\n  id\n  ...GrantFormSlide_DefaultGrantee_4xMPKw\n}\n\nfragment PortalAccessTag_Grantee on Grantee {\n  cannotBeInvitedReason\n  hasPortalAccess\n  hasVisitedTheirPortal\n  lastSeenAt\n  firstSeenAt\n}\n\nfragment PreviewGranteesPortalModal_Grantees on Grantee {\n  id\n  name\n  email\n  ...PreviewGranteesPortalModal_PreviewGranteeRow_Grantees\n  ...RevokeGranteeAccessModal_Grantee\n}\n\nfragment PreviewGranteesPortalModal_PreviewGranteeRow_Grantees on Grantee {\n  id\n  name\n  hasPortalAccess\n  cannotBeInvitedReason\n  hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal\n  ...PortalAccessTag_Grantee\n}\n\nfragment RevokeGranteeAccessModal_Grantee on Grantee {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "f60aa4b480e4af9872b9661d2aab654e";

export default node;
