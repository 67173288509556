import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import ReactSelect, {
  ControlProps,
  CSSObjectWithLabel,
  FormatOptionLabelMeta,
  StylesConfig,
} from "react-select";
import ReactSelectCreatable from "react-select/creatable";

import { getInputWrapperClassName } from "../Input";

const _Control = <Option,>({
  children,
  className,
  innerProps,
  innerRef,
}: ControlProps<Option, false>) => (
  <div
    className={classNames(
      "flex items-center rounded border-none transition-all",

      className,
    )}
    ref={innerRef}
    {...innerProps}
  >
    {children}
  </div>
);

const SelectAutocomplete_ = <Option,>({
  className,
  controlClassName,
  dataCy,
  disabled = false,
  formatCreateLabel,
  formatOptionLabel,
  getOptionLabel,
  getOptionValue,
  id,
  invalid = false,
  leftIcon,
  name,
  noOptionsMessage,
  onChange,
  onCreateOption,
  options,
  placeholder,
  placement = "auto",
  readOnly = false,
  usePortal,
  value,
}: Pick<React.ComponentProps<typeof ReactSelect>, "noOptionsMessage"> & {
  className?: string;
  controlClassName?: string;
  dataCy?: string;
  disabled?: boolean;
  formatCreateLabel?: (inputValue: string) => React.ReactNode;
  formatOptionLabel?: (
    data: Option,
    formatOptionLabelMeta: FormatOptionLabelMeta<Option>,
  ) => React.ReactNode;
  getOptionLabel: (option: Option) => string;
  getOptionValue: (option: Option) => string;
  id?: string;
  invalid?: boolean;
  leftIcon?: React.ReactElement<React.ComponentProps<"svg">>;
  name?: string;
  onChange: (option: null | Option) => void;
  onCreateOption?: (inputValue: string) => void;
  options: readonly Option[];
  placeholder?: string;
  placement?: "auto" | "bottom" | "top";
  readOnly?: boolean;
  usePortal?: boolean;
  value?: null | Option;
}) => {
  const Control = useCallback(
    ({ className, ...props }: ControlProps<Option, false>) => (
      <_Control
        className={classNames("h-[52px]", className, controlClassName)}
        {...props}
      />
    ),
    [controlClassName],
  );

  const props = useMemo(() => {
    return {
      className: classNames("flex-auto", className),
      classNames: {
        menu: () => classNames("!z-20"),
        placeholder: () => "text-gray-08",
        singleValue: () => "text-black-07",
      },
      components: {
        Control,
      },
      formatOptionLabel,
      getOptionLabel,
      getOptionValue,
      isDisabled: readOnly || disabled,
      menuPlacement: placement,
      menuPortalTarget: usePortal ? document.body : undefined,
      name,
      noOptionsMessage,
      onChange,
      options,
      placeholder,
      styles: {
        control: (base: CSSObjectWithLabel) => ({
          ...base,
          borderStyle: "none",
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
          minHeight: "36px",
        }),
        indicatorSeparator: (base: CSSObjectWithLabel) => ({
          ...base,
          marginBottom: "16px",
          marginTop: "16px",
          maxHeight: "20px",
        }),
        input: (base: CSSObjectWithLabel) => ({
          ...base,
          "input:focus": {
            boxShadow: "none",
          },
          paddingLeft: leftIcon ? "22px" : undefined,
        }),
        menuPortal: usePortal
          ? (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 })
          : undefined,
        option: (base: CSSObjectWithLabel) => ({
          ...base,
          fontSize: "0.875rem",
        }),
        placeholder: (base: CSSObjectWithLabel) => ({
          ...base,
          fontFamily: `IBM Plex Sans, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
          fontSize: "0.875rem",
          fontWeight: 400,
          lineHeight: "1.25rem",
          paddingLeft: leftIcon ? "22px" : undefined,
        }),
        singleValue: (base: CSSObjectWithLabel) => ({
          ...base,
          border: "none",
          fontSize: "0.875rem",
          fontWeight: 400,
          "input:focus": {
            border: "none",
            boxShadow: "none",
            outline: "none",
          },
          lineHeight: "1.25rem",
        }),
      } as StylesConfig<Option>,
      value,
    };
  }, [
    leftIcon,
    Control,
    className,
    readOnly,
    disabled,
    formatOptionLabel,
    getOptionLabel,
    getOptionValue,
    name,
    onChange,
    options,
    placeholder,
    placement,
    noOptionsMessage,
    usePortal,
    value,
  ]);

  return (
    <div
      className={classNames(
        "relative flex-auto",
        getInputWrapperClassName({ disabled: readOnly || disabled, invalid }),
        className,
      )}
      data-cy={dataCy}
    >
      {leftIcon &&
        React.cloneElement(leftIcon, {
          "aria-hidden": true,
          className: classNames(
            "pointer-events-none absolute left-2 top-2 w-5",
            leftIcon.props.className,
          ),
        })}
      {onCreateOption ? (
        <ReactSelectCreatable
          formatCreateLabel={formatCreateLabel}
          id={id}
          onCreateOption={onCreateOption}
          {...props}
        />
      ) : (
        <ReactSelect {...props} />
      )}
    </div>
  );
};

const SelectAutocompleteForm = <Option, TFieldValues extends FieldValues>({
  control,
  name,
  ...props
}: Omit<
  React.ComponentProps<typeof SelectAutocomplete_<Option>>,
  "disabled" | "invalid" | "name" | "onChange" | "value"
> & {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        const value = props.options.find(
          (option) => props.getOptionValue(option) === field.value,
        );

        return (
          <SelectAutocomplete
            disabled={field.disabled}
            invalid={fieldState.invalid}
            name={field.name}
            onChange={(vestingOccurrence) => {
              field.onChange(
                vestingOccurrence
                  ? props.getOptionValue(vestingOccurrence)
                  : null,
              );
            }}
            value={value}
            {...props}
          />
        );
      }}
    />
  );
};

const SelectAutocompleteSkeleton: React.FC = () => (
  <div className="h-[36px] animate-pulse rounded bg-gray-02" />
);

export const SelectAutocomplete = Object.assign(SelectAutocomplete_, {
  Form: SelectAutocompleteForm,
  Skeleton: SelectAutocompleteSkeleton,
});
