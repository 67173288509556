import * as Sentry from "@sentry/react";
import { createClient } from "graphql-sse";
import { noop } from "lodash";
import { meros } from "meros/browser";
import { useMemo } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import {
  Environment,
  FetchFunction,
  GraphQLResponse,
  Network,
  Observable,
  SubscribeFunction,
  Variables,
} from "relay-runtime";

import { useRelayStoreContext } from "./RelayStoreContextProvider";

type Part =
  | { data: unknown; hasNext?: boolean }
  | {
      hasNext: boolean;
      incremental: {
        data?: unknown;
        items?: unknown[];
        label: string;
        path: string[];
      }[];
    };

export function fetchSteamedGraphQL({
  attempt,
  operationName,
  query,
  variables,
}: {
  attempt?: number;
  operationName: null | string | undefined;
  query: null | string | undefined;
  variables: Record<string, unknown>;
}): Observable<GraphQLResponse> {
  return Observable.create((sink) => {
    fetchGraphQL({
      attempt,
      operationName,
      query,
      variables,
    })
      .then(async (response) => {
        const parts = await meros(response);

        if (parts instanceof Response) {
          const data = (await parts.json()) as GraphQLResponse;
          sink.next(data);
          sink.complete();
          return;
        }

        for await (const part_ of parts) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (!part_.json) {
            throw new Error("expected json");
          }

          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const part = part_.body as Part;

          if ("incremental" in part) {
            for (const incremental of part.incremental) {
              if (incremental.items) {
                let offset = Number(
                  incremental.path[incremental.path.length - 1],
                );
                const path = incremental.path.slice(0, -1);
                for (const item of incremental.items) {
                  const isLast = offset === incremental.items.length - 1;

                  const payload = {
                    ...incremental,
                    data: item,
                    extensions: {
                      is_final: isLast && !part.hasNext,
                    },
                    hasNext: part.hasNext || !isLast,
                    path: [...path, offset],
                  };

                  sink.next(payload as GraphQLResponse);

                  offset += 1;
                }
              }
              if (incremental.data) {
                const payload = {
                  ...incremental,
                  extensions: {
                    is_final: !part.hasNext,
                  },
                  hasNext: part.hasNext,
                };

                sink.next(payload as GraphQLResponse);
              }
            }
          }

          if ("data" in part) {
            const payload = {
              ...part,
              extensions: {
                is_final: !part.hasNext,
              },
              hasNext: part.hasNext,
            };

            sink.next(payload as GraphQLResponse);
          }
        }

        sink.complete();
      })
      .catch((error: unknown) => {
        sink.error(error as Error);
        throw error;
      });
  });
}

async function fetchGraphQL({
  attempt = 0,
  operationName,
  query,
  variables,
}: {
  attempt?: number;
  operationName: null | string | undefined;
  query: null | string | undefined;
  variables: Record<string, unknown>;
}): Promise<Response> {
  try {
    const response = await fetch("/graphql", {
      body: JSON.stringify({
        operationName,
        query,
        variables,
      }),
      headers: {
        "content-type": "application/json",
      },
      method: "POST",
    });

    Sentry.addBreadcrumb({
      category: "graphql",
      data: {
        httpStatus: response.status,
        operationName,
        query,
        variables,
      },
      message: `GraphQL request: ${operationName}`,
    });

    return response;
  } catch (error) {
    if (attempt < 3) {
      return fetchGraphQL({
        attempt: attempt + 1,
        operationName,
        query,
        variables,
      });
    }

    throw error;
  }
}

const executeQueryOrMutation: FetchFunction = (
  { name: operationName, text: query },
  variables,
) =>
  fetchSteamedGraphQL({
    operationName,
    query,
    variables,
  });

const SSE_SINGLE_CONNECTION_MODE = process.env.NODE_ENV === "development";

const initGraphQLSubscriptionSSEConnection = ({
  onComplete,
  onData,
  onError = noop,
  operationName,
  query,
  variables,
}: {
  onComplete: () => void;
  onData: (data: GraphQLResponse) => void;
  onError?: (event: Event) => void;
  onOpen?: () => void;
  operationName: string;
  query: string;
  variables: Variables;
}) => {
  const client = createClient({
    singleConnection: SSE_SINGLE_CONNECTION_MODE,
    url: SSE_SINGLE_CONNECTION_MODE ? `/graphql/stream` : `/graphql`,
  });

  client.subscribe(
    {
      operationName,
      query,
      variables,
    },
    {
      complete: () => {
        onComplete();
      },
      error: (err) => {
        onError(err as Event);
      },
      next: (data) => {
        onData(data as GraphQLResponse);
      },
    },
  );

  return client;
};

const SSE_MAX_RECONNECT_ATTEMPTS = 10;

const executeSubscription: SubscribeFunction = (
  { name: operationName, text: query },
  variables,
) => {
  if (!query) {
    throw new Error(`Missing query`);
  }

  return Observable.create<GraphQLResponse>((sink) => {
    let connectionAttempts = 0;
    let errored = false;

    const source = initGraphQLSubscriptionSSEConnection({
      onComplete: () => {
        sink.complete();
      },
      onData: (data) => {
        sink.next(data);
      },
      onError: () => {
        connectionAttempts += 1;

        if (connectionAttempts > SSE_MAX_RECONNECT_ATTEMPTS && !errored) {
          errored = true;
          const error = new Error(
            `SSE connection failed, max attempts reached, operationName: ${operationName}`,
          );
          Sentry.captureException(error);
        }
      },
      operationName,
      query,
      variables,
    });

    return () => {
      source.dispose();
    };
  });
};

const network = Network.create(executeQueryOrMutation, executeSubscription);

export const RelayProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const relayStoreContext = useRelayStoreContext();

  const relayEnvironment = useMemo(
    () =>
      new Environment({
        network,
        store: relayStoreContext.store,
      }),
    [relayStoreContext.store],
  );

  return (
    // @ts-expect-error - relay-runtime types are not up-to-date
    <RelayEnvironmentProvider environment={relayEnvironment}>
      {children}
    </RelayEnvironmentProvider>
  );
};
