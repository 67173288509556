import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Pill } from "@remote-com/norma";
import { partition } from "lodash";
import { useState, useTransition } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { isNonEmptyArray } from "../helpers/ts-utlity";
import { useQuery } from "../hooks/useQuery";
import { useSafeMutation } from "../hooks/useSafeMutation";
import { APPLICATION_ROUTES } from "../paths";
import { InviteGranteesModal_GrantAccessToGranteePortal_Mutation } from "./__generated__/InviteGranteesModal_GrantAccessToGranteePortal_Mutation.graphql";
import {
  InviteGranteesModal_MissingInformationGranteesList_Grantees$data,
  InviteGranteesModal_MissingInformationGranteesList_Grantees$key,
} from "./__generated__/InviteGranteesModal_MissingInformationGranteesList_Grantees.graphql";
import {
  InviteGranteesModal_Query,
  InviteGranteesModal_Query$data,
} from "./__generated__/InviteGranteesModal_Query.graphql";
import { GranteeFormSlide, useGranteeFormSlideState } from "./GranteeFormSlide";
import {
  InviteGranteeEmailPreviewModal,
  useInviteGranteeEmailPreviewModalState,
} from "./InviteGranteeEmailPreviewModal";
import { useToaster } from "./Toaster";
import { ArrowLine } from "./ui/ArrowLine";
import { Button, LinkButton } from "./ui/Button";
import { Modal } from "./ui/Modal";
import { NoticeMessage } from "./ui/NoticeMessage";
import { Toast } from "./ui/Toast";
import { Typography } from "./ui/Typography";

const GRANT_ACCESS_TO_GRANTEE_PORTAL_MUTATION = graphql`
  mutation InviteGranteesModal_GrantAccessToGranteePortal_Mutation(
    $organizationId: OrganizationId!
    $granteeIds: [GranteeId!]!
  ) {
    grantAccessToGranteePortal(
      organizationId: $organizationId
      granteeIds: $granteeIds
      source: "Remote Equity Web App"
    ) {
      __typename
      id
      hasPortalAccess
      cannotBeInvitedReason
      hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal
    }
  }
`;

const MISSING_INFORMATION_GRANTEES_LIST_FRAGMENT = graphql`
  fragment InviteGranteesModal_MissingInformationGranteesList_Grantees on Grantee
  @relay(plural: true) {
    id
    name
    cannotBeInvitedReason
  }
`;

type Grantee =
  InviteGranteesModal_MissingInformationGranteesList_Grantees$data[number];

const _GranteeRow: React.FC<{
  children?: React.ReactNode;
  grantee: Grantee;
}> = ({ children, grantee }) => {
  return (
    <li
      className="flex items-center justify-between border-b-[0.5px] border-gray-03 pb-3 pt-3 first-of-type:pt-0"
      key={grantee.id}
    >
      <Typography
        as="div"
        className="flex items-center gap-1"
        variant="Regular/Extra Small"
      >
        <div className="font-emoji">⚠️</div>
        <div>{grantee.name}</div>
      </Typography>
      {children}
    </li>
  );
};

const InvitableGranteeRow: React.FC<{
  grantee: Grantee;
}> = ({ grantee }) => {
  return <_GranteeRow grantee={grantee} />;
};

const NoGrantsGranteeRow: React.FC<{
  grantee: Grantee;
}> = ({ grantee }) => {
  return (
    <_GranteeRow grantee={grantee}>
      <Pill tone="neutralDark">No grant</Pill>
    </_GranteeRow>
  );
};

const MissingInformationGranteeRow: React.FC<{
  grantee: Grantee;
  onEditGranteeButtonClick: (granteeId: string) => void;
}> = ({ grantee, onEditGranteeButtonClick }) => {
  return (
    <_GranteeRow grantee={grantee}>
      <button
        onClick={() => {
          onEditGranteeButtonClick(grantee.id);
        }}
      >
        <Typography className="text-primary" variant="Medium/Extra Small">
          Update information
        </Typography>
      </button>
    </_GranteeRow>
  );
};

const SettledGranteeRow: React.FC<{
  grantee: Grantee;
}> = ({ grantee }) => {
  return (
    <_GranteeRow grantee={grantee}>
      <Pill tone="neutralDark">Settled</Pill>
    </_GranteeRow>
  );
};

const RemoteEmployeeGranteeRow: React.FC<{
  grantee: Grantee;
}> = ({ grantee }) => {
  return (
    <_GranteeRow grantee={grantee}>
      <Pill tone="neutralDark">Remote employee</Pill>
    </_GranteeRow>
  );
};

const AlreadyInvitedGranteeRow: React.FC<{
  grantee: Grantee;
}> = ({ grantee }) => {
  return (
    <_GranteeRow grantee={grantee}>
      <Pill tone="neutralDark">Already invited</Pill>
    </_GranteeRow>
  );
};

const GranteeRow: React.FC<{
  grantee: Grantee;
  onEditGranteeButtonClick: (granteeId: string) => void;
}> = ({ grantee, onEditGranteeButtonClick }) => {
  if (!grantee.cannotBeInvitedReason) {
    return <InvitableGranteeRow grantee={grantee} />;
  }

  switch (grantee.cannotBeInvitedReason) {
    case "ALREADY_INVITED":
      return <AlreadyInvitedGranteeRow grantee={grantee} />;
    case "MISSING_INFORMATION":
      return (
        <MissingInformationGranteeRow
          grantee={grantee}
          onEditGranteeButtonClick={onEditGranteeButtonClick}
        />
      );
    case "NO_GRANTS":
      return <NoGrantsGranteeRow grantee={grantee} />;
    case "REMOTE_EMPLOYEE":
      return <RemoteEmployeeGranteeRow grantee={grantee} />;
    case "SETTLED":
      return <SettledGranteeRow grantee={grantee} />;
  }
};

type GranteeCannotBeInvitedReason = NonNullable<
  Grantee["cannotBeInvitedReason"]
>;

const CannotBeInvitedNoticeMessage: React.FC<{
  granteeCannotBeInvitedReason: GranteeCannotBeInvitedReason;
  onDraftNewGrantButtonClick?: () => void;
  onUpdateGranteeInformationButtonClick: () => void;
}> = ({
  granteeCannotBeInvitedReason,
  onDraftNewGrantButtonClick,
  onUpdateGranteeInformationButtonClick,
}) => {
  switch (granteeCannotBeInvitedReason) {
    case "ALREADY_INVITED":
      return (
        <NoticeMessage
          size="Large"
          title="Grantee already invited"
          variant="Warning"
        >
          This grantee has already been invited to access their portal.
        </NoticeMessage>
      );
    case "MISSING_INFORMATION":
      return (
        <NoticeMessage
          size="Large"
          title="Mandatory information needed"
          variant="Warning"
        >
          <div className="space-y-2">
            <div>
              We&apos;re missing mandatory information to provide a clear portal
              to the grantee you want to invite. Please fill in the information
              first, then invite the grantee.
            </div>
            <Button
              onClick={onUpdateGranteeInformationButtonClick}
              size="small"
            >
              Update grantee information
            </Button>
          </div>
        </NoticeMessage>
      );
    case "NO_GRANTS":
      return (
        <NoticeMessage
          size="Large"
          title="Grantee has not received any equity yet"
          variant="Warning"
        >
          <div className="space-y-2">
            <div>
              Make sure your grantee has been granted equity before sending an
              invitation to a portal.
            </div>
            {onDraftNewGrantButtonClick && (
              <Button onClick={onDraftNewGrantButtonClick} size="small">
                Draft a new grant
              </Button>
            )}
          </div>
        </NoticeMessage>
      );
    case "REMOTE_EMPLOYEE":
      return (
        <NoticeMessage size="Large" title="Remote employee" variant="Warning">
          <div className="space-y-2">
            <div>
              This grantee is a remote employee and can&apos;t access their
              portal.
            </div>
            <div>
              If you need to provide additional information to this grantee,
              please contact our support team.
            </div>
          </div>
        </NoticeMessage>
      );
    case "SETTLED":
      return (
        <NoticeMessage
          size="Large"
          title="Grantee has been settled"
          variant="Warning"
        >
          <div className="space-y-2">
            <div>
              Terminated and settled grantees can no longer access their portal.
            </div>
            <div>
              If you need to provide additional information to this grantee,
              please contact our support team.
            </div>
          </div>
        </NoticeMessage>
      );
  }
};

const MissingInformationGranteesList: React.FC<{
  granteesFragment: InviteGranteesModal_MissingInformationGranteesList_Grantees$key;
  onEditGranteeButtonClick: (granteeId: string) => void;
}> = ({ granteesFragment, onEditGranteeButtonClick }) => {
  const grantees = useFragment(
    MISSING_INFORMATION_GRANTEES_LIST_FRAGMENT,
    granteesFragment,
  );
  const [shownCount, setShownCount] = useState(5);
  const shownGrantees = grantees.slice(0, shownCount);
  const hasMoreToShow = shownCount < grantees.length;
  const handleShowMoreButtonClick = () => {
    setShownCount((shownCount) => shownCount + 5);
  };

  return (
    <div className="space-y-4 rounded-lg border-[0.5px] border-gray-05 bg-gray-01 p-4">
      <Typography as="div" variant="Regular/Extra Small">
        We noticed that{" "}
        <Typography variant="Medium/Extra Small">
          <FormattedMessage
            defaultMessage="{granteeCount, plural, one {# grantee} other {# grantees}}"
            values={{ granteeCount: grantees.length }}
          />
        </Typography>{" "}
        are missing information or have not received any equity yet.
        <br />
        Please provide the information in order to allow the creation of their
        portal.
      </Typography>
      <ul>
        {shownGrantees.map((grantee) => (
          <GranteeRow
            grantee={grantee}
            key={grantee.id}
            onEditGranteeButtonClick={onEditGranteeButtonClick}
          />
        ))}
      </ul>
      {hasMoreToShow && (
        <div className="flex items-center justify-center">
          <button
            className="flex items-center gap-1 text-primary"
            onClick={handleShowMoreButtonClick}
          >
            <Typography as="div" variant="Medium/Extra Small">
              Show more...
            </Typography>
            <ChevronDownIcon className="w-4" />
          </button>
        </div>
      )}
    </div>
  );
};

type State =
  | {
      granteeIds: string[];
      shown: true;
      singleGranteeMode: boolean;
    }
  | {
      granteeIds?: null | string[];
      shown: false;
      singleGranteeMode?: boolean;
    };

export const useInviteGranteesModalState = () => {
  const [inviteGranteeModalIsOpening, startInviteGranteeModalTransition] =
    useTransition();
  const [inviteGranteeModalState, setState] = useState<State>({
    shown: false,
  });

  const showInviteGranteeModal = (granteeIds: string | string[]) => {
    startInviteGranteeModalTransition(() => {
      setState({
        granteeIds: Array.isArray(granteeIds) ? granteeIds : [granteeIds],
        shown: true,
        singleGranteeMode: !Array.isArray(granteeIds),
      });
    });
  };

  const hideInviteGranteeModal = () => {
    setState((previousState) => ({
      ...previousState,
      shown: false,
    }));
  };

  return {
    hideInviteGranteeModal,
    inviteGranteeModalIsOpening,
    inviteGranteeModalState,
    showInviteGranteeModal,
  };
};

const QUERY = graphql`
  query InviteGranteesModal_Query(
    $organizationId: OrganizationId!
    $granteeIds: [ID!]!
  ) {
    grantees: nodes(ids: $granteeIds) {
      ... on Grantee {
        __typename
        id
        name
        cannotBeInvitedReason
        shouldReceiveGranteePortalInvitationEmail
        ...InviteGranteeEmailPreviewModal_Grantee
        ...GranteeFormSlide_Grantee
        ...InviteGranteesModal_MissingInformationGranteesList_Grantees
      }
    }
    organization(id: $organizationId) @required(action: THROW) {
      id
      ...GranteeFormSlide_Organization
    }
    viewer: me {
      ...InviteGranteeEmailPreviewModal_Viewer
    }
  }
`;

export const InviteGranteesModal: React.FC<{
  granteesRelayConnectionIds?: string[];
  onClose: () => void;
  onDraftNewGrantButtonClick?: (granteeId: string) => void;
  onGranteeCreated?: (granteeId: string) => void;
  onGranteeDeleted?: (granteeId: string) => void;
  onGranteesInvited?: (granteeIds: string[]) => void;
  onGranteeUpdated?: (granteeId: string) => void;
  organizationId: string;
  state: State;
}> = ({
  granteesRelayConnectionIds,
  onClose,
  onDraftNewGrantButtonClick,
  onGranteeCreated,
  onGranteeDeleted,
  onGranteesInvited,
  onGranteeUpdated,
  organizationId,
  state,
}) => {
  const {
    query: { grantees: _grantees, organization, viewer },
  } = useQuery<InviteGranteesModal_Query>(QUERY, {
    granteeIds: state.granteeIds ?? [],
    organizationId,
  });

  const grantees = _grantees.map((grantee) => {
    if (grantee?.__typename === "Grantee") {
      return grantee;
    }

    throw new Error(`Unexpected type: ${grantee?.__typename}`);
  });

  const [
    _grantAccessToGranteePortal,
    grantAccessToGranteePortalMutationIsInFlight,
  ] = useSafeMutation<InviteGranteesModal_GrantAccessToGranteePortal_Mutation>(
    GRANT_ACCESS_TO_GRANTEE_PORTAL_MUTATION,
  );

  const toaster = useToaster();

  const inviteGrantee = async (
    grantees: Extract<
      InviteGranteesModal_Query$data["grantees"][number],
      { __typename: "Grantee" }
    >[],
  ) => {
    const granteeIds = grantees.map((grantee) => grantee.id);
    await _grantAccessToGranteePortal({
      variables: {
        granteeIds,
        organizationId: organization.id,
      },
    });

    if (state.singleGranteeMode && grantees[0]) {
      toaster.push(
        <Toast title="Great!">
          Invitation successfully sent to {grantees[0].name}.
        </Toast>,
      );
    } else {
      toaster.push(
        <Toast title="Great!">
          <FormattedMessage
            defaultMessage={`Invitations successfully sent to {granteesCount, plural, one {# grantee} other {# grantees}}.`}
            values={{
              granteesCount: grantees.length,
            }}
          />
        </Toast>,
      );
    }

    onGranteesInvited?.(granteeIds);

    onClose();
  };

  const handleGranteeCreated = (granteeId: string) => {
    closeGranteeFormSlide();
    onGranteeCreated?.(granteeId);
  };

  const handleGranteeUpdated = (granteeId: string) => {
    closeGranteeFormSlide();
    onGranteeUpdated?.(granteeId);
  };

  const handleGranteeDeleted = (granteeId: string) => {
    closeGranteeFormSlide();
    onGranteeDeleted?.(granteeId);
  };

  const {
    closeGranteeFormSlide,
    granteeFormSlideState,
    openGranteeFormSlideInEditMode,
  } = useGranteeFormSlideState();

  const {
    closeInviteGranteeEmailPreviewModal,
    inviteGranteeEmailPreviewModalState,
    openInviteGranteeEmailPreviewModal,
  } = useInviteGranteeEmailPreviewModalState();

  const [canBeInvitedGrantees, cannotBeInvitedGrantees] = partition(
    grantees,
    (grantee) => !grantee.cannotBeInvitedReason,
  );

  const handleMissingInformationGranteesListEditGranteeButtonClick = (
    granteeId: string,
  ) => {
    const grantee = grantees.find((grantee) => grantee.id === granteeId);

    if (!grantee) {
      throw new Error(
        `Grantee with id ${granteeId} not found in missingInformationGrantees.`,
      );
    }

    openGranteeFormSlideInEditMode(grantee);
  };

  return (
    <>
      <GranteeFormSlide
        granteesRelayConnectionIds={granteesRelayConnectionIds}
        onCancel={closeGranteeFormSlide}
        onGranteeCreated={handleGranteeCreated}
        onGranteeDeleted={handleGranteeDeleted}
        onGranteeUpdated={handleGranteeUpdated}
        organizationFragment={organization}
        state={granteeFormSlideState}
      />
      <InviteGranteeEmailPreviewModal
        onClose={closeInviteGranteeEmailPreviewModal}
        state={inviteGranteeEmailPreviewModalState}
        viewerFragment={viewer}
      />
      <Modal onClose={onClose} show={state.shown} suspense width={680}>
        <Modal.Content
          onClose={onClose}
          subTitle={
            grantees[0]?.shouldReceiveGranteePortalInvitationEmail
              ? "Grantees can create an account and learn about their equity."
              : "Grantees can access their portal from their Remote account and learn about their equity."
          }
          title={
            state.singleGranteeMode ? (
              <>Invite grantee</>
            ) : (
              <>Invite all grantees</>
            )
          }
        >
          <div className="space-y-10">
            <div className="space-y-4">
              {state.singleGranteeMode &&
                isNonEmptyArray(grantees) &&
                grantees[0].cannotBeInvitedReason && (
                  <CannotBeInvitedNoticeMessage
                    granteeCannotBeInvitedReason={
                      grantees[0].cannotBeInvitedReason
                    }
                    onDraftNewGrantButtonClick={
                      onDraftNewGrantButtonClick &&
                      (() => {
                        onDraftNewGrantButtonClick(grantees[0].id);
                      })
                    }
                    onUpdateGranteeInformationButtonClick={() => {
                      openGranteeFormSlideInEditMode(grantees[0]);
                    }}
                  />
                )}
              {!state.singleGranteeMode &&
                cannotBeInvitedGrantees.length > 0 && (
                  <MissingInformationGranteesList
                    granteesFragment={cannotBeInvitedGrantees}
                    onEditGranteeButtonClick={(granteeId) => {
                      handleMissingInformationGranteesListEditGranteeButtonClick(
                        granteeId,
                      );
                    }}
                  />
                )}
              <div className="flex items-center gap-2">
                <Button
                  disabled={!canBeInvitedGrantees.length}
                  loading={grantAccessToGranteePortalMutationIsInFlight}
                  onClick={() => {
                    void inviteGrantee(canBeInvitedGrantees);
                  }}
                  size="small"
                >
                  {grantees.length === 1 ? (
                    <>Invite grantee</>
                  ) : (
                    <>
                      Invite {canBeInvitedGrantees.length}/{grantees.length}{" "}
                      grantees
                    </>
                  )}
                </Button>
                {isNonEmptyArray(grantees) && (
                  <LinkButton
                    size="small"
                    target="_blank"
                    to={generatePath(APPLICATION_ROUTES.employeePortal, {
                      granteeId: grantees[0].id,
                    })}
                    variant="Primary Outline"
                  >
                    Preview sample portal
                  </LinkButton>
                )}
              </div>
            </div>
            <Typography
              as="div"
              className="space-y-2"
              variant="Regular/Extra Small"
            >
              <Typography variant="Medium/Default">
                What’s happening when I invite a grantee?
              </Typography>
              <div>
                {state.singleGranteeMode ? (
                  <>When you click “Invite grantee” the following will occur:</>
                ) : (
                  <>
                    When you click “Invite {canBeInvitedGrantees.length}/
                    {grantees.length} grantees” the following will occur:
                  </>
                )}
              </div>
              <div className="space-y-3">
                {isNonEmptyArray(grantees) && (
                  <ArrowLine>
                    {state.singleGranteeMode ||
                    canBeInvitedGrantees.length === 1 ? (
                      grantees[0].shouldReceiveGranteePortalInvitationEmail ? (
                        <>
                          The grantee will receive an email inviting them to
                          create an account.
                        </>
                      ) : (
                        <>
                          The grantee will have a new Equity space in their
                          Remote app.
                        </>
                      )
                    ) : (
                      <>
                        Those {canBeInvitedGrantees.length} grantees will
                        receive an email inviting them to create an account.
                      </>
                    )}{" "}
                    {grantees[0].shouldReceiveGranteePortalInvitationEmail && (
                      <Typography
                        className="cursor-pointer text-primary"
                        onClick={() => {
                          openInviteGranteeEmailPreviewModal({
                            granteeFragment: grantees[0],
                          });
                        }}
                        variant="Medium/Extra Small"
                      >
                        Preview email
                      </Typography>
                    )}
                  </ArrowLine>
                )}

                <ArrowLine>
                  {state.singleGranteeMode ? (
                    <>
                      The grantee will be able to access their portal and follow
                      their equity over time.
                    </>
                  ) : (
                    <>
                      Grantees will be able to access their portal and follow
                      their equity over time.
                    </>
                  )}{" "}
                  You can{" "}
                  <Link
                    target="_blank"
                    to={generatePath(
                      APPLICATION_ROUTES.organizationToolsGranteePortalSettings,
                      {
                        organizationId: organization.id,
                      },
                    )}
                  >
                    <Typography
                      className="cursor-pointer text-primary"
                      variant="Medium/Extra Small"
                    >
                      manage the portal settings.
                    </Typography>
                  </Link>
                </ArrowLine>
              </div>
            </Typography>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};
