import { Article, Aside, Paragraph } from "./Utils";

export function Exercise() {
  return (
    <Article title="What happens when you vest some of these options?">
      <Paragraph>
        Congratulations 🎉, your long term commitment in the company is
        rewarded: you now have the right to buy a slice of the company at a
        pre-defined price (the “exercise price”). We call this stage the
        “exercise”.
      </Paragraph>

      <Aside title="Definition">
        “Exercising” means that you turn the unlocked (vested) options (i.e. the
        right to get a slice of your company) into an actual slice of the
        company by paying what we call an “exercise price” or “strike price”, a
        price that was pre-defined when the company granted you the options.
      </Aside>

      <Paragraph>
        In principle, you can do it as soon as you get new options unlocked
        (vested), or later (for instance, before the company is about to be
        acquired)! Once you&apos;ve exercised your options, you become an owner
        of your company: you are what we call a shareholder 👨‍💼.
      </Paragraph>
    </Article>
  );
}
