/**
 * @generated SignedSource<<aed80107dd89b250df356c83f2745c98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useOrganizationCTMS_Organization$data = {
  readonly isConnectedToAngelList: boolean;
  readonly isConnectedToCarta: boolean;
  readonly isConnectedToPulley: boolean;
  readonly " $fragmentType": "useOrganizationCTMS_Organization";
};
export type useOrganizationCTMS_Organization$key = {
  readonly " $data"?: useOrganizationCTMS_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"useOrganizationCTMS_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useOrganizationCTMS_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToCarta",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToPulley",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToAngelList",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "7ed7b4c440bc5cd7d2a38fbd28a9daec";

export default node;
