/**
 * @generated SignedSource<<b073a05d0cdf0e3da2f6916d37b28232>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteesTableRow_Organization$data = {
  readonly granteePortalInvitationIsAllowed: boolean;
  readonly id: string;
  readonly planIsFreemium: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeFormSlide_Organization" | "MissingInformationTag_MissingCTMSInformationTag_Organization" | "useOrganizationCTMS_Organization" | "useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "GranteesTableRow_Organization";
};
export type GranteesTableRow_Organization$key = {
  readonly " $data"?: GranteesTableRow_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteesTableRow_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteesTableRow_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planIsFreemium",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "granteePortalInvitationIsAllowed",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationCTMS_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MissingInformationTag_MissingCTMSInformationTag_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "279f5cddf9170df949dac7b62455781e";

export default node;
