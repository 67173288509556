import * as Sentry from "@sentry/react";
import React from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";

import FeatureFlagRouter from "./components/FeatureFlagRouter";
import { SuspenseWithLoadingFallback } from "./components/LoadingPlaceholder";
import { RedirectHandler } from "./components/RedirectHandler";
import { useUpgradeNotificationDispatcher } from "./hooks/useUpgradeNotificationDispatcher";
import AdminLayout from "./layouts/AdminLayout/AdminLayout";
import AssistedGrantLayout from "./layouts/AssistedGrantLayout/AssistedGrantLayout";
import AuthLayout from "./layouts/AuthLayout/AuthLayout";
import BoardMemberLayout from "./layouts/BoardMemberLayout/BoardMemberLayout";
import GranteeEquityTypesGroupLayout from "./layouts/GranteeEquityTypesGroupLayout/GranteeEquityTypesGroupLayout";
import GranteeLayout from "./layouts/GranteeLayout/GranteeLayout";
import OrganizationLayout from "./layouts/OrganizationLayout/OrganizationLayout";
import SafeSimulatorLayout from "./layouts/SafeSimulatorLayout/SafeSimulatorLayout";
import SuperAdminLayout from "./layouts/SuperAdminLayout/SuperAdminLayout";
import AdminAssistedGrantGranteePage from "./pages/Admin/AssistedGrant/Grantee";
import AdminAssistedGrantInstrumentPage from "./pages/Admin/AssistedGrant/Instrument";
import AdminAssistedGrantPTEPPage from "./pages/Admin/AssistedGrant/PTEP";
import AdminAssistedGrantReviewPage from "./pages/Admin/AssistedGrant/Review/Review";
import AdminAssistedGrantSharesPage from "./pages/Admin/AssistedGrant/Shares";
import AdminAssistedGrantVestingSchedulePage from "./pages/Admin/AssistedGrant/VestingSchedule";
import BookADemoPage from "./pages/Admin/BookADemo/BookADemoPage";
import AdminCleanupGranteesPage from "./pages/Admin/CleanupGrantees/CleanupGrantees";
import AdminCleanupGranteesCreateNewGranteesPage from "./pages/Admin/CleanupGrantees/CreateNewGrantees/CreateNewGrantees";
import AdminCleanupGranteesIgnoredPage from "./pages/Admin/CleanupGrantees/Ignored";
import AdminCleanupGranteesSynchronizeEmployeesDataPage from "./pages/Admin/CleanupGrantees/SynchronizeEmployeesData/SynchronizeEmployeesData";
import AdminCleanupGranteesUpdateInformationPage from "./pages/Admin/CleanupGrantees/UpdateInformation/UpdateInformation";
import OrganizationCountriesPage from "./pages/Admin/Countries/Countries";
import OrganizationCountriesFreemiumPage from "./pages/Admin/Countries/CountriesFreemium";
import OrganizationCountriesCountryPage from "./pages/Admin/Countries/Country/Country";
import OrganizationCountriesEquityTypeWorkRelationshipPage from "./pages/Admin/Countries/EquityType/EquityTypeWorkRelationship";
import OrganizationCountriesUnlockPage from "./pages/Admin/Countries/UnlockCountries";
import OrganizationCountryUnlockPage from "./pages/Admin/Countries/UnlockCountry/UnlockCountry";
import AdminCtmsGrantDetailsPage from "./pages/Admin/CtmsGrant/Details/CtmsGrantDetails";
import AdminCtmsGrantModifyLayout from "./pages/Admin/CtmsGrant/Modify/Layout";
import AdminCtmsGrantModifyPage from "./pages/Admin/CtmsGrant/Modify/Modify";
import AdminCtmsGrantModifyReviewPage from "./pages/Admin/CtmsGrant/Modify/Review";
import AdminGrantAmendmentRequestsBoardApprovalPage from "./pages/Admin/CTMSGrantAmendmentRequests/BoardApproval";
import AdminCTMSGrantAmendmentRequestDetailsPage from "./pages/Admin/CTMSGrantAmendmentRequests/Details";
import AdminCTMSGrantAmendmentRequestDetailsLayout from "./pages/Admin/CTMSGrantAmendmentRequests/DetailsLayout";
import AdminCTMSGrantAmendmentRequestDetailsReviewModificationPage from "./pages/Admin/CTMSGrantAmendmentRequests/DetailsReviewModification";
import AdminGrantAmendmentRequestsImplementationPage from "./pages/Admin/CTMSGrantAmendmentRequests/Implementation";
import AdminGrantAmendmentRequestsPendingApprovalPage from "./pages/Admin/CTMSGrantAmendmentRequests/PendingApproval";
import AdminGrantAmendmentRequestsUnderReviewPage from "./pages/Admin/CTMSGrantAmendmentRequests/UnderReview";
import AdminDocumentsBoardConsentsPage from "./pages/Admin/Documents/BoardConsents";
import AdminDocumentsPage from "./pages/Admin/Documents/Documents";
import AdminGeographiesDocumentsPage from "./pages/Admin/Documents/GeographiesDocuments";
import AdminDocumentsGrantDocumentationPage from "./pages/Admin/Documents/GrantDocumentation";
import AdminDocumentsGrantDocumentationDetailsPage from "./pages/Admin/Documents/GrantDocumentationDetails";
import AdminDocumentsLegalDocumentationPage from "./pages/Admin/Documents/LegalDocumentation";
import EmployeePortalsSettingsPage from "./pages/Admin/EmployeePortalsSettings/EmployeePortalsSettings";
import AdminGrantEquityFreemiumPage from "./pages/Admin/Equity/GrantEquityFreemium";
import AdminEquityPrepareYourGrantsBoardApprovalPage from "./pages/Admin/Equity/PrepareYourGrants/BoardApproval/BoardApproval";
import AdminEquityPrepareYourGrantsGrantDetailsPage from "./pages/Admin/Equity/PrepareYourGrants/DraftGrantDetails";
import AdminEquityPrepareYourGrantsDraftsPage from "./pages/Admin/Equity/PrepareYourGrants/Drafts";
import AdminEquityPrepareYourGrantsPendingApprovalPage from "./pages/Admin/Equity/PrepareYourGrants/PendingApproval";
import AdminEquityPrepareYourGrantsPendingImplementationPage from "./pages/Admin/Equity/PrepareYourGrants/PendingImplementation";
import AdminEquityPrepareYourGrantsUnderReviewPage from "./pages/Admin/Equity/PrepareYourGrants/UnderReview";
// Equity offers
import EquityOfferCandidateSettingsPage from "./pages/Admin/EquityOffer/CandidateSettings";
import EquityOfferCompanySettingsPage from "./pages/Admin/EquityOffer/CompanySettings";
import { EquityOfferLayout } from "./pages/Admin/EquityOffer/EquityOfferLayout";
import EquityOfferEquiuniversitySettingsPage from "./pages/Admin/EquityOffer/EquiuniversitySettings";
import EquityOfferFinalThoughtsSettingsPage from "./pages/Admin/EquityOffer/FinalThoughtsSettings";
import EquityOfferIncentiveSettingsPage from "./pages/Admin/EquityOffer/IncentiveSettings";
import EquityOfferProjectionSettingsPage from "./pages/Admin/EquityOffer/ProjectionSettings";
import EquityOfferVestingSettingsPage from "./pages/Admin/EquityOffer/VestingSettings";
import AdminExerciseRequestDetailsPage from "./pages/Admin/ExerciseRequestDetails/ExerciseRequestDetails";
import AdminExerciseRequestSimulationPage from "./pages/Admin/ExerciseRequestDetails/ExerciseRequestSimulation";
import ExerciseRequestTaxInformationExportPage from "./pages/Admin/ExerciseRequestDetails/ExerciseRequestTaxInformationExport";
import AdminExercisesPage from "./pages/Admin/Exercises/Exercises";
import AdminExercisesFreemiumPage from "./pages/Admin/Exercises/ExercisesFreemium";
import AdminFairMarketValueApprovalsBoardApprovalPage from "./pages/Admin/FairMarketValueApprovals/BoardApproval";
import AdminFairMarketValueApprovalsPendingApprovalPage from "./pages/Admin/FairMarketValueApprovals/PendingApproval";
import AdminFairMarketValueApprovalsUnderReviewPage from "./pages/Admin/FairMarketValueApprovals/UnderReview";
import FinishRemoteEquityOnboardingPage from "./pages/Admin/FinishRemoteEquityOnboarding/FinishRemoteEquityOnboardingPage";
import AdminGranteeDetailsPage from "./pages/Admin/Grantee/GranteeDetails/GranteeDetails";
import AdminGranteesPage from "./pages/Admin/Grantee/Grantees";
import AdminTerminateGranteePage from "./pages/Admin/Grantee/GranteeTermination/GranteeTermination";
import AdminNewGranteePage from "./pages/Admin/Grantee/NewGrantee";
import AdminGranteeTerminationGrantAmendmentRequestsBoardApprovalPage from "./pages/Admin/GranteeTerminationCTMSGrantAmendmentRequests/BoardApproval";
import GranteeTerminationCTMSGrantAmendmentRequestPage from "./pages/Admin/GranteeTerminationCTMSGrantAmendmentRequests/GranteeTerminationAmendmentRequest";
import AdminGranteeTerminationGrantAmendmentRequestsImplementationPage from "./pages/Admin/GranteeTerminationCTMSGrantAmendmentRequests/Implementation";
import AdminGranteeTerminationGrantAmendmentRequestsPendingApprovalPage from "./pages/Admin/GranteeTerminationCTMSGrantAmendmentRequests/PendingApproval";
import AdminTerminationsFreemiumPage from "./pages/Admin/GranteeTerminationCTMSGrantAmendmentRequests/TerminationsFreemium";
import AdminGranteeTerminationGrantAmendmentRequestsUnderReviewPage from "./pages/Admin/GranteeTerminationCTMSGrantAmendmentRequests/UnderReview";
import AdminGrantsLayout from "./pages/Admin/Grants/AdminGrantsLayout";
import ConfigureGrantsAcceleration from "./pages/Admin/Grants/Configure/Acceleration/Acceleration";
import ConfigureGrantsDoubleTriggerAccelerationDetails from "./pages/Admin/Grants/Configure/Acceleration/DoubleTriggerAccelerationDetails";
import ConfigureGrantsSingleTriggerAccelerationDetails from "./pages/Admin/Grants/Configure/Acceleration/SingleTriggerAccelerationDetails";
import ConfigureGrantsLayout from "./pages/Admin/Grants/Configure/ConfigureGrantsRootLayout";
import ConfigureGrantsEarlyExercise from "./pages/Admin/Grants/Configure/EarlyExercise";
import ConfigureGrantsEquityGrid from "./pages/Admin/Grants/Configure/EquityGrid/EquityGrid";
import ConfigureGrantsEquityPhilosophy from "./pages/Admin/Grants/Configure/EquityPhilosophy";
import ConfigureGrantsNewHiresAdvisors from "./pages/Admin/Grants/Configure/GrantDefaults/NewHires/Advisors";
import ConfigureGrantsNewHiresEmployeesAndContractors from "./pages/Admin/Grants/Configure/GrantDefaults/NewHires/EmployeesAndContractors";
import ConfigureGrantsNewHiresLayout from "./pages/Admin/Grants/Configure/GrantDefaults/NewHires/Layout";
import ConfigureGrantsRefresher from "./pages/Admin/Grants/Configure/GrantDefaults/Refresher/Refresher";
import ConfigureGrantsPostTerminationAddNew from "./pages/Admin/Grants/Configure/PostTermination/AddNew";
import ConfigureGrantsPostTermination from "./pages/Admin/Grants/Configure/PostTermination/PostTermination";
import ConfigureGrantsVestingSchedulesAddNew from "./pages/Admin/Grants/Configure/VestingSchedules/AddNew";
import ConfigureGrantsVestingSchedulesDetailsView from "./pages/Admin/Grants/Configure/VestingSchedules/DetailsView";
import ConfigureGrantsVestingSchedules from "./pages/Admin/Grants/Configure/VestingSchedules/VestingSchedules";
import AdminGrantsPage from "./pages/Admin/Grants/Grants";
import AdminCliffPeriodsPage from "./pages/Admin/Insights/CliffPeriods/CliffPeriods";
import AdminEquityGridLevelsPage from "./pages/Admin/Insights/EquityGridLevels/EquityGridLevels";
import AdminExercisesActivityPage from "./pages/Admin/Insights/ExercisesActivity/ExercisesActivity";
import AdminInsightsHomePage from "./pages/Admin/Insights/Home";
import AdminLargestGainPage from "./pages/Admin/Insights/LargestGain/LargestGain";
import AdminPostTerminationExercisePeriodPage from "./pages/Admin/Insights/PostTerminationExercisePeriod/PostTerminationExercisePeriod";
import AdminQuantityVestedPage from "./pages/Admin/Insights/QuantityVested/QuantityVested";
import AdminTeamIncentivizationPage from "./pages/Admin/Insights/TeamIncentivization/TeamIncentivization";
import AdminUnderwaterGrantsPage from "./pages/Admin/Insights/UnderwaterGrants/UnderwaterGrants";
import AdminInsightsVestingSchedulesPage from "./pages/Admin/Insights/VestingSchedules/VestingSchedules";
import OrganizationSettingsBillingPage from "./pages/Admin/OrganizationSettings/Billing/OrganizationSettingsBilling";
import OrganizationSettingsIntegrationCartaPage from "./pages/Admin/OrganizationSettings/Integrations/OrganizationSettingsIntegrationCarta";
import OrganizationSettingsIntegrationDeelPage from "./pages/Admin/OrganizationSettings/Integrations/OrganizationSettingsIntegrationDeel";
import OrganizationSettingsIntegrationsPage from "./pages/Admin/OrganizationSettings/Integrations/OrganizationSettingsIntegrations";
import OrganizationSettingsPage from "./pages/Admin/OrganizationSettings/OrganizationSettings";
import OrganizationSettingsAdminsPage from "./pages/Admin/OrganizationSettings/OrganizationSettingsAdmins";
import OrganizationSettingsBoardMembersPage from "./pages/Admin/OrganizationSettings/OrganizationSettingsBoardMembers";
import OrganizationSettingsEquityViewersPage from "./pages/Admin/OrganizationSettings/OrganizationSettingsEquityViewers";
import OrganizationSettingsSafeCalculatorLayout from "./pages/Admin/OrganizationSettings/OrganizationSettingsSafeCalculator/OrganizationSettingsSafeCalculatorLayout";
import OrganizationSettingsSafeCalculatorVersionPage from "./pages/Admin/OrganizationSettings/OrganizationSettingsSafeCalculator/OrganizationSettingsSafeCalculatorVersion";
import AdminHomePage from "./pages/Admin/Overview/Overview";
import AdminPlanningIgnoredPage from "./pages/Admin/Plannings/Ignored";
import AdminPlanningPage from "./pages/Admin/Plannings/Planning";
import AdminPlanningLayout from "./pages/Admin/Plannings/PlanningLayout";
import AdminPlanningProjectionPage from "./pages/Admin/Plannings/Projection";
import AdminEquityOffersFreemiumPage from "./pages/Admin/Tools/EquityOffer/EquityOffersFreemium";
import EquityOffersSettingsPage from "./pages/Admin/Tools/EquityOffer/EquityOffersSettings";
import AdminGranteePortalFreemiumPage from "./pages/Admin/Tools/GranteePortal/GranteePortalFreemium";
import GranteePortalSettingsPage from "./pages/Admin/Tools/GranteePortal/GranteePortalSettings";
import ForgotPasswordPage from "./pages/Auth/ForgotPassword";
import ForgotPasswordSuccessPage from "./pages/Auth/ForgotPasswordSuccess";
import JoinTheTeamPage from "./pages/Auth/JoinTheTeam";
import LoginPage from "./pages/Auth/Login";
import SignUpPage from "./pages/Auth/SignUp/SignUp";
import UpdatePasswordPage from "./pages/Auth/UpdatePassword";
import BoardMembersApprovalsPage from "./pages/BoardMember/Approvals/Approvals";
import BoardMemberEquityGridPage from "./pages/BoardMember/EquityGrid/BoardMemberEquityGrid";
import BoardMembersOverviewPage from "./pages/BoardMember/Overview/Overview";
import CrashTestPage from "./pages/CrashTest";
import CriticalErrorPage from "./pages/CriticalErrorPage";
import DebugPage from "./pages/Debug";
import EmployeePortalPage from "./pages/EmployeePortal/EmployeePortalPage";
import EquityOfferPage from "./pages/EquityOffer/EquityOffer";
import GranteeEquityTypeSelectorPage from "./pages/Grantee/EquityTypeSelector";
import GranteeExercisePage from "./pages/Grantee/Exercise/Exercise";
import GranteeFAQPage from "./pages/Grantee/FAQ";
import GranteeOverviewPage from "./pages/Grantee/Overview";
import GranteeProjectionPage from "./pages/Grantee/Projection";
import GranteeReportingPage from "./pages/Grantee/Reporting";
import GranteeTaxationPage from "./pages/Grantee/Taxation";
import NotFoundPage from "./pages/NotFound/NotFound";
import RemoteAdminNotAllowedPage from "./pages/RemoteAdminNotAllowedPage";
import RoleSelectionPage from "./pages/RoleSelection/RoleSelection";
import SafeSimulatorCalculatorPage from "./pages/SafeSimulator/Calculator/SafeSimulatorCalculator";
import SuperAdminBulkUploadDraftsPage from "./pages/SuperAdmin/BulkUploadDrafts/BulkUploadDrafts";
import SuperAdminDocumentsUploadPage from "./pages/SuperAdmin/DocumentsUpload/DocumentsUpload";
import SuperAdminDocumentsUploadOrganizationViewPage from "./pages/SuperAdmin/DocumentsUpload/DocumentsUploadOrganizationView/DocumentsUploadOrganizationView";
import SuperAdminDocumentsApprovalPage from "./pages/SuperAdmin/LegalContent/DocumentsApproval/DocumentsApproval";
import SuperAdminEarlyExerciseBeneficialPage from "./pages/SuperAdmin/LegalContent/EarlyExerciseBeneficial/EarlyExerciseBeneficial";
import SuperAdminExercisesTaxParametersEditionPage from "./pages/SuperAdmin/LegalContent/ExercisesTaxParameters/Edition/ExercisesTaxParametersEdition";
import SuperAdminExercisesTaxParametersPage from "./pages/SuperAdmin/LegalContent/ExercisesTaxParameters/ExercisesTaxParameters";
import SuperAdminLegalContentPage from "./pages/SuperAdmin/LegalContent/LegalContent";
import SuperAdminLegalWatchOutsEditionPage from "./pages/SuperAdmin/LegalContent/LegalWatchOuts/Edition/LegalWatchOutsEdition";
import SuperAdminLegalWatchOutsPage from "./pages/SuperAdmin/LegalContent/LegalWatchOuts/LegalWatchOuts";
import SuperAdminOngoingObligationsPage from "./pages/SuperAdmin/LegalContent/OngoingObligations/OngoingObligations";
import SuperAdminCartaOrganizationsPage from "./pages/SuperAdmin/OrganizationAdmin/CartaOrganizations/CartaOrganizations";
import SuperAdminChangeGrantInstrumentPage from "./pages/SuperAdmin/OrganizationAdmin/ChangeGrantInstrument/ChangeGrantInstrument";
import SuperAdminEquityAlertingPage from "./pages/SuperAdmin/OrganizationAdmin/EquityAlerting/EquityAlerting";
import SuperAdminOrganizationAdminPage from "./pages/SuperAdmin/OrganizationAdmin/OrganizationAdmin";
import RemoteEquityPlanThresholdsExceeded from "./pages/SuperAdmin/OrganizationAdmin/RemoteEquityPlanThresholdsExceeded/RemoteEquityPlanThresholdsExceeded";
import SuperAdminRepricingPage from "./pages/SuperAdmin/OrganizationAdmin/Repricing/Repricing";
import SubplanApprovalDate from "./pages/SuperAdmin/OrganizationAdmin/SubplanApprovalDate/SubplanApprovalDate";
import SuperAdminUnusedGeographiesPage from "./pages/SuperAdmin/OrganizationAdmin/UnusedGeographies/UnusedGeographies";
import WorkspaceSetupAccountCreationPage from "./pages/WorkspaceSetup/AccountCreationPage";
import CartaIssuerSelectionPage from "./pages/WorkspaceSetup/CartaIssuerSelection/CartaIssuerSelection";
import WorkspaceSetupIndexPage from "./pages/WorkspaceSetup/IndexPage";
import RootLayout from "./RootLayout";
import AuthenticatedGuard from "./services/AuthenticatedGuard";
import AuthenticationProvider from "./services/AuthenticationProvider";
import FreemiumGuard from "./services/FreemiumGuard";
import MaintenanceInProgressGuard from "./services/MaintenanceInProgressGuard";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    children: [
      {
        children: [
          {
            element: <JoinTheTeamPage />,
            path: "join-the-team",
          },
          {
            element: <ForgotPasswordPage />,
            path: "forgot-password",
          },
          {
            element: <ForgotPasswordSuccessPage />,
            path: "forgot-password-success",
          },
          {
            element: <UpdatePasswordPage />,
            path: "update-password",
          },
          {
            element: <SignUpPage />,
            path: "sign-up",
          },
          {
            children: [
              {
                element: <WorkspaceSetupIndexPage />,
                index: true,
              },
              {
                element: <WorkspaceSetupAccountCreationPage />,
                path: "account-creation",
              },
            ],
            path: "setup-workspace",
          },
          {
            element: <Navigate replace to="/safe-converter" />,
            path: "safe-simulator",
          },
          {
            children: [
              {
                element: <SafeSimulatorCalculatorPage />,
                index: true,
              },
            ],
            element: <SafeSimulatorLayout />,
            path: "safe-converter",
          },
        ],
        element: <AuthLayout />,
      },
      {
        element: (
          <SuspenseWithLoadingFallback>
            <EquityOfferPage />
          </SuspenseWithLoadingFallback>
        ),
        path: "offer/:equityOfferCandidateAccessSecretKey",
      },
      {
        element: <RemoteAdminNotAllowedPage />,
        path: "remote-admin-not-allowed",
      },
      {
        children: [
          {
            element: (
              <SuspenseWithLoadingFallback>
                <RoleSelectionPage />
              </SuspenseWithLoadingFallback>
            ),
            index: true,
          },
          {
            children: [
              {
                element: <CartaIssuerSelectionPage />,
                path: "carta-issuer-selection",
              },
            ],
            element: <AuthLayout hideSignInButton />,
            path: "setup-workspace",
          },
          {
            children: [
              {
                element: (
                  <SuspenseWithLoadingFallback>
                    <FinishRemoteEquityOnboardingPage />
                  </SuspenseWithLoadingFallback>
                ),
                path: "finish-onboarding",
              },
              {
                element: (
                  <SuspenseWithLoadingFallback>
                    <BookADemoPage />
                  </SuspenseWithLoadingFallback>
                ),
                path: "book-a-demo",
              },
              {
                children: [
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <AdminHomePage />
                      </SuspenseWithLoadingFallback>
                    ),
                    index: true,
                  },
                  {
                    children: [
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminGranteesPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        index: true,
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminNewGranteePage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "new-grantee",
                      },
                      {
                        children: [
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <AdminGranteeDetailsPage />
                              </SuspenseWithLoadingFallback>
                            ),
                            index: true,
                          },
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <AdminTerminateGranteePage />
                              </SuspenseWithLoadingFallback>
                            ),
                            path: "terminate",
                          },
                        ],
                        path: ":granteeId",
                      },
                    ],
                    path: "grantees",
                  },
                  {
                    children: [
                      {
                        element: <Navigate replace to="grants" />,
                        index: true,
                      },
                      {
                        children: [
                          {
                            element: <Navigate replace to="active" />,
                            index: true,
                          },
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <AdminGrantsPage grantStatus="Active" />
                              </SuspenseWithLoadingFallback>
                            ),
                            path: "active",
                          },
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <AdminGrantsPage grantStatus="Terminated" />
                              </SuspenseWithLoadingFallback>
                            ),
                            path: "terminated",
                          },
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <AdminGrantsPage grantStatus="Canceled" />
                              </SuspenseWithLoadingFallback>
                            ),
                            path: "canceled",
                          },
                        ],
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminGrantsLayout />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "grants",
                      },
                      {
                        children: [
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <AdminCtmsGrantDetailsPage />
                              </SuspenseWithLoadingFallback>
                            ),
                            index: true,
                          },
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <AdminExerciseRequestSimulationPage />
                              </SuspenseWithLoadingFallback>
                            ),
                            path: "exercise-request-simulation",
                          },
                          {
                            children: [
                              {
                                element: (
                                  <SuspenseWithLoadingFallback>
                                    <AdminCtmsGrantModifyPage />
                                  </SuspenseWithLoadingFallback>
                                ),
                                index: true,
                              },
                              {
                                element: (
                                  <SuspenseWithLoadingFallback>
                                    <AdminCtmsGrantModifyReviewPage />
                                  </SuspenseWithLoadingFallback>
                                ),
                                path: "review",
                              },
                            ],
                            element: <AdminCtmsGrantModifyLayout />,
                            path: "modify",
                          },
                        ],
                        path: "grants/ctms/:ctmsGrantId",
                      },
                      {
                        children: [
                          {
                            element: (
                              <Navigate replace to="equity-philosophy" />
                            ),
                            index: true,
                          },
                          {
                            children: [
                              {
                                element: (
                                  <SuspenseWithLoadingFallback>
                                    <ConfigureGrantsEquityPhilosophy />
                                  </SuspenseWithLoadingFallback>
                                ),
                                path: "equity-philosophy",
                              },
                              {
                                element: (
                                  <SuspenseWithLoadingFallback>
                                    <ConfigureGrantsEquityGrid />
                                  </SuspenseWithLoadingFallback>
                                ),
                                path: "equity-grid",
                              },
                              {
                                children: [
                                  {
                                    element: (
                                      <Navigate
                                        replace
                                        to="employees-and-contractors"
                                      />
                                    ),
                                    index: true,
                                  },
                                  {
                                    element: (
                                      <SuspenseWithLoadingFallback>
                                        <ConfigureGrantsNewHiresEmployeesAndContractors />
                                      </SuspenseWithLoadingFallback>
                                    ),
                                    path: "employees-and-contractors",
                                  },
                                  {
                                    element: (
                                      <SuspenseWithLoadingFallback>
                                        <ConfigureGrantsNewHiresAdvisors />
                                      </SuspenseWithLoadingFallback>
                                    ),
                                    path: "advisors",
                                  },
                                ],
                                element: <ConfigureGrantsNewHiresLayout />,
                                path: "new-hires",
                              },
                              {
                                element: (
                                  <SuspenseWithLoadingFallback>
                                    <ConfigureGrantsRefresher />
                                  </SuspenseWithLoadingFallback>
                                ),
                                path: "refreshers",
                              },
                              {
                                element: (
                                  <SuspenseWithLoadingFallback>
                                    <ConfigureGrantsVestingSchedules />
                                  </SuspenseWithLoadingFallback>
                                ),
                                path: "vesting-schedules",
                              },
                              {
                                element: (
                                  <SuspenseWithLoadingFallback>
                                    <ConfigureGrantsPostTermination />
                                  </SuspenseWithLoadingFallback>
                                ),
                                path: "post-termination",
                              },
                              {
                                element: (
                                  <SuspenseWithLoadingFallback>
                                    <ConfigureGrantsAcceleration />
                                  </SuspenseWithLoadingFallback>
                                ),
                                path: "acceleration",
                              },
                              {
                                element: (
                                  <SuspenseWithLoadingFallback>
                                    <ConfigureGrantsEarlyExercise />
                                  </SuspenseWithLoadingFallback>
                                ),
                                path: "early-exercise",
                              },
                            ],
                            element: (
                              <SuspenseWithLoadingFallback>
                                <ConfigureGrantsLayout />
                              </SuspenseWithLoadingFallback>
                            ),
                          },
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <ConfigureGrantsPostTerminationAddNew />
                              </SuspenseWithLoadingFallback>
                            ),
                            path: "post-termination/add-new",
                          },
                          {
                            children: [
                              {
                                element: (
                                  <SuspenseWithLoadingFallback>
                                    <ConfigureGrantsVestingSchedulesAddNew />
                                  </SuspenseWithLoadingFallback>
                                ),
                                path: "add-new",
                              },
                              {
                                element: (
                                  <SuspenseWithLoadingFallback>
                                    <ConfigureGrantsVestingSchedulesDetailsView />
                                  </SuspenseWithLoadingFallback>
                                ),
                                path: ":vestingScheduleId",
                              },
                            ],
                            path: "vesting-schedules",
                          },
                          {
                            children: [
                              {
                                element: (
                                  <SuspenseWithLoadingFallback>
                                    <ConfigureGrantsSingleTriggerAccelerationDetails />
                                  </SuspenseWithLoadingFallback>
                                ),
                                path: "single-trigger",
                              },
                              {
                                element: (
                                  <SuspenseWithLoadingFallback>
                                    <ConfigureGrantsDoubleTriggerAccelerationDetails />
                                  </SuspenseWithLoadingFallback>
                                ),
                                path: "double-trigger",
                              },
                            ],
                            path: "acceleration",
                          },
                        ],
                        path: "configure",
                      },
                    ],
                    path: "equity",
                  },
                  {
                    children: [
                      {
                        element: <Navigate replace to="drafts" />,
                        index: true,
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <FreemiumGuard
                              freemiumPage={<AdminGrantEquityFreemiumPage />}
                            >
                              <AdminEquityPrepareYourGrantsDraftsPage />
                            </FreemiumGuard>
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "drafts",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminEquityPrepareYourGrantsUnderReviewPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "under-review",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminEquityPrepareYourGrantsBoardApprovalPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "board-approval",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminEquityPrepareYourGrantsPendingApprovalPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "pending-approval",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminEquityPrepareYourGrantsPendingImplementationPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "pending-implementation",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminEquityPrepareYourGrantsGrantDetailsPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "grants/:easopGrantId",
                      },
                    ],
                    path: "prepare-your-grants",
                  },
                  {
                    children: [
                      {
                        element: <Navigate replace to="under-review" />,
                        index: true,
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <FreemiumGuard
                              freemiumPage={<AdminTerminationsFreemiumPage />}
                            >
                              <AdminGranteeTerminationGrantAmendmentRequestsUnderReviewPage />
                            </FreemiumGuard>
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "under-review",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminGranteeTerminationGrantAmendmentRequestsBoardApprovalPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "board-approval",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminGranteeTerminationGrantAmendmentRequestsPendingApprovalPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "pending-approval",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminGranteeTerminationGrantAmendmentRequestsImplementationPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "implementation",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <GranteeTerminationCTMSGrantAmendmentRequestPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: ":granteeTerminationCTMSGrantAmendmentRequestId",
                      },
                    ],
                    path: "grantee-termination-grant-amendment-requests",
                  },
                  {
                    children: [
                      {
                        element: <Navigate replace to="under-review" />,
                        index: true,
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminGrantAmendmentRequestsUnderReviewPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "under-review",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminGrantAmendmentRequestsBoardApprovalPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "board-approval",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminGrantAmendmentRequestsPendingApprovalPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "pending-approval",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminGrantAmendmentRequestsImplementationPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "implementation",
                      },
                      {
                        children: [
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <AdminCTMSGrantAmendmentRequestDetailsPage />
                              </SuspenseWithLoadingFallback>
                            ),
                            index: true,
                          },
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <AdminCTMSGrantAmendmentRequestDetailsReviewModificationPage />
                              </SuspenseWithLoadingFallback>
                            ),
                            path: "review-modification",
                          },
                        ],
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminCTMSGrantAmendmentRequestDetailsLayout />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: ":ctmsGrantAmendmentRequestId",
                      },
                    ],
                    path: "grant-amendment-requests",
                  },
                  {
                    children: [
                      {
                        element: <Navigate replace to="under-review" />,
                        index: true,
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminFairMarketValueApprovalsUnderReviewPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "under-review",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminFairMarketValueApprovalsBoardApprovalPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "board-approval",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminFairMarketValueApprovalsPendingApprovalPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "pending-approval",
                      },
                    ],
                    path: "fair-market-value-approvals",
                  },
                  {
                    children: [
                      {
                        element: <Navigate replace to="equity-grid" />,
                        index: true,
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <FreemiumGuard
                              freemiumPage={<AdminGranteePortalFreemiumPage />}
                            >
                              <GranteePortalSettingsPage />
                            </FreemiumGuard>
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "grantee-portal",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <FreemiumGuard
                              freemiumPage={<AdminEquityOffersFreemiumPage />}
                            >
                              <EquityOffersSettingsPage />
                            </FreemiumGuard>
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "equity-offers",
                      },
                    ],
                    path: "tools",
                  },
                  {
                    children: [
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <OrganizationSettingsPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        index: true,
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <OrganizationSettingsAdminsPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "admins",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <OrganizationSettingsEquityViewersPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "equity-viewers",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <OrganizationSettingsBoardMembersPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "board-members",
                      },
                      {
                        children: [
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <OrganizationSettingsSafeCalculatorVersionPage />
                              </SuspenseWithLoadingFallback>
                            ),
                            path: ":safeCalculatorVersionId",
                          },
                        ],
                        element: (
                          <SuspenseWithLoadingFallback>
                            <OrganizationSettingsSafeCalculatorLayout />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "safe-converter",
                      },
                      {
                        children: [
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <OrganizationSettingsIntegrationsPage />
                              </SuspenseWithLoadingFallback>
                            ),
                            index: true,
                          },
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <OrganizationSettingsIntegrationDeelPage />
                              </SuspenseWithLoadingFallback>
                            ),
                            path: "deel",
                          },
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <OrganizationSettingsIntegrationCartaPage />
                              </SuspenseWithLoadingFallback>
                            ),
                            path: "carta",
                          },
                        ],
                        path: "integrations",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <OrganizationSettingsBillingPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "billing",
                      },
                      {
                        children: [
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <AdminCleanupGranteesPage />
                              </SuspenseWithLoadingFallback>
                            ),
                            index: true,
                          },
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <AdminCleanupGranteesSynchronizeEmployeesDataPage />
                              </SuspenseWithLoadingFallback>
                            ),
                            path: "synchronize-employees-data",
                          },
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <AdminCleanupGranteesCreateNewGranteesPage />
                              </SuspenseWithLoadingFallback>
                            ),
                            path: "create-new-grantees",
                          },
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <AdminCleanupGranteesUpdateInformationPage />
                              </SuspenseWithLoadingFallback>
                            ),
                            path: "update-information",
                          },
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <AdminCleanupGranteesIgnoredPage />
                              </SuspenseWithLoadingFallback>
                            ),
                            path: "ignored",
                          },
                        ],
                        path: "cleanup-grantees",
                      },
                    ],
                    path: "settings",
                  },
                  {
                    children: [
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <FreemiumGuard
                              freemiumPage={<AdminExercisesFreemiumPage />}
                            >
                              <AdminExercisesPage />
                            </FreemiumGuard>
                          </SuspenseWithLoadingFallback>
                        ),
                        index: true,
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminExerciseRequestDetailsPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: ":ctmsExerciseRequestCTMSId",
                      },
                    ],
                    path: "exercises",
                  },
                  {
                    children: [
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminDocumentsPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        index: true,
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminGeographiesDocumentsPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "geographies",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminDocumentsBoardConsentsPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "board-consents",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminDocumentsGrantDocumentationPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "grant-documentation",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminDocumentsGrantDocumentationDetailsPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "grant-documentation/:easopGrantId",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminDocumentsLegalDocumentationPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "legal-documentation",
                      },
                    ],
                    path: "documents",
                  },
                  {
                    children: [
                      {
                        element: <Navigate replace to="../countries" />,
                        index: true,
                      },
                      {
                        element: (
                          <RedirectHandler to="../../countries/:countryCode" />
                        ),
                        path: ":countryCode",
                      },
                    ],
                    // DEPRECATED: temporary redirect to the new page
                    path: "geographies",
                  },
                  {
                    children: [
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <FreemiumGuard
                              freemiumPage={
                                <OrganizationCountriesFreemiumPage />
                              }
                            >
                              <OrganizationCountriesPage />
                            </FreemiumGuard>
                          </SuspenseWithLoadingFallback>
                        ),
                        index: true,
                      },
                      {
                        children: [
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <OrganizationCountriesUnlockPage />
                              </SuspenseWithLoadingFallback>
                            ),
                            index: true,
                          },
                          {
                            element: (
                              <SuspenseWithLoadingFallback>
                                <OrganizationCountryUnlockPage />
                              </SuspenseWithLoadingFallback>
                            ),
                            path: ":countryCode",
                          },
                        ],
                        path: "unlock",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <OrganizationCountriesCountryPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: ":countryCode",
                      },
                    ],
                    path: "countries",
                  },
                  {
                    children: [
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminInsightsHomePage />
                          </SuspenseWithLoadingFallback>
                        ),
                        index: true,
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminTeamIncentivizationPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "team-incentivization",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminLargestGainPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "largest-gain",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminUnderwaterGrantsPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "underwater-grants",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminExercisesActivityPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "exercises-activity",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminQuantityVestedPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "quantity-vested",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminCliffPeriodsPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "cliff-periods",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminPostTerminationExercisePeriodPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "ptep",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminInsightsVestingSchedulesPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "vesting-schedules",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminEquityGridLevelsPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "equity-grid-levels",
                      },
                    ],
                    path: "insights",
                  },
                  {
                    children: [
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminPlanningPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        index: true,
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminPlanningProjectionPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "projection",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <AdminPlanningIgnoredPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "ignored",
                      },
                    ],
                    element: (
                      <SuspenseWithLoadingFallback>
                        <AdminPlanningLayout />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "planning",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <FreemiumGuard
                          freemiumPage={<AdminGranteePortalFreemiumPage />}
                        >
                          <EmployeePortalsSettingsPage />
                        </FreemiumGuard>
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "employee-portals-settings",
                  },
                ],
                element: (
                  <SuspenseWithLoadingFallback>
                    <AdminLayout />
                  </SuspenseWithLoadingFallback>
                ),
              },
              {
                element: (
                  <SuspenseWithLoadingFallback>
                    <ExerciseRequestTaxInformationExportPage />
                  </SuspenseWithLoadingFallback>
                ),
                path: "exercise-request-tax-information/:ctmsExerciseRequestCTMSId",
              },
              {
                element: (
                  <SuspenseWithLoadingFallback>
                    <OrganizationCountriesEquityTypeWorkRelationshipPage />
                  </SuspenseWithLoadingFallback>
                ),
                path: "equity-types/:equityTypeId/work-relationships/:equityTypeWorkRelationshipCategory",
              },
              {
                children: [
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <EquityOfferCandidateSettingsPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "candidate",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <EquityOfferCompanySettingsPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "company",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <EquityOfferIncentiveSettingsPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "incentive",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <EquityOfferVestingSettingsPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "vesting",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <EquityOfferProjectionSettingsPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "projection",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <EquityOfferEquiuniversitySettingsPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "equiuniversity",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <EquityOfferFinalThoughtsSettingsPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "final-thoughts",
                  },
                ],
                element: (
                  <SuspenseWithLoadingFallback>
                    <EquityOfferLayout />
                  </SuspenseWithLoadingFallback>
                ),
                path: "equity-offer/:equityOfferId",
              },
              {
                children: [
                  {
                    element: <Navigate replace to="grantee" />,
                    index: true,
                  },
                  {
                    element: <Navigate replace to="grantee" />,
                    path: "grantee",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <AdminAssistedGrantGranteePage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "grantee/grantee",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <AdminAssistedGrantInstrumentPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "grantee/instrument",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <AdminAssistedGrantSharesPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "shares",
                  },
                  {
                    element: <Navigate replace to="vesting-schedule" />,
                    path: "vesting",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <AdminAssistedGrantVestingSchedulePage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "vesting/vesting-schedule",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <AdminAssistedGrantPTEPPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "vesting/post-termination-exercise-period",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <AdminAssistedGrantReviewPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "review",
                  },
                ],
                element: (
                  <SuspenseWithLoadingFallback>
                    <AssistedGrantLayout />
                  </SuspenseWithLoadingFallback>
                ),
                path: "assisted-grant",
              },
            ],
            element: (
              <SuspenseWithLoadingFallback>
                <OrganizationLayout />
              </SuspenseWithLoadingFallback>
            ),
            path: "organizations/:organizationId",
          },
          {
            children: [
              {
                element: <Navigate replace to="overview" />,
                index: true,
              },
              {
                element: (
                  <SuspenseWithLoadingFallback>
                    <BoardMembersOverviewPage />
                  </SuspenseWithLoadingFallback>
                ),
                path: "overview",
              },
              {
                element: (
                  <SuspenseWithLoadingFallback>
                    <BoardMembersApprovalsPage />
                  </SuspenseWithLoadingFallback>
                ),
                path: "approvals",
              },
              {
                element: (
                  <SuspenseWithLoadingFallback>
                    <BoardMemberEquityGridPage />
                  </SuspenseWithLoadingFallback>
                ),
                path: "equity/grid",
              },
            ],
            element: <BoardMemberLayout />,
            path: "board-member/:boardMemberId",
          },
          {
            children: [
              {
                children: [
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <FeatureFlagRouter
                          featureFlag="newGranteePortal"
                          ifDisabled={<GranteeEquityTypeSelectorPage />}
                          ifEnabled={<EmployeePortalPage />}
                        />
                      </SuspenseWithLoadingFallback>
                    ),
                    index: true,
                  },
                  {
                    element: <Navigate replace to="../" />,
                    path: "overview",
                  },
                  {
                    element: <Navigate replace to="../" />,
                    path: "selector",
                  },
                  {
                    children: [
                      {
                        element: <Navigate replace to="overview" />,
                        index: true,
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <GranteeOverviewPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "overview",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <GranteeExercisePage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "exercise",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <GranteeProjectionPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "projection",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <GranteeReportingPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "reporting",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <GranteeTaxationPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "taxation",
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <GranteeFAQPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: "faq",
                      },
                    ],
                    element: (
                      <SuspenseWithLoadingFallback>
                        <GranteeEquityTypesGroupLayout />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: ":equityTypesGroup",
                  },
                ],
                element: (
                  <SuspenseWithLoadingFallback>
                    <GranteeLayout />
                  </SuspenseWithLoadingFallback>
                ),
              },
            ],
            path: "grantee/:granteeId",
          },
          {
            children: [
              {
                element: <Navigate replace to="legal-content" />,
                index: true,
              },
              {
                children: [
                  {
                    element: <SuperAdminLegalContentPage />,
                    index: true,
                  },
                  {
                    children: [
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <SuperAdminExercisesTaxParametersPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        index: true,
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <SuperAdminExercisesTaxParametersEditionPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: ":equityTypeId",
                      },
                    ],
                    path: "exercises-tax-parameters",
                  },
                  {
                    children: [
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <SuperAdminLegalWatchOutsPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        index: true,
                      },
                      {
                        element: (
                          <SuspenseWithLoadingFallback>
                            <SuperAdminLegalWatchOutsEditionPage />
                          </SuspenseWithLoadingFallback>
                        ),
                        path: ":equityTypeId",
                      },
                    ],
                    path: "legal-watch-outs",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <SuperAdminEarlyExerciseBeneficialPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "early-exercise-beneficial",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <SuperAdminOngoingObligationsPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "ongoing-obligations",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <SuperAdminDocumentsApprovalPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "documents-approval",
                  },
                ],
                path: "legal-content",
              },
              {
                children: [
                  {
                    element: <SuperAdminOrganizationAdminPage />,
                    index: true,
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <SuperAdminChangeGrantInstrumentPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "change-grant-instrument",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <SuperAdminRepricingPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "repricing",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <SuperAdminEquityAlertingPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "equity-alerting",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <SubplanApprovalDate />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "subplan-approval-date",
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <RemoteEquityPlanThresholdsExceeded />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: "remote-equity-plan-thresholds-exceeded",
                  },
                ],
                path: "organization-admin",
              },
              {
                children: [
                  {
                    element: <SuperAdminDocumentsUploadPage />,
                    index: true,
                  },
                  {
                    element: (
                      <SuspenseWithLoadingFallback>
                        <SuperAdminDocumentsUploadOrganizationViewPage />
                      </SuspenseWithLoadingFallback>
                    ),
                    path: ":organizationId",
                  },
                ],
                path: "documents-upload",
              },
              {
                element: <SuperAdminBulkUploadDraftsPage />,
                path: "bulk-upload-drafts",
              },
              {
                element: (
                  <SuspenseWithLoadingFallback>
                    <SuperAdminUnusedGeographiesPage />
                  </SuspenseWithLoadingFallback>
                ),
                path: "unused-geographies",
              },
              {
                element: (
                  <SuspenseWithLoadingFallback>
                    <SuperAdminCartaOrganizationsPage />
                  </SuspenseWithLoadingFallback>
                ),
                path: "carta-organizations",
              },
            ],
            element: (
              <SuspenseWithLoadingFallback>
                <SuperAdminLayout />
              </SuspenseWithLoadingFallback>
            ),
            path: "administration",
          },
          {
            element: <DebugPage />,
            path: "debug",
          },
          {
            element: <CrashTestPage />,
            path: "crash-test",
          },
          {
            element: <NotFoundPage />,
            path: "*",
          },
        ],
        element: (
          <AuthenticatedGuard
            loginPageRender={({ onLoginSuccess }) => (
              <LoginPage onLoginSuccess={onLoginSuccess} />
            )}
          >
            <Outlet />
          </AuthenticatedGuard>
        ),
      },
    ],
    element: (
      <>
        <MaintenanceInProgressGuard>
          <AuthenticationProvider>
            <RootLayout>
              <Outlet />
            </RootLayout>
          </AuthenticationProvider>
        </MaintenanceInProgressGuard>
      </>
    ),
    errorElement: <CriticalErrorPage />,
  },
]);

const Router: React.FC = () => {
  useUpgradeNotificationDispatcher();

  return <RouterProvider router={router} />;
};

export default Router;
