/**
 * @generated SignedSource<<044206616b16bd4a72131778c80c4fd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PreviewGranteesPortalModal_Grantees$data = ReadonlyArray<{
  readonly email: string;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"PreviewGranteesPortalModal_PreviewGranteeRow_Grantees" | "RevokeGranteeAccessModal_Grantee">;
  readonly " $fragmentType": "PreviewGranteesPortalModal_Grantees";
}>;
export type PreviewGranteesPortalModal_Grantees$key = ReadonlyArray<{
  readonly " $data"?: PreviewGranteesPortalModal_Grantees$data;
  readonly " $fragmentSpreads": FragmentRefs<"PreviewGranteesPortalModal_Grantees">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PreviewGranteesPortalModal_Grantees",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PreviewGranteesPortalModal_PreviewGranteeRow_Grantees"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RevokeGranteeAccessModal_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "90be6154308f91ff3ec0168269de45bf";

export default node;
