/**
 * @generated SignedSource<<cf33b821b3a985ee4fe938be46cd60f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConnectHRISProvidersMessage_Organization$data = {
  readonly id: string;
  readonly isConnectedToDeel: boolean;
  readonly isConnectedToRemoteEquity: boolean;
  readonly " $fragmentType": "ConnectHRISProvidersMessage_Organization";
};
export type ConnectHRISProvidersMessage_Organization$key = {
  readonly " $data"?: ConnectHRISProvidersMessage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectHRISProvidersMessage_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConnectHRISProvidersMessage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToDeel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToRemoteEquity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "b18f5b0f73c13eedcefb1e3d6d8b9e85";

export default node;
