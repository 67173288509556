import { Article, Aside, Paragraph } from "./Utils";

export function VestingSchedule() {
  return (
    <Article>
      <Article title="Vesting schedule">
        <Paragraph>
          You don’t own these shares, yet! You&apos;ll need to work for the
          company for a certain time before you unlock the options giving you
          the right to buy these shares. We call this unlocking process the
          “vesting”. Have a look at your portal to see how many options have
          vested or have yet to be vested.
        </Paragraph>
        <Paragraph>
          Your company has set a specific schedule 🗓 (called the “vesting
          schedule”) defining when you vest (unlock) your options. Usually, you
          will not vest (unlock) any of them in your first year at the company,
          to be sure you’re fully onboard 🚢 before allowing you to own a slice
          of the company.
        </Paragraph>
        <Aside title="Definition">
          Also called vesting period, the vesting schedule determines when the
          option holder may exercise their options. A common type of vesting
          schedules for startups is 4 years, with a 1 year cliff and a monthly
          vesting → 25% of the options vest at the end of the 1st year, and the
          remaining options vest on a monthly basis throughout the next 3 years.
          Another type of vesting schedule is “backloaded vesting”, in which the
          majority of the options become vested towards the later stages of the
          vesting period. Most vesting are time-based, but some may be linked to
          specific individual or collective performance or milestones.
        </Aside>
      </Article>
      <Article title="You get it: ">
        <Paragraph>
          The idea is to make you committed for the long run! 🤝 You will then
          progressively vest (unlock) your options, month after month (or
          quarter after quarter) as you stay at the company, until you unlock
          100% of them! You can see below how many options you have already
          vested (unlocked):
        </Paragraph>
      </Article>
    </Article>
  );
}
