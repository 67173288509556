import React from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { Divider } from "../../../../components/ui/Divider";
import { CenteredColumnLayout } from "../../../../components/ui/Layout/CenteredColumnLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Typography } from "../../../../components/ui/Typography";
import { useQuery } from "../../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { useSetAdminPageVisited } from "../../useSetAdminPageVisited";
import { GranteePortalSettings_Organization$key } from "./__generated__/GranteePortalSettings_Organization.graphql";
import { GranteePortalSettings_Query } from "./__generated__/GranteePortalSettings_Query.graphql";
import { GranteePortalSettingsDisplayFullyDilutedValuesSection } from "./GranteePortalSettingsDisplayFullyDilutedValuesSection";
import { GranteePortalSettingsProjectionSection } from "./GranteePortalSettingsProjectionSection";
import { GranteePortalSettingsUsageSection } from "./GranteePortalSettingsUsageSection";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteePortalSettings_Organization on Organization
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    id
    name
    granteePortalInvitationIsAllowed
    ...GranteePortalSettingsProjectionSection_Organization
    ...GranteePortalSettingsUsageSection_Organization
      @arguments(organizationId: $organizationId)
    ...GranteePortalSettingsDisplayFullyDilutedValuesSection_Organization
  }
`;

const GranteePortalSettingsPage_: React.FC<{
  onGrantCreated: () => void;
  onGranteeCreated: () => void;
  onGranteeDeleted: () => void;
  onGranteesInvited: () => void;
  onGranteeUpdated: () => void;
  organizationFragment: GranteePortalSettings_Organization$key;
}> = ({
  onGrantCreated,
  onGranteeCreated,
  onGranteeDeleted,
  onGranteesInvited,
  onGranteeUpdated,
  organizationFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  useSetAdminPageVisited({
    organizationId: organization.id,
    page: "GRANTEE_PORTAL_SETTINGS",
  });

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES.organizationHome, {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to=".">Grantee portal</BreadCrumb.Link>
        </BreadCrumb>
      }
    >
      <RoundedBox withBorder withShadow>
        <div className="space-y-4 p-10">
          <Typography as="div" variant="Medium/Default">
            Grantee portal
          </Typography>
          {organization.granteePortalInvitationIsAllowed && (
            <GranteePortalSettingsUsageSection
              onGrantCreated={onGrantCreated}
              onGranteeCreated={onGranteeCreated}
              onGranteeDeleted={onGranteeDeleted}
              onGranteesInvited={onGranteesInvited}
              onGranteeUpdated={onGranteeUpdated}
              organizationFragment={organization}
            />
          )}
        </div>

        <Divider />

        <GranteePortalSettingsDisplayFullyDilutedValuesSection
          organizationFragment={organization}
        />

        <Divider />

        <GranteePortalSettingsProjectionSection
          organizationFragment={organization}
        />
      </RoundedBox>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query GranteePortalSettings_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...GranteePortalSettings_Organization
        @arguments(organizationId: $organizationId)
    }
  }
`;

const GranteePortalSettingsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();

  const {
    query: { organization },
    refreshQueryWithoutSuspense,
  } = useQuery<GranteePortalSettings_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Settings"
      analyticsName="Admin - Grantee portal settings"
      organizationId={organization.id}
      title={`Admin | ${organization.name} Grantee portal settings`}
    >
      <GranteePortalSettingsPage_
        onGrantCreated={refreshQueryWithoutSuspense}
        onGranteeCreated={refreshQueryWithoutSuspense}
        onGranteeDeleted={refreshQueryWithoutSuspense}
        onGranteesInvited={refreshQueryWithoutSuspense}
        onGranteeUpdated={refreshQueryWithoutSuspense}
        organizationFragment={organization}
      />
    </Page>
  );
};

export default GranteePortalSettingsPage;
