/**
 * @generated SignedSource<<f40d8f628d775ce297b9225b2b5b7596>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeDetails_Viewer$data = {
  readonly isAllowedToManageOrganization: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeDetailsBox_Viewer" | "GranteeTotalOwnershipBox_Viewer">;
  readonly " $fragmentType": "GranteeDetails_Viewer";
};
export type GranteeDetails_Viewer$key = {
  readonly " $data"?: GranteeDetails_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeDetails_Viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeDetails_Viewer",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "isAllowedToManageOrganization",
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "GranteeTotalOwnershipBox_Viewer"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "GranteeDetailsBox_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};
})();

(node as any).hash = "eb785dbde65bc8a075d9fc8de09078d1";

export default node;
