/**
 * @generated SignedSource<<4fb39df1eed5329897d43863bfac4352>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalsSettingsBody_Employees$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeTableRow_Employees">;
  readonly " $fragmentType": "EmployeePortalsSettingsBody_Employees";
}>;
export type EmployeePortalsSettingsBody_Employees$key = ReadonlyArray<{
  readonly " $data"?: EmployeePortalsSettingsBody_Employees$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalsSettingsBody_Employees">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "EmployeePortalsSettingsBody_Employees",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeeTableRow_Employees"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "3b45feabc79a757ea871c6504d6e2168";

export default node;
