/**
 * @generated SignedSource<<ab244c08edbf0eba8e149b0760e34503>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HRISProvider = "DEEL" | "REMOTE";
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalPage_Grantee$data = {
  readonly hRISProviderEmployee: {
    readonly hRISProvider: HRISProvider;
  } | null;
  readonly organization: {
    readonly isOriginatingFromRemoteEquity: boolean;
  };
  readonly pageRootCTMSGrants: ReadonlyArray<{
    readonly __typename: "CTMSGrant";
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalExerciseSection_Grantee" | "EmployeePortalGrantAndValueDetailsSection_Grantee" | "EmployeePortalOwnershipSection_Grantee" | "EmployeePortalReportingAndGeneralTaxationSection_Grantee" | "EmployeePortalVestingSection_Grantee">;
  readonly " $fragmentType": "EmployeePortalPage_Grantee";
};
export type EmployeePortalPage_Grantee$key = {
  readonly " $data"?: EmployeePortalPage_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalPage_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalPage_Grantee",
  "selections": [
    {
      "alias": "pageRootCTMSGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "grantStatusIn",
          "value": [
            "Active",
            "Terminated"
          ]
        }
      ],
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": "ctmsGrants(grantStatusIn:[\"Active\",\"Terminated\"])"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOriginatingFromRemoteEquity",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "hRISProviderEmployee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hRISProvider",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeePortalOwnershipSection_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeePortalVestingSection_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeePortalGrantAndValueDetailsSection_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeePortalExerciseSection_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeePortalReportingAndGeneralTaxationSection_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "f156a3182a2783fdfb0e9623c54eba63";

export default node;
