/**
 * @generated SignedSource<<750fdd1dbe96880aaed85528f1408dd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type OrganizationPlan = "freeTrial" | "freemiumDemoBooked" | "freemiumDemoToBook" | "fullAccess";
export type RemoteOnboardingStep = "CLEAN_UP_GRANTEE_DATA" | "CONNECT_CTMS" | "SET_PRICE_PER_SHARE" | "TEST_LAW_FIRM_CARTA_CONNECTION";
import { FragmentRefs } from "relay-runtime";
export type AdminLayout_Organization$data = {
  readonly currentRemoteOnboardingStep: RemoteOnboardingStep | null;
  readonly id: string;
  readonly isRemoteEquityBasic: boolean;
  readonly name: string;
  readonly plan: OrganizationPlan;
  readonly " $fragmentSpreads": FragmentRefs<"AdvancedApplicationSidebar_Organization" | "ApplicationSideBar_Organization" | "BasicApplicationSidebar_Organization" | "BookADemoModal_Organization" | "FreeTrialPlanStickyBanner_Organization">;
  readonly " $fragmentType": "AdminLayout_Organization";
};
export type AdminLayout_Organization$key = {
  readonly " $data"?: AdminLayout_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminLayout_Organization">;
};

import AdminLayout_Organization_RefetchableQuery_graphql from './AdminLayout_Organization_RefetchableQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": AdminLayout_Organization_RefetchableQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "AdminLayout_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plan",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentRemoteOnboardingStep",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRemoteEquityBasic",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplicationSideBar_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FreeTrialPlanStickyBanner_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookADemoModal_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdvancedApplicationSidebar_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BasicApplicationSidebar_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "c3664bd9197a25f75521086a3b3a7ce8";

export default node;
