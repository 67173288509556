import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Footer } from "../../../components/Footer";
import { Page } from "../../../components/Page";
import { HelpCard } from "../../../components/ui/HelpCard";
import { useQuery } from "../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { EmployeePortalsSettings_Organization$key } from "./__generated__/EmployeePortalsSettings_Organization.graphql";
import { EmployeePortalsSettings_Query } from "./__generated__/EmployeePortalsSettings_Query.graphql";
import { EmployeePortalsSettingsBody } from "./EmployeePortalsSettingsBody";
import { EmployeePortalTopSection } from "./EmployeePortalTopSection";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EmployeePortalsSettings_Organization on Organization {
    id
    ...EmployeePortalTopSection_Organization
    ...Footer_Organization
    ...EmployeePortalsSettingsBody_Organization
  }
`;

function EmployeePortalsSettingsPage_({
  organizationFragment,
}: {
  organizationFragment: EmployeePortalsSettings_Organization$key;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <div className="container mx-auto w-full max-w-[1032px] space-y-6 px-6 py-16">
      <div className="font-remote-brand text-2XL/Medium">Employee portals</div>
      <EmployeePortalTopSection organizationFragment={organization} />
      <EmployeePortalsSettingsBody organizationFragment={organization} />
      <HelpCard
        buttonLabel="Contact Us"
        label="Any questions? Feel free to contact us"
      />
      <Footer organizationFragment={organization} />
    </div>
  );
}

const QUERY = graphql`
  query EmployeePortalsSettings_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...EmployeePortalsSettings_Organization
    }
  }
`;

export default function EmployeePortalsSettingsPage() {
  const organizationId = useOrganizationIdParam();

  const {
    query: { organization },
  } = useQuery<EmployeePortalsSettings_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Settings"
      analyticsName="Admin - Employee portals settings"
      organizationId={organization.id}
      title={`Admin | ${organization.name} Employee portal settings`}
    >
      <EmployeePortalsSettingsPage_ organizationFragment={organization} />
    </Page>
  );
}
