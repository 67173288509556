/**
 * @generated SignedSource<<8362cd867a71787456b50f86a68e0a48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type FairMarketValueStatus = "Approved" | "Draft" | "PendingBoardConsent" | "PendingReview" | "Reviewed";
import { FragmentRefs } from "relay-runtime";
export type BoardApproval_FairMarketValues_Organization$data = {
  readonly fairMarketValueHistory: ReadonlyArray<{
    readonly id: string;
    readonly status: FairMarketValueStatus;
    readonly " $fragmentSpreads": FragmentRefs<"ConfirmSubmitBoardConsentSlide_FairMarketValue" | "FairMarketValueApprovalTable_FairMarketValue">;
  }>;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmSubmitBoardConsentSlide_Organization" | "LegalWorkflowLayout_Organization">;
  readonly " $fragmentType": "BoardApproval_FairMarketValues_Organization";
};
export type BoardApproval_FairMarketValues_Organization$key = {
  readonly " $data"?: BoardApproval_FairMarketValues_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardApproval_FairMarketValues_Organization">;
};

import BoardApproval_FairMarketValues_Organization_RefetchQuery_graphql from './BoardApproval_FairMarketValues_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": BoardApproval_FairMarketValues_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "BoardApproval_FairMarketValues_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "fairMarketValueHistory",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FairMarketValueApprovalTable_FairMarketValue"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ConfirmSubmitBoardConsentSlide_FairMarketValue"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LegalWorkflowLayout_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConfirmSubmitBoardConsentSlide_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "89433026b1159f126818d9ac6e828d19";

export default node;
