/**
 * @generated SignedSource<<4f3ab6e8cfa58ade1a81ddc137dd9c6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MatchGranteeView_Organization_Subscription$variables = {
  id: string;
};
export type MatchGranteeView_Organization_Subscription$data = {
  readonly organizationUpdates: {
    readonly " $fragmentSpreads": FragmentRefs<"MatchGranteeView_Organization">;
  };
};
export type MatchGranteeView_Organization_Subscription = {
  response: MatchGranteeView_Organization_Subscription$data;
  variables: MatchGranteeView_Organization_Subscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "hRISProvider",
    "value": "REMOTE"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
},
v7 = [
  (v5/*: any*/)
],
v8 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hRISProviderEmployeeId",
    "storageKey": null
  },
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hRISProvider",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "ignored",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Grantee",
    "kind": "LinkedField",
    "name": "grantee",
    "plural": false,
    "selections": (v7/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Grantee",
    "kind": "LinkedField",
    "name": "suggestedGrantee",
    "plural": false,
    "selections": (v7/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MatchGranteeView_Organization_Subscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationUpdates",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MatchGranteeView_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MatchGranteeView_Organization_Subscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationUpdates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remoteEquityCTMSSyncCompleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "HRISProviderEmployee",
            "kind": "LinkedField",
            "name": "unmatchedHRISProviderEmployees",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": "unmatchedHRISProviderEmployees(hRISProvider:\"REMOTE\")"
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "HRISProviderEmployee",
            "kind": "LinkedField",
            "name": "matchedHRISProviderEmployees",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": "matchedHRISProviderEmployees(hRISProvider:\"REMOTE\")"
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "HRISProviderEmployee",
            "kind": "LinkedField",
            "name": "ignoredHRISProviderEmployees",
            "plural": true,
            "selections": (v8/*: any*/),
            "storageKey": "ignoredHRISProviderEmployees(hRISProvider:\"REMOTE\")"
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GranteesConnection",
            "kind": "LinkedField",
            "name": "grantees",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GranteeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Grantee",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v4/*: any*/),
                      (v3/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remoteCompanyId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToPulley",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToAngelList",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1782ccf2b08d8c75af6055202e96c44e",
    "id": null,
    "metadata": {},
    "name": "MatchGranteeView_Organization_Subscription",
    "operationKind": "subscription",
    "text": "subscription MatchGranteeView_Organization_Subscription(\n  $id: OrganizationId!\n) {\n  organizationUpdates(id: $id) {\n    ...MatchGranteeView_Organization\n    id\n  }\n}\n\nfragment MatchGranteeTableRow_HRISProviderEmployee on HRISProviderEmployee {\n  hRISProviderEmployeeId\n  name\n  email\n  jobTitle\n  hRISProvider\n  ignored\n  grantee {\n    id\n  }\n  suggestedGrantee {\n    id\n  }\n}\n\nfragment MatchGranteeTableRow_Organization on Organization {\n  id\n  grantees {\n    edges {\n      node {\n        id\n        name\n        email\n        jobTitle\n      }\n    }\n  }\n  ...useTrackRemoteEquityOnboardingFlowEvent_Organization\n}\n\nfragment MatchGranteeTable_HRISProviderEmployee on HRISProviderEmployee {\n  id\n  ...MatchGranteeTableRow_HRISProviderEmployee\n}\n\nfragment MatchGranteeTable_Organization on Organization {\n  id\n  ...MatchGranteeTableRow_Organization\n}\n\nfragment MatchGranteeView_Organization on Organization {\n  remoteEquityCTMSSyncCompleted\n  unmatchedHRISProviderEmployees(hRISProvider: REMOTE) {\n    email\n    name\n    ...MatchGranteeTable_HRISProviderEmployee\n    id\n  }\n  matchedHRISProviderEmployees(hRISProvider: REMOTE) {\n    email\n    name\n    ...MatchGranteeTable_HRISProviderEmployee\n    id\n  }\n  ignoredHRISProviderEmployees(hRISProvider: REMOTE) {\n    email\n    name\n    ...MatchGranteeTable_HRISProviderEmployee\n    id\n  }\n  ...MatchGranteeTable_Organization\n  ...useOrganizationCTMS_Organization\n  ...useTrackRemoteEquityOnboardingFlowEvent_Organization\n  id\n}\n\nfragment useOrganizationCTMS_Organization on Organization {\n  isConnectedToCarta\n  isConnectedToPulley\n  isConnectedToAngelList\n}\n\nfragment useTrackRemoteEquityOnboardingFlowEvent_Organization on Organization {\n  id\n  name\n  remoteCompanyId\n}\n"
  }
};
})();

(node as any).hash = "9526b487d6ac282f38401c9bd44b66d4";

export default node;
