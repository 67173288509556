import { BoxedIcon, Text } from "@remote-com/norma";
import { IconV2DuotoneBriefcase } from "@remote-com/norma/icons/IconV2DuotoneBriefcase";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { ColoredBoxValue } from "../../../components/ColoredBoxValue";
import { FormattedCurrency } from "../../../components/Formatted/FormattedCurrency";
import { FormattedCurrencyCompact } from "../../../components/Formatted/FormattedCurrencyCompact";
import { FormattedNumber } from "../../../components/Formatted/FormattedNumber";
import { RangeInput } from "../../../components/RangeInput";
import { SectionHero } from "../../../components/SectionHero";
import { useCtmsGrantAndSharesToNetEquityValue } from "../../../hooks/useCtmsGrantAndSharesToNetEquityValue";
import { EmployeePortalWhatDoYouHaveSection_Grantee$key } from "./__generated__/EmployeePortalWhatDoYouHaveSection_Grantee.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment EmployeePortalWhatDoYouHaveSection_Grantee on Grantee {
    ctmsGrantsCount
    totalGrantedSharesBreakdown {
      total
    }
    ctmsGrants(
      grantStatusIn: [Active, Terminated]
      orderBy: { field: vestingStartDate, direction: ASC }
    ) {
      quantityIssued
      # eslint-disable-next-line relay/unused-fields
      exercisePrice
    }
    organization {
      latestValuation
      granteePortalSettings {
        displayFullyDilutedValues
      }
      ...useCtmsGrantAndSharesToNetEquityValue_Organization
    }
  }
`;

export function EmployeePortalWhatDoYouHaveSection({
  granteeFragment,
  onValuationMultipleChange,
  valuationMultiple,
}: {
  granteeFragment: EmployeePortalWhatDoYouHaveSection_Grantee$key;
  onValuationMultipleChange: (valuationMultiple: number) => void;
  valuationMultiple: number;
}) {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const totalGrantedShares = grantee.totalGrantedSharesBreakdown.total;

  const { ctmsGrantAndSharesToNetEquityValue } =
    useCtmsGrantAndSharesToNetEquityValue({
      organizationFragment: grantee.organization,
      valuationMultiple,
    });

  const totalMultipliedNetEquityValueInUSD = useMemo(() => {
    return grantee.ctmsGrants.reduce<null | number>(
      (totalMultipliedNetEquityValueInUSD, ctmsGrant) => {
        const multipliedNetEquityValueInUSD =
          ctmsGrantAndSharesToNetEquityValue({
            ctmsGrant,
            shares: ctmsGrant.quantityIssued,
          });

        if (
          multipliedNetEquityValueInUSD === null ||
          totalMultipliedNetEquityValueInUSD === null
        ) {
          return (
            totalMultipliedNetEquityValueInUSD ?? multipliedNetEquityValueInUSD
          );
        }

        return (
          totalMultipliedNetEquityValueInUSD + multipliedNetEquityValueInUSD
        );
      },
      null,
    );
  }, [grantee.ctmsGrants, ctmsGrantAndSharesToNetEquityValue]);

  const displayFullyDilutedValues =
    grantee.organization.granteePortalSettings.displayFullyDilutedValues;

  return (
    <SectionHero id="what-do-you-have" withBorder>
      <div className="space-y-6">
        <div className="flex items-center gap-3">
          <BoxedIcon Icon={IconV2DuotoneBriefcase} size="sm" tone="pink" />
          <Text variant="lgMedium">What do you have exactly?</Text>
        </div>
        <Text className="text-grey-600" variant="sm">
          You will often be told about a number of shares, but what really
          matters for you is the potential value of these shares. You will see
          below what your shares could potentially be worth in terms of $ value
          as of today:
        </Text>
        <div className="flex flex-wrap gap-4 lg:gap-6">
          <ColoredBoxValue
            className="flex-1"
            title="Compensation"
            tone="blueLight"
            unit={
              <FormattedMessage
                defaultMessage="{count, plural, one {grant} other {grants}}"
                values={{
                  count: grantee.ctmsGrantsCount,
                }}
              />
            }
            value={grantee.ctmsGrantsCount}
          />
          <ColoredBoxValue
            className="flex-1"
            title="Ownership"
            tone="green"
            unit="shares"
            value={<FormattedNumber animated value={totalGrantedShares} />}
          />
          {displayFullyDilutedValues &&
            totalMultipliedNetEquityValueInUSD !== null && (
              <ColoredBoxValue
                className="flex-1"
                title="Net Equity Value"
                tone="purple"
                unit="USD"
                value={
                  <FormattedCurrency
                    animated
                    maximumFractionDigits={0}
                    value={totalMultipliedNetEquityValueInUSD}
                  />
                }
              />
            )}
        </div>
        {displayFullyDilutedValues && (
          <div className="flex flex-col gap-4 rounded-2xl bg-background-subtle p-6 lg:flex-row lg:items-center">
            <div className="flex-1 space-y-2">
              <div className="space-y-1">
                <Text variant="medium">
                  The company&apos;s model over the years
                </Text>
                <Text className="text-grey-600" variant="sm">
                  This would be the company valuation multiplied by
                </Text>
              </div>
              <RangeInput
                id="threshold-range"
                max={10}
                min={1}
                onChange={onValuationMultipleChange}
                renderStepLabel={({ step }) => `${step}x`}
                step={1}
                tone="purple"
                value={valuationMultiple}
              />
            </div>
            {grantee.organization.latestValuation !== null && (
              <div className="space-y-2 text-center lg:text-right">
                <Text variant="lgMedium">Company valuation</Text>
                <Text className="text-purple-700" variant="4xlMedium">
                  <FormattedCurrencyCompact
                    animated
                    value={
                      grantee.organization.latestValuation * valuationMultiple
                    }
                  />
                </Text>
              </div>
            )}
          </div>
        )}
      </div>
    </SectionHero>
  );
}
