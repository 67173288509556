import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { BoxedIcon, Button, Text } from "@remote-com/norma";
import { IconFileSnake } from "@remote-com/norma/icons/IconFileSnake";
import { IconUmbrella } from "@remote-com/norma/icons/IconUmbrella";
import { ArrowDown } from "iconsax-react";
import { forwardRef, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { RemoteLikeInfoBlock } from "../../../components/RemoteLikeInfoBlock";
import { SectionHero } from "../../../components/SectionHero";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../services/workRelationship";
import { Equity101DrawerRemote } from "../Equity101Drawer/Equity101Drawer";
import { generateEmployeePortalSectionAnchorPath } from "../useSections";
import { EmployeePortalWelcomeSection_Grantee$key } from "./__generated__/EmployeePortalWelcomeSection_Grantee.graphql";
import illustration from "./illustration.png";

const GRANTEE_FRAGMENT = graphql`
  fragment EmployeePortalWelcomeSection_Grantee on Grantee {
    id
    name
    email
    workRelationship
    jobTitle
    taxResidenceCountry {
      emoji
      name
    }
    organization {
      name
    }
  }
`;

export const EmployeePortalWelcomeSection = forwardRef<
  HTMLDivElement,
  {
    granteeFragment: EmployeePortalWelcomeSection_Grantee$key;
    id?: string;
  }
>(function EmployeePortalWelcomeSection(
  { granteeFragment, id = "welcome" },
  ref,
) {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const { organization } = grantee;

  const details = useMemo(
    () => [
      { key: "full-name", label: "Full name", value: grantee.name },
      { key: "email", label: "Email", value: grantee.email },
      {
        key: "country",
        label: "Country of residence",
        value: grantee.taxResidenceCountry ? (
          <>
            {grantee.taxResidenceCountry.emoji}{" "}
            {grantee.taxResidenceCountry.name}
          </>
        ) : null,
      },
      {
        key: "work-relationship",
        label: "Employment type",
        value: grantee.workRelationship
          ? WORK_RELATIONSHIP_TO_LABEL_HELPER[grantee.workRelationship]
              .singularLabel
          : null,
      },
      {
        key: "job-title",
        label: "Job title",
        value: grantee.jobTitle,
      },
    ],
    [grantee],
  );

  return (
    <SectionHero
      className="bg-background-subtle"
      hideBorderOnMobile
      id={id}
      ref={ref}
      withBorder
    >
      <div className="flex items-center gap-10">
        <div className="flex-1 space-y-4">
          <BoxedIcon Icon={IconUmbrella} size="lg" tone="orange" />
          <Text variant="3xlMedium">Welcome {grantee.name}!</Text>
          <Text variant="lgMedium">
            You are the proud owner of equity from {organization.name} 🎉
          </Text>
          <Text className="text-grey-600" variant="sm">
            Here you can find detailed information on the equity you receive for
            being part of the team at {organization.name}.
            <span className="hidden lg:inline">
              {" "}
              Should you have any questions, make sure you click on the{" "}
              <Equity101DrawerRemote.Controller
                render={({ open }) => (
                  <button
                    className="text-brand-600"
                    onClick={() => {
                      open({ data: {} });
                    }}
                  >
                    Equity 101
                  </button>
                )}
              />{" "}
              tab.
            </span>
          </Text>
          <Button
            as="a"
            href={generateEmployeePortalSectionAnchorPath({
              anchor: "what-do-you-have",
              granteeId: grantee.id,
            })}
            IconAfter={ArrowDown}
            size="md"
          >
            Discover
          </Button>
          <RoundedBox background="base" className="w-full p-4">
            <Text className="text-grey-600" variant="xs">
              The values represented on this portal page represent a potential
              value in case of a liquidity event of the company{" "}
              {organization.name}. Should you have any questions related to the
              data shown here, please{" "}
              <span className="hidden lg:inline">
                refer to the{" "}
                <Equity101DrawerRemote.Controller
                  render={({ open }) => (
                    <button
                      className="text-brand-600"
                      onClick={() => {
                        open({ data: {} });
                      }}
                    >
                      Equity 101
                    </button>
                  )}
                />{" "}
                or{" "}
              </span>
              contact your company admin .
            </Text>
          </RoundedBox>
        </div>
        <img className="hidden w-[320px] lg:block" src={illustration} />
      </div>
      <RoundedBox className="space-y-6 p-6" withBorder>
        <Disclosure>
          {({ open }) => (
            <>
              <div className="flex items-center gap-2">
                <BoxedIcon Icon={IconFileSnake} size="sm" tone="grey" />
                <Text className="flex-1" variant="lgMedium">
                  Your employment details
                </Text>
                <DisclosureButton>
                  <Button
                    IconAfter={open ? ChevronUpIcon : ChevronDownIcon}
                    size="sm"
                    tone="primary"
                    variant="outline"
                  >
                    {open ? <>Hide</> : <>Show</>}
                  </Button>
                </DisclosureButton>
              </div>

              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <DisclosurePanel>
                  <RemoteLikeInfoBlock info={details} />
                </DisclosurePanel>
              </Transition>
            </>
          )}
        </Disclosure>
      </RoundedBox>
    </SectionHero>
  );
});
