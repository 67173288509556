/**
 * @generated SignedSource<<e6579ecb69f24339208558b060b2ff31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VestingSchedules_Query$variables = {
  organizationId: string;
};
export type VestingSchedules_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"VestingSchedules_Viewer">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"VestingSchedules_Organization">;
  } | null;
};
export type VestingSchedules_Query = {
  response: VestingSchedules_Query$data;
  variables: VestingSchedules_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VestingSchedules_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VestingSchedules_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VestingSchedules_Viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VestingSchedules_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationCTMSGrantsConnection",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationCTMSGrantsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CTMSGrant",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "vestingScheduleName",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Grantee",
                        "kind": "LinkedField",
                        "name": "grantee",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToPulley",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToAngelList",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ce1c93379f8a4cdcffea108bf1dbbbb",
    "id": null,
    "metadata": {},
    "name": "VestingSchedules_Query",
    "operationKind": "query",
    "text": "query VestingSchedules_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    name\n    id\n    ...VestingSchedules_Organization\n  }\n  me {\n    ...VestingSchedules_Viewer\n    id\n  }\n}\n\nfragment BookADemoSlideOver_Viewer on Account {\n  email\n  firstName\n  lastName\n}\n\nfragment CTMSGrantsListSlideOver_CTMSGrants on CTMSGrant {\n  id\n  label\n  grantee {\n    name\n    email\n    id\n  }\n}\n\nfragment CTMSGrantsListSlideOver_Organization on Organization {\n  id\n}\n\nfragment InsightNotAvailableBox_Organization on Organization {\n  name\n  ...useOrganizationCTMS_Organization\n}\n\nfragment InsightNotAvailableBox_Viewer on Account {\n  ...BookADemoSlideOver_Viewer\n}\n\nfragment VestingSchedulesGraph_Organization on Organization {\n  ctmsGrants {\n    edges {\n      node {\n        vestingScheduleName\n        ...CTMSGrantsListSlideOver_CTMSGrants\n        id\n      }\n    }\n  }\n  ...CTMSGrantsListSlideOver_Organization\n  ...InsightNotAvailableBox_Organization\n}\n\nfragment VestingSchedulesGraph_Viewer on Account {\n  ...InsightNotAvailableBox_Viewer\n}\n\nfragment VestingSchedules_Organization on Organization {\n  id\n  name\n  ...VestingSchedulesGraph_Organization\n}\n\nfragment VestingSchedules_Viewer on Account {\n  ...VestingSchedulesGraph_Viewer\n}\n\nfragment useOrganizationCTMS_Organization on Organization {\n  isConnectedToCarta\n  isConnectedToPulley\n  isConnectedToAngelList\n}\n"
  }
};
})();

(node as any).hash = "0ada34a31ab2fd9f5eed92b585f80a50";

export default node;
