import { startTransition, useRef } from "react";
import {
  useFragment,
  useRefetchableFragment,
  useSubscribeToInvalidationState,
} from "react-relay";
import {
  generatePath,
  Navigate,
  Outlet,
  useOutletContext,
} from "react-router-dom";
import { useScroll } from "react-use";
import { graphql } from "relay-runtime";

import { AdvancedApplicationSidebar } from "../../components/ApplicationSidebar/AdvancedApplicationSidebar";
import { BasicApplicationSidebar } from "../../components/ApplicationSidebar/BasicApplicationSidebar";
import { BookADemoModal } from "../../components/BookADemo/BookADemoModal";
import { FairMarketValueSendToRewiewSliderRemote } from "../../components/FairMarketValueSendToRewiewSlider";
import { InviteAdminSlideOver } from "../../components/InviteAdminSlideOver";
import {
  NewEquitySelectionModal,
  useNewEquitySelectionModalState,
} from "../../components/NewEquitySelectionModal/NewEquitySelectionModal";
import { ApplicationSideBar } from "../../components/ui/ApplicationSideBar/ApplicationSideBar";
import { AnalyticsContextProvider } from "../../hooks/useAnalytics";
import { useBoolean } from "../../hooks/useBoolean";
import { FeatureFlagProvider } from "../../hooks/useIsFeatureEnabled";
import { useQuery } from "../../hooks/useQuery";
import { useScrollToTopOnNavigation } from "../../hooks/useScrollToTopOnNavigation";
import NotFoundPage from "../../pages/NotFound/NotFound";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../paths";
import { useAuthentication } from "../../services/AuthenticationProvider";
import { AdminLayout_Organization$key } from "./__generated__/AdminLayout_Organization.graphql";
import { AdminLayout_Organization_Query } from "./__generated__/AdminLayout_Organization_Query.graphql";
import { AdminLayout_Viewer$key } from "./__generated__/AdminLayout_Viewer.graphql";
import { FreemiumPlanStickyBanner } from "./FreemiumPlanStickyBanner";
import { FreeTrialPlanStickyBanner } from "./FreeTrialPlanStickyBanner";

const VIEWER_FRAGMENT = graphql`
  fragment AdminLayout_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isSuperAdmin
    ...ApplicationSideBar_Viewer
    ...BookADemoModal_Viewer
    ...AdvancedApplicationSidebar_Viewer
      @arguments(organizationId: $organizationId)
    ...BasicApplicationSidebar_Viewer
      @arguments(organizationId: $organizationId)
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment AdminLayout_Organization on Organization
  @refetchable(queryName: "AdminLayout_Organization_RefetchableQuery") {
    id
    name
    plan
    currentRemoteOnboardingStep
    isRemoteEquityBasic
    ...ApplicationSideBar_Organization
    ...FreeTrialPlanStickyBanner_Organization
    ...BookADemoModal_Organization
    ...AdvancedApplicationSidebar_Organization
    ...BasicApplicationSidebar_Organization
  }
`;

export interface OutletContext {
  mainContentScroll: {
    x: number;
    y: number;
  };
}

export const useAdminLayoutContext = () => {
  return useOutletContext<OutletContext>();
};

const AdminLayout_: React.FC<{
  organizationFragment: AdminLayout_Organization$key;
  viewerFragment: AdminLayout_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const mainContentRef = useRef<HTMLDivElement | null>(null);
  useScrollToTopOnNavigation(mainContentRef);
  const mainContentScroll = useScroll(mainContentRef);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  useSubscribeToInvalidationState([organization.id], () => {
    startTransition(() => {
      refetchOrganization({});
    });
  });

  const {
    hideNewEquitySelectionModal,
    newEquitySelectionModalState,
    showNewEquitySelectionModal,
  } = useNewEquitySelectionModalState();

  const {
    setFalse: hideInviteAdminSlideOver,
    setTrue: showInviteAdminSlideOver,
    value: inviteAdminSlideOverIsShown,
  } = useBoolean(false);

  if (organization.currentRemoteOnboardingStep && !viewer.isSuperAdmin) {
    return (
      <Navigate
        replace
        to={generatePath(APPLICATION_ROUTES.organizationFinishOnboarding, {
          organizationId: organization.id,
        })}
      />
    );
  }

  return (
    <>
      <BookADemoModal
        organizationFragment={organization}
        viewerFragment={viewer}
      />
      <InviteAdminSlideOver
        onClose={hideInviteAdminSlideOver}
        organizationId={organization.id}
        show={inviteAdminSlideOverIsShown}
      />
      <NewEquitySelectionModal
        onClose={hideNewEquitySelectionModal}
        organizationId={organization.id}
        showEquityOfferCard
        state={newEquitySelectionModalState}
      />
      <ApplicationSideBar.Layout>
        {organization.isRemoteEquityBasic ? (
          <BasicApplicationSidebar
            organizationFragment={organization}
            viewerFragment={viewer}
          />
        ) : (
          <AdvancedApplicationSidebar
            onCreateNewButtonClick={() => {
              showNewEquitySelectionModal({ granteeFragment: null });
            }}
            onInviteAdminButtonClick={() => {
              showInviteAdminSlideOver();
            }}
            organizationFragment={organization}
            viewerFragment={viewer}
          />
        )}
        <div
          className="relative h-full flex-auto overflow-auto"
          ref={mainContentRef}
        >
          {organization.plan === "freemiumDemoBooked" && (
            <FreemiumPlanStickyBanner />
          )}
          {organization.plan === "freeTrial" && (
            <FreeTrialPlanStickyBanner organizationFragment={organization} />
          )}
          <Outlet context={{ mainContentScroll } satisfies OutletContext} />
        </div>
      </ApplicationSideBar.Layout>
    </>
  );
};

const QUERY = graphql`
  query AdminLayout_Organization_Query($organizationId: OrganizationId!) {
    me {
      ...AdminLayout_Viewer @arguments(organizationId: $organizationId)
    }
    organization(id: $organizationId) {
      name
      ...AdminLayout_Organization
    }
  }
`;

const AdminLayout: React.FC = () => {
  const organizationId = useOrganizationIdParam();

  const { query } = useQuery<AdminLayout_Organization_Query>(QUERY, {
    organizationId,
  });
  const { account } = useAuthentication();

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <FeatureFlagProvider
      account={account}
      organizationName={query.organization.name}
    >
      <AnalyticsContextProvider organizationId={organizationId}>
        <FairMarketValueSendToRewiewSliderRemote.Provider>
          <AdminLayout_
            organizationFragment={query.organization}
            viewerFragment={query.me}
          />
        </FairMarketValueSendToRewiewSliderRemote.Provider>
      </AnalyticsContextProvider>
    </FeatureFlagProvider>
  );
};

export default AdminLayout;
