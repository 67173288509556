import { ComponentProps } from "react";
import { FormattedNumber as _FormattedNumber } from "react-intl";

import { SkeletonWrapper } from "../ui/SkeletonWrapper";
import { FormattedNumberAnimated } from "./FormattedNumberAnimated";

export function FormattedNumber({
  animated,
  skeleton,
  ...props
}: ComponentProps<typeof _FormattedNumber> & {
  animated?: boolean;
  skeleton?: boolean;
}) {
  const Component = animated ? FormattedNumberAnimated : _FormattedNumber;

  return (
    <SkeletonWrapper reveal={!skeleton}>
      <Component {...props} />
    </SkeletonWrapper>
  );
}
