/**
 * @generated SignedSource<<9a46a16fc72e66b88001e6ede788a959>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalsSettingsBody_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalsSettingsBody_Deferred_Organization_PaginatedGrantees">;
  readonly " $fragmentType": "EmployeePortalsSettingsBody_Organization";
};
export type EmployeePortalsSettingsBody_Organization$key = {
  readonly " $data"?: EmployeePortalsSettingsBody_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalsSettingsBody_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalsSettingsBody_Organization",
  "selections": [
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EmployeePortalsSettingsBody_Deferred_Organization_PaginatedGrantees"
        }
      ]
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "6e345df5af111f9d3afdf7e27402ec0e";

export default node;
