import classNames from "classnames";
import { useEffect, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { Link, NavLink } from "react-router-dom";

import { useAdminLayoutContext } from "../../../layouts/AdminLayout/AdminLayout";
import { Footer } from "../../Footer";
import { Typography } from "../Typography";
import { LargeOneColumnLayout_Organization$key } from "./__generated__/LargeOneColumnLayout_Organization.graphql";

export const ORGANIZATION_FRAGMENT = graphql`
  fragment LargeOneColumnLayout_Organization on Organization {
    ...Footer_Organization
  }
`;

const LinkItem: React.FC<React.ComponentProps<typeof Link>> = ({
  children,
  className,
  ...props
}) => (
  <NavLink
    className={({ isActive, isPending, isTransitioning }) =>
      classNames(
        "block rounded-xl px-4 py-3 transition-all",
        {
          "animate-pulse bg-gray-02": isPending || isTransitioning,
          "bg-gray-02 text-black-07": isActive,
          "text-black-05 hover:bg-gray-02 hover:text-black-07": !isActive,
        },
        className,
      )
    }
    {...props}
  >
    <Typography variant="Regular/Extra Small">{children}</Typography>
  </NavLink>
);

const SubNavigation_: React.FC<
  React.PropsWithChildren<{
    links: React.ReactElement;
    organizationFragment: LargeOneColumnLayout_Organization$key | null;
  }>
> = ({ children, links, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <div className="grid grid-cols-[200px_minmax(auto,_940px)] gap-10">
      <div>
        <div className="sticky top-[72.5px] py-10">{links}</div>
      </div>
      <div className="space-y-6">
        <div className="pt-10">{children}</div>
        <Footer organizationFragment={organization} />
      </div>
    </div>
  );
};

const SubNavigation = Object.assign(SubNavigation_, {
  LinkItem,
});

const TOP_BAR_ITEMS_VERTICAL_ALIGNMENTS = {
  bottom: classNames("items-end"),
  center: classNames("items-center"),
};

const MAX_WIDTHS = {
  1200: classNames("max-w-[1200px]"),
  1920: classNames("max-w-[1920px]"),
};

const LargeOneColumnLayout_: React.FC<
  React.PropsWithChildren<{
    dataCy?: string;
    helpFooter?: React.ReactNode;
    maxWidth?: keyof typeof MAX_WIDTHS;
    organizationFragment: LargeOneColumnLayout_Organization$key | null;
    showFooter?: boolean;
    subtitle?: React.ReactNode;
    title?: React.ReactNode;
    topBarActionsRender?: (props: {
      mainContentIsScrolled?: boolean;
    }) => React.ReactNode;
    topBarItemsVerticalAlignment?: keyof typeof TOP_BAR_ITEMS_VERTICAL_ALIGNMENTS;
  }>
> = ({
  children,
  dataCy,
  helpFooter,
  maxWidth = 1920,
  organizationFragment,
  showFooter = true,
  subtitle,
  title,
  topBarActionsRender,
  topBarItemsVerticalAlignment = "center",
}) => {
  const { mainContentScroll } = useAdminLayoutContext();
  // const mainContentIsScrolled = mainContentScroll.y > 0;
  const [mainContentIsScrolled, setMainContentIsScrolled] = useState(
    mainContentScroll.y > 0,
  );
  useEffect(() => {
    setMainContentIsScrolled((previousState) => {
      // User have to scroll 80px to trigger the transition
      if (!previousState && mainContentScroll.y > 80) {
        return true;
      }

      // But have to scroll back to 0 to remove the transition, so it's not clunky :D
      if (previousState && mainContentScroll.y === 0) {
        return false;
      }

      return previousState;
    });
  }, [mainContentScroll.y]);

  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <div data-cy={dataCy}>
      <div
        className={classNames(
          "sticky top-0 z-10 border-b-[0.5px] bg-white px-10 transition-all",
          {
            "border-gray-04 py-6": mainContentIsScrolled,
            "border-transparent pb-4 pt-20": !mainContentIsScrolled,
          },
        )}
      >
        <div
          className={classNames(
            "mx-auto flex w-full flex-wrap gap-4",
            {
              "px-0": !mainContentIsScrolled,
              "px-6": mainContentIsScrolled,
            },
            MAX_WIDTHS[maxWidth],
            TOP_BAR_ITEMS_VERTICAL_ALIGNMENTS[topBarItemsVerticalAlignment],
          )}
        >
          <div
            className={classNames("flex-1 transition-all", {
              "space-y-2": !mainContentIsScrolled,
            })}
          >
            <Typography
              as="h1"
              className="block transition-all"
              variant={mainContentIsScrolled ? "Medium/Small" : "Medium/Large"}
            >
              {title}
            </Typography>
            <Typography
              as="div"
              className={classNames("text-black-05 transition-all", {
                "h-0 scale-0 opacity-0": mainContentIsScrolled,
                "opacity-100": !mainContentIsScrolled,
              })}
              variant="Regular/Small"
            >
              {subtitle}
            </Typography>
          </div>
          <div className="flex flex-1 justify-end gap-2">
            {topBarActionsRender?.({ mainContentIsScrolled })}
          </div>
        </div>
      </div>
      <div className="px-10">
        <div
          className={classNames(
            "mx-auto flex w-full flex-1 flex-col space-y-10",
            MAX_WIDTHS[maxWidth],
          )}
        >
          <div className="flex-1">{children}</div>
          {helpFooter && <div>{helpFooter}</div>}
          {showFooter && (
            <div>
              <Footer organizationFragment={organization} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const LargeOneColumnLayout = Object.assign(LargeOneColumnLayout_, {
  SubNavigation,
});
