/**
 * @generated SignedSource<<5498561b2acc70c2b23cff7088dbd7f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalTopSection_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalTopSection_Deferred_Organization">;
  readonly " $fragmentType": "EmployeePortalTopSection_Organization";
};
export type EmployeePortalTopSection_Organization$key = {
  readonly " $data"?: EmployeePortalTopSection_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalTopSection_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalTopSection_Organization",
  "selections": [
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EmployeePortalTopSection_Deferred_Organization"
        }
      ]
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "cb5701d78a9816d652057414c930a09f";

export default node;
