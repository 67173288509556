import { BoxedIcon, Button, Text } from "@remote-com/norma";
import { IconV2DuotoneDollarCircle } from "@remote-com/norma/icons/IconV2DuotoneDollarCircle";
import { ArrowDown } from "iconsax-react";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";

import { SectionHero } from "../../../components/SectionHero";
import { APPLICATION_ROUTES } from "../../../paths";
import { EmployeePortalExerciseSection_Grantee$key } from "./__generated__/EmployeePortalExerciseSection_Grantee.graphql";
import { EmployeePortalExerciseDetailsSection } from "./EmployeePortalExerciseDetailsSection";
import illustration from "./illustration.png";

const GRANTEE_FRAGMENT = graphql`
  fragment EmployeePortalExerciseSection_Grantee on Grantee {
    id
    employeePortalExerciseSectionNonVirtualCTMSGrants: ctmsGrants(
      grantStatusIn: [Active, Terminated]
      grantType: real
    ) {
      __typename
    }
    ...EmployeePortalExerciseDetailsSection_Grantee
  }
`;

export const EmployeePortalExerciseSection = forwardRef<
  HTMLDivElement,
  {
    granteeFragment: EmployeePortalExerciseSection_Grantee$key;
  }
>(function GranteePortalExerciseSection({ granteeFragment }, ref) {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  if (!grantee.employeePortalExerciseSectionNonVirtualCTMSGrants.length) {
    return null;
  }

  return (
    <div className="space-y-10" ref={ref}>
      <SectionHero
        className="bg-background-subtle"
        hideBorderOnMobile
        id="exercise"
        withBorder
      >
        <div className="flex items-center gap-10">
          <div className="flex-1 space-y-4">
            <BoxedIcon
              Icon={IconV2DuotoneDollarCircle}
              size="lg"
              tone="purple"
            />
            <Text variant="3xlMedium">Exercising your options</Text>
            <Text variant="lgMedium">You have options you can exercise</Text>
            <Text className="text-grey-600" variant="sm">
              But first, you can learn more about the exercise and situations
              when you’ll want or not to exercise your vested options
            </Text>
            <Button
              as="a"
              href={`${generatePath(APPLICATION_ROUTES.employeePortal, {
                granteeId: grantee.id,
              })}#exercise-details`}
              IconAfter={ArrowDown}
              size="md"
            >
              Learn more
            </Button>
          </div>
          <img className="hidden w-[320px] lg:block" src={illustration} />
        </div>
      </SectionHero>
      <EmployeePortalExerciseDetailsSection granteeFragment={grantee} />
    </div>
  );
});
