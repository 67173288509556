import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { EmployeePortalOwnershipSection_Grantee$key } from "./__generated__/EmployeePortalOwnershipSection_Grantee.graphql";
import { EmployeePortalWelcomeSection } from "./EmployeePortalWelcomeSection";
import { EmployeePortalWhatDoYouHaveSection } from "./EmployeePortalWhatDoYouHaveSection";

const GRANTEE_FRAGMENT = graphql`
  fragment EmployeePortalOwnershipSection_Grantee on Grantee {
    ...EmployeePortalWelcomeSection_Grantee
    ...EmployeePortalWhatDoYouHaveSection_Grantee
  }
`;

export const EmployeePortalOwnershipSection = forwardRef<
  HTMLDivElement,
  {
    granteeFragment: EmployeePortalOwnershipSection_Grantee$key;
    setValuationMultiple: (valuationMultiple: number) => void;
    valuationMultiple: number;
  }
>(function EmployeePortalOwnershipSection(
  { granteeFragment, setValuationMultiple, valuationMultiple },
  ref,
) {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  return (
    <div className="space-y-6" id="ownership" ref={ref}>
      <EmployeePortalWelcomeSection granteeFragment={grantee} />
      <EmployeePortalWhatDoYouHaveSection
        granteeFragment={grantee}
        onValuationMultipleChange={setValuationMultiple}
        valuationMultiple={valuationMultiple}
      />
    </div>
  );
});
