import { Button, Pill } from "@remote-com/norma";
import { Eye } from "iconsax-react";
import { compact } from "lodash";
import { useCallback } from "react";
import { graphql, useFragment, useMutation } from "react-relay";
import { generatePath, Link } from "react-router-dom";

import {
  InviteGranteesModal,
  useInviteGranteesModalState,
} from "../../../components/InviteGranteesModal";
import { useToaster } from "../../../components/Toaster";
import { SkeletonWrapper } from "../../../components/ui/SkeletonWrapper";
import { Table } from "../../../components/ui/Table";
import { Toast } from "../../../components/ui/Toast";
import { APPLICATION_ROUTES } from "../../../paths";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../services/workRelationship";
import {
  EmployeeTableRow_Employees$key,
  WorkRelationship,
} from "./__generated__/EmployeeTableRow_Employees.graphql";
import { EmployeeTableRow_RevokeAccessToEmployeePortal_Mutation } from "./__generated__/EmployeeTableRow_RevokeAccessToEmployeePortal_Mutation.graphql";

const REVOKE_ACCESS_TO_EMPLOYEE_PORTAL_MUTATION = graphql`
  mutation EmployeeTableRow_RevokeAccessToEmployeePortal_Mutation(
    $employeeId: GranteeId!
  ) {
    revokeAccessToGranteePortal(granteeId: $employeeId) {
      id
      hasPortalAccess
      hasVisitedTheirPortal
    }
  }
`;

const EMPLOYEE_FRAGMENT = graphql`
  fragment EmployeeTableRow_Employees on Grantee {
    id
    name
    hasPortalAccess
    hasVisitedTheirPortal
    email
    taxResidenceCountry {
      emoji
    }
    jobTitle
    workRelationship
  }
`;

export function EmployeeTableRow({
  employeeFragment,
  organizationId,
}: {
  employeeFragment: EmployeeTableRow_Employees$key;
  organizationId: string;
}) {
  const employee = useFragment(EMPLOYEE_FRAGMENT, employeeFragment);

  const [
    commitRevokeAccessToEmployeePortalMutation,
    revokeAccessToEmployeePortalMutationIsInFlight,
  ] = useMutation<EmployeeTableRow_RevokeAccessToEmployeePortal_Mutation>(
    REVOKE_ACCESS_TO_EMPLOYEE_PORTAL_MUTATION,
  );

  const toaster = useToaster();

  const handleRevokeAccessButtonClick = useCallback(() => {
    commitRevokeAccessToEmployeePortalMutation({
      onCompleted: () => {
        toaster.push(
          <Toast>
            {employee.name}’s access to their portal has been removed
          </Toast>,
        );
      },
      variables: {
        employeeId: employee.id,
      },
    });
  }, [
    commitRevokeAccessToEmployeePortalMutation,
    employee.id,
    employee.name,
    toaster,
  ]);

  const {
    hideInviteGranteeModal,
    inviteGranteeModalIsOpening,
    inviteGranteeModalState,
    showInviteGranteeModal,
  } = useInviteGranteesModalState();

  const handleGrantAccessButtonClick = useCallback(() => {
    showInviteGranteeModal(employee.id);
  }, [employee.id, showInviteGranteeModal]);

  return (
    <>
      <InviteGranteesModal
        onClose={hideInviteGranteeModal}
        organizationId={organizationId}
        state={inviteGranteeModalState}
      />
      <EmployeeTableRowUI
        employeeEmail={employee.email}
        employeeHasPortalAccess={employee.hasPortalAccess}
        employeeHasVisitedTheirPortal={employee.hasVisitedTheirPortal}
        employeeId={employee.id}
        employeeJobTitle={employee.jobTitle ?? null}
        employeeName={employee.name}
        employeeTaxResidenceCountryEmoji={employee.taxResidenceCountry?.emoji}
        employeeWorkRelationship={employee.workRelationship ?? null}
        inviteButtonIsLoading={inviteGranteeModalIsOpening}
        onGrantAccessButtonClick={handleGrantAccessButtonClick}
        onRevokeAccessButtonClick={handleRevokeAccessButtonClick}
        revokeButtonIsLoading={revokeAccessToEmployeePortalMutationIsInFlight}
      />
    </>
  );
}

export function EmployeeTableRowUI({
  employeeEmail,
  employeeHasPortalAccess,
  employeeHasVisitedTheirPortal,
  employeeId = "employeeId",
  employeeJobTitle,
  employeeName,
  employeeTaxResidenceCountryEmoji,
  employeeWorkRelationship,
  inviteButtonIsLoading,
  onGrantAccessButtonClick,
  onRevokeAccessButtonClick,
  revokeButtonIsLoading,
  skeleton,
}: {
  employeeEmail: string;
  employeeHasPortalAccess: boolean;
  employeeHasVisitedTheirPortal: boolean;
  employeeId?: string;
  employeeJobTitle?: null | string;
  employeeName: string;
  employeeTaxResidenceCountryEmoji?: null | string;
  employeeWorkRelationship?: null | WorkRelationship;
  inviteButtonIsLoading?: boolean;
  onGrantAccessButtonClick?: () => void;
  onRevokeAccessButtonClick?: () => void;
  revokeButtonIsLoading?: boolean;
  skeleton?: boolean;
}) {
  return (
    <Table.Row>
      <Table.Cell>
        <div className="space-y-1">
          <div>
            <SkeletonWrapper reveal={!skeleton}>
              {compact([employeeTaxResidenceCountryEmoji, employeeName]).join(
                " ",
              )}
            </SkeletonWrapper>
          </div>
          <div className="text-XS text-grey-500">
            <SkeletonWrapper reveal={!skeleton}>
              {employeeEmail}
            </SkeletonWrapper>
          </div>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="space-y-1">
          <div>
            <SkeletonWrapper reveal={!skeleton}>
              {employeeJobTitle}
            </SkeletonWrapper>
          </div>
          {employeeWorkRelationship && (
            <div className="text-XS text-grey-500">
              <SkeletonWrapper reveal={!skeleton}>
                {
                  WORK_RELATIONSHIP_TO_LABEL_HELPER[employeeWorkRelationship]
                    .singularLabel
                }
              </SkeletonWrapper>
            </div>
          )}
        </div>
      </Table.Cell>
      <Table.Cell stretchContent>
        <div className="flex w-full items-center justify-end">
          {employeeHasPortalAccess && employeeHasVisitedTheirPortal && (
            <SkeletonWrapper reveal={!skeleton}>
              <Pill tone="success">Active</Pill>
            </SkeletonWrapper>
          )}
          {employeeHasPortalAccess && !employeeHasVisitedTheirPortal && (
            <SkeletonWrapper reveal={!skeleton}>
              <Pill tone="warning">Pending</Pill>
            </SkeletonWrapper>
          )}
          {!employeeHasPortalAccess && (
            <SkeletonWrapper reveal={!skeleton}>
              <Pill tone="error">Not invited</Pill>
            </SkeletonWrapper>
          )}
        </div>
      </Table.Cell>
      <Table.Cell stretchContent>
        <div className="flex items-center justify-end gap-2">
          {!employeeHasPortalAccess && (
            <SkeletonWrapper reveal={!skeleton}>
              <Button
                isLoading={inviteButtonIsLoading}
                onClick={onGrantAccessButtonClick}
                size="sm"
                tone="primary"
                type="button"
              >
                Invite
              </Button>
            </SkeletonWrapper>
          )}
          {employeeHasPortalAccess && (
            <SkeletonWrapper reveal={!skeleton}>
              <Button
                isLoading={revokeButtonIsLoading}
                onClick={onRevokeAccessButtonClick}
                size="sm"
                tone="secondary"
                type="button"
              >
                Revoke
              </Button>
            </SkeletonWrapper>
          )}
          <SkeletonWrapper reveal={!skeleton}>
            <Link
              className="px-3 py-1.5 text-brand-600 transition-all hover:text-brand-700"
              target="_blank"
              to={generatePath(APPLICATION_ROUTES.employeePortal, {
                granteeId: employeeId,
              })}
            >
              <Eye className="size-4" />
            </Link>
          </SkeletonWrapper>
        </div>
      </Table.Cell>
    </Table.Row>
  );
}
