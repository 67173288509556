import { BoxedIcon, Pill, Text } from "@remote-com/norma";
import { IconV2DuotoneRocket } from "@remote-com/norma/icons/IconV2DuotoneRocket";
import classNames from "classnames";
import { compact, first } from "lodash";
import { useMemo, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { FormattedCurrency } from "../../../components/Formatted/FormattedCurrency";
import { FormattedNumber } from "../../../components/Formatted/FormattedNumber";
import { FormattedPercentage } from "../../../components/Formatted/FormattedPercentage";
import {
  RemoteLikeInfoBlock,
  RemoteLikeInfoBlockItem,
} from "../../../components/RemoteLikeInfoBlock";
import { SectionHero } from "../../../components/SectionHero";
import { ShortDate } from "../../../components/ShortDate";
import { AnchorButton } from "../../../components/ui/Button";
import { PoweredByCarta } from "../../../components/ui/PoweredByCarta/PoweredByCarta";
import { Progress } from "../../../components/ui/Progress";
import { Switch } from "../../../components/ui/Switch";
import { useOrganizationCTMS } from "../../../hooks/useOrganizationCTMS";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../services/workRelationship";
import { EmployeePortalExerciseDetailsSection_Grantee$key } from "./__generated__/EmployeePortalExerciseDetailsSection_Grantee.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment EmployeePortalExerciseDetailsSection_Grantee on Grantee {
    organization {
      ...useOrganizationCTMS_Organization
    }
    nonVirtualCTMSGrants: ctmsGrants(
      grantStatusIn: [Active, Terminated]
      orderBy: { field: vestingStartDate, direction: ASC }
      grantType: real
    ) {
      label
      quantityIssued
      grantDate
      vestingStatus
      cumulativeVested
      exercisePrice
      exercisableNonExercisedAmount
      cumulativeVested
      exercisableShares
      earlyExercise
      quantityExercised
      exercisableUntilDate
      vestingEndDate
      postTerminationExercisePeriod {
        displayName
      }
      matchingInstrument {
        taxResidenceCountry {
          emoji
        }
        workRelationship
        awardSuperType
      }
    }
  }
`;

export function EmployeePortalExerciseDetailsSection({
  granteeFragment,
}: {
  granteeFragment: EmployeePortalExerciseDetailsSection_Grantee$key;
}) {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const firstCTMSGrant = first(grantee.nonVirtualCTMSGrants);

  if (!firstCTMSGrant) {
    throw new Error("Unexpected: grantee has no grants");
  }

  const [selectedGrant, setSelectedGrant] = useState(firstCTMSGrant);

  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: grantee.organization,
  });

  const grantDetailsInfo = useMemo(
    () =>
      compact([
        selectedGrant.exercisePrice && {
          label: "Exercise price (one share)",
          value: (
            <FormattedCurrency animated value={selectedGrant.exercisePrice} />
          ),
        },
        typeof selectedGrant.exercisableNonExercisedAmount === "number" && {
          label: "Exercise price (all exercisable shares)",
          value: (
            <FormattedCurrency
              animated
              value={selectedGrant.exercisableNonExercisedAmount}
            />
          ),
        },
        {
          label: "Grant date",
          subLabel: "Based on the board approval date",
          value: <ShortDate value={selectedGrant.grantDate} />,
        },
        selectedGrant.matchingInstrument && {
          label: "Type",
          value: `${selectedGrant.matchingInstrument.taxResidenceCountry.emoji} ${selectedGrant.matchingInstrument.awardSuperType} - ${WORK_RELATIONSHIP_TO_LABEL_HELPER[selectedGrant.matchingInstrument.workRelationship].singularLabel}`,
        },
        selectedGrant.earlyExercise && {
          label: "Early exercise",
          value: <Pill tone="success">Allowed</Pill>,
        },
        selectedGrant.exercisableUntilDate && {
          label: "Last day to exercise",
          subLabel: "Based on the date of termination",
          value: <ShortDate value={selectedGrant.exercisableUntilDate} />,
        },
        selectedGrant.vestingStatus !== "terminated" &&
          selectedGrant.postTerminationExercisePeriod && {
            label: "Post-termination exercise period",
            subLabel: "In case of employee contract termination",
            value: selectedGrant.postTerminationExercisePeriod.displayName,
          },
      ]) satisfies RemoteLikeInfoBlockItem[],
    [
      selectedGrant.earlyExercise,
      selectedGrant.exercisableNonExercisedAmount,
      selectedGrant.exercisableUntilDate,
      selectedGrant.exercisePrice,
      selectedGrant.grantDate,
      selectedGrant.matchingInstrument,
      selectedGrant.postTerminationExercisePeriod,
      selectedGrant.vestingStatus,
    ],
  );

  return (
    <SectionHero
      aria-labelledby="heading"
      id="exercise-details"
      role="region"
      withBorder
    >
      <div className="space-y-6">
        <div className="flex items-center gap-3">
          <BoxedIcon Icon={IconV2DuotoneRocket} size="sm" tone="cyan" />
          <Text id="heading" variant="lgMedium">
            Exercising your options
          </Text>
        </div>
        <Text className="text-grey-600" variant="sm">
          Congratulations 🎉, your long term commitment in the company is
          rewarded: you now have the right to buy a slice of the company at a
          pre-defined price (the “exercise price”). We call this stage the
          “exercise”.
        </Text>
        <div>
          <Switch
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.label}
            name="grant"
            onChange={setSelectedGrant}
            options={grantee.nonVirtualCTMSGrants}
            selectedOption={selectedGrant}
          />
        </div>
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-[300px_1fr]">
          <div className="divide-y-[0.5px] divide-grey-300 self-start rounded-remote-lg border-[0.5px] border-grey-300 bg-white shadow md:border-none">
            <div className="p-2">
              <div className="flex flex-col items-center justify-center gap-2 rounded-t-remote-sm bg-background-subtle p-6">
                <Text variant="lgMedium">Exercise</Text>
                <div className="flex w-full flex-col items-center justify-center gap-1">
                  <Text variant="4xlMedium">
                    {selectedGrant.exercisableShares > 0 ? (
                      <FormattedPercentage
                        animated
                        value={
                          selectedGrant.quantityExercised /
                          selectedGrant.exercisableShares
                        }
                      />
                    ) : (
                      "-"
                    )}
                  </Text>
                  <Progress
                    className="w-full bg-grey-300"
                    max={selectedGrant.exercisableShares}
                  >
                    <Progress.Value
                      className="bg-primary"
                      value={selectedGrant.quantityExercised}
                    />
                  </Progress>
                  <Text variant="lg">
                    <FormattedNumber
                      animated
                      maximumFractionDigits={0}
                      value={selectedGrant.quantityExercised}
                    />
                    /
                    <span className="text-grey-500">
                      <FormattedNumber
                        animated
                        maximumFractionDigits={0}
                        value={selectedGrant.exercisableShares}
                      />
                    </span>
                  </Text>
                </div>
                {selectedGrant.postTerminationExercisePeriod && (
                  <div className="space-y-0.5 text-center">
                    <Text className="text-grey-500" variant="xs">
                      Post-termination exercise period
                    </Text>
                    <Text variant="smMedium">
                      {selectedGrant.postTerminationExercisePeriod.displayName}
                    </Text>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col items-center justify-center space-y-2 p-8">
              <Text variant="lgMedium">Vesting</Text>
              <div className="flex w-full flex-col items-center justify-center gap-1">
                <Text
                  className={classNames("transition-all", {
                    "text-error": selectedGrant.vestingStatus === "terminated",
                    "text-green-600":
                      selectedGrant.vestingStatus === "fully_vested",
                    "text-primary":
                      selectedGrant.vestingStatus === "vesting" &&
                      selectedGrant.cumulativeVested > 0,
                  })}
                  variant="4xlMedium"
                >
                  <FormattedPercentage
                    animated
                    value={
                      selectedGrant.cumulativeVested /
                      selectedGrant.quantityIssued
                    }
                  />
                </Text>
                <Progress
                  className="w-full bg-grey-300"
                  max={selectedGrant.quantityIssued}
                >
                  <Progress.Value
                    className={classNames("transition-colors", {
                      "bg-error": selectedGrant.vestingStatus === "terminated",
                      "bg-green-600":
                        selectedGrant.vestingStatus === "fully_vested",
                      "bg-primary": selectedGrant.vestingStatus === "vesting",
                    })}
                    value={selectedGrant.cumulativeVested}
                  />
                </Progress>
                <Text variant="lg">
                  <FormattedNumber
                    animated
                    maximumFractionDigits={0}
                    value={selectedGrant.cumulativeVested}
                  />
                  /
                  <span className="text-grey-500">
                    <FormattedNumber
                      animated
                      maximumFractionDigits={0}
                      value={selectedGrant.quantityIssued}
                    />
                  </span>
                </Text>
              </div>
              {selectedGrant.vestingStatus !== "terminated" &&
                selectedGrant.vestingEndDate && (
                  <div className="space-y-0.5 text-center">
                    <Text className="text-grey-500" variant="xs">
                      Vesting end date
                    </Text>
                    <Text variant="smMedium">
                      <ShortDate value={selectedGrant.vestingEndDate} />
                    </Text>
                  </div>
                )}
              {selectedGrant.vestingStatus === "terminated" && (
                <div className="flex justify-center">
                  <Pill tone="error">Terminated</Pill>
                </div>
              )}
            </div>
          </div>
          <div className="space-y-6">
            <RemoteLikeInfoBlock info={grantDetailsInfo} />
            {organizationCTMS && (
              <div className="space-y-6 rounded-2xl border-[0.5px] border-grey-300 bg-background-subtle p-6 md:border-none md:p-10">
                <div className="space-y-2">
                  <Text variant="lgMedium">
                    Ready to exercise your vested stock options?
                  </Text>
                  <Text variant="sm">
                    Just head to{" "}
                    <span className="font-medium">{organizationCTMS.name}</span>
                    . You can easily exercise your options and follow the
                    procedure.
                  </Text>
                </div>
                <div className="flex flex-wrap items-center justify-between gap-2">
                  <AnchorButton
                    href={organizationCTMS.loginUrl}
                    size="medium"
                    target="_blank"
                  >
                    Exercise your options
                  </AnchorButton>
                  {organizationCTMS.name === "Carta" && (
                    <PoweredByCarta className="w-[126px]" />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </SectionHero>
  );
}
