/**
 * @generated SignedSource<<d0bccfba303e5f26210ade055a697229>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HRISProvider = "DEEL" | "REMOTE";
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalLayout_Grantee$data = {
  readonly hRISProviderEmployee: {
    readonly hRISProvider: HRISProvider;
  } | null;
  readonly id: string;
  readonly organization: {
    readonly granteePortalSettings: {
      readonly displayFullyDilutedValues: boolean;
    };
    readonly latestValuation: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"ApplicationSideBar_Organization">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useSections_Grantee">;
  readonly " $fragmentType": "EmployeePortalLayout_Grantee";
};
export type EmployeePortalLayout_Grantee$key = {
  readonly " $data"?: EmployeePortalLayout_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalLayout_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalLayout_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteePortalSettings",
          "kind": "LinkedField",
          "name": "granteePortalSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayFullyDilutedValues",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestValuation",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ApplicationSideBar_Organization"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "hRISProviderEmployee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hRISProvider",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSections_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "5de4a68d62d11e1631e49c1a93aac6bf";

export default node;
