import { BoxedIcon, Button } from "@remote-com/norma";
import { IconV2DuotoneEyeGlasses } from "@remote-com/norma/icons/IconV2DuotoneEyeGlasses";
import classNames from "classnames";
import { useCallback, useEffect, useRef } from "react";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";
import { Link } from "react-router-dom";

import { Page } from "../../components/Page";
import { useGranteeIdParam } from "../../paths";
import CONFIGURATION from "../../services/configuration";
import NotFoundPage from "../NotFound/NotFound";
import { EmployeePortalPage_Grantee$key } from "./__generated__/EmployeePortalPage_Grantee.graphql";
import { EmployeePortalPage_Query } from "./__generated__/EmployeePortalPage_Query.graphql";
import { EmployeePortalExerciseSection } from "./EmployeePortalExerciseSection/EmployeePortalExerciseSection";
import { EmployeePortalGrantAndValueDetailsSection } from "./EmployeePortalGrantAndValueDetailsSection";
import { EmployeePortalLayout } from "./EmployeePortalLayout";
import { EmployeePortalOwnershipSection } from "./EmployeePortalOwnershipSection/EmployeePortalOwnershipSection";
import { EmployeePortalReportingAndGeneralTaxationSection } from "./EmployeePortalReportingAndGeneralTaxationSection/EmployeePortalReportingAndGeneralTaxationSection";
import { EmployeePortalVestingSection } from "./EmployeePortalVestingSection";
import { Equity101DrawerRemote } from "./Equity101Drawer/Equity101Drawer";
import { useEmployeePortalContext } from "./useEmployeePortalContext";
import { SectionLabel } from "./useSections";

const GRANTEE_FRAGMENT = graphql`
  fragment EmployeePortalPage_Grantee on Grantee {
    pageRootCTMSGrants: ctmsGrants(grantStatusIn: [Active, Terminated]) {
      __typename
    }
    organization {
      isOriginatingFromRemoteEquity
    }
    hRISProviderEmployee {
      hRISProvider
    }
    ...EmployeePortalOwnershipSection_Grantee
    ...EmployeePortalVestingSection_Grantee
    ...EmployeePortalGrantAndValueDetailsSection_Grantee
    ...EmployeePortalExerciseSection_Grantee
    ...EmployeePortalReportingAndGeneralTaxationSection_Grantee
  }
`;

const EmployeePortalPage_: React.FC<{
  granteeFragment: EmployeePortalPage_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const sections = useRef<Map<SectionLabel, HTMLElement>>(new Map());
  const {
    containerHeight,
    containerScrollY,
    setActiveSection,
    setValuationMultiple,
    valuationMultiple,
  } = useEmployeePortalContext();

  const registerSection = useCallback(
    (sectionLabel: SectionLabel) => (sectionRef: HTMLElement | null) => {
      if (!sectionRef) {
        return;
      }

      sections.current.set(sectionLabel, sectionRef);
    },
    [],
  );

  useEffect(() => {
    const containerScrollVisibleAreaTop = containerScrollY;
    const containerScrollVisibleAreaBottom = containerScrollY + containerHeight;

    function getElementVisibleRatio(element: HTMLElement) {
      const elementTop = element.offsetTop;
      const elementBottom = elementTop + element.offsetHeight;

      if (
        elementTop >= containerScrollVisibleAreaBottom ||
        elementBottom <= containerScrollVisibleAreaTop
      ) {
        return 0;
      }

      const visibleHeight =
        Math.min(containerScrollVisibleAreaBottom, elementBottom) -
        Math.max(containerScrollVisibleAreaTop, elementTop);
      const elementHeight = elementBottom - elementTop;

      return visibleHeight / elementHeight;
    }

    function getElementDistanceToEdge(element: HTMLElement) {
      const elementTop = element.offsetTop;
      const elementBottom = elementTop + element.offsetHeight;

      const distanceToTop = Math.max(
        containerScrollVisibleAreaTop - elementTop,
        0,
      );
      const distanceToBottom = Math.max(
        elementBottom - containerScrollVisibleAreaBottom,
        0,
      );

      return Math.min(distanceToTop, distanceToBottom);
    }

    const mostVisibleSection = Array.from(sections.current.entries()).reduce<
      [SectionLabel, HTMLElement] | null
    >((mostVisible, section) => {
      if (!mostVisible) {
        return section;
      }

      const mostVisibleRatio = getElementVisibleRatio(mostVisible[1]);
      const sectionRatio = getElementVisibleRatio(section[1]);

      if (sectionRatio === mostVisibleRatio) {
        return getElementDistanceToEdge(section[1]) <
          getElementDistanceToEdge(mostVisible[1])
          ? section
          : mostVisible;
      }

      return sectionRatio > mostVisibleRatio ? section : mostVisible;
    }, null);

    if (mostVisibleSection) {
      setActiveSection(mostVisibleSection[0]);
    }
  }, [containerHeight, containerScrollY, setActiveSection]);

  const canGoBackToRemote =
    grantee.organization.isOriginatingFromRemoteEquity &&
    grantee.hRISProviderEmployee?.hRISProvider === "REMOTE";

  if (!grantee.pageRootCTMSGrants.length) {
    return (
      <Container className="flex h-full items-center justify-center p-6 md:items-start md:p-0">
        <EmptyPortalPlaceholder showBackToRemoteButton={canGoBackToRemote} />
      </Container>
    );
  }

  return (
    <Container>
      <EmployeePortalOwnershipSection
        granteeFragment={grantee}
        ref={registerSection("Ownership")}
        setValuationMultiple={setValuationMultiple}
        valuationMultiple={valuationMultiple}
      />
      <EmployeePortalGrantAndValueDetailsSection
        granteeFragment={grantee}
        id="grant-details"
        ref={registerSection("Grant details")}
        valuationMultiple={valuationMultiple}
      />
      <EmployeePortalVestingSection
        granteeFragment={grantee}
        id="vesting-timeline"
        ref={registerSection("Vesting timeline")}
        valuationMultiple={valuationMultiple}
      />
      <EmployeePortalExerciseSection
        granteeFragment={grantee}
        ref={registerSection("Exercise")}
      />
      <EmployeePortalReportingAndGeneralTaxationSection
        granteeFragment={grantee}
        ref={registerSection("Reporting & General taxation")}
      />
    </Container>
  );
};

function Container({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={classNames(
        "relative mx-auto max-w-[1000px] space-y-10",
        className,
      )}
    >
      {children}
    </div>
  );
}

function EmptyPortalPlaceholder({
  showBackToRemoteButton,
}: {
  showBackToRemoteButton: boolean;
}) {
  return (
    <div className="flex flex-col items-center gap-4 rounded-remote-lg border-[0.5px] border-grey-300 bg-background-subtle p-14">
      <div className="flex flex-col items-center gap-1 font-brand">
        <BoxedIcon Icon={IconV2DuotoneEyeGlasses} size="lg" tone="orange" />
        <div className="text-center text-3XL/Medium">
          There is nothing we can show you, sadly...
        </div>
        <div className="text-center text-LG/Medium">
          No equity grants were made or reported
        </div>
      </div>
      {showBackToRemoteButton && (
        <Button as={Link} size="md" to={CONFIGURATION.REMOTE_APP_URL}>
          Back to Remote
        </Button>
      )}
    </div>
  );
}

const QUERY = graphql`
  query EmployeePortalPage_Query($granteeId: GranteeId!) {
    grantee(id: $granteeId) {
      organization {
        id
        name
      }
      ...EmployeePortalPage_Grantee
      ...EmployeePortalLayout_Grantee
    }
    me {
      ...EmployeePortalLayout_Viewer
    }
  }
`;

export default function EmployeePortalPage() {
  const granteeId = useGranteeIdParam();
  const { grantee, me } = useLazyLoadQuery<EmployeePortalPage_Query>(QUERY, {
    granteeId,
  });

  if (!grantee) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Grantee portal"
      analyticsName="Grantee - Portal"
      organizationId={grantee.organization.id}
      title={`Grantee | ${grantee.organization.name} grantee portal`}
    >
      <Equity101DrawerRemote.Provider>
        <EmployeePortalLayout granteeFragment={grantee} viewerFragment={me}>
          <EmployeePortalPage_ granteeFragment={grantee} />
        </EmployeePortalLayout>
      </Equity101DrawerRemote.Provider>
    </Page>
  );
}
