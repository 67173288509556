/**
 * @generated SignedSource<<0e1b30fd541ef0c3c2d90828f709825e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalLayout_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationSideBar_Viewer">;
  readonly " $fragmentType": "EmployeePortalLayout_Viewer";
};
export type EmployeePortalLayout_Viewer$key = {
  readonly " $data"?: EmployeePortalLayout_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalLayout_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalLayout_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplicationSideBar_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "f986598f8acdac9d11aa1373d7db3ddd";

export default node;
