/**
 * @generated SignedSource<<0b04a888496ba292c4a7b17d900398cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminGrantsLayout_Query$variables = {
  organizationId: string;
};
export type AdminGrantsLayout_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminGrantsLayout_Viewer">;
  };
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminGrantsLayout_Organization">;
  } | null;
};
export type AdminGrantsLayout_Query = {
  response: AdminGrantsLayout_Query$data;
  variables: AdminGrantsLayout_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminGrantsLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "kind": "FragmentSpread",
            "name": "AdminGrantsLayout_Viewer"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdminGrantsLayout_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminGrantsLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "kind": "ScalarField",
            "name": "isAllowedToManageOrganization",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "planIsFreemium",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isRemoteEquityBasic",
            "storageKey": null
          },
          {
            "alias": "activeCTMSGrants",
            "args": [
              {
                "kind": "Literal",
                "name": "filters",
                "value": {
                  "statusIn": [
                    "Active"
                  ]
                }
              }
            ],
            "concreteType": "OrganizationCTMSGrantsConnection",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": "ctmsGrants(filters:{\"statusIn\":[\"Active\"]})"
          },
          {
            "alias": "terminatedCTMSGrants",
            "args": [
              {
                "kind": "Literal",
                "name": "filters",
                "value": {
                  "statusIn": [
                    "Terminated"
                  ]
                }
              }
            ],
            "concreteType": "OrganizationCTMSGrantsConnection",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": "ctmsGrants(filters:{\"statusIn\":[\"Terminated\"]})"
          },
          {
            "alias": "canceledCTMSGrants",
            "args": [
              {
                "kind": "Literal",
                "name": "filters",
                "value": {
                  "statusIn": [
                    "Canceled"
                  ]
                }
              }
            ],
            "concreteType": "OrganizationCTMSGrantsConnection",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": "ctmsGrants(filters:{\"statusIn\":[\"Canceled\"]})"
          },
          {
            "if": null,
            "kind": "Defer",
            "label": "AdminGrantsLayout_Organization$defer$DraftGrantButton_Organization",
            "selections": [
              (v3/*: any*/)
            ]
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c54501961233b4dd976d57f5fd2b8f5",
    "id": null,
    "metadata": {},
    "name": "AdminGrantsLayout_Query",
    "operationKind": "query",
    "text": "query AdminGrantsLayout_Query(\n  $organizationId: OrganizationId!\n) {\n  me {\n    ...AdminGrantsLayout_Viewer_4xMPKw\n    id\n  }\n  organization(id: $organizationId) {\n    ...AdminGrantsLayout_Organization\n    id\n  }\n}\n\nfragment AdminGrantsLayout_Organization on Organization {\n  id\n  planIsFreemium\n  isRemoteEquityBasic\n  activeCTMSGrants: ctmsGrants(filters: {statusIn: [Active]}) {\n    totalCount\n  }\n  terminatedCTMSGrants: ctmsGrants(filters: {statusIn: [Terminated]}) {\n    totalCount\n  }\n  canceledCTMSGrants: ctmsGrants(filters: {statusIn: [Canceled]}) {\n    totalCount\n  }\n  ...DraftGrantButton_Organization @defer(label: \"AdminGrantsLayout_Organization$defer$DraftGrantButton_Organization\")\n  ...LargeOneColumnLayout_Organization\n}\n\nfragment AdminGrantsLayout_Viewer_4xMPKw on Account {\n  isAllowedToManageOrganization(organizationId: $organizationId)\n}\n\nfragment DraftGrantButton_Organization on Organization {\n  id\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n"
  }
};
})();

(node as any).hash = "df5836b0c2872328257b3341cd2f6237";

export default node;
