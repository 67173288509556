import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ConnectHRISProviderMessage_Organization$key } from "./__generated__/ConnectHRISProviderMessage_Organization.graphql";
import { HRISProvider } from "./__generated__/GranteeForm_CreateGrantee_Mutation.graphql";
import { ConnectToDeelButton } from "./ConnectToDeelButton";
import { ActionableMessage } from "./ui/ActionableMessage";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ConnectHRISProviderMessage_Organization on Organization {
    isConnectedToDeel
    ...ConnectToDeelButton_Organization
  }
`;

const _ConnectHRISProviderMessage: React.FC<{
  children: React.ReactNode;
  hRISProviderName: string;
}> = ({ children, hRISProviderName }) => {
  return (
    <ActionableMessage
      border
      description={
        <>
          Easily connect with {hRISProviderName} and synchronize all your
          contracts
        </>
      }
      isNew
      title={<>{hRISProviderName} integration</>}
    >
      {children}
    </ActionableMessage>
  );
};

export const ConnectHRISProviderMessage: React.FC<{
  hRISProvider: HRISProvider;
  organizationFragment: ConnectHRISProviderMessage_Organization$key;
}> = ({ hRISProvider, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  switch (hRISProvider) {
    case "DEEL": {
      if (organization.isConnectedToDeel) {
        return null;
      }
      return (
        <_ConnectHRISProviderMessage hRISProviderName="Deel">
          <ConnectToDeelButton
            className="shrink-0"
            organizationFragment={organization}
            size="extra small"
          >
            Connect with Deel
          </ConnectToDeelButton>
        </_ConnectHRISProviderMessage>
      );
    }
    case "REMOTE":
      return null;
  }
};
