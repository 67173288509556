/**
 * @generated SignedSource<<3e3f5107b65fd39392afe45b62cd0895>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardApproval_FairMarketValues_Query$variables = {
  organizationId: string;
};
export type BoardApproval_FairMarketValues_Query$data = {
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"BoardApproval_FairMarketValues_Organization">;
  } | null;
};
export type BoardApproval_FairMarketValues_Query = {
  response: BoardApproval_FairMarketValues_Query$data;
  variables: BoardApproval_FairMarketValues_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardApproval_FairMarketValues_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BoardApproval_FairMarketValues_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BoardApproval_FairMarketValues_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FairMarketValue",
            "kind": "LinkedField",
            "name": "fairMarketValueHistory",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "valuationFirm",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "valuationReportDocument",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "downloadUrl",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardMember",
            "kind": "LinkedField",
            "name": "boardMembers",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "allowBoardConsentHandledOutsideEasop",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pendingCeoNote",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pendingGrantAmendmentCeoNote",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pendingGranteeTerminationGrantAmendmentCeoNote",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pendingFairMarketValueCeoNote",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "354085e6ec12b3af98d83915eadcafe5",
    "id": null,
    "metadata": {},
    "name": "BoardApproval_FairMarketValues_Query",
    "operationKind": "query",
    "text": "query BoardApproval_FairMarketValues_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...BoardApproval_FairMarketValues_Organization\n  }\n}\n\nfragment BoardApproval_FairMarketValues_Organization on Organization {\n  id\n  name\n  fairMarketValueHistory {\n    id\n    status\n    ...FairMarketValueApprovalTable_FairMarketValue\n    ...ConfirmSubmitBoardConsentSlide_FairMarketValue\n  }\n  ...LegalWorkflowLayout_Organization\n  ...ConfirmSubmitBoardConsentSlide_Organization\n}\n\nfragment BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization on Organization {\n  id\n  boardMembers {\n    id\n    account {\n      firstName\n      lastName\n      email\n      id\n    }\n  }\n}\n\nfragment BoardNoteSection_Organization on Organization {\n  id\n  pendingCeoNote\n  pendingGrantAmendmentCeoNote\n  pendingGranteeTerminationGrantAmendmentCeoNote\n  pendingFairMarketValueCeoNote\n}\n\nfragment ConfirmSubmitBoardConsentSlide_FairMarketValue on FairMarketValue {\n  id\n  value\n}\n\nfragment ConfirmSubmitBoardConsentSlide_Organization on Organization {\n  id\n  boardMembers {\n    id\n  }\n  allowBoardConsentHandledOutsideEasop\n  ...BoardNoteSection_Organization\n  ...BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization\n  ...useDownloadBoardConsentPreview_Organization\n}\n\nfragment FairMarketValueApprovalTable_FairMarketValue on FairMarketValue {\n  id\n  date\n  value\n  valuationFirm\n  status\n  valuationReportDocument {\n    downloadUrl\n    id\n  }\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n\nfragment LegalWorkflowLayout_Organization on Organization {\n  ...LargeOneColumnLayout_Organization\n}\n\nfragment useDownloadBoardConsentPreview_Organization on Organization {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "b3907c7bc661b545d3e8501b34479d09";

export default node;
