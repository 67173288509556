import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { ArrowRightIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import _, { sortBy, uniqBy } from "lodash";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  generatePath,
  Link,
  Navigate,
  useSearchParams,
} from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../components/Page";
import { Avatar } from "../../components/ui/Avatar";
import { Button, LinkButton } from "../../components/ui/Button";
import { Logo } from "../../components/ui/Logo/Logo";
import { SearchBar } from "../../components/ui/SearchBar";
import { Tag } from "../../components/ui/Tag";
import { Typography } from "../../components/ui/Typography";
import { searchInString } from "../../helpers/string";
import { useQuery } from "../../hooks/useQuery";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import { APPLICATION_ROUTES } from "../../paths";
import { useAuthentication } from "../../services/AuthenticationProvider";
import { RoleSelectionPage_Query } from "./__generated__/RoleSelectionPage_Query.graphql";
import CreateSandboxOrganizationModalButton from "./CreateSandboxOrganizationModal";

const RoleOrganizationsList = <T,>({
  getItemId,
  getItemLabel,
  getItemLinkPath,
  getRightAddon,
  isItemFreemium,
  isItemFreeTrial,
  isItemTerminated,
  isItemViewer,
  itemMatchSearch,
  items,
  viewer,
}: {
  getItemId: (item: T) => string;
  getItemLabel: (item: T) => string;
  getItemLinkPath: (item: T) => string;
  getRightAddon?: (item: T) => React.ReactNode;
  isItemFreemium: (item: T) => boolean;
  isItemFreeTrial: (item: T) => boolean;
  isItemTerminated: (item: T) => boolean;
  isItemViewer?: (item: T) => boolean;
  itemMatchSearch: (item: T, search: string) => boolean;
  items: readonly T[];
  viewer: {
    isSuperAdmin: boolean;
  };
}) => {
  const [searchInputValue, setSearchInputValue] = useState("");

  return (
    <div className="space-y-6">
      <SearchBar
        autoFocus
        onChange={setSearchInputValue}
        placeholder="Search organization"
        value={searchInputValue}
      />
      <div className="space-y-2">
        {items
          .filter((item) => itemMatchSearch(item, searchInputValue))
          .map((item) => (
            <Link
              className="flex items-center gap-3 rounded border-[0.5px] border-gray-03 bg-white-01 p-4 drop-shadow-100 hover:border-primary"
              key={getItemId(item)}
              to={getItemLinkPath(item)}
            >
              <Avatar>{getItemLabel(item)}</Avatar>
              <Typography
                className="flex flex-1 items-center gap-2"
                variant="Medium/Extra Small"
              >
                <div>{getItemLabel(item)}</div>
                {isItemFreemium(item) && viewer.isSuperAdmin && (
                  <Tag color="purple" size="Extra Small">
                    FREEMIUM
                  </Tag>
                )}
                {isItemFreeTrial(item) && viewer.isSuperAdmin && (
                  <Tag color="purple" size="Extra Small">
                    FREE TRIAL
                  </Tag>
                )}
                {isItemViewer?.(item) && (
                  <Tag color="glass-green" size="Extra Small">
                    VIEWER
                  </Tag>
                )}
                {isItemTerminated(item) && (
                  <Tag color="red" size="Extra Small">
                    TERMINATED
                  </Tag>
                )}
              </Typography>
              <div className="flex items-center gap-2">
                {getRightAddon?.(item)}
                <ChevronRightIcon className="block h-5 w-5 text-gray-09" />
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

const QUERY = graphql`
  query RoleSelectionPage_Query {
    me {
      email
      organizationsAsAdmin {
        id
        name
        planIsFreemium
        plan
        terminated
      }
      boardMembers {
        id
        pendingSignatureRequestsCount
        organization {
          name
          planIsFreemium
          plan
          terminated
        }
      }
      grantees {
        id
        name
        organization {
          name
          planIsFreemium
          plan
          terminated
        }
      }
      equityViewers {
        id
        organization {
          id
          name
          planIsFreemium
          plan
          terminated
        }
      }
      isSuperAdmin
      isAllowedToCreateSandboxOrganization
    }
  }
`;

const RoleTab: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Tab className="rounded px-4 py-2 text-black-05 transition-all hover:bg-primary-01 hover:text-primary-05 ui-selected:bg-primary-01 ui-selected:text-primary">
    <Typography variant="Medium/Extra Small">{children}</Typography>
  </Tab>
);

const RoleSelectionPage: React.FC = () => {
  const { query } = useQuery<RoleSelectionPage_Query>(QUERY, {});
  const authentication = useAuthentication();

  const firstBoardMemberRole = query.me.boardMembers[0];
  const firstAdminRoleOrganization = query.me.organizationsAsAdmin[0];
  const firstGranteeRole = query.me.grantees[0];
  const firstEquityViewerRole = query.me.equityViewers[0];
  const accountRoleCount =
    query.me.boardMembers.length +
    uniqBy(
      [...query.me.organizationsAsAdmin, ...query.me.equityViewers],
      ({ id }) => id,
    ).length +
    query.me.grantees.length;
  const accountHasSingleRole = accountRoleCount === 1;
  const accountHasBoardMemberRole = query.me.boardMembers.length > 0;
  const accountHasAdminOrEquityViewerRole =
    query.me.organizationsAsAdmin.length > 0 ||
    query.me.equityViewers.length > 0;
  const accountHasGranteeRole = query.me.grantees.length > 0;
  const isViewerAdminInOrganization = (organizationId: string) =>
    query.me.organizationsAsAdmin.some(
      (organization) => organization.id === organizationId,
    );

  const [searchParams] = useSearchParams();
  const goToParam = searchParams.get("goto");

  const getOrganizationAdminNavigationPath = useCallback(
    ({ organizationId }: { organizationId: string }) => {
      if (goToParam === "integrations") {
        return generatePath(
          APPLICATION_ROUTES.organizationSettingsIntegrations,
          {
            organizationId,
          },
        );
      }
      return generatePath(APPLICATION_ROUTES.organizationHome, {
        organizationId,
      });
    },
    [goToParam],
  );

  if (accountHasSingleRole && firstAdminRoleOrganization) {
    return (
      <Navigate
        to={getOrganizationAdminNavigationPath({
          organizationId: firstAdminRoleOrganization.id,
        })}
      />
    );
  }

  if (accountHasSingleRole && firstBoardMemberRole) {
    return (
      <Navigate
        to={generatePath(APPLICATION_ROUTES.boardMemberOverview, {
          boardMemberId: firstBoardMemberRole.id,
        })}
      />
    );
  }

  if (accountHasSingleRole && firstGranteeRole) {
    return (
      <Navigate
        to={generatePath(APPLICATION_ROUTES.employeePortal, {
          granteeId: firstGranteeRole.id,
        })}
      />
    );
  }

  if (accountHasSingleRole && firstEquityViewerRole) {
    return (
      <Navigate
        to={getOrganizationAdminNavigationPath({
          organizationId: firstEquityViewerRole.organization.id,
        })}
      />
    );
  }

  return (
    <Page analyticsName="Organization Choice Home" title="Role selection">
      <div className="fixed inset-0 z-10 flex h-fit items-center justify-between bg-white px-6 py-4 shadow-100">
        <Logo className="h-6" variant="black" />
        {query.me.isSuperAdmin && (
          <LinkButton
            rightIcon={<ArrowRightIcon />}
            size="extra small"
            to={generatePath(APPLICATION_ROUTES.superAdmin)}
            variant="Primary Outline"
          >
            Admin access
          </LinkButton>
        )}
      </div>
      <div className="relative h-full overflow-y-auto bg-white p-10 pt-26 shadow-100">
        <AuthLayout.Card
          logo={null}
          subtitle={
            <div className="space-y-2">
              <div>
                You&apos;re connected as{" "}
                <Typography variant="Medium/Extra Small">
                  {query.me.email}
                </Typography>
              </div>
              <div>
                Not you?{" "}
                <button
                  className="text-primary-05 transition-all hover:text-primary-07"
                  onClick={() => {
                    authentication.logout();
                  }}
                >
                  <Typography variant="Medium/Extra Small">
                    Change account
                  </Typography>
                </button>
              </div>
            </div>
          }
          title="Choose an Organization"
        >
          <TabGroup className="space-y-6">
            <TabList
              as="div"
              className="flex items-center justify-center gap-2"
            >
              {accountHasAdminOrEquityViewerRole && <RoleTab>Admin</RoleTab>}
              {accountHasBoardMemberRole && <RoleTab>Board Member</RoleTab>}
              {accountHasGranteeRole && <RoleTab>Grantee</RoleTab>}
            </TabList>
            <TabPanels>
              {accountHasAdminOrEquityViewerRole && (
                <TabPanel>
                  <RoleOrganizationsList
                    getItemId={(item) => item.id}
                    getItemLabel={(item) => item.name}
                    getItemLinkPath={(item) =>
                      getOrganizationAdminNavigationPath({
                        organizationId: item.id,
                      })
                    }
                    isItemFreemium={(item) => item.planIsFreemium}
                    isItemFreeTrial={(item) => item.plan === "freeTrial"}
                    isItemTerminated={(item) => item.terminated}
                    isItemViewer={(item) =>
                      !isViewerAdminInOrganization(item.id)
                    }
                    itemMatchSearch={(item, search) =>
                      searchInString(item.name, search)
                    }
                    items={_([
                      ...query.me.organizationsAsAdmin,
                      ...query.me.equityViewers.map(
                        ({ organization }) => organization,
                      ),
                    ])
                      .uniqBy((organization) => organization.id)
                      .sortBy((organization) => organization.name)
                      .value()}
                    viewer={query.me}
                  />
                </TabPanel>
              )}
              {accountHasBoardMemberRole && (
                <TabPanel>
                  <RoleOrganizationsList
                    getItemId={(item) => item.id}
                    getItemLabel={(item) => item.organization.name}
                    getItemLinkPath={(item) =>
                      generatePath(APPLICATION_ROUTES.boardMemberOverview, {
                        boardMemberId: item.id,
                      })
                    }
                    getRightAddon={(item) =>
                      item.pendingSignatureRequestsCount > 0 && (
                        <Tag color="orange">
                          <FormattedMessage
                            defaultMessage={`{pendingSignatureRequestCount, plural, one {# Approval} other {# Approvals}}`}
                            values={{
                              pendingSignatureRequestCount:
                                item.pendingSignatureRequestsCount,
                            }}
                          />
                        </Tag>
                      )
                    }
                    isItemFreemium={(item) => item.organization.planIsFreemium}
                    isItemFreeTrial={(item) =>
                      item.organization.plan === "freeTrial"
                    }
                    isItemTerminated={(item) => item.organization.terminated}
                    itemMatchSearch={(item, search) =>
                      searchInString(item.organization.name, search)
                    }
                    items={sortBy(
                      query.me.boardMembers,
                      (boardMember) => boardMember.organization.name,
                    )}
                    viewer={query.me}
                  />
                </TabPanel>
              )}
              {accountHasGranteeRole && (
                <TabPanel>
                  <RoleOrganizationsList
                    getItemId={(item) => item.id}
                    getItemLabel={(item) =>
                      `${item.organization.name} - ${item.name}`
                    }
                    getItemLinkPath={(item) =>
                      generatePath(APPLICATION_ROUTES.employeePortal, {
                        granteeId: item.id,
                      })
                    }
                    isItemFreemium={(item) => item.organization.planIsFreemium}
                    isItemFreeTrial={(item) =>
                      item.organization.plan === "freeTrial"
                    }
                    isItemTerminated={(item) => item.organization.terminated}
                    itemMatchSearch={(item, search) =>
                      searchInString(item.organization.name, search)
                    }
                    items={sortBy(
                      query.me.grantees,
                      (boardMember) => boardMember.organization.name,
                    )}
                    viewer={query.me}
                  />
                </TabPanel>
              )}
            </TabPanels>
          </TabGroup>
          {query.me.isAllowedToCreateSandboxOrganization && (
            <CreateSandboxOrganizationModalButton />
          )}

          <div className="flex justify-center">
            <Button
              onClick={authentication.logout}
              size="small"
              variant="Danger Outline"
            >
              Log out
            </Button>
          </div>
        </AuthLayout.Card>
      </div>
    </Page>
  );
};

export default RoleSelectionPage;
