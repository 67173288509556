/**
 * @generated SignedSource<<80ab1d6d59f8af13ee68ab49ec51edb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdvancedApplicationSidebar_Viewer$data = {
  readonly isAllowedToManageOrganization: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationSideBar_Viewer">;
  readonly " $fragmentType": "AdvancedApplicationSidebar_Viewer";
};
export type AdvancedApplicationSidebar_Viewer$key = {
  readonly " $data"?: AdvancedApplicationSidebar_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdvancedApplicationSidebar_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdvancedApplicationSidebar_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplicationSideBar_Viewer"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "ScalarField",
      "name": "isAllowedToManageOrganization",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "16b7a2e396b09b7e19b9391f0ac76053";

export default node;
