import { useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Button } from "../../../../../components/ui/Button";
import { SlideOver } from "../../../../../components/ui/SlideOver";
import { makeRemoteController } from "../../../../../helpers/makeRemoteController";
import { useSafeMutation } from "../../../../../hooks/useSafeMutation";
import { CreateSlideOver_BackloadedVestingSchedules_Mutation } from "./__generated__/CreateSlideOver_BackloadedVestingSchedules_Mutation.graphql";
import { CreateSlideOver_LinearVestingSchedules_Mutation } from "./__generated__/CreateSlideOver_LinearVestingSchedules_Mutation.graphql";
import { CreateSlideOver_VestingSchedule_Organization$key } from "./__generated__/CreateSlideOver_VestingSchedule_Organization.graphql";
import {
  BackloadedVestingScheduleFormValues,
  LinearVestingScheduleFormValues,
  VestingScheduleFormType,
} from "./FORM_SCHEMA";
import {
  useBackloadedVestingScheduleForm,
  useLinearVestingScheduleForm,
} from "./useVestingScheduleForm";
import { VestingScheduleForm } from "./VestingScheduleForm";
import { VestingSchedulePreview } from "./VestingSchedulePreview";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CreateSlideOver_VestingSchedule_Organization on Organization {
    id
    ...useVestingScheduleForm_Organization
  }
`;

const CREATE_LINEAR_VESTING_SCHEDULE_MUTATION = graphql`
  mutation CreateSlideOver_LinearVestingSchedules_Mutation(
    $attributes: LinearVestingScheduleAttributes!
    $organizationId: OrganizationId!
  ) {
    createLinearVestingSchedule(
      attributes: $attributes
      organizationId: $organizationId
    ) {
      id
    }
  }
`;

const CREATE_BACKLOADED_VESTING_SCHEDULE_MUTATION = graphql`
  mutation CreateSlideOver_BackloadedVestingSchedules_Mutation(
    $attributes: BackloadedVestingScheduleAttributes!
    $organizationId: OrganizationId!
  ) {
    createBackloadedVestingSchedule(
      attributes: $attributes
      organizationId: $organizationId
    ) {
      id
    }
  }
`;

function CreateSlideOver({
  onClose,
  onExited,
  organizationFragment,
  show,
}: {
  onClose: (props: { vestingScheduleId?: string }) => void;
  onExited?: () => void;
  organizationFragment: CreateSlideOver_VestingSchedule_Organization$key;
  show: boolean;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const [
    triggerCreateLinearVestingScheduleMutation,
    linearVestingScheduleMutationIsInFlight,
  ] = useSafeMutation<CreateSlideOver_LinearVestingSchedules_Mutation>(
    CREATE_LINEAR_VESTING_SCHEDULE_MUTATION,
  );

  const [
    triggerCreateBackloadedVestingScheduleMutation,
    backloadedVestingScheduleMutationIsInFlight,
  ] = useSafeMutation<CreateSlideOver_BackloadedVestingSchedules_Mutation>(
    CREATE_BACKLOADED_VESTING_SCHEDULE_MUTATION,
  );

  const handleLinearVestingScheduleFormSubmit = async (
    values: LinearVestingScheduleFormValues,
  ) => {
    const { createLinearVestingSchedule: vestingSchedule } =
      await triggerCreateLinearVestingScheduleMutation({
        variables: {
          attributes: {
            cliffDurationInMonths: values.hasCliff
              ? (values.cliffDurationInMonths ?? 0)
              : 0,
            durationInMonths: values.durationInMonths,
            name: values.name,
            vestedAtCliffPercentage: values.vestedAtCliffPercentage ?? 0,
            vestingOccurrence: values.vestingOccurrence,
          },
          organizationId: organization.id,
        },
      });

    onClose({ vestingScheduleId: vestingSchedule.id });
  };

  const linearVestingScheduleForm = useLinearVestingScheduleForm({
    currentVestingScheduleId: null,
    organizationFragment: organization,
  });

  const handleBackloadedVestingScheduleFormSubmit = async (
    values: BackloadedVestingScheduleFormValues,
  ) => {
    const { createBackloadedVestingSchedule: vestingSchedule } =
      await triggerCreateBackloadedVestingScheduleMutation({
        variables: {
          attributes: {
            cliffActivatedOnFirstPeriod: values.cliffActivatedOnFirstPeriod,
            name: values.name,
            periods: values.periods,
            vestingOccurrence: values.vestingOccurrence,
          },
          organizationId: organization.id,
        },
      });

    onClose({ vestingScheduleId: vestingSchedule.id });
  };

  const backloadedVestingScheduleForm = useBackloadedVestingScheduleForm({
    currentVestingScheduleId: null,
    organizationFragment: organization,
  });

  const [vestingScheduleType, setVestingScheduleType] =
    useState<VestingScheduleFormType>("LINEAR");
  const validatedLiveValues = useMemo(() => {
    switch (vestingScheduleType) {
      case "BACKLOADED":
        return backloadedVestingScheduleForm.validatedLiveValues;
      case "LINEAR":
        return linearVestingScheduleForm.validatedLiveValues;
    }
  }, [
    vestingScheduleType,
    backloadedVestingScheduleForm.validatedLiveValues,
    linearVestingScheduleForm.validatedLiveValues,
  ]);

  return (
    <SlideOver
      footer={
        <div className="flex items-center justify-end gap-2 p-6">
          <Button
            onClick={() => {
              onClose({});
            }}
            size="small"
            type="button"
            variant="Secondary Full"
          >
            Cancel
          </Button>
          <Button
            form="create-vesting-schedule-form"
            loading={
              linearVestingScheduleMutationIsInFlight ||
              backloadedVestingScheduleMutationIsInFlight
            }
            size="small"
            type="submit"
          >
            Create
          </Button>
        </div>
      }
      header={
        <SlideOver.Header
          onClose={() => {
            onClose({});
          }}
          padding={10}
          subtitle="Define the vesting parameters below"
        >
          Create vesting schedule
        </SlideOver.Header>
      }
      onClose={() => {
        onClose({});
      }}
      onExited={onExited}
      show={show}
      width="4xl"
    >
      <div className="space-y-10 px-10 py-16">
        <div>
          <VestingScheduleForm
            backloadedVestingScheduleForm={backloadedVestingScheduleForm}
            id="create-vesting-schedule-form"
            linearVestingScheduleForm={linearVestingScheduleForm}
            onBackloadedVestingScheduleFormSubmit={
              handleBackloadedVestingScheduleFormSubmit
            }
            onLinearVestingScheduleFormSubmit={
              handleLinearVestingScheduleFormSubmit
            }
            onTypeChange={setVestingScheduleType}
            type={vestingScheduleType}
          />
        </div>
        {validatedLiveValues && (
          <VestingSchedulePreview
            type={vestingScheduleType}
            values={validatedLiveValues}
          />
        )}
      </div>
    </SlideOver>
  );
}

export const CreateSlideOverRemote = makeRemoteController<
  {
    organizationFragment: CreateSlideOver_VestingSchedule_Organization$key;
  },
  {
    vestingScheduleId?: string;
  }
>({
  render: ({ close, reset, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <CreateSlideOver
        onClose={close}
        onExited={reset}
        organizationFragment={state.data.organizationFragment}
        show={state.show}
      />
    );
  },
});
