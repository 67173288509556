import {
  ChartBarIcon,
  GlobeAmericasIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { FreemiumBlockerCard } from "../../../../components/FreemiumBlockerCard";
import { Page } from "../../../../components/Page";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { Button, LinkButton } from "../../../../components/ui/Button";
import { CenteredColumnLayout } from "../../../../components/ui/Layout/CenteredColumnLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Typography } from "../../../../components/ui/Typography";
import { useBoolean } from "../../../../hooks/useBoolean";
import { useQuery } from "../../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { GranteePortalFreemium_Organization$key } from "./__generated__/GranteePortalFreemium_Organization.graphql";
import { GranteePortalFreemium_Query } from "./__generated__/GranteePortalFreemium_Query.graphql";
import GranteePortalFreemiumIllustration from "./grantee-portal-freemium-illustration.png";
import { PreviewGranteePortalFreemiumModal } from "./PreviewGranteePortalFreemiumModal";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteePortalFreemium_Organization on Organization {
    id
    name
    grantees {
      totalCount
    }
    freemiumPortalPreviewGrantee {
      id
    }
    ...FreemiumBlockerCard_Organization
    ...PreviewGranteePortalFreemiumModal_Organization
  }
`;

const GranteePortalFreemiumPage_: React.FC<{
  organizationFragment: GranteePortalFreemium_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const {
    setFalse: closePreviewGranteePortalFreemiumModal,
    setTrue: showPreviewGranteePortalFreemiumModal,
    value: previewGranteePortalFreemiumModalIsShown,
  } = useBoolean(false);

  return (
    <>
      <PreviewGranteePortalFreemiumModal
        onClose={closePreviewGranteePortalFreemiumModal}
        organizationFragment={organization}
        show={previewGranteePortalFreemiumModalIsShown}
      />
      <CenteredColumnLayout
        breadcrumbs={
          <BreadCrumb>
            <BreadCrumb.Link
              to={generatePath(APPLICATION_ROUTES.organizationHome, {
                organizationId: organization.id,
              })}
            >
              {organization.name}
            </BreadCrumb.Link>
            <BreadCrumb.Link to=".">Grantee portal</BreadCrumb.Link>
          </BreadCrumb>
        }
        showFooter
      >
        <FreemiumBlockerCard
          detailsList={
            <>
              <FreemiumBlockerCard.LabelWithIcon
                icon={<LightBulbIcon />}
                label="Stop treating international employees like second class citizens"
              />
              <FreemiumBlockerCard.LabelWithIcon
                icon={<ChartBarIcon />}
                label="Increase awareness of equity and its perceived value"
              />
              <FreemiumBlockerCard.LabelWithIcon
                icon={<GlobeAmericasIcon />}
                label="Provide country-specific insights about taxation, regulations, and more"
              />
            </>
          }
          mailtoSubject="About grantee portal"
          organizationFragment={organization}
          rightSideIllustration={
            <div
              className={classNames(
                "bg-gradient-golden-hour",
                "relative w-[453px] max-w-[50%] rounded-2xl",
              )}
            >
              <div className="absolute bottom-0 left-6 top-0 flex w-full items-center">
                <img
                  alt="Grantee portal freemium illustration"
                  src={GranteePortalFreemiumIllustration}
                />
              </div>
            </div>
          }
          subTitle={
            <div className="space-y-6">
              <RoundedBox
                className="flex items-center gap-6 !border-purple p-6"
                withBorder
                withShadow
              >
                <div className="rounded-full bg-purple-01 p-2 text-purple">
                  <ChartBarIcon className="h-5 w-5" />
                </div>
                <div className="flex-1 space-y-2">
                  <Typography as="div" variant="Medium/Small">
                    Preview grantee portal
                  </Typography>
                  <Typography
                    as="div"
                    className="text-black-05"
                    variant="Regular/Extra Small"
                  >
                    Choose a grantee from your team and preview their portal.
                  </Typography>
                </div>
                {organization.freemiumPortalPreviewGrantee ? (
                  <LinkButton
                    size="small"
                    target="_blank"
                    to={generatePath(APPLICATION_ROUTES.employeePortal, {
                      granteeId: organization.freemiumPortalPreviewGrantee.id,
                    })}
                    variant="Primary Full"
                  >
                    Preview portal
                  </LinkButton>
                ) : (
                  <Button
                    onClick={showPreviewGranteePortalFreemiumModal}
                    size="small"
                    type="button"
                    variant="Primary Full"
                  >
                    Preview portal
                  </Button>
                )}
              </RoundedBox>
              <div>
                Show the importance of equity comp to improve retention and give
                all your{" "}
                <Typography className="text-primary" variant="Medium/Small">
                  {organization.grantees.totalCount}
                </Typography>{" "}
                other team members relevant guidance wherever they live, taking
                the task of explaining equity off your hands
              </div>
            </div>
          }
          title="Educate Employees on Equity"
        />
      </CenteredColumnLayout>
    </>
  );
};

const QUERY = graphql`
  query GranteePortalFreemium_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...GranteePortalFreemium_Organization
    }
  }
`;

const GranteePortalFreemiumPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<GranteePortalFreemium_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName="Admin - Grantee portal freemium"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} grantee portal freemium`}
    >
      <GranteePortalFreemiumPage_ organizationFragment={query.organization} />
    </Page>
  );
};

export default GranteePortalFreemiumPage;
