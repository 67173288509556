import { Text } from "@remote-com/norma";
import classNames from "classnames";
import React from "react";

export interface RemoteLikeInfoBlockItem {
  key?: string;
  label: string;
  subLabel?: React.ReactNode;
  value?: null | React.ReactNode;
}

export const RemoteLikeInfoBlock: React.FC<{
  info: readonly RemoteLikeInfoBlockItem[];
}> = ({ info }) => {
  return (
    <div className="divide-y-[0.5px] divide-grey-300 @container">
      {info.map(({ label, key = label, subLabel, value }) => (
        <div
          className={classNames(
            "gap-x-10 gap-y-1",
            "flex flex-col py-2",
            "@md:grid @md:min-h-12 @md:grid-cols-[1fr_2fr] @md:items-center @md:py-2",
          )}
          key={key}
        >
          <div>
            <Text variant="smMedium">{label}</Text>
            {subLabel && (
              <Text className="text-grey-600" variant="xs">
                {subLabel}
              </Text>
            )}
          </div>
          <Text className="text-grey-600" variant="sm">
            {value ?? "-"}
          </Text>
        </div>
      ))}
    </div>
  );
};
