/**
 * @generated SignedSource<<7750331384857d850030abf7cf0f9245>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSections_Grantee$data = {
  readonly useSectionsNonVirtualCTMSGrants: ReadonlyArray<{
    readonly __typename: "CTMSGrant";
  }>;
  readonly " $fragmentType": "useSections_Grantee";
};
export type useSections_Grantee$key = {
  readonly " $data"?: useSections_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSections_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useSections_Grantee",
  "selections": [
    {
      "alias": "useSectionsNonVirtualCTMSGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "grantStatusIn",
          "value": [
            "Active",
            "Terminated"
          ]
        },
        {
          "kind": "Literal",
          "name": "grantType",
          "value": "real"
        }
      ],
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": "ctmsGrants(grantStatusIn:[\"Active\",\"Terminated\"],grantType:\"real\")"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "21ffb0eef3afa051e13a8c3cf4ce5f13";

export default node;
