import classNames from "classnames";
import { isNaN, isNil, round } from "lodash";
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";
import { useIntl } from "react-intl";

import {
  convertFullyDilutedRatioToShares,
  convertFullyDilutedRatioToValue,
  convertSharesToFullyDilutedRatio,
  convertSharesToValue,
  convertValueToFullyDilutedRatio,
  convertValueToShares,
} from "../hooks/useOrganizationSharesUtil";
import { SharesValueMode } from "./SharesValue";
import {
  SharesValueSwitcher,
  useSharesInputModesAvailable,
} from "./SharesValueSwitcher";
import { Input as RegularInput } from "./ui/Form/Inputs/Input";
import { MaskedInput } from "./ui/Form/Inputs/MaskedInput";

interface INPUT_VALUE {
  mode: SharesValueMode;
  value: string;
}

const ownershipToString = (ownership: number) =>
  round(ownership * 100, 5).toString();

export const useGrantedSharesController = ({
  fullyDilutedShares,
  initialState = { mode: "FULLY_DILUTED" },
  latestPricePerShare,
  maximumShares,
}: {
  fullyDilutedShares: null | number;
  initialState?: {
    mode: SharesValueMode;
    ownership?: number;
    shares?: number;
    value?: number;
  };
  latestPricePerShare: null | number;
  maximumShares?: null | number;
}) => {
  const modesAvailable = useSharesInputModesAvailable({
    fullyDilutedShares,
    latestPricePerShare,
  });

  const initialMode = useMemo(() => {
    if (modesAvailable.includes(initialState.mode)) {
      return initialState.mode;
    }
    return "SHARES";
  }, [initialState.mode, modesAvailable]);

  const [state, setState] = useState<INPUT_VALUE>(() => {
    if (initialState.shares) {
      switch (initialMode) {
        case "FULLY_DILUTED":
          if (fullyDilutedShares === null)
            throw new Error(
              "fullyDilutedShares is required when using FULLY_DILUTED mode",
            );
          return {
            mode: "FULLY_DILUTED",
            value: ownershipToString(
              convertSharesToFullyDilutedRatio({
                fullyDilutedShares,
                shares: initialState.shares,
              }),
            ),
          };
        case "SHARES":
          return {
            mode: "SHARES",
            value: initialState.shares.toString(),
          };
        case "USD_VALUE":
          if (latestPricePerShare === null) {
            throw new Error(
              "latestPricePerShare is required when using USD_VALUE mode",
            );
          }

          return {
            mode: "USD_VALUE",
            value: convertSharesToValue({
              latestPricePerShare,
              shares: initialState.shares,
            }).toString(),
          };
      }
    }

    if (initialState.ownership) {
      switch (initialMode) {
        case "FULLY_DILUTED":
          return {
            mode: "FULLY_DILUTED",
            value: ownershipToString(initialState.ownership),
          };
        case "SHARES":
          if (fullyDilutedShares === null)
            throw new Error(
              "fullyDilutedShares is required when using FULLY_DILUTED mode",
            );
          return {
            mode: "SHARES",
            value: convertFullyDilutedRatioToShares({
              fullyDilutedRatio: initialState.ownership,
              fullyDilutedShares,
            }).toString(),
          };
        case "USD_VALUE":
          if (latestPricePerShare === null) {
            throw new Error(
              "latestPricePerShare is required when using USD_VALUE mode",
            );
          }

          if (fullyDilutedShares === null)
            throw new Error(
              "fullyDilutedShares is required when using FULLY_DILUTED mode",
            );

          return {
            mode: "USD_VALUE",
            value: convertFullyDilutedRatioToValue({
              fullyDilutedRatio: initialState.ownership,
              fullyDilutedShares,
              latestPricePerShare,
            }).toString(),
          };
      }
    }

    if (initialState.value) {
      if (latestPricePerShare === null) {
        throw new Error(
          "latestPricePerShare is required when using value as initial state",
        );
      }

      switch (initialMode) {
        case "FULLY_DILUTED":
          if (fullyDilutedShares === null)
            throw new Error(
              "fullyDilutedShares is required when using FULLY_DILUTED mode",
            );
          return {
            mode: "FULLY_DILUTED",
            value: ownershipToString(
              convertValueToFullyDilutedRatio({
                fullyDilutedShares,
                latestPricePerShare,
                value: initialState.value,
              }),
            ),
          };
        case "SHARES":
          return {
            mode: "SHARES",
            value: convertValueToShares({
              latestPricePerShare,
              value: initialState.value,
            }).toString(),
          };
        case "USD_VALUE":
          return {
            mode: "USD_VALUE",
            value: initialState.value.toString(),
          };
      }
    }

    return { mode: initialMode, value: "" };
  });

  const stateValueAsNumber = useMemo(() => {
    const valueAsNumber_ = parseFloat(state.value);

    return isNaN(valueAsNumber_) ? null : valueAsNumber_;
  }, [state.value]);

  const shares = useMemo(() => {
    if (isNil(stateValueAsNumber)) {
      return null;
    }

    switch (state.mode) {
      case "FULLY_DILUTED":
        if (fullyDilutedShares === null)
          throw new Error(
            "fullyDilutedShares is required when using FULLY_DILUTED mode",
          );
        return convertFullyDilutedRatioToShares({
          fullyDilutedRatio: stateValueAsNumber / 100,
          fullyDilutedShares,
        });
      case "SHARES":
        return stateValueAsNumber;
      case "USD_VALUE":
        if (latestPricePerShare === null) {
          return null;
        }

        return convertValueToShares({
          latestPricePerShare,
          value: stateValueAsNumber,
        });
    }
  }, [fullyDilutedShares, latestPricePerShare, state.mode, stateValueAsNumber]);

  const value = useMemo(() => {
    if (isNil(shares) || latestPricePerShare === null) {
      return null;
    }

    return shares * latestPricePerShare;
  }, [latestPricePerShare, shares]);

  const ownership = useMemo(() => {
    if (shares === null || fullyDilutedShares === null) {
      return null;
    }
    return shares / fullyDilutedShares;
  }, [fullyDilutedShares, shares]);

  const setMode = useCallback(
    (mode: SharesValueMode) => {
      setState(() => {
        switch (mode) {
          case "FULLY_DILUTED":
            return {
              mode,
              value: ownership === null ? "" : ownershipToString(ownership),
            };
          case "SHARES":
            return {
              mode,
              value: shares === null ? "" : shares.toString(),
            };
          case "USD_VALUE":
            return {
              mode,
              value: value === null ? "" : value.toString(),
            };
        }
      });
    },
    [ownership, shares, value],
  );

  const setShares = useCallback(
    (shares: number) => {
      setState(({ mode }) => {
        switch (mode) {
          case "FULLY_DILUTED":
            if (fullyDilutedShares === null)
              throw new Error(
                "fullyDilutedShares is required when using FULLY_DILUTED mode",
              );
            return {
              mode,
              value: ownershipToString(
                convertSharesToFullyDilutedRatio({
                  fullyDilutedShares,
                  shares,
                }),
              ),
            };
          case "SHARES":
            return { mode, value: shares.toString() };
          case "USD_VALUE":
            if (latestPricePerShare === null) {
              throw new Error(
                "latestPricePerShare is required when using USD_VALUE mode",
              );
            }

            return {
              mode,
              value: convertSharesToValue({
                latestPricePerShare,
                shares,
              }).toString(),
            };
        }
      });
    },
    [fullyDilutedShares, latestPricePerShare],
  );

  const setInputValue = useCallback(
    (inputValue: string) => {
      const inputValueAsNumber_ = parseFloat(inputValue);
      const inputValueAsNumber = isNaN(inputValueAsNumber_)
        ? null
        : inputValueAsNumber_;

      setState(({ mode }) => {
        if (inputValueAsNumber === null) {
          return { mode, value: inputValue };
        }

        switch (mode) {
          case "FULLY_DILUTED":
            if (fullyDilutedShares === null)
              throw new Error(
                "fullyDilutedShares is required when using FULLY_DILUTED mode",
              );
            return {
              mode,
              value:
                typeof maximumShares === "number" &&
                convertFullyDilutedRatioToShares({
                  fullyDilutedRatio: inputValueAsNumber / 100,
                  fullyDilutedShares,
                }) > maximumShares
                  ? ownershipToString(
                      convertSharesToFullyDilutedRatio({
                        fullyDilutedShares,
                        shares: maximumShares,
                      }),
                    )
                  : inputValue,
            };
          case "SHARES":
            return {
              mode,
              value:
                typeof maximumShares === "number" &&
                inputValueAsNumber > maximumShares
                  ? maximumShares.toString()
                  : inputValue,
            };
          case "USD_VALUE":
            if (latestPricePerShare === null) {
              throw new Error(
                "latestPricePerShare is required when using value as initial state",
              );
            }

            return {
              mode,
              value:
                typeof maximumShares === "number" &&
                convertValueToShares({
                  latestPricePerShare,
                  value: inputValueAsNumber,
                }) > maximumShares
                  ? convertSharesToValue({
                      latestPricePerShare,
                      shares: maximumShares,
                    }).toString()
                  : inputValue,
            };
        }
      });
    },
    [latestPricePerShare, fullyDilutedShares, maximumShares],
  );

  const sharesValueSwitcherOptions = useSharesInputModesAvailable({
    fullyDilutedShares,
    latestPricePerShare,
  });

  const inputController = useMemo(
    () => ({
      fullyDilutedShares,
      latestPricePerShare,
      onModeChange: setMode,
      onValueChange: setInputValue,
      sharesValueSwitcherOptions,
      value: state,
    }),
    [
      setInputValue,
      setMode,
      sharesValueSwitcherOptions,
      state,
      fullyDilutedShares,
      latestPricePerShare,
    ],
  );

  return useMemo(
    () => ({
      inputController,
      mode: state.mode,
      ownership,
      setMode,
      setShares,
      shares,
      value,
    }),
    [setMode, setShares, inputController, shares, value, ownership, state.mode],
  );
};

const MODES_STEP_MAP: Record<SharesValueMode, number | string> = {
  FULLY_DILUTED: "any",
  SHARES: 1,
  USD_VALUE: 0.01,
};

const SPACING = {
  2: "space-y-2",
  4: "space-y-4",
} as const;

const INPUT_AFTER_MAP: Record<
  SharesValueMode,
  {
    shares: string;
    virtualShares: string;
  }
> = {
  FULLY_DILUTED: {
    shares: "%",
    virtualShares: "%",
  },
  SHARES: {
    shares: "Shares",
    virtualShares: "SARs",
  },
  USD_VALUE: {
    shares: "$",
    virtualShares: "$",
  },
};

const GrantedSharesInput_ = ({
  autoFocus = true,
  className,
  disabled,
  fullyDilutedShares,
  invalid,
  isVirtual = false,
  latestPricePerShare,
  name,
  onModeChange,
  onValueChange,
  placeholder,
  sharesValueSwitcherOptions,
  spacing = 4,
  value,
}: {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  fullyDilutedShares: null | number;
  invalid?: boolean;
  isVirtual?: boolean;
  latestPricePerShare: null | number;
  name?: string;
  onModeChange: (mode: SharesValueMode) => void;
  onValueChange: (value: string) => void;
  placeholder?: string;
  sharesValueSwitcherOptions: SharesValueMode[];
  spacing?: 2 | 4;
  value: INPUT_VALUE;
}) => {
  const intl = useIntl();

  const INPUT_PLACEHOLDER_MAP = useMemo(
    () => ({
      FULLY_DILUTED: `e.g. ${intl.formatNumber(0.1 / 100, {
        maximumFractionDigits: 2,
        style: "percent",
      })}`,
      SHARES: `e.g. ${intl.formatNumber(15_000)}`,
      USD_VALUE: `e.g. ${intl.formatNumber(100_000, {
        currency: "USD",
        style: "currency",
      })}`,
    }),
    [intl],
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      onValueChange(event.target.value);
    },
    [onValueChange],
  );

  const Input =
    value.mode === "USD_VALUE" ? MaskedInput.Currency : RegularInput;

  return (
    <div className={classNames(SPACING[spacing])}>
      <SharesValueSwitcher
        fullyDilutedShares={fullyDilutedShares}
        isVirtualShares={isVirtual}
        latestPricePerShare={latestPricePerShare}
        onChange={onModeChange}
        options={sharesValueSwitcherOptions}
        value={value.mode}
      />

      <Input
        after={
          INPUT_AFTER_MAP[value.mode][isVirtual ? "virtualShares" : "shares"]
        }
        autoFocus={autoFocus}
        className={className}
        disabled={disabled}
        id={name}
        invalid={!!invalid}
        min={0}
        name={name}
        onChange={handleChange}
        placeholder={placeholder ?? INPUT_PLACEHOLDER_MAP[value.mode]}
        prefix=""
        step={MODES_STEP_MAP[value.mode]}
        type="number"
        value={value.value}
      />
    </div>
  );
};

const useGrantedSharesFormController = <TFieldValues extends FieldValues>({
  control,
  fullyDilutedShares,
  latestPricePerShare,
  maximumShares,
  name,
}: {
  control: Control<TFieldValues>;
  fullyDilutedShares: null | number;
  latestPricePerShare: null | number;
  maximumShares?: null | number;
  name: FieldPath<TFieldValues>;
  pricePerShare?: null | number;
}) => {
  const sharesInputController = useController({
    control,
    name,
  });

  const grantedSharesController = useGrantedSharesController({
    fullyDilutedShares,
    initialState: {
      mode: "SHARES",
      shares: sharesInputController.field.value,
    },
    latestPricePerShare,
    maximumShares,
  });

  useEffect(() => {
    sharesInputController.field.onChange(grantedSharesController.shares);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantedSharesController.shares]);

  return grantedSharesController;
};

export const GrantedSharesInputForm = <TFieldValues extends FieldValues>({
  control,
  disabled,
  fullyDilutedShares,
  latestPricePerShare,
  maximumShares,
  name,
  placeholder,
}: {
  control: Control<TFieldValues>;
  disabled?: boolean;
  fullyDilutedShares: null | number;
  latestPricePerShare: null | number;
  maximumShares?: null | number;
  name: FieldPath<TFieldValues>;
  placeholder?: string;
}) => {
  const grantedSharesController = useGrantedSharesFormController({
    control,
    fullyDilutedShares,
    latestPricePerShare,
    maximumShares,
    name,
  });

  return (
    <GrantedSharesInput_
      {...grantedSharesController.inputController}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

export const GrantedSharesInput = Object.assign(GrantedSharesInput_, {
  Form: GrantedSharesInputForm,
});
