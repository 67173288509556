import {
  ChevronDownIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import {
  flexRender,
  Row as ReactTableRow,
  RowData,
  SortDirection,
  Table as TableCore,
} from "@tanstack/react-table";
import classNames from "classnames";
import { isNil } from "lodash";
import React, { useCallback, useMemo, useTransition } from "react";
import { Link } from "react-router-dom";
import { useScroll } from "react-use";

import { SearchBar } from "./SearchBar";
import { Typography } from "./Typography";

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    alignRight?: boolean;
    cellClassName?: string;
  }
}

const HeaderCell: React.FC<
  React.ComponentProps<"section"> & {
    alignRight?: boolean;
  }
> = ({ alignRight, children, className, ...props }) => (
  <section
    className={classNames(
      "table-cell select-none whitespace-nowrap py-2 text-left align-middle text-2XS/Medium uppercase text-grey-500 group-[.grid]:block group-[.grid]:py-0",
      "px-4 first:!pl-4 last:!pr-4 group-[.no-x-padding-in-cells]:px-0",
      { ["text-right"]: alignRight },
      className,
    )}
    {...props}
  >
    {children}
  </section>
);

const SortableHeaderCell: React.FC<
  React.ComponentProps<typeof HeaderCell> & { sort: false | SortDirection }
> = ({ alignRight, children, className, sort, ...props }) => {
  const icon = useMemo(() => {
    const iconClassName = /* tailwind */ `text-gray-09 w-4 h-4 transition-all`;
    if (!sort) {
      return <ChevronUpDownIcon className={iconClassName} />;
    }

    return (
      <ChevronDownIcon
        className={classNames(
          {
            "rotate-180": sort === "asc",
          },
          iconClassName,
        )}
      />
    );
  }, [sort]);

  return (
    <HeaderCell className={classNames("cursor-pointer", className)} {...props}>
      <div
        className={classNames("flex items-center gap-1", {
          ["justify-end"]: alignRight,
        })}
      >
        <div>{children}</div>
        {icon}
      </div>
    </HeaderCell>
  );
};

const Cell: React.FC<
  Pick<React.ComponentProps<typeof Typography>, "variant"> &
    React.ComponentProps<"section"> & {
      stretchContent?: boolean;
      truncate?: boolean;
    }
> = ({
  children,
  className,
  stretchContent,
  truncate,
  variant = "Regular/Extra Small",
  ...props
}) => (
  <section
    className={classNames(
      "table-cell align-middle group-[.grid]:flex group-[.grid]:items-center group-[.grid]:py-2 group-[.table]:py-1.5",
      "px-4 first:!pl-4 last:!pr-4 group-[.no-x-padding-in-cells]:px-0",
      { truncate },
      className,
    )}
    {...props}
  >
    <Typography
      className={classNames({
        ["flex-1"]: stretchContent,
        ["min-w-0 truncate"]: truncate,
      })}
      variant={variant}
    >
      {children}
    </Typography>
  </section>
);

const rowStyle = classNames(
  "table-row border-t-[0.5px] border-grey-300 first-of-type:border-t-0 hover:bg-gray-01 group-[.grid]:col-span-full group-[.grid]:grid group-[.grid]:grid-cols-subgrid group-[.borderless]:border-t-0 group-[.grid]:py-2",
);

const LinkRow: React.FC<
  React.ComponentProps<typeof Link> & {
    skeleton?: boolean;
  }
> = ({ className, skeleton, ...props }) => (
  <Link
    className={classNames(
      rowStyle,
      {
        "animate-pulse": skeleton,
      },
      className,
    )}
    {...props}
  />
);

const Row: React.FC<
  React.ComponentProps<"section"> & {
    skeleton?: boolean;
  }
> = ({ className, skeleton, ...props }) => (
  <section
    className={classNames(
      "will-change-transform",
      rowStyle,
      {
        "animate-pulse": skeleton,
        "cursor-pointer": !isNil(props.onClick),
      },
      className,
    )}
    {...props}
  />
);

const Body: React.FC<React.ComponentProps<"main">> = ({
  className,
  ...props
}) => (
  <main
    className={classNames(
      "table-row-group group-[.grid]:col-span-full group-[.grid]:grid group-[.grid]:grid-cols-subgrid [&>tr]:py-3.5",
      className,
    )}
    {...props}
  />
);

const Header: React.FC<React.ComponentProps<"header">> = ({
  className,
  ...props
}) => (
  <header
    className={classNames(
      "table-header-group bg-background-base group-[.grid]:col-span-full group-[.grid]:grid group-[.grid]:grid-cols-subgrid group-[.borderless]:border-b-0",

      className,
    )}
    {...props}
  />
);

const getCellStyle = ({
  isFirstOrLastColumn,
  maxSize,
  minSize,
  size,
}: {
  isFirstOrLastColumn: boolean;
  maxSize?: number;
  minSize?: number;
  size?: number;
}) => ({
  maxWidth: maxSize,
  minWidth: minSize,
  // Add margins to size
  width:
    typeof size === "number" ? size + (isFirstOrLastColumn ? 26 : 24) : "auto",
});

const Smart = <TData extends RowData>({
  className,
  dataCy,
  footer,
  loading,
  rowRenderer = ({ cells }) => <Row>{cells}</Row>,
  searchBarPlaceholder,
  showSearchBar,
  table,
  tableClassName,
  tableDisplay,
  tableLayout = "auto",
}: {
  className?: string;
  dataCy?: string;
  footer?: React.ReactNode;
  loading?: boolean;
  rowRenderer?: (properties: {
    cells: React.ReactNode;
    rowData: ReactTableRow<TData>;
  }) => React.ReactNode;
  /**
   * @deprecated use your own search bar component
   */
  searchBarPlaceholder?: string;
  /**
   * @deprecated use your own search bar component
   */
  showSearchBar?: boolean;
  table: TableCore<TData>;
  tableClassName?: string;
  tableDisplay?: "grid" | "table";
  tableLayout?: "auto" | "fixed";
}) => {
  const state = table.getState();

  return (
    <div className={classNames("space-y-4", className)} data-cy={dataCy}>
      {table.options.enableGlobalFilter && showSearchBar && (
        <SearchBar
          onChange={table.setGlobalFilter}
          placeholder={searchBarPlaceholder}
          value={(state.globalFilter as string) ?? ""}
        />
      )}
      <Containerized
        className={classNames(
          {
            "table-auto": tableLayout === "auto",
            "table-fixed": tableLayout === "fixed",
          },
          tableClassName,
        )}
        display={tableDisplay}
        loading={loading}
      >
        <Table.Header className="border-b-[0.5px] border-gray-04">
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header, i) => {
                const isFirstOrLastColumn =
                  i === 0 || i === headerGroup.headers.length - 1;
                const canSort = header.column.getCanSort();
                const isSorted = header.column.getIsSorted();
                const cellContent = header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    );

                const cellStyle =
                  tableDisplay !== "grid"
                    ? getCellStyle({
                        isFirstOrLastColumn,
                        maxSize: header.column.columnDef.maxSize,
                        minSize: header.column.columnDef.minSize,
                        size: header.column.columnDef.size,
                      })
                    : undefined;

                if (canSort) {
                  return (
                    <Table.SortableHeaderCell
                      alignRight={header.column.columnDef.meta?.alignRight}
                      key={header.id}
                      onClick={() => {
                        header.column.toggleSorting();
                      }}
                      sort={isSorted}
                      style={cellStyle}
                    >
                      {cellContent}
                    </Table.SortableHeaderCell>
                  );
                }

                return (
                  <Table.HeaderCell
                    alignRight={header.column.columnDef.meta?.alignRight}
                    key={header.id}
                    style={cellStyle}
                  >
                    {cellContent}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body>
          {table.getRowModel().rows.map((rowData) => (
            <React.Fragment key={rowData.id}>
              {rowRenderer({
                cells: rowData.getVisibleCells().map((cell) => (
                  <Table.Cell
                    className={classNames(
                      cell.column.columnDef.meta?.cellClassName,
                      {
                        "justify-end text-right":
                          cell.column.columnDef.meta?.alignRight,
                      },
                    )}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                )),
                rowData,
              })}
            </React.Fragment>
          ))}
        </Table.Body>
        {footer}
      </Containerized>
    </div>
  );
};

const SCROLL_SHADOW_WIDTH = 12;
const SCROLL_SHADOW_CLASS_NAME = classNames(
  "absolute bottom-0 top-0 h-full from-black-07/10 blur-sm",
);

const Container: React.FC<React.PropsWithChildren> = ({ children }) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const scrollPosition = useScroll(scrollRef);
  const rightScrolled = scrollPosition.x;
  const leftScrolled = scrollRef.current
    ? scrollRef.current.scrollWidth -
      scrollRef.current.clientWidth -
      scrollPosition.x
    : 0;

  return (
    <div className="w-full">
      <div className={classNames("relative overflow-hidden")}>
        <div
          className={classNames(
            SCROLL_SHADOW_CLASS_NAME,
            "left-0 bg-gradient-to-r",
          )}
          style={{
            width: Math.min(rightScrolled / 1.5, SCROLL_SHADOW_WIDTH),
          }}
        />
        <div className="w-full overflow-y-hidden" ref={scrollRef}>
          {children}
        </div>
        <div
          className={classNames(
            SCROLL_SHADOW_CLASS_NAME,
            "right-0 bg-gradient-to-l",
          )}
          style={{
            width: Math.min(leftScrolled / 1.5, SCROLL_SHADOW_WIDTH),
          }}
        />
      </div>
    </div>
  );
};

const _Table: React.FC<
  React.ComponentProps<"section"> & {
    borderless?: boolean;
    dataCy?: string;
    display?: "grid" | "table";
    loading?: boolean;
    noXPaddingInCells?: boolean;
  }
> = ({
  borderless,
  className,
  dataCy,
  display = "table",
  loading,
  noXPaddingInCells,
  ...props
}) => (
  <section
    className={classNames(
      "group",
      {
        borderless,
        grid: display === "grid",
        "no-x-padding-in-cells": noXPaddingInCells,
        "pointer-events-none animate-pulse": loading,
        "table border-collapse": display === "table",
      },
      className,
    )}
    data-cy={dataCy}
    {...props}
  />
);

const Containerized = ({
  className,
  ...props
}: React.ComponentProps<typeof _Table>) => (
  <Container>
    <Table className={classNames("w-full", className)} {...props} />
  </Container>
);

export interface OrderBy<TOrderByField> {
  direction: "ASC" | "DESC";
  field: TOrderByField;
}

function SortableHeaderCellForPaginatedTable<TOrderByField extends string>({
  alignRight,
  children,
  className,
  field,
  onOrderByChange,
  orderBy,
}: {
  alignRight?: boolean;
  children: React.ReactNode;
  className?: string;
  field: OrderBy<TOrderByField>["field"];
  onOrderByChange: (orderBy: null | OrderBy<TOrderByField>) => void;
  orderBy?: null | OrderBy<TOrderByField>;
}) {
  const [transitionInProgress, startTransition] = useTransition();
  const onHeaderCellClick = useCallback(() => {
    startTransition(() => {
      if (!orderBy || orderBy.field !== field) {
        onOrderByChange({ direction: "ASC", field });
      } else if (orderBy.direction === "ASC") {
        onOrderByChange({ direction: "DESC", field });
      } else {
        onOrderByChange(null);
      }
    });
  }, [orderBy, field, onOrderByChange]);

  const headerCellSort = useMemo(
    () => (orderBy && orderBy.field === field ? orderBy.direction : false),
    [orderBy, field],
  );

  const sort_ = useMemo(() => {
    switch (headerCellSort) {
      case "ASC":
        return "asc";
      case "DESC":
        return "desc";
      default:
        return false;
    }
  }, [headerCellSort]);

  return (
    <Table.SortableHeaderCell
      alignRight={alignRight}
      className={classNames(className, {
        "animate-pulse": transitionInProgress,
      })}
      onClick={onHeaderCellClick}
      sort={sort_}
    >
      {children}
    </Table.SortableHeaderCell>
  );
}

export const Table = Object.assign(_Table, {
  Body,
  Cell,
  Container,
  Containerized,
  Header,
  HeaderCell,
  LinkRow,
  Row,
  rowStyle,
  Smart,
  SortableHeaderCell,
  SortableHeaderCellForPaginatedTable,
});
