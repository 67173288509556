import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Button } from "../../../../../components/ui/Button";
import { ConfirmationModalRemote } from "../../../../../components/ui/ConfirmationModal";
import { SlideOver } from "../../../../../components/ui/SlideOver";
import { makeRemoteController } from "../../../../../helpers/makeRemoteController";
import { useQuery } from "../../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../../hooks/useSafeMutation";
import { EditEquityGridLevelSlideOver_DeleteEquityGridLevel_Mutation } from "./__generated__/EditEquityGridLevelSlideOver_DeleteEquityGridLevel_Mutation.graphql";
import { EditEquityGridLevelSlideOver_Organization$key } from "./__generated__/EditEquityGridLevelSlideOver_Organization.graphql";
import { EditEquityGridLevelSlideOver_Query } from "./__generated__/EditEquityGridLevelSlideOver_Query.graphql";
import { EditEquityGridLevelSlideOver_SetEquityGridLevel_Mutation } from "./__generated__/EditEquityGridLevelSlideOver_SetEquityGridLevel_Mutation.graphql";
import { EquityGridLevelForm } from "./EquityGridLevelForm";
import { FORM_SCHEMA, FormValues } from "./FORM_SCHEMA";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EditEquityGridLevelSlideOver_Organization on Organization {
    ...EquityGridLevelForm_ConfigureGrants_Organization
  }
`;

const EDIT_EQUITY_GRID_LEVEL_MUTATION = graphql`
  mutation EditEquityGridLevelSlideOver_SetEquityGridLevel_Mutation(
    $equityGridLevelId: EquityGridLevelId!
    $attributes: EquityGridLevelAttributes!
  ) {
    setEquityGridLevel(id: $equityGridLevelId, attributes: $attributes) {
      id
    }
  }
`;

const DELETE_EQUITY_GRID_LEVEL_MUTATION = graphql`
  mutation EditEquityGridLevelSlideOver_DeleteEquityGridLevel_Mutation(
    $equityGridLevelId: EquityGridLevelId!
  ) {
    deleteEquityGridLevel(id: $equityGridLevelId)
  }
`;

const QUERY = graphql`
  query EditEquityGridLevelSlideOver_Query($equityGridLevelId: ID!) {
    node(id: $equityGridLevelId) {
      ... on EquityGridLevel {
        __typename
        equityInUsd
        name
        role
        yearlySalary
        equityGridId
      }
    }
  }
`;

function EditEquityGridLevelSlideOver({
  equityGridLevelId,
  onClose,
  onExited,
  onUpdated,
  organizationFragment,
  show,
}: {
  equityGridLevelId: string;
  onClose: () => void;
  onExited?: () => void;
  onUpdated?: () => void;
  organizationFragment: EditEquityGridLevelSlideOver_Organization$key;
  show: boolean;
}) {
  const { query } = useQuery<EditEquityGridLevelSlideOver_Query>(QUERY, {
    equityGridLevelId,
  });

  if (query.node?.__typename !== "EquityGridLevel") {
    throw new Error("Invalid equity grid level id");
  }

  const originalEquityGridLevel = query.node;

  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const form = useForm<FormValues>({
    defaultValues: {
      equityInUsd: originalEquityGridLevel.equityInUsd,
      name: originalEquityGridLevel.name,
      role: originalEquityGridLevel.role,
      yearlySalary: originalEquityGridLevel.yearlySalary ?? undefined,
    },
    resolver: zodResolver(FORM_SCHEMA),
  });
  const [
    triggerEditEquityGridLevelMutation,
    editEquityGridLevelMutationIsInFlight,
  ] = useSafeMutation<EditEquityGridLevelSlideOver_SetEquityGridLevel_Mutation>(
    EDIT_EQUITY_GRID_LEVEL_MUTATION,
  );
  const [
    triggerDeleteEquityGridLevelMutation,
    deleteEquityGridLevelMutationIsInFlight,
  ] =
    useSafeMutation<EditEquityGridLevelSlideOver_DeleteEquityGridLevel_Mutation>(
      DELETE_EQUITY_GRID_LEVEL_MUTATION,
    );
  const handleSubmit = form.handleSubmit(async (values) => {
    await triggerEditEquityGridLevelMutation({
      variables: {
        attributes: {
          equityGridId: originalEquityGridLevel.equityGridId,
          equityInUsd: values.equityInUsd,
          name: values.name,
          role: values.role,
          yearlySalary: values.yearlySalary,
        },
        equityGridLevelId,
      },
    });

    onClose();
    onUpdated?.();
  });

  const name = form.watch("name");

  return (
    <SlideOver
      footer={
        <div className="flex items-center justify-between gap-4 p-6">
          <ConfirmationModalRemote.Provider>
            <ConfirmationModalRemote.Controller
              render={({ open }) => (
                <Button
                  disabled={editEquityGridLevelMutationIsInFlight}
                  onClick={() => {
                    open({
                      data: {
                        confirmationLabel: "Delete",
                        loading: deleteEquityGridLevelMutationIsInFlight,
                        title: `Delete ${name} level?`,
                      },
                      onClose: async ({ confirmed }) => {
                        if (confirmed) {
                          await triggerDeleteEquityGridLevelMutation({
                            variables: {
                              equityGridLevelId,
                            },
                          });

                          onUpdated?.();
                          onClose();
                        }
                      },
                    });
                  }}
                  size="small"
                  type="button"
                  variant="Danger Full"
                >
                  Delete
                </Button>
              )}
            />
          </ConfirmationModalRemote.Provider>
          <div className="flex items-center justify-end gap-4">
            <Button
              disabled={editEquityGridLevelMutationIsInFlight}
              onClick={onClose}
              size="small"
              type="button"
              variant="Secondary Full"
            >
              Cancel
            </Button>
            <Button
              form="edit-equity-grid-level"
              loading={editEquityGridLevelMutationIsInFlight}
              size="small"
              type="submit"
            >
              Save level
            </Button>
          </div>
        </div>
      }
      header={
        <SlideOver.Header onClose={onClose}>Edit {name} level</SlideOver.Header>
      }
      onClose={onClose}
      onExited={onExited}
      show={show}
    >
      <div className="px-6 py-10">
        <EquityGridLevelForm
          control={form.control}
          id="edit-equity-grid-level"
          onSubmit={handleSubmit}
          organizationFragment={organization}
        />
      </div>
    </SlideOver>
  );
}

export const EditEquityGridLevelSlideOverController = makeRemoteController<
  {
    equityGridLevelId: string;
    organizationFragment: EditEquityGridLevelSlideOver_Organization$key;
  },
  {
    updated: boolean;
  }
>({
  render: ({ close, reset, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <EditEquityGridLevelSlideOver
        equityGridLevelId={state.data.equityGridLevelId}
        onClose={() => {
          close({ updated: false });
        }}
        onExited={reset}
        onUpdated={() => {
          close({ updated: true });
        }}
        organizationFragment={state.data.organizationFragment}
        show={state.show}
      />
    );
  },
});
