/**
 * @generated SignedSource<<137c22524a4e1b385a17874215413818>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CannotBeInvitedReason = "ALREADY_INVITED" | "MISSING_INFORMATION" | "NO_GRANTS" | "REMOTE_EMPLOYEE" | "SETTLED";
export type GranteeStatus = "Active" | "Terminated";
import { FragmentRefs } from "relay-runtime";
export type GranteeDetails_Grantee$data = {
  readonly activeCTMSGrants: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
    readonly vestingDataPoints: ReadonlyArray<{
      readonly cumulativeVested: number;
      readonly date: string;
    }>;
  }>;
  readonly cannotBeInvitedReason: CannotBeInvitedReason | null;
  readonly contractStartDate: string | null;
  readonly ctmsGrants: ReadonlyArray<{
    readonly exercisePrice: number | null;
    readonly id: string;
    readonly label: string;
    readonly " $fragmentSpreads": FragmentRefs<"GranteeGrantDetails_CTMSGrant">;
  }>;
  readonly drafts: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
    readonly vestingDataPoints: ReadonlyArray<{
      readonly cumulativeVested: number;
      readonly date: string;
    }>;
  }>;
  readonly equityGridLevel: {
    readonly name: string;
  } | null;
  readonly hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal: boolean;
  readonly hasPortalAccess: boolean;
  readonly hasSettlingGrant: boolean;
  readonly id: string;
  readonly isDeletable: boolean;
  readonly isTerminable: boolean;
  readonly jobTitle: string | null;
  readonly name: string;
  readonly offGrid: boolean;
  readonly status: GranteeStatus;
  readonly terminationDate: string | null;
  readonly terminationInformations: {
    readonly terminationDate: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantButton_DefaultGrantee" | "GranteeDetailsBox_Grantee" | "GranteeExerciseBox_Grantee" | "GranteeFormSlide_Grantee" | "GranteeNameWithCountryFlag_Grantee" | "GranteeTotalOwnershipBox_Grantee" | "NewEquitySelectionModal_DefaultGrantee" | "RevokeGranteeAccessModal_Grantee" | "useGranteeGrantIndex_Grantee">;
  readonly " $fragmentType": "GranteeDetails_Grantee";
};
export type GranteeDetails_Grantee$key = {
  readonly " $data"?: GranteeDetails_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeDetails_Grantee">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminationDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "VestingDataPoint",
    "kind": "LinkedField",
    "name": "vestingDataPoints",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cumulativeVested",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeDetails_Grantee",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteeTerminationInformations",
      "kind": "LinkedField",
      "name": "terminationInformations",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGridLevel",
      "kind": "LinkedField",
      "name": "equityGridLevel",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offGrid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractStartDate",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasSettlingGrant",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "exercisePrice",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GranteeGrantDetails_CTMSGrant"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "activeCTMSGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "grantStatusIn",
          "value": [
            "Active"
          ]
        }
      ],
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": "ctmsGrants(grantStatusIn:[\"Active\"])"
    },
    {
      "alias": "drafts",
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrantsWithoutCtmsGrant",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPortalAccess",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cannotBeInvitedReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTerminable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDeletable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeNameWithCountryFlag_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeTotalOwnershipBox_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeDetailsBox_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeExerciseBox_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RevokeGranteeAccessModal_Grantee"
    },
    {
      "args": (v5/*: any*/),
      "kind": "FragmentSpread",
      "name": "DraftGrantButton_DefaultGrantee"
    },
    {
      "args": (v5/*: any*/),
      "kind": "FragmentSpread",
      "name": "NewEquitySelectionModal_DefaultGrantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGranteeGrantIndex_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "e0ffda3b70fa912c69e4df79e7627256";

export default node;
