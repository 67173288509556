import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { graphql } from "relay-runtime";

import { APPLICATION_ROUTES } from "../paths";
import { ConnectHRISProvidersMessage_Organization$key } from "./__generated__/ConnectHRISProvidersMessage_Organization.graphql";
import { ActionableMessage } from "./ui/ActionableMessage";
import { Button, LinkButton } from "./ui/Button";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ConnectHRISProvidersMessage_Organization on Organization {
    isConnectedToDeel
    isConnectedToRemoteEquity
    id
  }
`;

export const ConnectHRISProvidersMessage: React.FC<{
  organizationFragment: ConnectHRISProvidersMessage_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const [isHidden, setIsHidden] = useLocalStorage(
    "connect_hris_providers_message_hidden_key",
    false,
  );

  if (isHidden) return null;

  if (
    organization.isConnectedToRemoteEquity ||
    organization.isConnectedToDeel
  ) {
    return null;
  }

  return (
    <ActionableMessage
      description="Easily connect with Deel and Remote"
      isNew
      title="Integrate with your favorite EoR solutions"
    >
      <LinkButton
        size="extra small"
        to={generatePath(APPLICATION_ROUTES.organizationSettingsIntegrations, {
          organizationId: organization.id,
        })}
        variant="Primary Full"
      >
        Discover our integrations
      </LinkButton>
      <Button
        onClick={() => {
          setIsHidden(true);
        }}
        size="extra small"
        variant="Primary Outline"
      >
        Close
      </Button>
    </ActionableMessage>
  );
};
