/**
 * @generated SignedSource<<7a03983c56af1e297927368e3e83e4c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Grantees_Viewer$data = {
  readonly isAllowedToManageOrganization: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationGranteesTable_Viewer">;
  readonly " $fragmentType": "Grantees_Viewer";
};
export type Grantees_Viewer$key = {
  readonly " $data"?: Grantees_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Grantees_Viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Grantees_Viewer",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "isAllowedToManageOrganization",
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "OrganizationGranteesTable_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};
})();

(node as any).hash = "bc2c0521d88aa184468fe2d95f727d0b";

export default node;
