import { useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { useCtmsGrantAndSharesToNetEquityValue_Organization$key } from "./__generated__/useCtmsGrantAndSharesToNetEquityValue_Organization.graphql";
import { useOrganizationSharesUtil } from "./useOrganizationSharesUtil";

const ORGANIZATION_FRAGMENT = graphql`
  fragment useCtmsGrantAndSharesToNetEquityValue_Organization on Organization {
    ...useOrganizationSharesUtil_Organization
  }
`;

export const useCtmsGrantAndSharesToNetEquityValue = ({
  organizationFragment,
  valuationMultiple = 1,
}: {
  organizationFragment: useCtmsGrantAndSharesToNetEquityValue_Organization$key;
  valuationMultiple?: number;
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const { sharesToValue } = useOrganizationSharesUtil({
    organizationFragment: organization,
  });

  const ctmsGrantAndSharesToNetEquityValue = useCallback(
    ({
      ctmsGrant,
      shares,
    }: {
      ctmsGrant: { exercisePrice: null | number };
      shares: number;
    }) => {
      const totalExercisePrice =
        ctmsGrant.exercisePrice !== null ? shares * ctmsGrant.exercisePrice : 0;
      const totalValueInUSD = sharesToValue(shares);
      const multipliedNetEquityValueInUSD =
        totalValueInUSD !== null
          ? totalValueInUSD * valuationMultiple - totalExercisePrice
          : null;

      return multipliedNetEquityValueInUSD;
    },
    [sharesToValue, valuationMultiple],
  );

  return {
    ctmsGrantAndSharesToNetEquityValue,
  };
};
