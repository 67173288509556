import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { graphql } from "relay-runtime";

import { Button } from "../../../../../components/ui/Button";
import { SlideOver } from "../../../../../components/ui/SlideOver";
import { makeRemoteController } from "../../../../../helpers/makeRemoteController";
import { useQuery } from "../../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../../hooks/useSafeMutation";
import { EditSlideOver_Mutation } from "./__generated__/EditSlideOver_Mutation.graphql";
import { EditSlideOver_Query } from "./__generated__/EditSlideOver_Query.graphql";
import { FormValues, useFormSchema } from "./FORM_SCHEMA";
import { PostTerminationForm } from "./PostTerminationForm";

const QUERY = graphql`
  query EditSlideOver_Query(
    $organizationId: OrganizationId!
    $postTerminationExercisePeriodId: ID!
  ) {
    node(id: $postTerminationExercisePeriodId) {
      ... on OrganizationFixedPostTerminationExercisePeriod {
        __typename
        duration
        durationUnit
      }
      ... on OrganizationVariablePostTerminationExercisePeriod {
        __typename
        duration
        durationUnit
        extensionDuration
        extensionDurationUnit
        thresholdForExtensionInYears
      }
    }
    organization(id: $organizationId) @required(action: THROW) {
      hasCooleyAsOutsideCounsel
      termOfOptionsInMonths
      ...PostTerminationForm_Organization
    }
  }
`;

const UPDATE_ORGANIZATION_POST_TERMINATION_EXERCISE_PERIOD_MUTATION = graphql`
  mutation EditSlideOver_Mutation(
    $input: UpdateOrganizationPostTerminationExercisePeriodInput!
  ) {
    updateOrganizationPostTerminationExercisePeriod(input: $input) {
      __typename
    }
  }
`;

function EditSlideOver({
  onClose,
  organizationId,
  postTerminationExercisePeriodId,
  show,
}: {
  onClose: () => void;
  organizationId: string;
  postTerminationExercisePeriodId: string;
  show: boolean;
}) {
  const { query } = useQuery<EditSlideOver_Query>(QUERY, {
    organizationId,
    postTerminationExercisePeriodId,
  });

  if (!query.node?.__typename || query.node.__typename === "%other") {
    throw new Error("Invalid typename");
  }

  const postTerminationExercisePeriod = query.node;

  const [updateOrganizationPostTerminationExercisePeriod] =
    useSafeMutation<EditSlideOver_Mutation>(
      UPDATE_ORGANIZATION_POST_TERMINATION_EXERCISE_PERIOD_MUTATION,
    );

  const values = useMemo((): FormValues => {
    if (
      postTerminationExercisePeriod.__typename ===
      "OrganizationFixedPostTerminationExercisePeriod"
    ) {
      return {
        duration: postTerminationExercisePeriod.duration,
        durationUnit: postTerminationExercisePeriod.durationUnit,
        type: "FIXED",
      };
    }

    return {
      duration: postTerminationExercisePeriod.duration,
      durationUnit: postTerminationExercisePeriod.durationUnit,
      extensionDuration: postTerminationExercisePeriod.extensionDuration,
      extensionDurationUnit:
        postTerminationExercisePeriod.extensionDurationUnit,
      thresholdForExtensionInYears:
        postTerminationExercisePeriod.thresholdForExtensionInYears,
      type: "VARIABLE",
    };
  }, [postTerminationExercisePeriod]);

  const form = useForm<FormValues>({
    resolver: zodResolver(
      useFormSchema({
        organizationHasCooleyAsOutsideCounsel:
          query.organization.hasCooleyAsOutsideCounsel,
        organizationTermOfOptionsInMonths:
          query.organization.termOfOptionsInMonths,
      }),
    ),
    values,
  });

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmit = form.handleSubmit(async (values) => {
    await updateOrganizationPostTerminationExercisePeriod({
      variables: {
        input: {
          id: postTerminationExercisePeriodId,
          ...values,
        },
      },
    });

    handleClose();
  });

  return (
    <SlideOver
      footer={
        <div className="flex items-center justify-end gap-2 p-6">
          <Button
            disabled={form.formState.isSubmitting}
            onClick={handleClose}
            size="small"
            type="button"
            variant="Secondary Full"
          >
            Cancel
          </Button>
          <Button
            form="edit-post-termination-exercise-period-form"
            loading={form.formState.isSubmitting}
            size="small"
            type="submit"
          >
            Update
          </Button>
        </div>
      }
      header={
        <SlideOver.Header
          onClose={onClose}
          padding={10}
          subtitle="Define the post-termination exercise period parameters below"
        >
          Edit Post-Termination Exercise Period
        </SlideOver.Header>
      }
      onClose={onClose}
      show={show}
      width="4xl"
    >
      <div className="px-10 py-16">
        <PostTerminationForm
          control={form.control}
          id="edit-post-termination-exercise-period-form"
          onSubmit={handleSubmit}
          organizationFragment={query.organization}
        />
      </div>
    </SlideOver>
  );
}

export const EditSlideOverRemote = makeRemoteController<{
  organizationId: string;
  postTerminationExercisePeriodId: string;
}>({
  render: ({ close, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <EditSlideOver
        onClose={close}
        organizationId={state.data.organizationId}
        postTerminationExercisePeriodId={
          state.data.postTerminationExercisePeriodId
        }
        show={state.show}
      />
    );
  },
});
