import React from "react";

export function ShiftlessContainer({
  children,
  largestChildren,
}: {
  children: React.ReactNode;
  largestChildren: React.ReactNode;
}) {
  return (
    <div className="relative">
      {/* We shadow display the greatest value so the container won't shift */}
      <div aria-hidden className="invisible">
        {largestChildren}
      </div>
      <div className="absolute inset-0 flex items-center justify-center">
        {children}
      </div>
    </div>
  );
}
