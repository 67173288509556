import classNames from "classnames";
import { motion, useMotionValue, useSpring, useTransform } from "motion/react";
import React, { useEffect } from "react";

function useProgressContext() {
  const context = React.useContext(ProgressContext);

  if (!context) {
    throw new Error("Progress.Value components must be used within a Progress");
  }

  return context;
}

const Value: React.FC<{
  className?: string;
  value: number;
}> = ({ className, value }) => {
  const { max } = useProgressContext();
  const widthRatioMotionValue = useMotionValue(max === 0 ? 0 : value / max);
  const springValue = useSpring(widthRatioMotionValue, {
    bounce: 0,
    duration: 300,
  });
  const widthStyle = useTransform(springValue, (latest) => `${latest * 100}%`);

  useEffect(() => {
    widthRatioMotionValue.set(max === 0 ? 0 : value / max);
  }, [widthRatioMotionValue, value, max]);

  return (
    <motion.div
      className={classNames(className, "h-full rounded-3xl", {
        hidden: value === 0,
      })}
      style={{
        flexBasis: widthStyle,
      }}
    />
  );
};

const ProgressContext = React.createContext<null | {
  max: number;
}>(null);

const _Progress: React.FC<
  React.PropsWithChildren<{
    className?: string;
    max: number;
    skeleton?: boolean;
  }>
> = ({ className, max, skeleton, ...props }) => (
  <ProgressContext.Provider value={{ max }}>
    <div
      className={classNames(
        "flex h-2 gap-[3px] overflow-hidden rounded-3xl",
        {
          "animate-pulse": skeleton,
        },
        className,
      )}
      {...props}
    />
  </ProgressContext.Provider>
);

export const Progress = Object.assign(_Progress, { Value });
