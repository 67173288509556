/**
 * @generated SignedSource<<bb22cc24607b5eafaa1d75dc2799abe8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalVestingGraph_Grantee$data = {
  readonly ctmsGrants: ReadonlyArray<{
    readonly exercisePrice: number | null;
    readonly label: string;
    readonly vestingDataPoints: ReadonlyArray<{
      readonly cumulativeVested: number;
      readonly date: string;
    }>;
  }>;
  readonly organization: {
    readonly granteePortalSettings: {
      readonly displayFullyDilutedValues: boolean;
    };
    readonly " $fragmentSpreads": FragmentRefs<"useCtmsGrantAndSharesToNetEquityValue_Organization">;
  };
  readonly " $fragmentType": "EmployeePortalVestingGraph_Grantee";
};
export type EmployeePortalVestingGraph_Grantee$key = {
  readonly " $data"?: EmployeePortalVestingGraph_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalVestingGraph_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalVestingGraph_Grantee",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "grantStatusIn",
          "value": [
            "Active",
            "Terminated"
          ]
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "ASC",
            "field": "vestingStartDate"
          }
        }
      ],
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "exercisePrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "VestingDataPoint",
          "kind": "LinkedField",
          "name": "vestingDataPoints",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cumulativeVested",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "ctmsGrants(grantStatusIn:[\"Active\",\"Terminated\"],orderBy:{\"direction\":\"ASC\",\"field\":\"vestingStartDate\"})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteePortalSettings",
          "kind": "LinkedField",
          "name": "granteePortalSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayFullyDilutedValues",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useCtmsGrantAndSharesToNetEquityValue_Organization"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "4f53f9a9dc4c8d8a3e0833855d81ed89";

export default node;
