import { isEmpty } from "lodash";
import React, { startTransition, useCallback, useMemo, useState } from "react";
import { graphql, useRefetchableFragment } from "react-relay";

import { ConfirmSubmitBoardConsentSlideRemote } from "../../../components/ConfirmSubmitBoardConsentSlide/ConfirmSubmitBoardConsentSlide";
import { EmptyListPlaceholder } from "../../../components/EmptyListPlaceholder/EmptyListPlaceholder";
import { Page } from "../../../components/Page";
import { SelectedItemsActionCard } from "../../../components/SelectedItemsActionCard";
import { useToaster } from "../../../components/Toaster";
import { Button } from "../../../components/ui/Button";
import { Toast } from "../../../components/ui/Toast";
import { useDownloadFairMarketValueBoardConsentPreview } from "../../../hooks/useDownloadBoardConsentPreview";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { LegalWorkflowLayout } from "../../../layouts/LegalWorkflowLayout";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { BoardApproval_FairMarketValues_ImportSignedBoardConsentApprovingFairMarketValue_Mutation } from "./__generated__/BoardApproval_FairMarketValues_ImportSignedBoardConsentApprovingFairMarketValue_Mutation.graphql";
import {
  BoardApproval_FairMarketValues_Organization$data,
  BoardApproval_FairMarketValues_Organization$key,
} from "./__generated__/BoardApproval_FairMarketValues_Organization.graphql";
import { BoardApproval_FairMarketValues_Query } from "./__generated__/BoardApproval_FairMarketValues_Query.graphql";
import { BoardApproval_FairMarketValues_SendFairMarketValueToBoardForConsent_Mutation } from "./__generated__/BoardApproval_FairMarketValues_SendFairMarketValueToBoardForConsent_Mutation.graphql";
import { FairMarketValueApprovalTable } from "./FairMarketValueApprovalTable";

const ORGANIZATION_FRAGMENT = graphql`
  fragment BoardApproval_FairMarketValues_Organization on Organization
  @refetchable(
    queryName: "BoardApproval_FairMarketValues_Organization_RefetchQuery"
  ) {
    id
    name
    fairMarketValueHistory {
      id
      status
      ...FairMarketValueApprovalTable_FairMarketValue
      ...ConfirmSubmitBoardConsentSlide_FairMarketValue
    }
    ...LegalWorkflowLayout_Organization
    ...ConfirmSubmitBoardConsentSlide_Organization
  }
`;

const SEND_FAIR_MARKET_VALUE_TO_BOARD_FOR_CONSENT_MUTATION = graphql`
  mutation BoardApproval_FairMarketValues_SendFairMarketValueToBoardForConsent_Mutation(
    $fairMarketValueId: ID!
  ) {
    sendFairMarketValueToBoardForConsent(
      fairMarketValueId: $fairMarketValueId
    ) {
      valuationWarnings {
        reason
        valuationType
      }
    }
  }
`;

const IMPORT_SIGNED_BOARD_CONSENT_APPROVING_FAIR_MARKET_VALUE_MUTATION = graphql`
  mutation BoardApproval_FairMarketValues_ImportSignedBoardConsentApprovingFairMarketValue_Mutation(
    $fairMarketValueId: ID!
    $signedBoardConsentDocumentIds: [String!]!
    $boardConsentSignatureDate: Date!
  ) {
    importSignedBoardConsentApprovingFairMarketValue(
      fairMarketValueId: $fairMarketValueId
      signedBoardConsentDocumentIds: $signedBoardConsentDocumentIds
      boardConsentSignatureDate: $boardConsentSignatureDate
    ) {
      valuationWarnings {
        reason
        valuationType
      }
    }
  }
`;

type FairMarketValue =
  BoardApproval_FairMarketValues_Organization$data["fairMarketValueHistory"][number];

const AdminFairMarketValueApprovalsBoardApprovalPage_: React.FC<{
  organizationFragment: BoardApproval_FairMarketValues_Organization$key;
}> = ({ organizationFragment }) => {
  const [organization, refetchOrganization_] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );

  const fairMarketValuesReviewed = useMemo(
    () =>
      organization.fairMarketValueHistory.filter(
        (fairMarketValue) => fairMarketValue.status === "Reviewed",
      ),
    [organization.fairMarketValueHistory],
  );

  const [selectedFairMarketValue, setSelectedFairMarketValue] =
    useState<FairMarketValue | null>(fairMarketValuesReviewed[0] ?? null);

  const confirmSubmitBoardConsentSlideController =
    ConfirmSubmitBoardConsentSlideRemote.useController();

  const { downloadFairMarketValueBoardConsentPreview, downloadIsInFlight } =
    useDownloadFairMarketValueBoardConsentPreview({
      fairMarketValueId: selectedFairMarketValue?.id ?? null,
    });

  const refetchOrganization = useCallback(() => {
    startTransition(() => {
      refetchOrganization_({});
    });
  }, [refetchOrganization_]);

  const [sendFairMarketValueToBoardForConsent, _mutationIsInFlight] =
    useSafeMutation<BoardApproval_FairMarketValues_SendFairMarketValueToBoardForConsent_Mutation>(
      SEND_FAIR_MARKET_VALUE_TO_BOARD_FOR_CONSENT_MUTATION,
    );

  const [
    importSignedBoardConsentApprovingFairMarketValue,
    __mutationIsInFlight,
  ] =
    useSafeMutation<BoardApproval_FairMarketValues_ImportSignedBoardConsentApprovingFairMarketValue_Mutation>(
      IMPORT_SIGNED_BOARD_CONSENT_APPROVING_FAIR_MARKET_VALUE_MUTATION,
    );

  const submissionInProgress = _mutationIsInFlight || __mutationIsInFlight;

  const toaster = useToaster();

  const handleConfirmSubmitBoardConsentSlideSubmit = useCallback(
    async ({
      boardConsentHandledOutsideEasop,
      boardConsentSignatureDate,
      signedBoardConsentDocumentIds,
    }: {
      boardConsentHandledOutsideEasop: boolean;
      boardConsentSignatureDate?: null | string;
      signedBoardConsentDocumentIds?: null | string[];
    }) => {
      if (!selectedFairMarketValue) return;

      if (boardConsentHandledOutsideEasop) {
        if (!boardConsentSignatureDate)
          throw new Error("Board consent signature date is required");
        if (!signedBoardConsentDocumentIds)
          throw new Error("Signed board consent document is required");

        await importSignedBoardConsentApprovingFairMarketValue({
          variables: {
            boardConsentSignatureDate: boardConsentSignatureDate,
            fairMarketValueId: selectedFairMarketValue.id,
            signedBoardConsentDocumentIds,
          },
        });

        toaster.push(
          <Toast title="Wonderful!">
            Your latest 409A is approved, you can now draft equity again!
          </Toast>,
        );
      } else {
        await sendFairMarketValueToBoardForConsent({
          variables: {
            fairMarketValueId: selectedFairMarketValue.id,
          },
        });
        toaster.push(
          <Toast title="Wonderful!">
            Your 409A was successfully sent to board for consent!
          </Toast>,
        );
      }

      setSelectedFairMarketValue(null);
      refetchOrganization();
    },
    [
      importSignedBoardConsentApprovingFairMarketValue,
      refetchOrganization,
      selectedFairMarketValue,
      sendFairMarketValueToBoardForConsent,
      toaster,
    ],
  );

  return (
    <LegalWorkflowLayout
      organizationFragment={organization}
      subtitle="Send document to your board members for approval"
      title="Board Approval"
      topBarActionsRender={({ mainContentIsScrolled }) => {
        return (
          <SelectedItemsActionCard
            actions={
              <>
                <Button
                  className="whitespace-nowrap"
                  disabled={!selectedFairMarketValue}
                  loading={downloadIsInFlight}
                  onClick={downloadFairMarketValueBoardConsentPreview}
                  size="small"
                  variant="Secondary Full"
                >
                  Preview board consent
                </Button>

                <Button
                  disabled={!selectedFairMarketValue}
                  onClick={() => {
                    if (selectedFairMarketValue) {
                      confirmSubmitBoardConsentSlideController.open({
                        data: {
                          fairMarketValueFragment: selectedFairMarketValue,
                          onSubmit: handleConfirmSubmitBoardConsentSlideSubmit,
                          organizationFragment: organization,
                          selectedItemsCount: 1,
                          submissionInProgress,
                          type: "FAIR_MARKET_VALUE",
                        },
                      });
                    }
                  }}
                  size="small"
                  variant="Approve Light"
                >
                  Proceed
                </Button>
              </>
            }
            compact={mainContentIsScrolled}
            emptyListLabel="Select fair market value"
            itemCount={selectedFairMarketValue ? 1 : 0}
            pluralLabel="fair market values selected"
            singularLabel="fair market value selected"
          />
        );
      }}
    >
      {isEmpty(fairMarketValuesReviewed) ? (
        <EmptyListPlaceholder
          hideImage
          title="No fair market value ready for consent"
        />
      ) : (
        <FairMarketValueApprovalTable
          containerized
          fairMarketValuesFragment={fairMarketValuesReviewed}
          selectedFairMarketValueId={selectedFairMarketValue?.id ?? null}
          setSelectedFairMarketValueId={(fairMarketValueId) => {
            const selectedFairMarketValue = fairMarketValuesReviewed.find(
              (fairMarketValue) => fairMarketValue.id === fairMarketValueId,
            );

            if (selectedFairMarketValue) {
              setSelectedFairMarketValue(selectedFairMarketValue);
            }
          }}
        />
      )}
    </LegalWorkflowLayout>
  );
};

const QUERY = graphql`
  query BoardApproval_FairMarketValues_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...BoardApproval_FairMarketValues_Organization
    }
  }
`;

const AdminFairMarketValueApprovalsBoardApprovalPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const {
    query: { organization },
  } = useQuery<BoardApproval_FairMarketValues_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Fair Market Value Approvals"
      analyticsName="Admin - Fair Market Value Board Approval"
      organizationId={organization.id}
      title={`Admin | ${organization.name} fair market value board approval`}
    >
      <ConfirmSubmitBoardConsentSlideRemote.Provider>
        <AdminFairMarketValueApprovalsBoardApprovalPage_
          organizationFragment={organization}
        />
      </ConfirmSubmitBoardConsentSlideRemote.Provider>
    </Page>
  );
};

export default AdminFairMarketValueApprovalsBoardApprovalPage;
