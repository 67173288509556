import {
  BanknotesIcon,
  CalendarIcon,
  EllipsisHorizontalIcon,
  EnvelopeIcon,
  LockClosedIcon,
  PencilIcon,
  PlayCircleIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { isFuture } from "date-fns";
import { isNil } from "lodash";
import React, { startTransition, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { DetailsBox } from "../../../../components/DetailsBox";
import DetailsHeader from "../../../../components/DetailsHeader";
import { DraftGrantButton } from "../../../../components/DraftGrantButton";
import {
  GranteeDeletionConfirmationModal,
  useGranteeDeletionConfirmationModalState,
} from "../../../../components/GranteeDeletionConfirmationModal";
import {
  GranteeFormSlide,
  useGranteeFormSlideState,
} from "../../../../components/GranteeFormSlide";
import { GranteeGrantDetails } from "../../../../components/GranteeGrantDetails";
import { GranteeNameWithCountryFlag } from "../../../../components/GranteeNameWithCountryFlag";
import {
  InviteGranteesModal,
  useInviteGranteesModalState,
} from "../../../../components/InviteGranteesModal";
import { MissingCTMSInformationTag } from "../../../../components/MissingInformationTag";
import {
  NewEquitySelectionModal,
  useNewEquitySelectionModalState,
} from "../../../../components/NewEquitySelectionModal/NewEquitySelectionModal";
import { Page } from "../../../../components/Page";
import {
  RevokeGranteeAccessModal,
  useRevokeGranteeAccessModalState,
} from "../../../../components/RevokeGranteeAccessModal";
import { ShortDate } from "../../../../components/ShortDate";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { Button, LinkButton } from "../../../../components/ui/Button";
import { CenteredColumnLayout } from "../../../../components/ui/Layout/CenteredColumnLayout";
import { MenuButton } from "../../../../components/ui/MenuButton";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { Tag } from "../../../../components/ui/Tag";
import { TwoColumnsGrid } from "../../../../components/ui/TwoColumnsGrid";
import { VestingGraph } from "../../../../components/VestingGraph";
import { useBoolean } from "../../../../hooks/useBoolean";
import { useGranteeGrantIndex } from "../../../../hooks/useGranteeGrantIndex";
import { useOrganizationCTMS } from "../../../../hooks/useOrganizationCTMS";
import { useQuery } from "../../../../hooks/useQuery";
import { useResendGranteeInvitationEmail } from "../../../../hooks/useResendGranteeInvitationEmail";
import {
  APPLICATION_ROUTES,
  useGranteeIdParam,
  useOrganizationIdParam,
} from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { GranteeDetails_Grantee$key } from "./__generated__/GranteeDetails_Grantee.graphql";
import { GranteeDetails_Organization$key } from "./__generated__/GranteeDetails_Organization.graphql";
import { GranteeDetails_Query } from "./__generated__/GranteeDetails_Query.graphql";
import { GranteeDetails_Viewer$key } from "./__generated__/GranteeDetails_Viewer.graphql";
import { GranteeDetailsBox } from "./GranteeDetailsBox";
import GranteeExerciseBox from "./GranteeExerciseBox";
import { GranteeTotalOwnershipBox } from "./GranteeTotalOwnershipBox";
import { SimulateExerciseModal } from "./SimulateExerciseModal";

const VIEWER_FRAGMENT = graphql`
  fragment GranteeDetails_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
    ...GranteeTotalOwnershipBox_Viewer
      @arguments(organizationId: $organizationId)
    ...GranteeDetailsBox_Viewer @arguments(organizationId: $organizationId)
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeDetails_Organization on Organization {
    id
    name
    planIsFreemium
    granteePortalInvitationIsAllowed
    equityGrid {
      activated
    }
    ...GranteeTotalOwnershipBox_Organization
    ...GranteeGrantDetails_Organization
    ...GranteeFormSlide_Organization
    ...DraftGrantButton_Organization
    ...GranteeDetailsBox_Organization
    ...MissingInformationTag_MissingCTMSInformationTag_Organization
    ...GranteeExerciseBox_Organization
    ...useOrganizationCTMS_Organization
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeDetails_Grantee on Grantee
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    id
    name
    status
    terminationInformations {
      terminationDate
    }
    equityGridLevel {
      name
    }
    offGrid
    contractStartDate
    terminationDate
    hasSettlingGrant
    ctmsGrants {
      id
      label
      exercisePrice
      ...GranteeGrantDetails_CTMSGrant
    }
    activeCTMSGrants: ctmsGrants(grantStatusIn: [Active]) {
      id
      label
      vestingDataPoints {
        cumulativeVested
        date
      }
    }
    drafts: easopGrantsWithoutCtmsGrant {
      id
      label
      vestingDataPoints {
        cumulativeVested
        date
      }
    }
    hasPortalAccess
    cannotBeInvitedReason
    hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal
    isTerminable
    isDeletable
    jobTitle
    terminationInformations {
      terminationDate
    }
    ...GranteeNameWithCountryFlag_Grantee
    ...GranteeTotalOwnershipBox_Grantee
    ...GranteeFormSlide_Grantee
    ...GranteeDetailsBox_Grantee
    ...GranteeExerciseBox_Grantee
    ...RevokeGranteeAccessModal_Grantee
    ...DraftGrantButton_DefaultGrantee
      @arguments(organizationId: $organizationId)
    ...NewEquitySelectionModal_DefaultGrantee
      @arguments(organizationId: $organizationId)
    ...useGranteeGrantIndex_Grantee
  }
`;

const GranteeDetails_: React.FC<{
  granteeFragment: GranteeDetails_Grantee$key;
  onGranteeAccessRevoked: () => void;
  onGranteesInvited: () => void;
  onGranteeUpdated: () => void;
  organizationFragment: GranteeDetails_Organization$key;
  viewerFragment: GranteeDetails_Viewer$key;
}> = ({
  granteeFragment,
  onGranteeAccessRevoked,
  onGranteesInvited,
  onGranteeUpdated,
  organizationFragment,
  viewerFragment,
}) => {
  const navigate = useNavigate();
  const organization = useFragment<GranteeDetails_Organization$key>(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const getGrantIndex = useGranteeGrantIndex({
    granteeFragment: grantee,
  });

  const ctmsGrantsWithVestingDataPoints = useMemo(
    () =>
      grantee.activeCTMSGrants.filter((ctmsGrant) =>
        ctmsGrant.vestingDataPoints.some(
          (vestingDataPoint) => vestingDataPoint.cumulativeVested > 0,
        ),
      ),
    [grantee.activeCTMSGrants],
  );

  const goToGranteesPage = useCallback(() => {
    void navigate(
      generatePath(APPLICATION_ROUTES.organizationGrantees, {
        organizationId: organization.id,
      }),
    );
  }, [navigate, organization.id]);

  const showEquityGridFeature = organization.equityGrid.activated;
  const equityGridLevelName = grantee.offGrid
    ? "Off Grid"
    : grantee.equityGridLevel?.name || null;

  const {
    hideRevokeGranteeAccessModal,
    revokeGranteeAccessModalState,
    showRevokeGranteeAccessModal,
  } = useRevokeGranteeAccessModalState();

  const {
    hideInviteGranteeModal,
    inviteGranteeModalState,
    showInviteGranteeModal,
  } = useInviteGranteesModalState();

  const {
    closeGranteeDeletionConfirmationModal: hideGranteeDeletionConfirmationModal,
    granteeDeletionConfirmationModalState,
    showGranteeDeletionConfirmationModal,
  } = useGranteeDeletionConfirmationModalState();

  const {
    closeGranteeFormSlide,
    granteeFormSlideState,
    openGranteeFormSlideInEditMode,
  } = useGranteeFormSlideState();

  const {
    hideNewEquitySelectionModal,
    newEquitySelectionModalState,
    showNewEquitySelectionModal,
  } = useNewEquitySelectionModalState();

  const { resendInvitationEmail } = useResendGranteeInvitationEmail();

  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });

  const {
    setFalse: closeSimulateExerciseModal,
    setTrue: openSimulateExerciseModal,
    value: simulateExerciseModalIsOpen,
  } = useBoolean(false);

  const simulationReadyGrants = grantee.ctmsGrants.filter(
    (grant) => !isNil(grant.exercisePrice),
  );

  const granteeHasSimulationReadyGrants = simulationReadyGrants.length > 0;

  return (
    <>
      {simulateExerciseModalIsOpen && (
        <SimulateExerciseModal
          granteeId={grantee.id}
          onExited={closeSimulateExerciseModal}
        />
      )}
      <CenteredColumnLayout
        breadcrumbs={
          <BreadCrumb>
            <BreadCrumb.Link
              to={generatePath(APPLICATION_ROUTES.organizationHome, {
                organizationId: organization.id,
              })}
            >
              {organization.name}
            </BreadCrumb.Link>
            <BreadCrumb.Link
              to={generatePath(APPLICATION_ROUTES.organizationGrantees, {
                organizationId: organization.id,
              })}
            >
              Grantees
            </BreadCrumb.Link>
            <BreadCrumb.Link to=".">{grantee.name}</BreadCrumb.Link>
          </BreadCrumb>
        }
      >
        <GranteeDeletionConfirmationModal
          onClose={hideGranteeDeletionConfirmationModal}
          onGranteeDeleted={() => {
            hideGranteeDeletionConfirmationModal();
            goToGranteesPage();
          }}
          state={granteeDeletionConfirmationModalState}
        />

        <GranteeFormSlide
          onCancel={closeGranteeFormSlide}
          onGranteeCreated={closeGranteeFormSlide}
          onGranteeDeleted={() => {
            closeGranteeFormSlide();
            goToGranteesPage();
          }}
          onGranteeUpdated={closeGranteeFormSlide}
          organizationFragment={organization}
          state={granteeFormSlideState}
        />

        <InviteGranteesModal
          onClose={hideInviteGranteeModal}
          onDraftNewGrantButtonClick={() => {
            hideInviteGranteeModal();
            showNewEquitySelectionModal({ granteeFragment: null });
          }}
          onGranteesInvited={() => {
            hideInviteGranteeModal();
            onGranteesInvited();
          }}
          onGranteeUpdated={onGranteeUpdated}
          organizationId={organization.id}
          state={inviteGranteeModalState}
        />
        <RevokeGranteeAccessModal
          onClose={hideRevokeGranteeAccessModal}
          onGranteeAccessRevoked={() => {
            hideRevokeGranteeAccessModal();
            onGranteeAccessRevoked();
          }}
          state={revokeGranteeAccessModalState}
        />

        <NewEquitySelectionModal
          onClose={hideNewEquitySelectionModal}
          organizationId={organization.id}
          state={newEquitySelectionModalState}
        />

        <div className="space-y-6">
          <DetailsHeader
            actions={
              viewer.isAllowedToManageOrganization && (
                <>
                  {organization.granteePortalInvitationIsAllowed &&
                    !grantee.cannotBeInvitedReason && (
                      <Button
                        onClick={() => {
                          showInviteGranteeModal(grantee.id);
                        }}
                        size="small"
                        variant="Primary Outline"
                      >
                        Invite grantee
                      </Button>
                    )}
                  {organization.planIsFreemium ? (
                    <LinkButton
                      leftIcon={<PlusIcon />}
                      size="small"
                      to={generatePath(
                        APPLICATION_ROUTES.organizationPrepareYourGrantsDrafts,
                        {
                          organizationId: organization.id,
                        },
                      )}
                      type="button"
                    >
                      Draft equity
                    </LinkButton>
                  ) : (
                    <DraftGrantButton
                      defaultGranteeFragment={grantee}
                      organizationFragment={organization}
                    >
                      Draft equity
                    </DraftGrantButton>
                  )}
                  <MenuButton
                    button={
                      <Button
                        leftIcon={<EllipsisHorizontalIcon />}
                        size="small"
                        variant="Secondary Full"
                      />
                    }
                  >
                    {grantee.hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal && (
                      <MenuButton.Item
                        leftIcon={<EnvelopeIcon />}
                        onClick={() => resendInvitationEmail(grantee)}
                      >
                        Resend invitation
                      </MenuButton.Item>
                    )}
                    <MenuButton.Item
                      leftIcon={<PencilIcon />}
                      onClick={() => {
                        openGranteeFormSlideInEditMode(grantee);
                      }}
                    >
                      Edit grantee
                    </MenuButton.Item>
                    {organization.granteePortalInvitationIsAllowed && (
                      <MenuButton.LinkItem
                        leftIcon={<PlayCircleIcon />}
                        target="_blank"
                        to={generatePath(APPLICATION_ROUTES.employeePortal, {
                          granteeId: grantee.id,
                        })}
                      >
                        Preview portal
                      </MenuButton.LinkItem>
                    )}
                    {grantee.hasPortalAccess && (
                      <MenuButton.Item
                        leftIcon={<LockClosedIcon />}
                        onClick={() => {
                          showRevokeGranteeAccessModal(grantee);
                        }}
                      >
                        Revoke access to portal
                      </MenuButton.Item>
                    )}
                    {!organization.planIsFreemium && grantee.isTerminable && (
                      <MenuButton.LinkItem
                        leftIcon={<CalendarIcon />}
                        to={generatePath(
                          APPLICATION_ROUTES.organizationTerminateGrantee,
                          {
                            granteeId: grantee.id,
                            organizationId: organization.id,
                          },
                        )}
                      >
                        Terminate grantee
                      </MenuButton.LinkItem>
                    )}
                    {grantee.isDeletable && (
                      <MenuButton.Item
                        leftIcon={<TrashIcon />}
                        onClick={() => {
                          showGranteeDeletionConfirmationModal(grantee.id);
                        }}
                        variant="Danger"
                      >
                        Delete grantee
                      </MenuButton.Item>
                    )}
                    {granteeHasSimulationReadyGrants &&
                      !organization.planIsFreemium && (
                        <MenuButton.Item
                          leftIcon={<BanknotesIcon />}
                          onClick={openSimulateExerciseModal}
                          variant="Default"
                        >
                          Simulate exercise
                        </MenuButton.Item>
                      )}
                  </MenuButton>
                </>
              )
            }
            subtitle={
              <>
                <Tag color={grantee.status === "Active" ? "green" : "red"}>
                  {grantee.status}
                </Tag>
                {grantee.hasSettlingGrant && <Tag color="orange">Settling</Tag>}
              </>
            }
            title={grantee.name}
            warning={
              grantee.status === "Active" &&
              grantee.terminationInformations?.terminationDate ? (
                isFuture(
                  new Date(grantee.terminationInformations.terminationDate),
                ) ? (
                  <FormattedMessage
                    defaultMessage="Grantee will be terminated on {terminationDate}"
                    values={{
                      terminationDate: (
                        <ShortDate
                          value={
                            grantee.terminationInformations.terminationDate
                          }
                        />
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Grantee {granteeName} has been terminated on {terminationDate}. Termination will be implemented in {ctmsName}."
                    values={{
                      ctmsName: organizationCTMS?.name ?? "CTMS",
                      granteeName: grantee.name,
                      terminationDate: (
                        <ShortDate
                          value={
                            grantee.terminationInformations.terminationDate
                          }
                        />
                      ),
                    }}
                  />
                )
              ) : null
            }
          >
            <DetailsHeader.Item label="Grantee">
              <GranteeNameWithCountryFlag granteeFragment={grantee} />
            </DetailsHeader.Item>
            <DetailsHeader.Item label="Equity received">
              <FormattedMessage
                defaultMessage="{equityReceived, plural, one {# grant} other {# grants}}"
                values={{
                  equityReceived: grantee.ctmsGrants.length,
                }}
              />
            </DetailsHeader.Item>
            {grantee.status === "Active"
              ? grantee.contractStartDate && (
                  <DetailsHeader.Item label="Contract start date">
                    <ShortDate value={grantee.contractStartDate} />
                  </DetailsHeader.Item>
                )
              : grantee.terminationDate && (
                  <DetailsHeader.Item label="Termination date">
                    <ShortDate value={grantee.terminationDate} />
                  </DetailsHeader.Item>
                )}
            {showEquityGridFeature && equityGridLevelName && (
              <DetailsHeader.Item label="Equity level">
                <Tag color="glass-green">{equityGridLevelName}</Tag>
              </DetailsHeader.Item>
            )}
            {grantee.jobTitle && (
              <DetailsHeader.Item label="Job title">
                {grantee.jobTitle}
              </DetailsHeader.Item>
            )}
          </DetailsHeader>

          <TwoColumnsGrid>
            <GranteeTotalOwnershipBox
              granteeFragment={grantee}
              onAddEquityLevelClick={() => {
                openGranteeFormSlideInEditMode(grantee);
              }}
              onCreateTopUpClick={() => {
                showNewEquitySelectionModal({ granteeFragment: grantee });
              }}
              organizationFragment={organization}
              viewerFragment={viewer}
            />

            <DetailsBox
              Icon={CalendarIcon}
              padded={false}
              title="Vesting schedule"
            >
              <div className="flex flex-col gap-4 p-6">
                {grantee.status === "Terminated" ? (
                  <NoticeMessage size="Small">
                    Grantee is no longer vesting shares
                  </NoticeMessage>
                ) : ctmsGrantsWithVestingDataPoints.length > 0 ? (
                  <VestingGraph
                    getGrantIndex={getGrantIndex}
                    vestingsDataPoints={[
                      ...ctmsGrantsWithVestingDataPoints,
                      ...grantee.drafts,
                    ].map((grant) => ({
                      dataPoints: grant.vestingDataPoints.map(
                        ({ cumulativeVested, date }) => ({
                          cumulativeVested,
                          date: new Date(date),
                        }),
                      ),
                      key: grant.id,
                      label: grant.label,
                    }))}
                  />
                ) : (
                  <MissingCTMSInformationTag
                    className="m-auto block"
                    organizationFragment={organization}
                  />
                )}
              </div>

              {grantee.ctmsGrants.map((grant) => (
                <div className="mt-4 p-6" key={grant.id}>
                  <GranteeGrantDetails
                    ctmsGrantFragment={grant}
                    grantIndex={getGrantIndex(grant.id)}
                    organizationFragment={organization}
                  />
                </div>
              ))}
            </DetailsBox>

            <GranteeExerciseBox
              granteeFragment={grantee}
              organizationFragment={organization}
            />

            <GranteeDetailsBox
              granteeFragment={grantee}
              organizationFragment={organization}
              viewerFragment={viewer}
            />
          </TwoColumnsGrid>
        </div>
      </CenteredColumnLayout>
    </>
  );
};

const QUERY = graphql`
  query GranteeDetails_Query(
    $organizationId: OrganizationId!
    $granteeId: GranteeId!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...GranteeDetails_Organization
    }
    grantee(id: $granteeId) {
      ...GranteeDetails_Grantee @arguments(organizationId: $organizationId)
    }
    me {
      ...GranteeDetails_Viewer @arguments(organizationId: $organizationId)
    }
  }
`;

const GranteeDetails: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const granteeId = useGranteeIdParam();

  const {
    query: { grantee, me, organization },
    refreshQuery,
  } = useQuery<GranteeDetails_Query>(QUERY, {
    granteeId,
    organizationId,
  });

  function onDataUpdated() {
    startTransition(() => {
      refreshQuery();
    });
  }

  if (!organization || !grantee) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Grantees"
      analyticsName="Admin - Grantees - Grantee details"
      organizationId={organization.id}
      title={`Admin | ${organization.name} grantee details`}
    >
      <GranteeDetails_
        granteeFragment={grantee}
        onGranteeAccessRevoked={onDataUpdated}
        onGranteesInvited={onDataUpdated}
        onGranteeUpdated={onDataUpdated}
        organizationFragment={organization}
        viewerFragment={me}
      />
    </Page>
  );
};

export default GranteeDetails;
