import { Article, Aside, Paragraph } from "./Utils";

export function StockOptions() {
  return (
    <div className="space-y-4">
      <Article title="Do you know what stock options are?">
        <Paragraph>
          Options are neither salary nor a cash bonus. An option is an agreement
          with your company that gives you the right, if you stay long enough in
          your company, to buy a slice of the company yourself at a low price if
          your company proves to be successful 💪. This slice has been
          specifically saved and put aside for you!
        </Paragraph>
        <Aside title="Definition">
          A stock option is an option to buy shares at a certain price
          determined at the time of grant.
        </Aside>
      </Article>
      <Article title="Is it going to make you rich?">
        <Aside title="Did you know" tone="orange">
          UK-based Fintech Revolut has turned at least 76 of its employees into
          millionaires thanks to stock options.
        </Aside>
        <Paragraph>
          Nothing is sure, but it might provide you with a significant gain 💸,
          which will be all the more advantageous if your company&apos;s
          valuation has increased substantially over time, that you stay for a
          number of years with the company, and if you eventually have the
          possibility to sell your shares.
        </Paragraph>
      </Article>
    </div>
  );
}
