/* eslint-disable testing-library/render-result-naming-convention */
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import {
  AdjustmentsVerticalIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { useTransition } from "react";

import { Button } from "./Button";
import { Input } from "./Form/Inputs/Input";
import { LoadingSpinner } from "./LoadingSpinner";
import { Typography } from "./Typography";

const FiltersBoxItem: React.FC<{
  children: React.ReactElement<{ className?: string }>;
  label: React.ReactNode;
}> = ({ children, label }) => (
  <label className="block w-full space-y-2">
    <Typography variant="Regular/Extra Small">{label}</Typography>
    {React.cloneElement(children, {
      ...children.props,
      className: classNames(/* tailwind */ `w-full`, children.props.className),
    })}
  </label>
);

const FilterChip: React.FC<
  React.PropsWithChildren<{
    label: React.ReactNode;
    onRemoveButtonClick: () => void;
  }>
> = ({ children, label, onRemoveButtonClick }) => {
  const [transitionInProgress, startTransition] = useTransition();
  const handleRemoveButtonClick = () => {
    startTransition(() => {
      onRemoveButtonClick();
    });
  };

  return (
    <div
      className={classNames(
        "flex items-center gap-2 rounded border-[0.5px] border-gray-04 bg-white px-2 py-1.5 shadow-100",
        {
          "animate-pulse": transitionInProgress,
        },
      )}
    >
      <Typography as="div" className="text-gray-09" variant="Regular/Caption">
        {label}
      </Typography>
      <Typography as="div" variant="Medium/Caption">
        {children}
      </Typography>
      <button onClick={handleRemoveButtonClick}>
        <XMarkIcon className="w-4 text-gray-09" />
      </button>
    </div>
  );
};

const FilterChipsContainer: React.FC<
  React.PropsWithChildren<{
    onClearAllFiltersButtonClick: () => void;
  }>
> = ({ children, onClearAllFiltersButtonClick }) => (
  <div className="flex flex-wrap items-center gap-4">
    {children}
    <button className="text-gray-09" onClick={onClearAllFiltersButtonClick}>
      <Typography variant="Regular/Caption">Clear all filters</Typography>
    </button>
  </div>
);

const FiltersBoxDeprecatedPopoverButton: React.FC = () => (
  <PopoverButton className="flex items-center gap-2 text-gray-09 focus:outline-none">
    <AdjustmentsVerticalIcon className="h-5 w-5" />
    <Typography variant="Medium/Extra Small">Filters</Typography>
  </PopoverButton>
);

const FiltersBoxPopoverButton: React.FC<{
  className?: string;
  loading?: boolean;
}> = ({ className, loading }) => (
  <PopoverButton
    as={Button}
    className={classNames(
      "whitespace-nowrap",
      {
        "animate-pulse": loading,
      },
      className,
    )}
    leftIcon={<PlusIcon />}
    size="small"
    variant="Secondary Outline"
  >
    Add filter
  </PopoverButton>
);

const FiltersBox: React.FC<
  React.PropsWithChildren<{
    popoverButton?: React.ReactElement;
    renderApplyButton: (
      close: () => void,
    ) => React.ReactElement<React.ComponentProps<typeof Button>>;
    renderClearButton: (
      close: () => void,
    ) => React.ReactElement<React.ComponentProps<typeof Button>>;
    renderForm: (
      close: () => void,
    ) => React.ReactElement<Omit<React.ComponentProps<"form">, "children">>;
  }>
> = ({
  children,
  popoverButton = <FiltersBoxPopoverButton />,
  renderApplyButton,
  renderClearButton,
  renderForm,
}) => {
  return (
    <Popover>
      {({ close }) => {
        const form = renderForm(close);
        const applyButton = renderApplyButton(close);
        const clearButton = renderClearButton(close);

        return (
          <>
            {popoverButton}

            <PopoverPanel
              anchor={{ gap: 8, to: "bottom start" }}
              className="absolute left-0 top-full z-10 mt-1 w-[552px] rounded border border-gray-03 bg-white-01 p-4 shadow-100"
            >
              {React.cloneElement(
                form,
                {
                  ...form.props,
                  className: classNames("space-y-6", form.props.className),
                },
                <>
                  <div className="grid grid-cols-2 gap-6">
                    {React.Children.map(
                      React.Children.toArray(children).filter(Boolean),
                      (child) => child,
                    )}
                  </div>
                  <div className="flex w-full items-center justify-between gap-2">
                    {clearButton &&
                      React.cloneElement(clearButton, {
                        ...clearButton.props,
                        children: "Reset",
                        size: "small",
                        type: "reset",
                        variant: "Secondary Outline",
                      })}
                    {applyButton &&
                      React.cloneElement(applyButton, {
                        ...applyButton.props,
                        children: "Apply",
                        size: "small",
                        type: "submit",
                      })}
                  </div>
                </>,
              )}
            </PopoverPanel>
          </>
        );
      }}
    </Popover>
  );
};

const _SearchBar: React.FC<{
  autoFocus?: boolean;
  className?: string;
  loading?: boolean;
  onChange?: (value: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  value?: string;
}> = ({
  autoFocus,
  className,
  loading,
  onChange,
  placeholder,
  readOnly,
  value,
}) => (
  <div
    className={classNames(
      { "animate-pulse cursor-progress": loading },
      className,
    )}
  >
    <Input
      after={
        <LoadingSpinner
          className={classNames("w-4 transition-all", {
            "opacity-0": !loading,
          })}
        />
      }
      autoFocus={autoFocus}
      before={<MagnifyingGlassIcon className="h-4 text-gray-09" />}
      className="!py-[7.5px]"
      onChange={(event) => onChange?.(event.currentTarget.value)}
      placeholder={placeholder}
      readOnly={readOnly}
      value={value}
      wrapperClassName="!rounded-full"
    />
  </div>
);

export const SearchBar = Object.assign(_SearchBar, {
  FilterChip,
  FilterChipsContainer,
  FiltersBox,
  FiltersBoxDeprecatedPopoverButton,
  FiltersBoxItem,
  FiltersBoxPopoverButton,
});
