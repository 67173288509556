import {
  ArrowTopRightOnSquareIcon,
  PlusIcon,
  RectangleStackIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { compact, isNil, range } from "lodash";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { DraftGrantButton } from "../../../components/DraftGrantButton";
import { Button, LinkButton } from "../../../components/ui/Button";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { RainbowCard } from "../../../components/ui/RainbowCard";
import { SlideOver } from "../../../components/ui/SlideOver";
import { Typography } from "../../../components/ui/Typography";
import { useApplicationSupportEmailAddress } from "../../../hooks/useApplicationTheme";
import { useBoolean } from "../../../hooks/useBoolean";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES } from "../../../paths";
import {
  OnboardingSlider_Organization$data,
  OnboardingSlider_Organization$key,
} from "./__generated__/OnboardingSlider_Organization.graphql";
import { AdminOnboardingStep } from "./__generated__/OnboardingSlider_SkipStep_Mutation.graphql";
import {
  OnboardingSlider_StripeCustomer$data,
  OnboardingSlider_StripeCustomer$key,
} from "./__generated__/OnboardingSlider_StripeCustomer.graphql";
import deelLogoSrc from "./deel-logo.png";
import { HRISSetupLinkButton } from "./HRISSetupLinkButton";
import { NotificationCountBadge } from "./NotificationCountBadge";

const STRIPE_CUSTOMER_FRAGMENT = graphql`
  fragment OnboardingSlider_StripeCustomer on StripeCustomer {
    invoicesDefaultPaymentMethod {
      __typename
    }
  }
`;
const ORGANIZATION_FRAGMENT = graphql`
  fragment OnboardingSlider_Organization on Organization {
    id
    name
    granteeCleanupSuggestionsCount
    isConnectedToHRISProvider
    adminOnboardingConnectHRISStepSkipped
    adminOnboardingSetupGranteesStepSkipped
    adminOnboardingSetupGranteesStepCompleted
    isOriginatingFromRemoteEquity
    planIsFreemium
    easopGrants {
      __typename
    }
    grantees {
      totalCount
    }
    ...DraftGrantButton_Organization
  }
`;

const SKIP_ADMINS_STEP_MUTATION = graphql`
  mutation OnboardingSlider_SkipStep_Mutation(
    $organizationId: OrganizationId!
    $step: AdminOnboardingStep!
  ) {
    skipAdminOnboardingStep(organizationId: $organizationId, step: $step) {
      adminOnboardingConnectHRISStepSkipped
      adminOnboardingSetupGranteesStepSkipped
      adminOnboardingSetupGranteesStepCompleted
    }
  }
`;

type OnboardingStep =
  | "BillingInformation"
  | "CleanupGrantees"
  | "ConnectHRIS"
  | "CreateGrantees"
  | "FirstGrant";

function useSkipOnboardingStep({ organizationId }: { organizationId: string }) {
  const [commit] = useSafeMutation(SKIP_ADMINS_STEP_MUTATION);

  return (step: AdminOnboardingStep) => {
    return commit({
      variables: {
        organizationId,
        step,
      },
    });
  };
}

const isBillingInformationStepCompleted = ({
  stripeCustomer,
}: {
  stripeCustomer: null | OnboardingSlider_StripeCustomer$data;
}) => !isNil(stripeCustomer?.invoicesDefaultPaymentMethod);

const isConnectHRISStepCompleted = ({
  organization,
}: {
  organization: OnboardingSlider_Organization$data;
}) =>
  organization.isConnectedToHRISProvider ||
  organization.adminOnboardingConnectHRISStepSkipped;

const isCleanupGranteesStepCompleted = ({
  organization,
}: {
  organization: OnboardingSlider_Organization$data;
}) =>
  organization.granteeCleanupSuggestionsCount === 0 ||
  organization.adminOnboardingSetupGranteesStepSkipped ||
  organization.adminOnboardingSetupGranteesStepCompleted;

const isCreateGranteesStepCompleted = ({
  organization,
}: {
  organization: OnboardingSlider_Organization$data;
}) =>
  organization.grantees.totalCount > 0 ||
  organization.adminOnboardingSetupGranteesStepSkipped ||
  organization.adminOnboardingSetupGranteesStepCompleted;

const isFirstGrantStepCompleted = ({
  organization,
}: {
  organization: OnboardingSlider_Organization$data;
}) => organization.easopGrants.length > 0;

const useOnboardingSteps = ({
  organization,
  stripeCustomer,
}: {
  organization: OnboardingSlider_Organization$data;
  stripeCustomer: null | OnboardingSlider_StripeCustomer$data;
}): {
  completed: boolean;
  step: OnboardingStep;
}[] => {
  if (organization.planIsFreemium) {
    return [
      {
        completed: isConnectHRISStepCompleted({ organization }),
        step: "ConnectHRIS",
      },
    ];
  }

  return compact([
    !organization.isOriginatingFromRemoteEquity && {
      completed: isBillingInformationStepCompleted({
        stripeCustomer,
      }),
      step: "BillingInformation",
    },
    {
      completed: isConnectHRISStepCompleted({ organization }),
      step: "ConnectHRIS",
    },
    {
      completed: isCreateGranteesStepCompleted({ organization }),
      step: "CreateGrantees",
    },
    {
      completed: isCleanupGranteesStepCompleted({ organization }),
      step: "CleanupGrantees",
    },
    {
      completed: isFirstGrantStepCompleted({ organization }),
      step: "FirstGrant",
    },
  ]);
};

const useActiveOnboardingStep = ({
  organization,
  stripeCustomer,
}: {
  organization: OnboardingSlider_Organization$data;
  stripeCustomer: null | OnboardingSlider_StripeCustomer$data;
}): null | OnboardingStep => {
  const onboardingSteps = useOnboardingSteps({
    organization,
    stripeCustomer,
  });

  const activeStep = onboardingSteps.find((step) => !step.completed);

  return activeStep?.step ?? null;
};

const useRemainingOnboardingStepsCount = ({
  organization,
  stripeCustomer,
}: {
  organization: OnboardingSlider_Organization$data;
  stripeCustomer: null | OnboardingSlider_StripeCustomer$data;
}): number => {
  const onboardingSteps = useOnboardingSteps({
    organization,
    stripeCustomer,
  });

  return onboardingSteps.filter((step) => !step.completed).length;
};

const useCompletedOnboardingStepsCount = ({
  organization,
  stripeCustomer,
}: {
  organization: OnboardingSlider_Organization$data;
  stripeCustomer: null | OnboardingSlider_StripeCustomer$data;
}): number => {
  const onboardingSteps = useOnboardingSteps({
    organization,
    stripeCustomer,
  });

  return onboardingSteps.filter((step) => step.completed).length;
};

export function OnboardingSlider({
  onGrantCreated,
  organizationFragment,
  stripeCustomerFragment,
}: {
  onGrantCreated?: () => void;
  organizationFragment: OnboardingSlider_Organization$key;
  stripeCustomerFragment: null | OnboardingSlider_StripeCustomer$key;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const stripeCustomer =
    useFragment(STRIPE_CUSTOMER_FRAGMENT, stripeCustomerFragment) ?? null;

  const {
    setFalse: closeOnboardingSlider,
    setTrue: openOnboardingSlider,
    value: onboardingSliderIsOpen,
  } = useBoolean(false);
  const mailtoSubject = encodeURIComponent(
    `[${organization.name}] set up my HRIS`,
  );

  const remainingOnboardingStepsCount = useRemainingOnboardingStepsCount({
    organization,
    stripeCustomer,
  });

  const activeOnboardingStep = useActiveOnboardingStep({
    organization,
    stripeCustomer,
  });

  const supportEmailAddress = useApplicationSupportEmailAddress();

  if (!activeOnboardingStep) {
    return null;
  }

  return (
    <>
      <Button
        onClick={openOnboardingSlider}
        size="small"
        variant="Secondary Full"
      >
        <div className="flex items-center gap-2">
          <div>Complete your workspace setup</div>
          <NotificationCountBadge>
            {remainingOnboardingStepsCount}
          </NotificationCountBadge>
        </div>
      </Button>
      <SlideOver
        onClose={closeOnboardingSlider}
        show={onboardingSliderIsOpen}
        width="sm"
      >
        <SlideOver.Header onClose={closeOnboardingSlider} />
        <div className="space-y-6 px-6">
          <Heading
            organization={organization}
            stripeCustomer={stripeCustomer}
          />
          {activeOnboardingStep === "BillingInformation" && (
            <UpdateBillingInformationStepCard organization={organization} />
          )}
          {activeOnboardingStep === "ConnectHRIS" && (
            <ConnectHRISStepCard organization={organization} />
          )}
          {activeOnboardingStep === "CreateGrantees" && (
            <CreateGranteesStepCard organization={organization} />
          )}
          {activeOnboardingStep === "CleanupGrantees" && (
            <CleanupGranteesStepCard organization={organization} />
          )}
          {activeOnboardingStep === "FirstGrant" && (
            <FirstGrantStepCard
              onGrantCreated={onGrantCreated}
              organization={organization}
            />
          )}
          <NoticeMessage size="Small" variant="Info">
            Any question? Feel free to{" "}
            <Link
              className="text-primary"
              to={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
            >
              contact us
            </Link>
          </NoticeMessage>
        </div>
      </SlideOver>
    </>
  );
}

function CleanupGranteesStepCard({
  organization,
}: {
  organization: OnboardingSlider_Organization$data;
}) {
  const skipOnboardingStep = useSkipOnboardingStep({
    organizationId: organization.id,
  });

  function handleCleanUpLaterClick() {
    return skipOnboardingStep("SetupGrantees");
  }

  return (
    <StepCard
      description="Review grantee cleanup suggestions."
      title="Clean up your grantees"
    >
      <div className="flex flex-col items-center gap-10">
        <LinkButton
          size="small"
          to={generatePath(
            APPLICATION_ROUTES.organizationSettingsCleanupGrantees,
            { organizationId: organization.id },
          )}
        >
          Clean up my grantees
        </LinkButton>
        <Button
          onClick={handleCleanUpLaterClick}
          size="small"
          variant="Secondary Full"
        >
          Clean up later
        </Button>
      </div>
    </StepCard>
  );
}

function ConnectHRISStepCard({
  organization,
}: {
  organization: OnboardingSlider_Organization$data;
}) {
  const skipOnboardingStep = useSkipOnboardingStep({
    organizationId: organization.id,
  });

  function handleConnectLaterClick() {
    return skipOnboardingStep("ConnectHRIS");
  }

  return (
    <StepCard
      description="Connect to your HRIS to automatically import your employees and their information"
      title="Enrich your international data"
    >
      <div className="space-y-2">
        <HRISSetupLinkButton
          logoSrc={deelLogoSrc}
          to={generatePath(
            APPLICATION_ROUTES.organizationSettingsIntegrationsDeel,
            { organizationId: organization.id },
          )}
        />
      </div>
      <div className="flex justify-center">
        <Button
          onClick={handleConnectLaterClick}
          size="small"
          variant="Secondary Full"
        >
          Connect later
        </Button>
      </div>
    </StepCard>
  );
}

function CreateGranteesStepCard({
  organization,
}: {
  organization: OnboardingSlider_Organization$data;
}) {
  const skipOnboardingStep = useSkipOnboardingStep({
    organizationId: organization.id,
  });

  function handleAddGranteeLaterClick() {
    return skipOnboardingStep("SetupGrantees");
  }

  return (
    <StepCard
      description="Create your first grantees to start using the platform."
      title="Create your first grantees"
    >
      <div className="flex flex-col items-center gap-10">
        <LinkButton
          leftIcon={<PlusIcon />}
          size="small"
          to={generatePath(APPLICATION_ROUTES.organizationNewGrantee, {
            organizationId: organization.id,
          })}
        >
          Create my first grantee
        </LinkButton>
        <Button
          onClick={handleAddGranteeLaterClick}
          size="small"
          variant="Secondary Full"
        >
          Add grantee later
        </Button>
      </div>
    </StepCard>
  );
}

function FirstGrantStepCard({
  onGrantCreated,
  organization,
}: {
  onGrantCreated?: () => void;
  organization: OnboardingSlider_Organization$data;
}) {
  return (
    <StepCard
      description="It's time to grant your awesome team members some equity incentives! Don't worry, if you're a newbie in equity, we have a flow to guide you through it!"
      title="Draft your first grant"
    >
      <div className="flex justify-center">
        <DraftGrantButton
          IconBefore={RectangleStackIcon}
          onGrantCreated={onGrantCreated}
          organizationFragment={organization}
        >
          Draft a new grant
        </DraftGrantButton>
      </div>
    </StepCard>
  );
}

function Heading({
  organization,
  stripeCustomer,
}: {
  organization: OnboardingSlider_Organization$data;
  stripeCustomer: null | OnboardingSlider_StripeCustomer$data;
}) {
  const remainingOnboardingStepsCount = useRemainingOnboardingStepsCount({
    organization,
    stripeCustomer,
  });

  const completedOnboardingStepsCount = useCompletedOnboardingStepsCount({
    organization,
    stripeCustomer,
  });

  if (completedOnboardingStepsCount + remainingOnboardingStepsCount === 1) {
    return (
      <Typography as="div" className="text-center" variant="Medium/Small">
        Complete your workspace setup
      </Typography>
    );
  }

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <Typography as="div" className="text-center" variant="Medium/Small">
          Finish setting up your account
        </Typography>
        <Typography
          as="div"
          className="text-center text-black-05"
          variant="Regular/Extra Small"
        >
          You’re a few clicks away before you can start granting equity to your
          team!
        </Typography>
      </div>
      <div className="flex items-center gap-2">
        {range(completedOnboardingStepsCount + 1).map((index) => (
          <LightStrip active={true} key={index} />
        ))}
        {range(remainingOnboardingStepsCount - 1).map((index) => (
          <LightStrip active={false} key={index} />
        ))}
      </div>
    </div>
  );
}

function LightStrip({ active }: { active: boolean }) {
  return (
    <div
      className={classNames("h-1 flex-1 rounded-full", {
        "bg-gray-07": !active,
        "bg-primary": active,
      })}
    />
  );
}

function StepCard({
  children,
  description,
  title,
}: React.PropsWithChildren<{
  description: React.ReactNode;
  title: React.ReactNode;
}>) {
  return (
    <RainbowCard>
      <div className="space-y-10">
        <div className="space-y-2">
          <Typography as="div" variant="Medium/Default">
            {title}
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Small"
          >
            {description}
          </Typography>
        </div>
        {children}
      </div>
    </RainbowCard>
  );
}

function UpdateBillingInformationStepCard({
  organization,
}: {
  organization: OnboardingSlider_Organization$data;
}) {
  return (
    <StepCard
      description="In order for us to set up your workspace, we need you to fill in your billing details."
      title="Update your billing information"
    >
      <div className="space-y-4">
        <div>
          <LinkButton
            rightIcon={<ArrowTopRightOnSquareIcon />}
            size="small"
            to={generatePath(APPLICATION_ROUTES.organizationSettingsBilling, {
              organizationId: organization.id,
            })}
          >
            Update your billing information
          </LinkButton>
        </div>
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Caption"
        >
          Define your billing details, your preferred credit card, platform
          access fee recurrence and geographies access fee recurrence.
        </Typography>
      </div>
    </StepCard>
  );
}
