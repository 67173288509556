import { Dialog, DialogPanel } from "@headlessui/react";
import { ArrowLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { AnimatePresence, motion } from "motion/react";
import React, { Suspense } from "react";

import { WithRequired } from "../../helpers/ts-utlity";
import { LoadingPlaceholder } from "../LoadingPlaceholder";
import { Button } from "./Button";
import { Divider } from "./Divider";
import { Typography } from "./Typography";

const RawModal: React.FC<{
  children: React.ReactNode;
  dataCy?: string;
  initialFocusRef?: React.ComponentProps<typeof Dialog>["initialFocus"];
  noEmptyBorder?: boolean;
  onClose?: () => void;
  onExited?: () => void;
  panelClassName?: React.ComponentProps<"div">["className"];
  show: boolean;
  suspense?: boolean;
  width?: number;
}> = ({
  children,
  dataCy,
  initialFocusRef,
  noEmptyBorder = false,
  onClose,
  onExited,
  panelClassName,
  show,
  suspense = false,
  width,
}) => {
  return (
    <AnimatePresence mode="wait" onExitComplete={onExited}>
      {show && (
        <Dialog
          className="relative z-50"
          initialFocus={initialFocusRef}
          onClose={() => onClose?.()}
          open
        >
          <motion.div
            animate={{ opacity: 1 }}
            className={classNames("fixed inset-0 bg-black bg-opacity-50")}
            data-cy="ModalOverlay"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          />

          <div
            className={classNames(
              "fixed inset-0 z-10 flex items-center justify-center",
              { ["p-4"]: !noEmptyBorder },
            )}
          >
            <DialogPanel
              animate={{ opacity: 1, scale: 1 }}
              as={motion.div}
              className={classNames(
                "flex max-h-full max-w-full flex-col overflow-hidden rounded-lg bg-white shadow-xl",
                panelClassName,
              )}
              data-cy={dataCy}
              exit={{ opacity: 0, scale: 0.97 }}
              initial={{ opacity: 0, scale: 1.03 }}
              style={{ width }}
            >
              {suspense ? (
                <Suspense fallback={<LoadingPlaceholder />}>
                  {children}
                </Suspense>
              ) : (
                children
              )}
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};

const _Modal: React.FC<{
  children: React.ReactNode;
  dataCy?: string;
  initialFocusRef?: React.ComponentProps<typeof Dialog>["initialFocus"];
  onClose?: () => void;
  onExited?: () => void;
  show: boolean;
  suspense?: boolean;
  width?: number;
}> = ({
  children,
  dataCy,
  initialFocusRef,
  onClose,
  onExited,
  show,
  suspense = false,
  width = 600,
}) => {
  return (
    <RawModal
      dataCy={dataCy}
      initialFocusRef={initialFocusRef}
      onClose={onClose}
      onExited={onExited}
      show={show}
      suspense={suspense}
      width={width}
    >
      {children}
    </RawModal>
  );
};

const ModalContent: React.FC<{
  actionsInHeader?:
    | React.ReactElement<typeof Button>
    | React.ReactElement<typeof Button>[];
  children?: React.ReactNode;
  onClose?: () => void;
  subTitle?: React.ReactNode;
  title?: React.ReactNode;
}> = ({ actionsInHeader, children, onClose, subTitle, title }) => {
  return (
    <>
      {actionsInHeader && (
        <div className="flex items-center gap-2 px-10 py-6">
          {onClose && (
            <button
              className="h-8 w-8 rounded p-1 hover:bg-gray-02 active:bg-gray-03"
              data-cy="close-modal-button"
              onClick={onClose}
              type="button"
            >
              <XMarkIcon className="text-black-07" />
            </button>
          )}
          <div className="flex-grow" />
          {actionsInHeader}
        </div>
      )}
      <Divider />
      <div className="space-y-6 overflow-y-auto p-10">
        {(title || subTitle) && (
          <div className="flex items-start justify-between">
            <div className="space-y-4">
              {title && (
                <Typography as="div" variant="Medium/Large">
                  {title}
                </Typography>
              )}
              {subTitle && (
                <Typography
                  as="div"
                  className="text-black-05"
                  variant="Regular/Small"
                >
                  {subTitle}
                </Typography>
              )}
            </div>
            {!actionsInHeader && onClose && (
              <button
                className="h-8 w-8 rounded p-1 hover:bg-gray-02 active:bg-gray-03"
                data-cy="close-modal-button"
                onClick={onClose}
                type="button"
              >
                <XMarkIcon className="text-black-07" />
              </button>
            )}
          </div>
        )}
        {children && <div className="space-y-4">{children}</div>}
      </div>
    </>
  );
};

const ActionButton: React.FC<
  Omit<React.ComponentProps<typeof Button>, "size">
> = ({ children, type = "button", variant = "Primary Full", ...props }) => {
  return (
    <Button {...props} size="small" type={type} variant={variant}>
      {children}
    </Button>
  );
};

const BackButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button
      leftIcon={<ArrowLeftIcon />}
      onClick={onClick}
      size="small"
      type="button"
      variant="Secondary Full"
    >
      Back
    </Button>
  );
};

const SubmitButton: React.FC<
  WithRequired<
    Omit<React.ComponentProps<typeof ActionButton>, "type" | "variant">,
    "form"
  >
> = ({ children, ...props }) => {
  return (
    <ActionButton type="submit" variant="Primary Full" {...props}>
      {children}
    </ActionButton>
  );
};

const ModalForm: React.FC<
  WithRequired<React.ComponentPropsWithoutRef<"form">, "id">
> = ({ children, className, ...props }) => {
  return (
    <form {...props} className={classNames(className, "space-y-4")}>
      {children}
    </form>
  );
};

export const Modal = Object.assign(_Modal, {
  ActionButton,
  BackButton,
  Content: ModalContent,
  Form: ModalForm,
  Raw: RawModal,
  SubmitButton,
});
