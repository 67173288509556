import React, { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useMutation } from "react-relay";
import { graphql } from "relay-runtime";

import { FormattedPercentage } from "../../../../components/Formatted/FormattedPercentage";
import {
  InviteGranteesModal,
  useInviteGranteesModalState,
} from "../../../../components/InviteGranteesModal";
import {
  NewEquitySelectionModal,
  useNewEquitySelectionModalState,
} from "../../../../components/NewEquitySelectionModal/NewEquitySelectionModal";
import { PortalAccessTag } from "../../../../components/PortalAccessTag";
import { PreviewGranteesPortalModal } from "../../../../components/PreviewGranteesPortalModal";
import { Button } from "../../../../components/ui/Button";
import { Progress } from "../../../../components/ui/Progress";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Typography } from "../../../../components/ui/Typography";
import { useBoolean } from "../../../../hooks/useBoolean";
import { GranteePortalSettingsUsageSection_Organization$key } from "./__generated__/GranteePortalSettingsUsageSection_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteePortalSettingsUsageSection_Organization on Organization
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    id
    granteesThatDidNotVisitTheirPortalYet {
      __typename
    }
    granteesInvitedMoreThanAWeekAgoThatDidNotVisitTheirPortalYet {
      __typename
    }
    grantees {
      edges {
        node {
          id
          cannotBeInvitedReason
          hasPortalAccess
          hasVisitedTheirPortal
          ...NewEquitySelectionModal_DefaultGrantee
            @arguments(organizationId: $organizationId)
          ...PreviewGranteesPortalModal_Grantees
        }
      }
    }
  }
`;

const RESEND_INVITATION_EMAIL_MUTATION = graphql`
  mutation GranteePortalSettingsUsageSection_ResendInvitationEmail_Mutation(
    $organizationId: OrganizationId!
  ) {
    resendInvitationEmailToGranteesThatDidNotVisitTheirPortalYet(
      organizationId: $organizationId
    ) {
      ...GranteePortalSettingsUsageSection_Organization
        @arguments(organizationId: $organizationId)
    }
  }
`;

const GranteePortalUsageRow: React.FC<{
  label: React.ReactNode;
  tag: React.ReactNode;
  total: number;
  value: number;
}> = ({ label, tag, total, value }) => {
  if (total === 0) return null;
  return (
    <div className="flex items-center gap-6 py-2">
      {tag}
      <Typography
        className="w-[104px] text-black-05"
        variant="Regular/Extra Small"
      >
        {label}
      </Typography>
      <div className="flex flex-grow items-center gap-8">
        <Progress className="flex-grow bg-gray-02" max={total}>
          <Progress.Value className="bg-primary" value={value} />
        </Progress>
        <Typography className="w-12" variant="Regular/Extra Small">
          {value}/{total}
        </Typography>
        <Typography className="w-10" variant="Medium/Extra Small">
          <FormattedPercentage
            maximumFractionDigits={0}
            value={value / total}
          />
        </Typography>
      </div>
    </div>
  );
};

const CallToActionCard: React.FC<
  React.PropsWithChildren<{
    background?: React.ComponentProps<typeof RoundedBox>["background"];
    callToActionLabel: React.ReactNode;
    emoji: React.ReactNode;
    loading?: boolean;
    onCallToActionClick: () => void;
  }>
> = ({
  background,
  callToActionLabel,
  children,
  emoji,
  loading,
  onCallToActionClick,
}) => (
  <RoundedBox background={background} className="flex items-center gap-2 p-2">
    <div className="w-6 text-center font-emoji">{emoji}</div>
    <Typography
      as="div"
      className="inline-block text-black-05"
      variant="Regular/Extra Small"
    >
      {children}
    </Typography>
    <Button
      className="ml-auto"
      loading={loading}
      onClick={onCallToActionClick}
      size="small"
      type="button"
      variant="Primary Outline"
    >
      {callToActionLabel}
    </Button>
  </RoundedBox>
);

export const GranteePortalSettingsUsageSection: React.FC<{
  onGrantCreated: () => void;
  onGranteeCreated: () => void;
  onGranteeDeleted: () => void;
  onGranteesInvited: () => void;
  onGranteeUpdated: () => void;
  organizationFragment: GranteePortalSettingsUsageSection_Organization$key;
}> = ({
  onGrantCreated,
  onGranteeCreated,
  onGranteeDeleted,
  onGranteesInvited,
  onGranteeUpdated,
  organizationFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const allGrantees = useMemo(
    () => organization.grantees.edges.map((edge) => edge.node),
    [organization.grantees],
  );

  const invitedOrInvitableGrantees = useMemo(
    () =>
      allGrantees.filter(
        (grantee) => grantee.hasPortalAccess || !grantee.cannotBeInvitedReason,
      ),
    [allGrantees],
  );
  const granteesWithPortalAccess = useMemo(
    () =>
      invitedOrInvitableGrantees.filter((grantee) => grantee.hasPortalAccess),
    [invitedOrInvitableGrantees],
  );
  const granteesHavingVisitedTheirPortal = useMemo(
    () =>
      granteesWithPortalAccess.filter(
        (grantee) => grantee.hasVisitedTheirPortal,
      ),
    [granteesWithPortalAccess],
  );
  const granteesWithoutPortalAccess = useMemo(
    () =>
      invitedOrInvitableGrantees.filter((grantee) => !grantee.hasPortalAccess),
    [invitedOrInvitableGrantees],
  );
  const granteesWithoutPortalAccessCount = granteesWithoutPortalAccess.length;
  const [resendInvitationEmail, resendInvitationEmailMutationIsInFlight] =
    useMutation(RESEND_INVITATION_EMAIL_MUTATION);

  const handleResendInvitationEmailButtonClick = useCallback(() => {
    resendInvitationEmail({
      onCompleted: () => {
        onGranteesInvited();
      },
      variables: {
        organizationId: organization.id,
      },
    });
  }, [resendInvitationEmail, organization.id, onGranteesInvited]);

  const {
    hideInviteGranteeModal,
    inviteGranteeModalState,
    showInviteGranteeModal,
  } = useInviteGranteesModalState();

  const {
    hideNewEquitySelectionModal,
    newEquitySelectionModalState,
    showNewEquitySelectionModal,
  } = useNewEquitySelectionModalState();

  const {
    setFalse: hidePreviewGranteesPortalModal,
    setTrue: showPreviewGranteesPortalModal,
    value: previewGranteesPortalModalShown,
  } = useBoolean(false);

  const onDraftNewGrantButtonClick = useCallback(
    (granteeId: string) => {
      const grantee = organization.grantees.edges
        .map((edge) => edge.node)
        .find((grantee) => grantee.id === granteeId);
      if (!grantee) throw new Error("Grantee not found");
      hideInviteGranteeModal();
      hidePreviewGranteesPortalModal();
      showNewEquitySelectionModal({ granteeFragment: grantee });
    },
    [
      organization.grantees,
      showNewEquitySelectionModal,
      hideInviteGranteeModal,
      hidePreviewGranteesPortalModal,
    ],
  );

  return (
    <>
      <PreviewGranteesPortalModal
        granteesFragment={allGrantees}
        onClose={hidePreviewGranteesPortalModal}
        onDraftNewGrantButtonClick={onDraftNewGrantButtonClick}
        onGranteeCreated={onGranteeCreated}
        onGranteeDeleted={onGranteeDeleted}
        onGranteesInvited={onGranteesInvited}
        onGranteeUpdated={onGranteeUpdated}
        organizationId={organization.id}
        show={previewGranteesPortalModalShown}
      />
      <NewEquitySelectionModal
        onClose={hideNewEquitySelectionModal}
        onGrantCreated={onGrantCreated}
        organizationId={organization.id}
        state={newEquitySelectionModalState}
      />
      <InviteGranteesModal
        onClose={hideInviteGranteeModal}
        onDraftNewGrantButtonClick={onDraftNewGrantButtonClick}
        onGranteeCreated={onGranteeCreated}
        onGranteeDeleted={onGranteeDeleted}
        onGranteesInvited={onGranteesInvited}
        onGranteeUpdated={onGranteeUpdated}
        organizationId={organization.id}
        state={inviteGranteeModalState}
      />
      <div className="space-y-6">
        <Typography
          as="div"
          className="space-y-2 text-black-05"
          variant="Regular/Extra Small"
        >
          <div>
            Define the scenarios that will appear it your grantee&apos;s
            portals.
          </div>
          <div className="inline-block">
            👀 You can also{" "}
            <Typography
              className="cursor-pointer text-primary"
              onClick={showPreviewGranteesPortalModal}
              variant="Medium/Extra Small"
            >
              preview portals
            </Typography>{" "}
            from grantees from here.
          </div>
        </Typography>

        <RoundedBox className="space-y-4 p-6" withBorder>
          <Typography as="div" variant="Medium/Small">
            Grantee portal usage
          </Typography>
          {organization
            .granteesInvitedMoreThanAWeekAgoThatDidNotVisitTheirPortalYet
            .length > 0 && (
            <CallToActionCard
              background="green"
              callToActionLabel="Resend invitations"
              emoji="👁️"
              loading={resendInvitationEmailMutationIsInFlight}
              onCallToActionClick={handleResendInvitationEmailButtonClick}
            >
              <FormattedMessage
                defaultMessage="You still have <strong>{count, plural, one {# grantee} other {# grantees}}</strong> that did not view their portal."
                values={{
                  count:
                    organization.granteesThatDidNotVisitTheirPortalYet.length,
                  strong: (chunks) => (
                    <Typography
                      className="text-black-07"
                      variant="Medium/Extra Small"
                    >
                      {chunks}
                    </Typography>
                  ),
                }}
              />
            </CallToActionCard>
          )}
          {granteesWithoutPortalAccessCount > 0 && (
            <CallToActionCard
              background="orange"
              callToActionLabel={
                <FormattedMessage
                  defaultMessage="Invite {count, plural, one {grantee} other {all grantees}}"
                  values={{ count: granteesWithoutPortalAccessCount }}
                />
              }
              emoji="😱"
              onCallToActionClick={() => {
                showInviteGranteeModal(
                  granteesWithoutPortalAccess.map(({ id }) => id),
                );
              }}
            >
              <FormattedMessage
                defaultMessage="You still have <strong>{count, plural, one {# grantee} other {# grantees}}</strong> with no access to their portal."
                values={{
                  count: granteesWithoutPortalAccessCount,
                  strong: (chunks) => (
                    <Typography
                      className="text-black-07"
                      variant="Medium/Extra Small"
                    >
                      {chunks}
                    </Typography>
                  ),
                }}
              />
            </CallToActionCard>
          )}
          <div>
            <GranteePortalUsageRow
              label="Not invited"
              tag={<PortalAccessTag.NotInvited />}
              total={invitedOrInvitableGrantees.length}
              value={granteesWithoutPortalAccess.length}
            />
            <GranteePortalUsageRow
              label="Invited"
              tag={<PortalAccessTag.InvitedButNotVisited />}
              total={invitedOrInvitableGrantees.length}
              value={granteesWithPortalAccess.length}
            />
            <GranteePortalUsageRow
              label="Viewed"
              tag={<PortalAccessTag.InvitedAndVisited />}
              total={granteesWithPortalAccess.length}
              value={granteesHavingVisitedTheirPortal.length}
            />
          </div>
        </RoundedBox>
      </div>
    </>
  );
};
