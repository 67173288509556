/**
 * @generated SignedSource<<f515353fd477e63ce4552c872f73b419>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalOwnershipSection_Grantee$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalWelcomeSection_Grantee" | "EmployeePortalWhatDoYouHaveSection_Grantee">;
  readonly " $fragmentType": "EmployeePortalOwnershipSection_Grantee";
};
export type EmployeePortalOwnershipSection_Grantee$key = {
  readonly " $data"?: EmployeePortalOwnershipSection_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalOwnershipSection_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalOwnershipSection_Grantee",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeePortalWelcomeSection_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeePortalWhatDoYouHaveSection_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "23e726ad19ee7eaee8c5ff6d7d0194d0";

export default node;
