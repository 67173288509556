/**
 * @generated SignedSource<<81541f1a6b299820210d75291a870de1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalsSettings_Organization$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalTopSection_Organization" | "EmployeePortalsSettingsBody_Organization" | "Footer_Organization">;
  readonly " $fragmentType": "EmployeePortalsSettings_Organization";
};
export type EmployeePortalsSettings_Organization$key = {
  readonly " $data"?: EmployeePortalsSettings_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalsSettings_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalsSettings_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeePortalTopSection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Footer_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeePortalsSettingsBody_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "b6a9f569117b8d359a8ebcc0a456de78";

export default node;
