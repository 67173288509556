import classNames from "classnames";
import React from "react";

import { useApplicationTheme } from "../../../hooks/useApplicationTheme";
import CONFIGURATION from "../../../services/configuration";
import easopXRemoteEquityLogo from "./easop-x-remote-equity-black.png";
import remoteEquityBlackLogo from "./remote-equity-black.png";
import remoteEquityWhiteLogo from "./remote-equity-white.png";

const inProduction = CONFIGURATION.ENVIRONMENT === "production";

const LOGO_CLASS_NAME = classNames("object-contain");

export const LogoWithoutText: React.FC<
  React.ComponentProps<"svg"> & { animate?: boolean }
> = ({ animate, className, ...props }) => {
  return (
    <svg
      className={classNames(className, "overflow-visible")}
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className={classNames({ "animate-logo-bump-1": animate })}
        d="M29.3066 2.78197C32.0665 3.12086 34.1399 5.45968 34.1399 8.23395L34.1399 31.766C34.1399 34.5403 32.0665 36.8791 29.3066 37.218L6.99238 39.9581C3.7096 40.3612 0.814009 37.806 0.814009 34.5061L0.81401 5.49391C0.814011 2.19398 3.7096 -0.361181 6.99239 0.0419234L29.3066 2.78197Z"
        fill={inProduction ? "#FFD972" : "#583DDD"}
      />
      <path
        className={classNames({ "animate-logo-bump-2": animate })}
        d="M34.2037 2.76153C36.9518 3.11247 39.0105 5.44607 39.0105 8.2102L39.0105 31.7896C39.0105 34.5538 36.9518 36.8874 34.2037 37.2383L13.1712 39.9243C9.87983 40.3446 6.96638 37.7862 6.96638 34.4756L6.96638 5.52424C6.96638 2.21363 9.87983 -0.344758 13.1712 0.0755718L34.2037 2.76153Z"
        fill={inProduction ? "#F39D8D" : "#E78EA9"}
      />
      <path
        className={classNames({ "animate-logo-bump-3": animate })}
        d="M34.5587 2.62926C37.2257 3.05931 39.186 5.35654 39.186 8.05202L39.186 31.9479C39.186 34.6433 37.2257 36.9406 34.5586 37.3706L20.1913 39.6873C16.8435 40.2271 13.8071 37.648 13.8071 34.2645L13.8071 5.73538C13.8071 2.35189 16.8435 -0.227204 20.1913 0.312617L34.5587 2.62926Z"
        fill={inProduction ? "#2A55E1" : "#F5C189"}
      />
    </svg>
  );
};

const EasopLogo: React.FC<
  React.ComponentProps<"svg"> & { variant?: "black" | "white" }
> = ({ className, variant = "white", ...props }) => {
  return (
    <svg
      className={classNames(
        "fill-current",
        {
          "text-black-07": variant === "black",
          "text-white": variant === "white",
        },
        className,
      )}
      viewBox="0 0 85 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.0972 1.51609C16.5595 1.70078 17.6581 2.97537 17.6581 4.48727L17.6581 17.3116C17.6581 18.8235 16.5595 20.0981 15.0972 20.2827L3.27369 21.776C1.53427 21.9957 2.95941e-06 20.6032 3.03406e-06 18.8048L3.69041e-06 2.99402C3.76506e-06 1.19566 1.53427 -0.196833 3.27369 0.022847L15.0972 1.51609Z"
        fill={inProduction ? "#FFD972" : "#583DDD"}
      />
      <path
        d="M17.692 1.50506C19.1481 1.69631 20.2389 2.96805 20.2389 4.47442L20.2389 17.3245C20.2389 18.8309 19.1481 20.1026 17.692 20.2939L6.54769 21.7577C4.80369 21.9867 3.25997 20.5925 3.25997 18.7883L3.25997 3.01066C3.25997 1.20647 4.8037 -0.187779 6.54769 0.0412878L17.692 1.50506Z"
        fill={inProduction ? "#F39D8D" : "#E78EA9"}
      />
      <path
        d="M17.8801 1.4329C19.2932 1.66726 20.3319 2.91919 20.3319 4.38814L20.3319 17.4107C20.3319 18.8796 19.2932 20.1316 17.8801 20.3659L10.2674 21.6284C8.49345 21.9226 6.88459 20.5171 6.88459 18.6732L6.88459 3.12564C6.88459 1.28174 8.49345 -0.123792 10.2674 0.170395L17.8801 1.4329Z"
        fill={inProduction ? "#2A55E1" : "#F5C189"}
      />
      <path
        d="M30.8606 17.5801C33.2196 17.5801 34.7782 16.4143 35.5786 15.1407L33.8515 13.9533C33.2407 14.9032 32.4192 15.594 30.8606 15.594C28.9861 15.594 27.7434 14.3419 27.5749 12.399H36.2315V11.4708C36.2315 8.59958 34.6308 5.75 30.8396 5.75C27.3643 5.75 25.3423 8.64276 25.3423 11.7082C25.3423 15.7883 28.1436 17.5801 30.8606 17.5801ZM27.6591 10.4561C28.0804 8.578 29.4284 7.73607 30.8606 7.73607C32.9458 7.73607 33.7461 9.0961 33.9778 10.4561H27.6591Z"
        fill="currentColor"
      />
      <path
        d="M40.9241 17.5801C42.6512 17.5801 43.7254 16.9109 44.2941 16.0689C44.3362 16.5007 44.4415 17.0188 44.6732 17.3642H46.8005C46.5899 16.8245 46.4635 16.0042 46.4635 15.2054V9.4415C46.4635 7.02368 44.8628 5.75 42.0825 5.75C39.9342 5.75 38.3545 6.69986 37.5331 7.84401L38.9443 9.20404C39.6393 8.27577 40.6292 7.73607 41.9562 7.73607C43.5569 7.73607 44.273 8.29735 44.273 9.18245C44.273 10.1107 43.599 10.6288 41.3664 10.6288C39.1549 10.6288 37.0486 11.5787 37.0486 14.0829C37.0486 16.3712 38.7126 17.5801 40.9241 17.5801ZM41.2822 15.702C40.0606 15.702 39.3023 15.0759 39.3023 14.0397C39.3023 12.9819 40.1027 12.3774 41.4717 12.3774C43.1988 12.3774 43.8728 12.2047 44.273 11.7298V12.9387C44.273 14.5362 43.1988 15.702 41.2822 15.702Z"
        fill="currentColor"
      />
      <path
        d="M52.5786 17.5801C55.5484 17.5801 57.1913 15.9826 57.1913 14.0397C57.1913 12.0536 55.9065 11.1685 53.5475 10.7152C51.6098 10.3698 50.5145 9.9812 50.5145 9.03134C50.5145 8.211 51.357 7.71449 52.6418 7.71449C53.8002 7.71449 54.6849 8.14624 55.3167 8.90181L56.8121 7.58496C55.9065 6.4624 54.4742 5.75 52.5997 5.75C50.1986 5.75 48.3451 7.08844 48.3451 9.05293C48.3451 11.1901 50.0933 12.0752 52.0942 12.4206C54.0319 12.7444 54.9376 13.1114 54.9376 14.0397C54.9376 14.9032 54.1372 15.5724 52.6208 15.5724C51.1675 15.5724 50.2828 14.9248 49.7563 13.9318L47.7975 14.9248C48.4925 16.5223 50.5145 17.5801 52.5786 17.5801Z"
        fill="currentColor"
      />
      <path
        d="M63.8371 17.5801C67.1228 17.5801 69.4818 15.0543 69.4818 11.665C69.4818 8.27577 67.1228 5.75 63.8371 5.75C60.5514 5.75 58.2134 8.27577 58.2134 11.665C58.2134 15.0543 60.5514 17.5801 63.8371 17.5801ZM63.8371 15.5724C61.8151 15.5724 60.4882 13.9749 60.4882 11.665C60.4882 9.35515 61.8151 7.75766 63.8371 7.75766C65.8801 7.75766 67.2071 9.35515 67.2071 11.665C67.2071 13.9749 65.8801 15.5724 63.8371 15.5724Z"
        fill="currentColor"
      />
      <path
        d="M77.0441 5.75C75.4855 5.75 74.3482 6.37605 73.6742 7.17479L73.2529 5.96588H71.2099V21.25H73.4846V15.9826C74.1586 16.954 75.3591 17.5801 77.0441 17.5801C80.3509 17.5801 82.3518 15.0111 82.3518 11.665C82.3518 8.31894 80.3509 5.75 77.0441 5.75ZM76.6861 15.5724C74.6009 15.5724 73.3161 13.9318 73.3161 11.665C73.3161 9.37674 74.6009 7.75766 76.6861 7.75766C78.7923 7.75766 80.0771 9.37674 80.0771 11.665C80.0771 13.9318 78.7923 15.5724 76.6861 15.5724Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const RemoteEquityLogo: React.FC<
  React.ComponentProps<"img"> & { variant?: "black" | "white" }
> = ({ variant = "white", ...props }) => {
  return (
    <img
      alt="Remote Equity"
      src={variant === "white" ? remoteEquityWhiteLogo : remoteEquityBlackLogo}
      {...props}
    />
  );
};

export const EasopXRemoteEquityLogo: React.FC<React.ComponentProps<"img">> = ({
  ...props
}) => {
  return (
    <img alt="Easop x Remote Equity" src={easopXRemoteEquityLogo} {...props} />
  );
};

export const Logo: React.FC<{
  className?: string;
  useEasopXRemoteEquityLogoInsteadOfEasopOnly?: boolean;
  variant?: "black" | "white";
}> = ({
  className: className_,
  useEasopXRemoteEquityLogoInsteadOfEasopOnly,
  variant = "white",
  ...props
}) => {
  const theme = useApplicationTheme();
  const className = classNames(LOGO_CLASS_NAME, className_);

  switch (theme) {
    case "easop":
    case null:
      return useEasopXRemoteEquityLogoInsteadOfEasopOnly ? (
        <EasopXRemoteEquityLogo className={className} {...props} />
      ) : (
        <EasopLogo className={className} variant={variant} {...props} />
      );
    case "remote equity":
      return (
        <RemoteEquityLogo className={className} variant={variant} {...props} />
      );
  }
};
