import React from "react";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { Button } from "../../../../components/ui/Button";
import { CenteredColumnLayout } from "../../../../components/ui/Layout/CenteredColumnLayout";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { Typography } from "../../../../components/ui/Typography";
import { useApplicationSupportEmailAddress } from "../../../../hooks/useApplicationTheme";
import { useQuery } from "../../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { SettingsContainer } from "../SettingsContainer";
import { OrganizationSettingsIntegrations_Organization$key } from "./__generated__/OrganizationSettingsIntegrations_Organization.graphql";
import { OrganizationSettingsIntegrations_Query } from "./__generated__/OrganizationSettingsIntegrations_Query.graphql";
import cartaLogoSrc from "./carta-logo.png";
import deelLogoSrc from "./deel-logo.png";

const ORGANIZATION_FRAGMENT = graphql`
  fragment OrganizationSettingsIntegrations_Organization on Organization {
    id
    name
    isConnectedToDeel
    isConnectedToCarta

    isOriginatingFromRemoteEquity
  }
`;

const IntegrationCard: React.FC<{
  connected: boolean;
  description: string;
  linkTo: string;
  logoSrc: string;
  name: string;
  type: string;
}> = ({ connected, description, linkTo, logoSrc, name, type }) => {
  return (
    <Link
      className="flex flex-col gap-4 rounded-lg border-[0.5px] border-gray-04 px-6 py-4 transition-all hover:shadow-100"
      to={linkTo}
    >
      <div className="flex items-center gap-2">
        <img
          alt={name}
          className="h-12 w-12 rounded-full object-fill"
          src={logoSrc}
        />
        <div>
          <Typography as="div" variant="Regular/Small">
            {name}
          </Typography>
          <Typography
            as="div"
            className="text-gray-07"
            variant="Regular/Caption"
          >
            {type}
          </Typography>
        </div>
      </div>
      <Typography as="div" variant="Regular/Extra Small">
        {description}
      </Typography>
      <div className="mt-auto">
        {connected ? (
          <NoticeMessage size="Small" variant="Positive">
            Connected to {name}
          </NoticeMessage>
        ) : (
          <Button size="small" variant="Primary Outline">
            Connect to {name}
          </Button>
        )}
      </div>
    </Link>
  );
};

const OrganizationSettingsIntegrationsPage_: React.FC<{
  organizationFragment: OrganizationSettingsIntegrations_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const mailtoSubject = encodeURIComponent(
    `[${organization.name}] integration request`,
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES.organizationHome, {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to=".">Integrations</BreadCrumb.Link>
        </BreadCrumb>
      }
    >
      <SettingsContainer title="Integrations">
        <SettingsContainer.Item title="Available integrations">
          <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
            <IntegrationCard
              connected={organization.isConnectedToCarta}
              description="Connect to Carta and synchronize your team equity compensation data."
              linkTo="carta"
              logoSrc={cartaLogoSrc}
              name="Carta"
              type="Equity management"
            />
            {!organization.isOriginatingFromRemoteEquity && (
              <>
                <IntegrationCard
                  connected={organization.isConnectedToDeel}
                  description="Connect to Deel and retrieve your employees and contractors data."
                  linkTo="deel"
                  logoSrc={deelLogoSrc}
                  name="Deel"
                  type="Payroll"
                />
              </>
            )}
          </div>
        </SettingsContainer.Item>
        <SettingsContainer.Item>
          <Typography className="text-gray-09" variant="Regular/Extra Small">
            Cannot find your favourite HR solution? Please{" "}
            <Link
              className="font-medium text-primary"
              to={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
            >
              let us know
            </Link>{" "}
            which one you’d like to find here.
          </Typography>
        </SettingsContainer.Item>
      </SettingsContainer>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query OrganizationSettingsIntegrations_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...OrganizationSettingsIntegrations_Organization
    }
  }
`;

const OrganizationSettingsIntegrationsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<OrganizationSettingsIntegrations_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Settings"
      analyticsName="Admin - Integrations Settings"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} integrations settings`}
    >
      <OrganizationSettingsIntegrationsPage_
        organizationFragment={query.organization}
      />
    </Page>
  );
};

export default OrganizationSettingsIntegrationsPage;
