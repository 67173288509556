/**
 * @generated SignedSource<<6567eb72dae63e69bd9249d5afdddd62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewGrantee_Query$variables = {
  organizationId: string;
};
export type NewGrantee_Query$data = {
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"NewGrantee_Organization">;
  } | null;
};
export type NewGrantee_Query = {
  response: NewGrantee_Query$data;
  variables: NewGrantee_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hRISProvider",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewGrantee_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewGrantee_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewGrantee_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOriginatingFromRemoteEquity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "companies",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completeName",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityGrid",
            "kind": "LinkedField",
            "name": "equityGrid",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "activated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityGridLevel",
                "kind": "LinkedField",
                "name": "levels",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HRISProviderEmployee",
            "kind": "LinkedField",
            "name": "HRISProviderEmployeesSuggestedAsNewGrantee",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hRISProviderEmployeeId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workRelationship",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jobTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "taxResidenceCountry",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "USState",
                "kind": "LinkedField",
                "name": "USStateOfResidence",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EmployerOfRecord",
            "kind": "LinkedField",
            "name": "employersOfRecord",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToDeel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToRemoteEquity",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "524f5a2779ca21483eef64d4e7d9c8c1",
    "id": null,
    "metadata": {},
    "name": "NewGrantee_Query",
    "operationKind": "query",
    "text": "query NewGrantee_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...NewGrantee_Organization\n  }\n}\n\nfragment ConnectHRISProviderMessage_Organization on Organization {\n  isConnectedToDeel\n  ...ConnectToDeelButton_Organization\n}\n\nfragment ConnectHRISProvidersMessage_Organization on Organization {\n  isConnectedToDeel\n  isConnectedToRemoteEquity\n  id\n}\n\nfragment ConnectToDeelButton_Organization on Organization {\n  id\n}\n\nfragment GranteeForm_HRISProviderEmployee on HRISProviderEmployee {\n  id\n  hRISProvider\n  hRISProviderEmployeeId\n  email\n  name\n  workRelationship\n  jobTitle\n  taxResidenceCountry {\n    code\n  }\n  USStateOfResidence {\n    code\n  }\n  startDate\n}\n\nfragment GranteeForm_Organization on Organization {\n  id\n  companies {\n    name\n    completeName\n    ...SelectCompanies_Companies\n    id\n  }\n  equityGrid {\n    activated\n    levels {\n      id\n      name\n    }\n    id\n  }\n  HRISProviderEmployeesSuggestedAsNewGrantee {\n    ...GranteeForm_HRISProviderEmployee\n    id\n  }\n  employersOfRecord {\n    id\n    name\n    hRISProvider\n  }\n  ...ConnectHRISProviderMessage_Organization\n}\n\nfragment NewGrantee_Organization on Organization {\n  id\n  name\n  isOriginatingFromRemoteEquity\n  ...GranteeForm_Organization\n  ...ConnectHRISProvidersMessage_Organization\n}\n\nfragment SelectCompanies_Companies on Company {\n  id\n  completeName\n}\n"
  }
};
})();

(node as any).hash = "76d3ebf8f468323db4c1ba3047869d89";

export default node;
