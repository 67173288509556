import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Avatar, Button, Text } from "@remote-com/norma";
import { IconV2OutlineArrowCornerCw } from "@remote-com/norma/icons/IconV2OutlineArrowCornerCw";
import { AnimatePresence, motion } from "motion/react";
import React, { PropsWithChildren, useCallback, useMemo, useRef } from "react";
import { graphql, useFragment } from "react-relay";
import { Link } from "react-router-dom";
import { useScroll } from "react-use";

import { FormattedCurrencyCompact } from "../../components/Formatted/FormattedCurrencyCompact";
import { FormattedNumber } from "../../components/Formatted/FormattedNumber";
import { RangeInput } from "../../components/RangeInput";
import { ApplicationSideBar } from "../../components/ui/ApplicationSideBar/ApplicationSideBar";
import { Logo } from "../../components/ui/Logo/Logo";
import { ShiftlessContainer } from "../../components/ui/ShiftlessContainer";
import { useAuthenticatedAccount } from "../../services/AuthenticatedGuard";
import CONFIGURATION from "../../services/configuration";
import { EmployeePortalLayout_Grantee$key } from "./__generated__/EmployeePortalLayout_Grantee.graphql";
import { EmployeePortalLayout_Viewer$key } from "./__generated__/EmployeePortalLayout_Viewer.graphql";
import { Equity101DrawerRemote } from "./Equity101Drawer/Equity101Drawer";
import { EmployeePortalContext } from "./useEmployeePortalContext";
import {
  generateEmployeePortalSectionAnchorPath,
  scrollToEmployeePortalSection,
  Section,
  SectionLabel,
  useSections,
} from "./useSections";

function TopBar({
  organizationValuation,
  setValuationMultiple,
  showBackToRemoteButton,
  showValuationMultipleInput,
  valuationMultiple,
}: {
  organizationValuation: null | number;
  setValuationMultiple: (valuationMultiple: number) => void;
  showBackToRemoteButton: boolean;
  showValuationMultipleInput?: boolean;
  valuationMultiple: number;
}) {
  const authenticatedAccount = useAuthenticatedAccount();
  const fullName = `${authenticatedAccount.firstName} ${authenticatedAccount.lastName}`;

  return (
    <div className="relative">
      <div className="relative z-20 border-b-[0.5px] border-remote-grey-200 bg-white">
        <div className="mx-auto grid grid-cols-[auto_1fr_auto] items-center gap-4 px-8 py-5.5">
          <Logo className="block h-5.5" variant="black" />
          <AnimatePresence>
            {showValuationMultipleInput && (
              <motion.div
                animate={{ opacity: 1, y: 0 }}
                className="hidden items-center justify-center gap-6 md:flex"
                exit={{ opacity: 0, y: -80 }}
                initial={{ opacity: 0, y: -80 }}
              >
                <div className="w-full max-w-[495px]">
                  <RangeInput
                    hideScale
                    max={10}
                    min={1}
                    onChange={setValuationMultiple}
                    renderStepLabel={({ step }) => `${step}x`}
                    step={1}
                    tone="purple"
                    value={valuationMultiple}
                  />
                </div>
                <Text className="text-purple-700" variant="baseMedium">
                  <ShiftlessContainer largestChildren={<>x10</>}>
                    x
                    <FormattedNumber
                      animated
                      maximumFractionDigits={0}
                      value={valuationMultiple}
                    />
                  </ShiftlessContainer>
                </Text>
                {typeof organizationValuation === "number" && (
                  <div className="rounded-remote-2xs bg-purple-700 px-1 py-0.5 text-white">
                    <Text variant="baseMedium">
                      <ShiftlessContainer
                        largestChildren={
                          <FormattedCurrencyCompact
                            animated
                            value={organizationValuation * 10}
                          />
                        }
                      >
                        <FormattedCurrencyCompact
                          animated
                          value={organizationValuation * valuationMultiple}
                        />
                      </ShiftlessContainer>
                    </Text>
                  </div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
          <div className="col-start-3 flex items-center gap-4">
            {showBackToRemoteButton && (
              <Button
                as={Link}
                IconBefore={IconV2OutlineArrowCornerCw}
                size="sm"
                to={CONFIGURATION.REMOTE_APP_URL}
                variant="outline"
              >
                Back to Remote
              </Button>
            )}
            <Avatar name={fullName} size="sm" />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {showValuationMultipleInput && (
          <motion.div
            animate={{ y: 0 }}
            className="relative z-10 flex transform-gpu items-center justify-center gap-2 border-b-[0.5px] border-remote-grey-200 bg-background-subtle px-6 py-4 md:hidden"
            exit={{ y: "-100%" }}
            initial={{ y: "-100%" }}
            transition={{ damping: 0 }}
          >
            <div className="w-full">
              <RangeInput
                hideScale
                max={10}
                min={1}
                onChange={setValuationMultiple}
                renderStepLabel={({ step }) => `${step}x`}
                step={1}
                tone="purple"
                value={valuationMultiple}
              />
            </div>
            <Text className="text-purple-700" variant="baseMedium">
              <ShiftlessContainer largestChildren={<>x10</>}>
                x
                <FormattedNumber
                  animated
                  maximumFractionDigits={0}
                  value={valuationMultiple}
                />
              </ShiftlessContainer>
            </Text>
            {typeof organizationValuation === "number" && (
              <div className="rounded-remote-2xs bg-purple-700 px-1 py-0.5 text-white">
                <Text variant="baseMedium">
                  <ShiftlessContainer
                    largestChildren={
                      <FormattedCurrencyCompact
                        animated
                        value={organizationValuation * 10}
                      />
                    }
                  >
                    <FormattedCurrencyCompact
                      animated
                      value={organizationValuation * valuationMultiple}
                    />
                  </ShiftlessContainer>
                </Text>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

const GRANTEE_FRAGMENT = graphql`
  fragment EmployeePortalLayout_Grantee on Grantee {
    id
    organization {
      granteePortalSettings {
        displayFullyDilutedValues
      }
      latestValuation
      ...ApplicationSideBar_Organization
    }
    hRISProviderEmployee {
      hRISProvider
    }
    ...useSections_Grantee
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment EmployeePortalLayout_Viewer on Account {
    ...ApplicationSideBar_Viewer
  }
`;

function getSectionIndex({
  sections,
  targetSectionLabel,
}: {
  sections: Section[];
  targetSectionLabel: SectionLabel;
}) {
  return sections.findIndex(({ label }) => label === targetSectionLabel);
}

function sectionIsPastOwnershipSection({
  sections,
  targetSectionLabel,
}: {
  sections: Section[];
  targetSectionLabel: SectionLabel;
}) {
  return (
    getSectionIndex({ sections, targetSectionLabel }) >
    getSectionIndex({ sections, targetSectionLabel: "Ownership" })
  );
}

export const EmployeePortalLayout: React.FC<
  PropsWithChildren<{
    granteeFragment: EmployeePortalLayout_Grantee$key;
    viewerFragment: EmployeePortalLayout_Viewer$key;
  }>
> = ({ children, granteeFragment, viewerFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const containerScrollRef = useRef<HTMLDivElement>(null);
  const sections = useSections({ granteeFragment: grantee });
  const [activeSection, setActiveSection] = React.useState<SectionLabel>(
    sections[0].label,
  );
  const containerScroll = useScroll(containerScrollRef);
  const [valuationMultiple, setValuationMultiple] = React.useState(1);

  const context: EmployeePortalContext = useMemo(
    () => ({
      containerHeight: containerScrollRef.current?.clientHeight ?? 0,
      containerScrollY: containerScroll.y,
      setActiveSection,
      setValuationMultiple,
      valuationMultiple,
    }),
    [containerScroll.y, valuationMultiple],
  );

  const equity101DrawerRemoteController = Equity101DrawerRemote.useController();

  const handleEquity101ButtonClick = useCallback(() => {
    equity101DrawerRemoteController.open({
      data: {
        granteeId: grantee.id,
      },
    });
  }, [equity101DrawerRemoteController, grantee.id]);

  const displayFullyDilutedValues =
    grantee.organization.granteePortalSettings.displayFullyDilutedValues;

  return (
    <div className="grid min-h-full grid-rows-[auto_1fr] font-remote-body">
      <div className="sticky top-0 z-10">
        <TopBar
          organizationValuation={grantee.organization.latestValuation}
          setValuationMultiple={setValuationMultiple}
          showBackToRemoteButton={
            grantee.hRISProviderEmployee?.hRISProvider === "REMOTE"
          }
          showValuationMultipleInput={
            displayFullyDilutedValues &&
            sectionIsPastOwnershipSection({
              sections,
              targetSectionLabel: activeSection,
            })
          }
          valuationMultiple={valuationMultiple}
        />
      </div>
      <div className="fixed flex h-screen w-full flex-col bg-white pt-20 md:flex-row">
        <div className="hidden flex-shrink-0 md:block">
          <ApplicationSideBar
            activeRole="Grantee"
            className="flex-shrink-0"
            organizationFragment={grantee.organization}
            showSideBarHeader={false}
            viewerFragment={viewer}
          >
            <ApplicationSideBar.Section title="Equity">
              {sections.map(({ anchor, icon, label }) => (
                <ApplicationSideBar.NavAnchorLink
                  active={activeSection === label}
                  href={generateEmployeePortalSectionAnchorPath({
                    anchor,
                    granteeId: grantee.id,
                  })}
                  icon={icon}
                  key={label}
                  layoutId="grantee-portal-sidebar"
                  onClick={(event) => {
                    event.preventDefault();

                    scrollToEmployeePortalSection(anchor);
                  }}
                >
                  {label}
                </ApplicationSideBar.NavAnchorLink>
              ))}
            </ApplicationSideBar.Section>
            <ApplicationSideBar.Section title="Tools">
              <ApplicationSideBar.NavButton
                icon={<QuestionMarkCircleIcon />}
                onClick={handleEquity101ButtonClick}
              >
                Equity 101
              </ApplicationSideBar.NavButton>
            </ApplicationSideBar.Section>
          </ApplicationSideBar>
        </div>
        <div
          className="h-full flex-auto scroll-py-6 overflow-auto overflow-x-hidden scroll-smooth bg-white md:overflow-x-auto md:px-6 md:py-6"
          ref={containerScrollRef}
        >
          <EmployeePortalContext.Provider value={context}>
            {children}
          </EmployeePortalContext.Provider>
        </div>
      </div>
    </div>
  );
};
