import {
  EllipsisVerticalIcon,
  EnvelopeOpenIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import { compact, isEmpty, sortBy } from "lodash";
import { useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { useResendGranteeInvitationEmail } from "../hooks/useResendGranteeInvitationEmail";
import { APPLICATION_ROUTES } from "../paths";
import { PreviewGranteesPortalModal_Grantees$key } from "./__generated__/PreviewGranteesPortalModal_Grantees.graphql";
import { PreviewGranteesPortalModal_PreviewGranteeRow_Grantees$key } from "./__generated__/PreviewGranteesPortalModal_PreviewGranteeRow_Grantees.graphql";
import {
  InviteGranteesModal,
  useInviteGranteesModalState,
} from "./InviteGranteesModal";
import { PortalAccessTag } from "./PortalAccessTag";
import {
  RevokeGranteeAccessModal,
  useRevokeGranteeAccessModalState,
} from "./RevokeGranteeAccessModal";
import { Button, LinkButton } from "./ui/Button";
import { MenuButton } from "./ui/MenuButton";
import { Modal } from "./ui/Modal";
import { SearchBar } from "./ui/SearchBar";
import { Typography } from "./ui/Typography";

const GRANTEES_FRAGMENT = graphql`
  fragment PreviewGranteesPortalModal_Grantees on Grantee @relay(plural: true) {
    id
    name
    email
    ...PreviewGranteesPortalModal_PreviewGranteeRow_Grantees
    ...RevokeGranteeAccessModal_Grantee
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment PreviewGranteesPortalModal_PreviewGranteeRow_Grantees on Grantee {
    id
    name
    hasPortalAccess
    cannotBeInvitedReason
    hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal
    ...PortalAccessTag_Grantee
  }
`;

const PreviewGranteeRow: React.FC<{
  granteeFragment: PreviewGranteesPortalModal_PreviewGranteeRow_Grantees$key;
  onInviteButtonClick: () => void;
  onRevokeButtonClick: () => void;
}> = ({ granteeFragment, onInviteButtonClick, onRevokeButtonClick }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const { resendInvitationEmail } = useResendGranteeInvitationEmail();

  const menuButtonItems = useMemo(
    () =>
      compact([
        grantee.hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal ? (
          <MenuButton.Item
            key="resend-invitation"
            leftIcon={<EnvelopeOpenIcon />}
            onClick={() => resendInvitationEmail(grantee)}
          >
            Resend invitation
          </MenuButton.Item>
        ) : null,
        grantee.hasPortalAccess ? (
          <MenuButton.Item
            key="revoke-access"
            leftIcon={<LockClosedIcon />}
            onClick={onRevokeButtonClick}
          >
            Revoke access to portal
          </MenuButton.Item>
        ) : null,
        !grantee.cannotBeInvitedReason ? (
          <MenuButton.Item
            key="invite"
            leftIcon={<EnvelopeOpenIcon />}
            onClick={onInviteButtonClick}
          >
            Invite grantee to portal
          </MenuButton.Item>
        ) : null,
      ]),
    [grantee, onInviteButtonClick, onRevokeButtonClick, resendInvitationEmail],
  );

  return (
    <div className="flex items-center gap-4 p-4">
      <PortalAccessTag.WithToolTip granteeFragment={grantee} />
      <Typography className="flex-grow" variant="Regular/Small">
        {grantee.name}
      </Typography>
      <LinkButton
        size="extra small"
        target="_blank"
        to={generatePath(APPLICATION_ROUTES.employeePortal, {
          granteeId: grantee.id,
        })}
        variant="Primary Outline"
      >
        Preview
      </LinkButton>
      {!isEmpty(menuButtonItems) && (
        <MenuButton
          button={
            <Button
              leftIcon={<EllipsisVerticalIcon />}
              size="extra small"
              variant="Secondary Full"
            />
          }
          menuClassName="z-10"
          placement="bottom end"
        >
          {menuButtonItems}
        </MenuButton>
      )}
    </div>
  );
};

export const PreviewGranteesPortalModal: React.FC<{
  granteesFragment: PreviewGranteesPortalModal_Grantees$key;
  onClose: () => void;
  onDraftNewGrantButtonClick: (granteeId: string) => void;
  onGranteeCreated: (granteeId: string) => void;
  onGranteeDeleted: (granteeId: string) => void;
  onGranteesInvited: (granteeIds: string[]) => void;
  onGranteeUpdated: (granteeId: string) => void;
  organizationId: string;
  show: boolean;
}> = ({
  granteesFragment,
  onClose,
  onDraftNewGrantButtonClick,
  onGranteeCreated,
  onGranteeDeleted,
  onGranteesInvited,
  onGranteeUpdated,
  organizationId,
  show,
}) => {
  const _grantees = useFragment(GRANTEES_FRAGMENT, granteesFragment);

  const [fullTextFilter, setFullTextFilter] = useState<string>("");

  const sortedGrantees = useMemo(
    () => sortBy([..._grantees], (grantee) => grantee.name),
    [_grantees],
  );

  const grantees = useMemo(() => {
    if (isEmpty(fullTextFilter)) return sortedGrantees;

    const fullTextFilterLowerCase = fullTextFilter.toLocaleLowerCase();

    return sortedGrantees.filter(
      (grantee) =>
        grantee.name.toLowerCase().includes(fullTextFilterLowerCase) ||
        grantee.email.toLowerCase().includes(fullTextFilterLowerCase),
    );
  }, [sortedGrantees, fullTextFilter]);

  const {
    hideRevokeGranteeAccessModal,
    revokeGranteeAccessModalState,
    showRevokeGranteeAccessModal,
  } = useRevokeGranteeAccessModalState();

  const {
    hideInviteGranteeModal,
    inviteGranteeModalState,
    showInviteGranteeModal,
  } = useInviteGranteesModalState();

  return (
    <>
      <RevokeGranteeAccessModal
        onClose={hideRevokeGranteeAccessModal}
        state={revokeGranteeAccessModalState}
      />
      <InviteGranteesModal
        onClose={hideInviteGranteeModal}
        onDraftNewGrantButtonClick={(granteeId) => {
          hideInviteGranteeModal();
          onDraftNewGrantButtonClick(granteeId);
        }}
        onGranteeCreated={onGranteeCreated}
        onGranteeDeleted={onGranteeDeleted}
        onGranteesInvited={onGranteesInvited}
        onGranteeUpdated={onGranteeUpdated}
        organizationId={organizationId}
        state={inviteGranteeModalState}
      />
      <Modal onClose={onClose} show={show} suspense width={680}>
        <Modal.Content onClose={onClose} title="Preview grantee's portals">
          <SearchBar
            onChange={setFullTextFilter}
            placeholder="Search grantees..."
            value={fullTextFilter}
          />
          <div className="divide-y-[0.5px] divide-gray-04">
            {grantees.map((grantee) => (
              <PreviewGranteeRow
                granteeFragment={grantee}
                key={grantee.id}
                onInviteButtonClick={() => {
                  showInviteGranteeModal(grantee.id);
                }}
                onRevokeButtonClick={() => {
                  showRevokeGranteeAccessModal(grantee);
                }}
              />
            ))}
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};
