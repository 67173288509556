import { FormattedNumber } from "./FormattedNumber";

export const FormattedCurrencyCompact: React.FC<{
  animated?: boolean;
  currency?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  value: number;
}> = ({
  animated,
  currency = "USD",
  maximumFractionDigits = 1,
  minimumFractionDigits = 1,
  value,
}) => (
  <FormattedNumber
    animated={animated}
    compactDisplay="short"
    currency={currency}
    maximumFractionDigits={maximumFractionDigits}
    minimumFractionDigits={minimumFractionDigits}
    notation="compact"
    style="currency"
    value={value}
  />
);
