/**
 * @generated SignedSource<<f10cf3d7f55925e72a92d8d3b05df556>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CannotBeInvitedReason = "ALREADY_INVITED" | "MISSING_INFORMATION" | "NO_GRANTS" | "REMOTE_EMPLOYEE" | "SETTLED";
export type InviteGranteesModal_GrantAccessToGranteePortal_Mutation$variables = {
  granteeIds: ReadonlyArray<string>;
  organizationId: string;
};
export type InviteGranteesModal_GrantAccessToGranteePortal_Mutation$data = {
  readonly grantAccessToGranteePortal: ReadonlyArray<{
    readonly __typename: "Grantee";
    readonly cannotBeInvitedReason: CannotBeInvitedReason | null;
    readonly hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal: boolean;
    readonly hasPortalAccess: boolean;
    readonly id: string;
  }>;
};
export type InviteGranteesModal_GrantAccessToGranteePortal_Mutation = {
  response: InviteGranteesModal_GrantAccessToGranteePortal_Mutation$data;
  variables: InviteGranteesModal_GrantAccessToGranteePortal_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "granteeIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "granteeIds",
        "variableName": "granteeIds"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      },
      {
        "kind": "Literal",
        "name": "source",
        "value": "Remote Equity Web App"
      }
    ],
    "concreteType": "Grantee",
    "kind": "LinkedField",
    "name": "grantAccessToGranteePortal",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasPortalAccess",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cannotBeInvitedReason",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteGranteesModal_GrantAccessToGranteePortal_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InviteGranteesModal_GrantAccessToGranteePortal_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "35c7ded8bb8f22972a9d148cfb73ddb1",
    "id": null,
    "metadata": {},
    "name": "InviteGranteesModal_GrantAccessToGranteePortal_Mutation",
    "operationKind": "mutation",
    "text": "mutation InviteGranteesModal_GrantAccessToGranteePortal_Mutation(\n  $organizationId: OrganizationId!\n  $granteeIds: [GranteeId!]!\n) {\n  grantAccessToGranteePortal(organizationId: $organizationId, granteeIds: $granteeIds, source: \"Remote Equity Web App\") {\n    __typename\n    id\n    hasPortalAccess\n    cannotBeInvitedReason\n    hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal\n  }\n}\n"
  }
};
})();

(node as any).hash = "c628a8a5433a615cdbfc03f270a26bff";

export default node;
