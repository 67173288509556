import useIsFeatureEnabled from "../hooks/useIsFeatureEnabled";

export default function FeatureFlagRouter({
  featureFlag,
  ifDisabled,
  ifEnabled,
}: {
  featureFlag: string;
  ifDisabled: React.ReactNode;
  ifEnabled: React.ReactNode;
}) {
  const featureIsEnabled = useIsFeatureEnabled(featureFlag);

  return featureIsEnabled ? <>{ifEnabled}</> : <>{ifDisabled}</>;
}
