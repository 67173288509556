import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import Fuse from "fuse.js";
import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { APPLICATION_ROUTES } from "../paths";
import {
  ExerciseRequestsListSlideOver_ExerciseRequests$data,
  ExerciseRequestsListSlideOver_ExerciseRequests$key,
} from "./__generated__/ExerciseRequestsListSlideOver_ExerciseRequests.graphql";
import {
  ExerciseRequestsListSlideOver_Organization$data,
  ExerciseRequestsListSlideOver_Organization$key,
} from "./__generated__/ExerciseRequestsListSlideOver_Organization.graphql";
import { NoticeMessage } from "./ui/NoticeMessage";
import { SearchBar } from "./ui/SearchBar";
import { SlideOver } from "./ui/SlideOver";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExerciseRequestsListSlideOver_Organization on Organization {
    id
  }
`;

const EXERCISE_REQUESTS_FRAGMENT = graphql`
  fragment ExerciseRequestsListSlideOver_ExerciseRequests on CTMSExerciseRequest
  @relay(plural: true) {
    quantityExercised
    ctmsGrant {
      id
      label
      grantee {
        id
        name
        email
      }
    }
  }
`;

type State =
  | {
      exerciseRequestsFragment: ExerciseRequestsListSlideOver_ExerciseRequests$key;
      show: true;
      title: React.ReactNode;
    }
  | {
      exerciseRequestsFragment: ExerciseRequestsListSlideOver_ExerciseRequests$key | null;
      show: false;
      title: null | React.ReactNode;
    };

export const useExerciseRequestsListSlideOverState = () => {
  const [state, setState] = useState<State>({
    exerciseRequestsFragment: null,
    show: false,
    title: null,
  });

  const open = ({
    exerciseRequestsFragment,
    title,
  }: {
    exerciseRequestsFragment: ExerciseRequestsListSlideOver_ExerciseRequests$key;
    title: React.ReactNode;
  }) => {
    setState({
      exerciseRequestsFragment,
      show: true,
      title,
    });
  };

  const close = () => {
    setState((previousState) => ({
      ...previousState,
      show: false,
    }));
  };

  return {
    close,
    open,
    state,
  };
};

const GrantsList: React.FC<{
  exerciseRequests: ExerciseRequestsListSlideOver_ExerciseRequests$data;
  organization: ExerciseRequestsListSlideOver_Organization$data;
}> = ({ exerciseRequests, organization }) => {
  if (isEmpty(exerciseRequests)) {
    return (
      <NoticeMessage size="Small">
        No grantees match your current filter.
      </NoticeMessage>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        {exerciseRequests.map((exerciseRequest) => (
          <Link
            key={exerciseRequest.ctmsGrant.label}
            to={generatePath(APPLICATION_ROUTES.organizationEquityCtmsGrant, {
              ctmsGrantId: exerciseRequest.ctmsGrant.id,
              organizationId: organization.id,
            })}
          >
            <div className="group flex cursor-pointer items-center rounded border-[0.5px] border-transparent p-2 transition-all hover:border-gray-04">
              <Tag color="gray">{exerciseRequest.ctmsGrant.label}</Tag>
              <div className="flex-grow space-y-1 px-4 py-2">
                <Typography as="div" variant="Regular/Extra Small">
                  {exerciseRequest.ctmsGrant.grantee.name}
                </Typography>
                <Typography
                  as="div"
                  className="text-black-05"
                  variant="Regular/Caption"
                >
                  {exerciseRequest.ctmsGrant.grantee.email}
                </Typography>
              </div>
              <div className="flex shrink-0 items-center">
                <Tag color="gray">
                  <FormattedMessage
                    defaultMessage="{shares, plural, one {# share} other {# shares}}"
                    values={{ shares: exerciseRequest.quantityExercised }}
                  />
                </Tag>
                <div className="px-4">
                  <ArrowTopRightOnSquareIcon className="h-5 w-5 text-primary opacity-0 transition-all group-hover:opacity-100" />
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

const SlideOverContent: React.FC<{
  exerciseRequestsFragment: ExerciseRequestsListSlideOver_ExerciseRequests$key;
  organization: ExerciseRequestsListSlideOver_Organization$data;
}> = ({ exerciseRequestsFragment, organization }) => {
  const exerciseRequests = useFragment(
    EXERCISE_REQUESTS_FRAGMENT,
    exerciseRequestsFragment,
  );
  const [fullTextFilter, setFullTextFilter] = useState<string>("");
  const filteredExerciseRequests = useMemo(() => {
    const fuse = new Fuse(exerciseRequests, {
      keys: [
        "ctmsGrant.label",
        "ctmsGrant.grantee.name",
        "ctmsGrant.grantee.email",
      ],
    });

    if (isEmpty(fullTextFilter)) {
      return exerciseRequests;
    }

    return fuse.search(fullTextFilter).map((result) => result.item);
  }, [exerciseRequests, fullTextFilter]);

  return (
    <>
      <SearchBar
        onChange={setFullTextFilter}
        placeholder="Search grantees..."
        value={fullTextFilter}
      />
      <GrantsList
        exerciseRequests={filteredExerciseRequests}
        organization={organization}
      />
    </>
  );
};

export const ExerciseRequestsListSlideOver: React.FC<{
  onClose: () => void;
  organizationFragment: ExerciseRequestsListSlideOver_Organization$key;
  state: State;
}> = ({ onClose, organizationFragment, state }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <SlideOver
      header={<SlideOver.Header onClose={onClose} padding={6} />}
      onClose={onClose}
      show={state.show}
      width="2xl"
    >
      <div className="space-y-6 p-6">
        <Typography
          as="div"
          className="w-full text-center"
          variant="Medium/Small"
        >
          {state.title}
        </Typography>
        {state.show && (
          <SlideOverContent
            exerciseRequestsFragment={state.exerciseRequestsFragment}
            organization={organization}
          />
        )}
      </div>
    </SlideOver>
  );
};
