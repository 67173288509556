import classNames from "classnames";
import React from "react";

export function Article({
  children,
  title,
}: React.PropsWithChildren<{
  title?: React.ReactNode;
}>) {
  return (
    <article className="space-y-4">
      {title && <Title>{title}</Title>}
      {children}
    </article>
  );
}

const ASIDE_TONES = {
  blue: classNames("bg-brand-50"),
  orange: classNames("bg-orange-50"),
};

export function Aside({
  children,
  title,
  tone = "blue",
}: React.PropsWithChildren<{
  title: React.ReactNode;
  tone?: keyof typeof ASIDE_TONES;
}>) {
  return (
    <aside
      className={classNames("rounded-remote-md px-6 py-4", ASIDE_TONES[tone])}
    >
      <h3 className="text-SM/Medium">{title}</h3>
      <Paragraph>{children}</Paragraph>
    </aside>
  );
}

export function Paragraph({ children }: React.PropsWithChildren) {
  return <p className="text-SM text-grey-600">{children}</p>;
}

export function Title({ children }: React.PropsWithChildren) {
  return (
    <h2 className="font-brand text-XL/Medium text-grey-900">{children}</h2>
  );
}
