/**
 * @generated SignedSource<<04071e28559dc89f5644af7cb4990472>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalWhatDoYouHaveSection_Grantee$data = {
  readonly ctmsGrants: ReadonlyArray<{
    readonly exercisePrice: number | null;
    readonly quantityIssued: number;
  }>;
  readonly ctmsGrantsCount: number;
  readonly organization: {
    readonly granteePortalSettings: {
      readonly displayFullyDilutedValues: boolean;
    };
    readonly latestValuation: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"useCtmsGrantAndSharesToNetEquityValue_Organization">;
  };
  readonly totalGrantedSharesBreakdown: {
    readonly total: number;
  };
  readonly " $fragmentType": "EmployeePortalWhatDoYouHaveSection_Grantee";
};
export type EmployeePortalWhatDoYouHaveSection_Grantee$key = {
  readonly " $data"?: EmployeePortalWhatDoYouHaveSection_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalWhatDoYouHaveSection_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalWhatDoYouHaveSection_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctmsGrantsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "totalGrantedSharesBreakdown",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "grantStatusIn",
          "value": [
            "Active",
            "Terminated"
          ]
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "ASC",
            "field": "vestingStartDate"
          }
        }
      ],
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantityIssued",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "exercisePrice",
          "storageKey": null
        }
      ],
      "storageKey": "ctmsGrants(grantStatusIn:[\"Active\",\"Terminated\"],orderBy:{\"direction\":\"ASC\",\"field\":\"vestingStartDate\"})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestValuation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteePortalSettings",
          "kind": "LinkedField",
          "name": "granteePortalSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayFullyDilutedValues",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useCtmsGrantAndSharesToNetEquityValue_Organization"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "ea6787898021e71fd8c7f157414a995b";

export default node;
