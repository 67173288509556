import classNames from "classnames";
import { AnimatePresence, motion } from "motion/react";

export function SkeletonWrapper({
  children,
  reveal,
}: React.PropsWithChildren<{
  reveal?: boolean;
}>) {
  return (
    <span className="relative">
      <AnimatePresence>
        {!reveal && (
          <motion.div
            animate={{
              opacity: 1,
            }}
            className="absolute inset-0 animate-pulse rounded bg-grey-300"
            exit={{
              opacity: 0,
            }}
            initial={{
              opacity: 0,
            }}
          />
        )}
      </AnimatePresence>
      <span
        className={classNames("transition-all", {
          "pointer-events-none select-none opacity-0": !reveal,
        })}
      >
        {children}
      </span>
    </span>
  );
}
