/**
 * @generated SignedSource<<34d769b0babccd844b569a3d6fafc56e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EmployeeTableRow_RevokeAccessToEmployeePortal_Mutation$variables = {
  employeeId: string;
};
export type EmployeeTableRow_RevokeAccessToEmployeePortal_Mutation$data = {
  readonly revokeAccessToGranteePortal: {
    readonly hasPortalAccess: boolean;
    readonly hasVisitedTheirPortal: boolean;
    readonly id: string;
  };
};
export type EmployeeTableRow_RevokeAccessToEmployeePortal_Mutation = {
  response: EmployeeTableRow_RevokeAccessToEmployeePortal_Mutation$data;
  variables: EmployeeTableRow_RevokeAccessToEmployeePortal_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "employeeId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "granteeId",
        "variableName": "employeeId"
      }
    ],
    "concreteType": "Grantee",
    "kind": "LinkedField",
    "name": "revokeAccessToGranteePortal",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasPortalAccess",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasVisitedTheirPortal",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmployeeTableRow_RevokeAccessToEmployeePortal_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmployeeTableRow_RevokeAccessToEmployeePortal_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b80a3a01f86a061a7c7da112916ba1d2",
    "id": null,
    "metadata": {},
    "name": "EmployeeTableRow_RevokeAccessToEmployeePortal_Mutation",
    "operationKind": "mutation",
    "text": "mutation EmployeeTableRow_RevokeAccessToEmployeePortal_Mutation(\n  $employeeId: GranteeId!\n) {\n  revokeAccessToGranteePortal(granteeId: $employeeId) {\n    id\n    hasPortalAccess\n    hasVisitedTheirPortal\n  }\n}\n"
  }
};
})();

(node as any).hash = "a9fc60f03f52a9febc897eb0b7b4ba18";

export default node;
