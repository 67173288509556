/**
 * @generated SignedSource<<dbd47815a8628bfa7e617948ada75e84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalWelcomeSection_Grantee$data = {
  readonly email: string;
  readonly id: string;
  readonly jobTitle: string | null;
  readonly name: string;
  readonly organization: {
    readonly name: string;
  };
  readonly taxResidenceCountry: {
    readonly emoji: string;
    readonly name: string;
  } | null;
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentType": "EmployeePortalWelcomeSection_Grantee";
};
export type EmployeePortalWelcomeSection_Grantee$key = {
  readonly " $data"?: EmployeePortalWelcomeSection_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalWelcomeSection_Grantee">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalWelcomeSection_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "965fdcfc963f1ffd84c12a18cbb03df2";

export default node;
