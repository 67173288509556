import { Button } from "@remote-com/norma";
import classNames from "classnames";
import { AnimatePresence, motion } from "motion/react";
import { PropsWithChildren, useCallback, useState } from "react";

import { SlideOver } from "../../../components/ui/SlideOver";
import { makeRemoteController } from "../../../helpers/makeRemoteController";
import { scrollToEmployeePortalSection, SectionAnchor } from "../useSections";
import { Exercise } from "./Exercise";
import { FAQ } from "./FAQ";
import { FundraisingEvents } from "./FundraisingEvents";
import { LiquidityEvents } from "./LiquidityEvents";
import { StockOptions } from "./StockOptions";
import { VestingSchedule } from "./VestingSchedule";

function NavigationButton({
  active,
  children,
  onClick,
}: PropsWithChildren<{
  active?: boolean;
  onClick?: () => void;
}>) {
  return (
    <div
      className={classNames(
        "relative text-SM transition-all hover:text-SM/Medium",
      )}
    >
      <button
        className={classNames(
          "flex min-h-9 w-full items-center rounded-remote-sm px-2.5 text-left transition-all",
          {
            "text-SM/Medium": active,
            "text-SM hover:bg-grey-100 active:bg-grey-200": !active,
          },
        )}
        onClick={onClick}
      >
        <span className="invisible">{children}</span>
      </button>
      {active && (
        <motion.div
          className="pointer-events-none absolute inset-0 rounded-remote-sm bg-brand-50"
          layoutId="equity-101-navigation-button"
        />
      )}
      <div
        className={classNames(
          "pointer-events-none absolute inset-0 z-10 flex items-center rounded-remote-sm px-2.5 text-left transition-all",
          {
            "text-SM/Medium text-primary-700": active,
          },
        )}
      >
        {children}
      </div>
    </div>
  );
}

const SECTIONS = [
  "Stock options",
  "Vesting schedule",
  "Exercise",
  "Fundraising events",
  "Liquidity events",
  "FAQ",
] as const;

type Section = (typeof SECTIONS)[number];

const Content = ({
  activeSection,
  onEmployeePortalNavigationRequested,
}: {
  activeSection: Section;
  onEmployeePortalNavigationRequested: (section: SectionAnchor) => void;
}) => {
  switch (activeSection) {
    case "Exercise":
      return <Exercise />;
    case "FAQ":
      return (
        <FAQ
          onEmployeePortalNavigationRequested={
            onEmployeePortalNavigationRequested
          }
        />
      );
    case "Fundraising events":
      return <FundraisingEvents />;
    case "Liquidity events":
      return <LiquidityEvents />;
    case "Stock options":
      return <StockOptions />;
    case "Vesting schedule":
      return <VestingSchedule />;
  }
};

const Equity101Drawer: React.FC<{
  onClose: () => void;
  show: boolean;
}> = ({ onClose, show }) => {
  const [activeSection, setActiveSection] = useState<Section>("Stock options");
  const nextSection = SECTIONS[SECTIONS.indexOf(activeSection) + 1];

  const handleNavigationButtonClick = useCallback(
    (section: Section) => () => {
      setActiveSection(section);
    },
    [],
  );

  const handleEmployeePortalNavigationRequested = useCallback(
    (section: SectionAnchor) => {
      onClose();
      scrollToEmployeePortalSection(section);
    },
    [onClose],
  );

  const resetState = useCallback(() => {
    setActiveSection("Stock options");
  }, []);

  return (
    <SlideOver
      header={<SlideOver.Header onClose={onClose}>Equity 101</SlideOver.Header>}
      onClose={onClose}
      onExited={resetState}
      show={show}
      width={904}
    >
      <div className="grid h-full grid-cols-[248px_1fr]">
        <div className="h-full overflow-y-auto border-r border-grey-200 bg-background-subtle p-4">
          {SECTIONS.map((section) => (
            <NavigationButton
              active={activeSection === section}
              key={section}
              onClick={handleNavigationButtonClick(section)}
            >
              {section}
            </NavigationButton>
          ))}
        </div>
        <div className="h-full overflow-y-auto p-10">
          <AnimatePresence mode="wait">
            <motion.div
              animate={{ opacity: 1, scale: 1 }}
              className="space-y-10"
              exit={{ opacity: 0, scale: 0.98 }}
              initial={{ opacity: 0, scale: 1.02 }}
              key={activeSection}
              transition={{ duration: 0.1 }}
            >
              <Content
                activeSection={activeSection}
                onEmployeePortalNavigationRequested={
                  handleEmployeePortalNavigationRequested
                }
              />
              {nextSection && (
                <div className="flex w-full items-center justify-end gap-4 text-XS text-grey-600">
                  <span>Next is {nextSection}</span>
                  <Button
                    onClick={handleNavigationButtonClick(nextSection)}
                    size="sm"
                  >
                    Next
                  </Button>
                </div>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </SlideOver>
  );
};

export const Equity101DrawerRemote = makeRemoteController<
  Omit<React.ComponentProps<typeof Equity101Drawer>, "onClose" | "show">
>({
  render: ({ close, state }) => {
    if (!state.data) {
      return null;
    }

    const props = {
      ...state.data,
      onClose: () => {
        close();
      },
    };

    return <Equity101Drawer show={state.show} {...props} />;
  },
});
