import { Pill } from "@remote-com/norma";
import React from "react";

import { Article, Paragraph } from "./Utils";

export function LiquidityEvents() {
  return (
    <Article title="You are probably wondering when you will eventually convert these options into cash 😉">
      <Paragraph>
        Now that you own a slice of the company, you will need to wait for an
        event when someone else can buy you this slice, be it:
      </Paragraph>
      <div className="space-y-2">
        <EventCard
          tag={<Pill tone="success">Most frequent</Pill>}
          title="Acquisition (exit)"
        >
          If your company is sold to another company, meaning the other company
          will buy all the shares from all shareholders, including you
        </EventCard>
        <EventCard
          tag={<Pill tone="blue">Second most frequent</Pill>}
          title="Secondary sale"
        >
          If some other companies or people are interested in buying a share of
          your company and your company has authorized you to sell your shares*
        </EventCard>
        <EventCard tag={<Pill tone="bayoux">Less frequent</Pill>} title="IPO">
          If your company goes public, meaning that people can publicly buy and
          sell its shares on a stock market (e.g. NYSE, Nasdaq)
        </EventCard>
      </div>
    </Article>
  );
}

function EventCard({
  children,
  tag,
  title,
}: React.PropsWithChildren<{
  tag: React.ReactNode;
  title: React.ReactNode;
}>) {
  return (
    <article className="space-y-2 rounded-remote-sm border-[0.5px] border-grey-300 p-4 shadow-100">
      <div className="flex items-center gap-4">
        <h3 className="text-XL/Medium">{title}</h3>
        {tag}
      </div>
      <p className="text-SM text-grey-900">{children}</p>
    </article>
  );
}
