/**
 * @generated SignedSource<<2b8cd9430fa9159567f2a7e4c736adf8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BasicApplicationSidebar_Organization$data = {
  readonly id: string;
  readonly isConnectedToCarta: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationSideBar_Organization">;
  readonly " $fragmentType": "BasicApplicationSidebar_Organization";
};
export type BasicApplicationSidebar_Organization$key = {
  readonly " $data"?: BasicApplicationSidebar_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BasicApplicationSidebar_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BasicApplicationSidebar_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToCarta",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplicationSideBar_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "78de3e5dba73a5930866160e4a9f62c0";

export default node;
