import { Pill } from "@remote-com/norma";
import classNames from "classnames";
import {
  addMonths,
  addYears,
  differenceInMonths,
  differenceInYears,
  formatDate,
  isBefore,
  isSameMonth,
  isSameYear,
} from "date-fns";
import _, { mapValues, maxBy, minBy, sumBy } from "lodash";
import { ComponentProps, useCallback, useMemo } from "react";
import { FormattedDate } from "react-intl";
import { graphql, useFragment } from "react-relay";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { FormattedCurrency } from "../../components/Formatted/FormattedCurrency";
import {
  RoundedBarShape,
  RoundedBarShapeProps,
} from "../../components/ui/RoundedBarShape";
import { Tag } from "../../components/ui/Tag";
import { Typography } from "../../components/ui/Typography";
import { formatCurrency, formatNumber } from "../../helpers/formatter";
import { useCtmsGrantAndSharesToNetEquityValue } from "../../hooks/useCtmsGrantAndSharesToNetEquityValue";
import {
  EmployeePortalVestingGraph_Grantee$data,
  EmployeePortalVestingGraph_Grantee$key,
} from "./__generated__/EmployeePortalVestingGraph_Grantee.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment EmployeePortalVestingGraph_Grantee on Grantee {
    ctmsGrants(
      grantStatusIn: [Active, Terminated]
      orderBy: { field: vestingStartDate, direction: ASC }
    ) {
      label
      # eslint-disable-next-line relay/unused-fields
      exercisePrice
      vestingDataPoints {
        cumulativeVested
        date
      }
    }
    organization {
      granteePortalSettings {
        displayFullyDilutedValues
      }
      ...useCtmsGrantAndSharesToNetEquityValue_Organization
    }
  }
`;

type PillTone = ComponentProps<typeof Pill>["tone"];

export const VESTING_SCHEDULE_GRANTS_STYLE: {
  fillColor: string;
  pillTone: PillTone;
  textColor: string;
}[] = [
  {
    fillColor: classNames("fill-brand-600"),
    pillTone: "blue",
    textColor: classNames("text-brand-600"),
  },
  {
    fillColor: classNames("fill-red-600"),
    pillTone: "error",
    textColor: classNames("text-red-600"),
  },
  {
    fillColor: classNames("fill-purple-600"),
    pillTone: "purple",
    textColor: classNames("text-purple-600"),
  },
  {
    fillColor: classNames("fill-green-600"),
    pillTone: "success",
    textColor: classNames("text-green-600"),
  },
  {
    fillColor: classNames("fill-pink-600"),
    pillTone: "pink",
    textColor: classNames("text-pink-600"),
  },
  {
    fillColor: classNames("fill-cyan-600"),
    pillTone: "cyan",
    textColor: classNames("text-cyan-600"),
  },
];

type GROUP_BY = "month" | "year";

const buildEmptyVestingEventsFromDates = ({
  groupBy,
  maximumVestingEventDate,
  minimumVestingEventDate,
}: {
  groupBy: GROUP_BY;
  maximumVestingEventDate: string;
  minimumVestingEventDate: string;
}): { date: string }[] => {
  switch (groupBy) {
    case "month":
      return Array.from(
        {
          length:
            differenceInMonths(
              maximumVestingEventDate,
              minimumVestingEventDate,
            ) + 1,
        },
        (_, index) => ({
          date: formatDate(
            addMonths(minimumVestingEventDate, index),
            "yyyy-MM",
          ),
        }),
      );
    case "year":
      return Array.from(
        {
          length:
            differenceInYears(
              maximumVestingEventDate,
              minimumVestingEventDate,
            ) + 1,
        },
        (_, index) => ({
          date: formatDate(addYears(minimumVestingEventDate, index), "yyyy"),
        }),
      );
  }
};

const getCumulativeVestedAtDatePerGrant = ({
  date,
  grantee,
  groupBy,
}: {
  date: string;
  grantee: EmployeePortalVestingGraph_Grantee$data;
  groupBy: GROUP_BY;
}): Record<string, { ctmsGrant: CTMSGrant; cumulativeVested: number }> => {
  switch (groupBy) {
    case "month":
      return grantee.ctmsGrants.reduce((acc, ctmsGrant) => {
        const vestingDataPoint = ctmsGrant.vestingDataPoints.findLast(
          (dataPoint) =>
            isSameMonth(dataPoint.date, date) || isBefore(dataPoint.date, date),
        );
        return {
          ...acc,
          [ctmsGrant.label]: {
            ctmsGrant,
            cumulativeVested: vestingDataPoint?.cumulativeVested || 0,
          },
        };
      }, {});
    case "year":
      return grantee.ctmsGrants.reduce((acc, ctmsGrant) => {
        const vestingDataPoint = ctmsGrant.vestingDataPoints.findLast(
          (dataPoint) =>
            isSameYear(dataPoint.date, date) || isBefore(dataPoint.date, date),
        );
        return {
          ...acc,
          [ctmsGrant.label]: {
            ctmsGrant,
            cumulativeVested: vestingDataPoint?.cumulativeVested || 0,
          },
        };
      }, {});
  }
};

type CTMSGrant = EmployeePortalVestingGraph_Grantee$data["ctmsGrants"][number];

export const EmployeePortalVestingGraph: React.FC<{
  granteeFragment: EmployeePortalVestingGraph_Grantee$key;
  groupBy: GROUP_BY;
  rounded?: boolean;
  valuationMultiple: number;
  view: "cumulative" | "split";
}> = ({ granteeFragment, groupBy, rounded, valuationMultiple, view }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const displayValuation =
    grantee.organization.granteePortalSettings.displayFullyDilutedValues;

  const { ctmsGrantAndSharesToNetEquityValue } =
    useCtmsGrantAndSharesToNetEquityValue({
      organizationFragment: grantee.organization,
      valuationMultiple,
    });

  const convertSharesToGraphData = useCallback(
    ({ ctmsGrant, shares }: { ctmsGrant: CTMSGrant; shares: number }) =>
      displayValuation
        ? ctmsGrantAndSharesToNetEquityValue({ ctmsGrant, shares })
        : shares,
    [ctmsGrantAndSharesToNetEquityValue, displayValuation],
  );

  const vestingEvents = useMemo(() => {
    const allVestingEventsDate = _(grantee.ctmsGrants)
      .flatMap((ctmsGrant) => ctmsGrant.vestingDataPoints)
      .map((dataPoint) =>
        dataPoint.cumulativeVested > 0 ? dataPoint.date : null,
      )
      .compact()
      .value();
    const minimumVestingEventDate = minBy(allVestingEventsDate, (date) =>
      new Date(date).getTime(),
    );
    const maximumVestingEventDate = maxBy(allVestingEventsDate, (date) =>
      new Date(date).getTime(),
    );

    if (!minimumVestingEventDate || !maximumVestingEventDate) {
      return [];
    }

    const emptyVestingEvents = buildEmptyVestingEventsFromDates({
      groupBy,
      maximumVestingEventDate,
      minimumVestingEventDate,
    });

    return emptyVestingEvents.map(({ date }) => {
      const cumulativeVestedAtDatePerGrants = getCumulativeVestedAtDatePerGrant(
        {
          date,
          grantee,
          groupBy,
        },
      );

      return {
        date,
        vested: sumBy(
          Object.values(cumulativeVestedAtDatePerGrants),
          ({ ctmsGrant, cumulativeVested }) =>
            convertSharesToGraphData({
              ctmsGrant,
              shares: cumulativeVested,
            }) ?? 0,
        ),
        ...mapValues(
          cumulativeVestedAtDatePerGrants,
          ({ ctmsGrant, cumulativeVested }) =>
            convertSharesToGraphData({
              ctmsGrant,
              shares: cumulativeVested,
            }) ?? 0,
        ),
      };
    });
  }, [convertSharesToGraphData, groupBy, grantee]);

  const today = formatDate(new Date(), "yyyy-MM");

  return (
    <ResponsiveContainer height="100%" width="100%">
      <BarChart data={vestingEvents} margin={{ bottom: 5, top: 20 }}>
        <CartesianGrid strokeDasharray="2 4" vertical={false} />
        <XAxis
          axisLine={false}
          dataKey="date"
          padding={{ left: 70, right: 20 }}
          tick={(props) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const date: string = props.payload.value;
            return (
              <Text
                {...props}
                className="font-regularSmall translate-y-3 fill-grey-500"
              >
                {formatDate(date, "yyyy")}
              </Text>
            );
          }}
          tickLine={false}
        />
        <YAxis
          axisLine={false}
          mirror={true}
          tick={(props: { payload: { value: number } }) => (
            <Text
              {...props}
              className="font-regularSmall -translate-x-2 -translate-y-3 transform fill-grey-500"
            >
              {displayValuation
                ? formatCurrency(props.payload.value, {
                    compactDisplay: "short",
                    maximumSignificantDigits: 3,
                    notation: "compact",
                  })
                : formatNumber(props.payload.value)}
            </Text>
          )}
          tickLine={false}
        />

        <Tooltip
          content={({ active, payload }) => {
            if (!active || !payload?.[0]) return null;

            const data = payload[0].payload as { date: string; vested: number };

            return (
              <div className="rounded bg-black-07 px-4 py-2">
                <Typography
                  as="div"
                  className="capitalize text-white"
                  variant="Regular/Extra Small"
                >
                  <FormattedDate
                    month="long"
                    value={data.date}
                    year="numeric"
                  />
                </Typography>

                {view === "cumulative" ? (
                  <Typography
                    as="div"
                    className="mt-1 text-white"
                    variant="Regular/Caption"
                  >
                    <span className="text-purple-03">All grants:</span>
                    &nbsp;
                    <FormattedCurrency
                      maximumFractionDigits={0}
                      value={data.vested}
                    />
                  </Typography>
                ) : (
                  payload

                    .filter((vestingDataPoints) => vestingDataPoints.value)
                    .map((vestingDataPoints, index) => (
                      <Typography
                        as="div"
                        className="mt-1 text-white"
                        key={vestingDataPoints.name}
                        variant="Regular/Caption"
                      >
                        <span
                          className={
                            VESTING_SCHEDULE_GRANTS_STYLE[
                              (grantee.ctmsGrants.length - index - 1) %
                                VESTING_SCHEDULE_GRANTS_STYLE.length
                            ]?.textColor
                          }
                        >
                          {vestingDataPoints.name}:
                        </span>
                        &nbsp;
                        <FormattedCurrency
                          maximumFractionDigits={0}
                          value={Number(vestingDataPoints.value)}
                        />
                      </Typography>
                    ))
                    .toReversed()
                )}
              </div>
            );
          }}
          cursor={false}
        />

        {view === "cumulative" && (
          <Bar
            className="fill-purple-700"
            dataKey="vested"
            shape={
              rounded
                ? (props: unknown) => (
                    <RoundedBarShape {...(props as RoundedBarShapeProps)} />
                  )
                : undefined
            }
          />
        )}
        {view === "split" &&
          grantee.ctmsGrants
            .toReversed()
            .map((ctmsGrant, index) => (
              <Bar
                className={
                  VESTING_SCHEDULE_GRANTS_STYLE[
                    (grantee.ctmsGrants.length - index - 1) %
                      VESTING_SCHEDULE_GRANTS_STYLE.length
                  ]?.fillColor
                }
                dataKey={ctmsGrant.label}
                key={ctmsGrant.label}
                shape={
                  rounded
                    ? (props: unknown) => (
                        <RoundedBarShape {...(props as RoundedBarShapeProps)} />
                      )
                    : undefined
                }
                stackId="__stackId"
              />
            ))}

        <ReferenceLine
          isFront
          label={({ viewBox }: { viewBox: { x: number; y: number } }) => {
            return (
              <foreignObject
                className={`h-7 w-[52px]`}
                x={viewBox.x - 26}
                y={viewBox.y - 20}
              >
                <Tag color="gray">Today</Tag>
              </foreignObject>
            );
          }}
          stroke="#626D7D"
          x={today}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
