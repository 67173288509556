/**
 * @generated SignedSource<<5ec84773fc7e134a8291b79ce6f54c6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostTerminationExercisePeriod_Query$variables = {
  organizationId: string;
};
export type PostTerminationExercisePeriod_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"PostTerminationExercisePeriod_Viewer">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"PostTerminationExercisePeriod_Organization">;
  } | null;
};
export type PostTerminationExercisePeriod_Query = {
  response: PostTerminationExercisePeriod_Query$data;
  variables: PostTerminationExercisePeriod_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PostTerminationExercisePeriod_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PostTerminationExercisePeriod_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PostTerminationExercisePeriod_Viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PostTerminationExercisePeriod_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GranteesConnection",
            "kind": "LinkedField",
            "name": "grantees",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GranteeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Grantee",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "grantStatusIn",
                            "value": [
                              "Active"
                            ]
                          }
                        ],
                        "concreteType": "CTMSGrant",
                        "kind": "LinkedField",
                        "name": "ctmsGrants",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ActivePostTerminationExercisePeriod",
                            "kind": "LinkedField",
                            "name": "activePostTerminationExercisePeriod",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PostTerminationExercisePeriod",
                                "kind": "LinkedField",
                                "name": "value",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "duration",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "unit",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": "ctmsGrants(grantStatusIn:[\"Active\"])"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToPulley",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToAngelList",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5f5c7d44c9be65e02b93eeb0bf501bb4",
    "id": null,
    "metadata": {},
    "name": "PostTerminationExercisePeriod_Query",
    "operationKind": "query",
    "text": "query PostTerminationExercisePeriod_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    name\n    id\n    ...PostTerminationExercisePeriod_Organization\n  }\n  me {\n    ...PostTerminationExercisePeriod_Viewer\n    id\n  }\n}\n\nfragment BookADemoSlideOver_Viewer on Account {\n  email\n  firstName\n  lastName\n}\n\nfragment GranteesListSlideOver_Organization on Organization {\n  id\n}\n\nfragment InsightNotAvailableBox_Organization on Organization {\n  name\n  ...useOrganizationCTMS_Organization\n}\n\nfragment InsightNotAvailableBox_Viewer on Account {\n  ...BookADemoSlideOver_Viewer\n}\n\nfragment PostTerminationExercisePeriodGraph_Organization on Organization {\n  grantees {\n    edges {\n      node {\n        id\n        status\n        ctmsGrants(grantStatusIn: [Active]) {\n          activePostTerminationExercisePeriod {\n            value {\n              duration\n              unit\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n  ...GranteesListSlideOver_Organization\n  ...InsightNotAvailableBox_Organization\n}\n\nfragment PostTerminationExercisePeriodGraph_Viewer on Account {\n  ...InsightNotAvailableBox_Viewer\n}\n\nfragment PostTerminationExercisePeriod_Organization on Organization {\n  id\n  name\n  ...PostTerminationExercisePeriodGraph_Organization\n}\n\nfragment PostTerminationExercisePeriod_Viewer on Account {\n  ...PostTerminationExercisePeriodGraph_Viewer\n}\n\nfragment useOrganizationCTMS_Organization on Organization {\n  isConnectedToCarta\n  isConnectedToPulley\n  isConnectedToAngelList\n}\n"
  }
};
})();

(node as any).hash = "2cbe3b8f03f8d69ff784d5c3278ac688";

export default node;
